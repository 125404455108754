import React, { Component } from 'react';
import { UncontrolledCollapse, Row, Col, Card, Label, Input, Button } from 'reactstrap';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { getConvertedDateToHotelTimezone, copyToClipboard } from '../Base/Common/ReferenceDataFunctions';
import Authorization from '../Base/Authorization';
import ReservationLog from './ReservationLog';
import moment from "moment";
import CustomToolTip from '../../Utils/CustomToolTip';
import { ReservationPayment } from './ReservationPayment';

class ReservationDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roomColumnsSize: 0
        }
    }


    componentDidMount() {
        this.countRoomColumns();
    }

    countRoomColumns = () => {
        let maxColumns = 0

        this.props.data.hotelReservation.forEach(item => {
            if (!global.isMobile) {
                let totalColumns = 5;

                totalColumns += (this.props.data.experiences?.length > 0 ? 1 : 0) + (item.amountCommission ? 1 : 0) +
                    (item.mealPlan ? 1 : 0) +(item.arrivalTime ? 1 : 0) + (item.departureTime ? 1 : 0) +
                    (this.props.isNone && item.hotelRateCode.filter(hrc => hrc.rateCodeChannelId === null).length > 0 ? 1 : 0)

                if (totalColumns > maxColumns) maxColumns = totalColumns;
            }
            else {
                let totalColumns = 2;

                totalColumns += (item.departureTime ? 1 : 0) + (this.props.isNone && item.hotelRateCode.filter(hrc => hrc.rateCodeChannelId === null).length > 0 ? 1 : 0)

                if (totalColumns > maxColumns) maxColumns = totalColumns;
            }
        });

        this.setState({ roomColumnsSize: 97 / maxColumns });
    }

    render() {
        const { data, isNone, toggleRemapReservationModal, isHotelBeds, rateCodes, isBookingSpaceBeds, isSendRoomRate, intl, addPayment, updateRefunds, updatePayment, checkPayment,
            hidePmsData } = this.props;


        return (
            data ?
                <Row>
                    <Col className="col-12 px-0 px-lg-3">
                        <Row className="pb-0">
                            <Col className="col-lg-3 col-6 mt-2">
                                <Label for="channel">
                                    <FormattedMessage id="ReservationDetail.Channel"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="channel"
                                    id="channel"
                                    bsSize="sm"
                                    className="text-sm"
                                    disabled
                                    defaultValue={data.channelConfigName}
                                />
                            </Col>
                            <Col className="col-lg-3 col-6 mt-2">
                                <Label className="text-center" for="source">
                                    <FormattedMessage id="ReservationList.Source"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="source"
                                    bsSize="sm"
                                    className="text-sm"
                                    disabled
                                    defaultValue={data.source}
                                />
                            </Col>
                            <Col className="col-6 mt-2">
                                <Label for="amount">
                                    <FormattedMessage id="ReservationDetail.ReservationAmount"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    bsSize="sm"
                                    className="text-sm"
                                    disabled
                                    defaultValue={intl.formatNumber(data.amountAfterTax, {style: 'currency', currency: data.currencyCode ?? global.hotelCurrency})}
                                />
                            </Col>
                            {data.amountCommission ?
                                <Col className="col-6 mt-2">
                                    <Label for="Country">
                                        <FormattedMessage id="ReservationDetail.ReservationCommission"/>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="Country"
                                        id="Country"
                                        bsSize="sm"
                                        className="text-sm"
                                        disabled
                                        defaultValue={intl.formatNumber(data.amountCommission, {style: 'currency', currency: data.currencyCode ?? global.hotelCurrency})}
                                    />
                                </Col>
                            :''}
                            <Col className="col-6 mt-2">
                                <Label for="cancelfee">
                                    <FormattedMessage id="ReservationDetail.CancelFee"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="cancelfee"
                                    id="cancelfee"
                                    bsSize="sm"
                                    className="text-sm"
                                    disabled
                                    defaultValue={data.cancelFee ? intl.formatNumber(data.cancelFee, {style: 'currency', currency: data.currencyCode ?? global.hotelCurrency}) : intl.formatNumber(0, { style: 'currency', currency: data.currencyCode ?? global.hotelCurrency})}
                                />
                            </Col>
                            <Col className="col-6 mt-2">
                                <Label for="guestCount">
                                    <FormattedMessage id="ReservationDetail.NumberOfGuests"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="guestCount"
                                    id="guestCount"
                                    bsSize="sm"
                                    className="text-sm"
                                    disabled
                                    defaultValue={data.guestCount}
                                />
                            </Col>
                            <Col className="col-6 mt-2">
                                <Label for="Type">
                                    <FormattedMessage id="ReservationDetail.ReservationDate"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="Type"
                                    id="Type"
                                    bsSize="sm"
                                    className="text-sm"
                                    disabled
                                    defaultValue={data.resIdDate ? getConvertedDateToHotelTimezone(data.resIdDate).format("YYYY-MM-DD HH:mm:ss") : ''}
                                />
                            </Col>
                            <Col className="col-6 mt-2">
                                <Label for="resIdLastUpdatedDate">
                                    <FormattedMessage id="ReservationDetail.ResIdLastUpdatedDate"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="resIdLastUpdatedDate"
                                    id="resIdLastUpdatedDate"
                                    bsSize="sm"
                                    className="text-sm"
                                    disabled
                                    defaultValue={data.resIdLastUpdatedDate ? getConvertedDateToHotelTimezone(data.resIdLastUpdatedDate).format("YYYY-MM-DD HH:mm:ss") : ''}
                                />
                            </Col>
                            <Col className="col-6 mt-2">
                                <Label for="resIdLastUpdatedDate">
                                    <FormattedMessage id="ReservationDetail.AmountPayed"/>
                                </Label>
                                <Input
                                    type="text"
                                    name="AmountPayed"
                                    id="AmountPayed"
                                    bsSize="sm"
                                    className="text-sm"
                                    disabled
                                    defaultValue={intl.formatNumber(data && data.amountPayed ? data.amountPayed : 0, {style: 'currency', currency: data.currencyCode ?? global.hotelCurrency})}
                                />
                            </Col>
                        </Row>
                    </Col>

                    {data.hotelReservation && data.hotelReservation.length > 0 ?
                        <Col className='mt-4 col-12 px-0 px-lg-3'>
                            <Row>
                                <Col>
                                    <h6>
                                        <span className="fas fa-concierge-bell mr-1" />
                                        <FormattedMessage id="ReservationDetail.RoomStays" />
                                    </h6>
                                </Col>
                                <Col className="text-right">
                                    {
                                        isNone || isSendRoomRate ?
                                            <Button className="btn btn-host btn-sm mr-1" onClick={toggleRemapReservationModal}>
                                                <FormattedMessage id="ReservationDetail.RemapReservation" />
                                            </Button>
                                            :
                                            isHotelBeds || isBookingSpaceBeds ?
                                                <Authorization
                                                    perform="reservation:remap"
                                                    yes={() => (
                                                        <Button className="btn btn-host btn-sm mr-1" onClick={toggleRemapReservationModal}>
                                                            <FormattedMessage id="ReservationDetail.RemapReservation" />
                                                        </Button>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                                :
                                                ''
                                    }
                                </Col>
                            </Row>
                            <div style={{ maxHeight: data.hotelReservation?.length > 3 ?'400px' : '', overflow: data.hotelReservation?.length > 3 ? 'auto' :'' }}>
                                {data.hotelReservation.map((item, key) => {
                                    const rateCodeChannelId = item.hotelRateCode.length > 0 ? item.hotelRateCode[0].rateCodeChannelId : null;
                                    const rateCode = rateCodes && rateCodeChannelId ? rateCodes.find(rc => rc.id === rateCodeChannelId) : null;

                                    const experience = data.experiences?.find(e => e.rateCodeId.toString() === item.hotelRateCode[0].ratePlanCode.toString());
                                    const expTranslation = experience?.translations.find(t => t.cultureCode === localStorage.getItem("locale")) || experience?.translations[0]
                                    
                                    return (
                                        <Card className="border-0 shadow mt-2 p-2 px-3" style={{ fontSize: '1em' }} key={key}>
                                            <div>
                                                <div>
                                                    <b>
                                                        <FormattedMessage id="ReservationDetail.Room"/>
                                                        <span className='ml-1'>{key + 1}</span>
                                                    </b>
                                                    <span>
                                                        <span className='mx-1'>-</span>
                                                        <span className='text-muted'>
                                                            #{item.numberOfRooms}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="py-2 d-flex align-items-center justify-content-between">
                                                    <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                        <div className='text-muted'>
                                                            <FormattedMessage id="ReservationDetail.RoomCatCode"/>
                                                        </div>
                                                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                            <span id={`SpanLocalRoomCodeUncontrolledTooltip${item.id}`} className="text-truncate">
                                                                {`${item.channelRoomTypeCode}`}
                                                            </span>
                                                            <CustomToolTip placement="bottom" target={`SpanLocalRoomCodeUncontrolledTooltip${item.id}`}>
                                                                {item.channelRoomTypeCode}
                                                                {rateCode ?
                                                                    <>
                                                                        <br /> <br />
                                                                        {rateCode.localRoomCode}
                                                                    </>
                                                                : '' }
                                                            </CustomToolTip>
                                                        </div>
                                                    </div>
                                                    {!global.isMobile ?
                                                        <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="ReservationDetail.RatePlanCode"/>
                                                            </div>
                                                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                                <span id={`SpanLocalRateCodeUncontrolledTooltip${item.id}`} className="text-truncate">
                                                                    {`${item.hotelRateCode && item.hotelRateCode.length > 0 && item.hotelRateCode[0].ratePlanCode}`}
                                                                </span>
                                                                <CustomToolTip placement="bottom" target={`SpanLocalRateCodeUncontrolledTooltip${item.id}`}>
                                                                    {item.hotelRateCode && item.hotelRateCode.length > 0 && item.hotelRateCode[0].ratePlanCode}
                                                                    <br/> <br/>
                                                                    {rateCode ?
                                                                        <>
                                                                            {rateCode.localRateCode}
                                                                        </>
                                                                        : ''}
                                                                </CustomToolTip>
                                                            </div>
                                                        </div>
                                                        : ''}

                                                    {!global.isMobile && data.experiences && data.experiences.length > 0 ?
                                                        <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="Experience.Experience" />
                                                            </div>
                                                            <div className="text-truncate">
                                                                {expTranslation?.title || '-'}
                                                            </div>
                                                        </div>
                                                        : ''}

                                                    <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                        <div className='text-muted'>
                                                            <FormattedMessage id="ReservationDetail.Amount" />
                                                        </div>
                                                        <div className="text-truncate">
                                                            {item.amountAfterTax ?
                                                                <FormattedNumber value={item.amountAfterTax} style={'currency'} currency={data.currencyCode ?? global.hotelCurrency} />
                                                                : '-'}
                                                        </div>
                                                    </div>
                                                    {item.amountCommission && !global.isMobile ?
                                                        <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="ReservationDetail.Commission"/>
                                                            </div>
                                                            <div className="text-truncate">
                                                                <FormattedNumber value={item.amountCommission} style={'currency'} currency={data.currencyCode ?? global.hotelCurrency}/>
                                                            </div>
                                                        </div>
                                                    :''}
                                                    {!global.isMobile ?
                                                        <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="ReservationDetail.Arrival"/>
                                                            </div>
                                                            <div className="text-truncate">
                                                                {item.checkIn ?
                                                                    moment(item.checkIn).format("YYYY-MM-DD")
                                                                :'-'}
                                                            </div>
                                                        </div>
                                                    :''}
                                                    {!global.isMobile ?
                                                        <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="ReservationDetail.Departure"/>
                                                            </div>
                                                            <div className="text-truncate">
                                                                {item.checkOut ?
                                                                    moment(item.checkOut).format("YYYY-MM-DD")
                                                                :'-'}
                                                            </div>
                                                        </div>
                                                    :''}
                                                    {item.mealPlan && !global.isMobile ?
                                                        <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                            <div className='text-muted mb-1'>
                                                                <FormattedMessage id="ReservationDetail.MealPlan"/>
                                                            </div>
                                                            <div className="text-truncate">
                                                                <FormattedMessage id={"OTA_MPT_" + item.mealPlan}/>
                                                            </div>
                                                        </div>
                                                    : ''}
                                                    {item.arrivalTime && !global.isMobile ?
                                                        <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="ReservationDetail.ArrivalTime"/>
                                                            </div>
                                                            <div className="text-truncate">
                                                                {item.arrivalTime}
                                                            </div>
                                                        </div>
                                                        :''}
                                                    {item.departureTime && !global.isMobile ?
                                                        <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="ReservationDetail.DepartureTime"/>
                                                            </div>
                                                            <div className="text-truncate">
                                                                {item.departureTime}
                                                            </div>
                                                        </div>
                                                    :''}
                                                    <div style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                        <div className='text-muted'>
                                                            <FormattedMessage id="ReservationDetail.Occupacion"/>
                                                        </div>
                                                        <div id={`Occupations${item.id}`} className="text-truncate">
                                                            {item.adults ?
                                                                <span>
                                                                    {item.adults}
                                                                    <i className="fas fa-user ml-1"/>
                                                                </span>
                                                            :''}
                                                            {item.children ?
                                                                <span className='ml-2'>
                                                                    {item.children}
                                                                    <i className="fas fa-child ml-1"/>
                                                                </span>
                                                            :''}
                                                            {item.infants ?
                                                                <span className='ml-2'>
                                                                    {item.infants}
                                                                    <i className="fas fa-baby ml-1"/>
                                                                </span>
                                                            :''}
                                                        </div>
                                                        <CustomToolTip placement="bottom" target={`Occupations${item.id}`}>
                                                            <div className='d-flex align-items-center'>
                                                                {item.adults > 0 ?
                                                                    <div>{item.adults} <FormattedMessage id="generic.adults"/></div>
                                                                : ''}
                                                                {item.children > 0 ?
                                                                    <div>{item.children} <FormattedMessage id="generic.children"/></div>
                                                                : ''}
                                                                {item.infants > 0 ?
                                                                    <div>{item.infants} <FormattedMessage id="generic.infants"/></div>
                                                                : ''}
                                                            </div>
                                                        </CustomToolTip>
                                                    </div>
                                                    {isNone && item.hotelRateCode.filter(hrc => hrc.rateCodeChannelId === null).length > 0 ?
                                                        <div className="d-flex align-items-center justify-content-center" style={{ width: `${this.state.roomColumnsSize}%` }}>
                                                            <div id={`NoMapping-${item.id}`}>
                                                                <i className="fas fa-exclamation-triangle text-warning"/>
                                                            </div>
                                                            <CustomToolTip placement="top" target={`NoMapping-${item.id}`}>
                                                                <FormattedMessage id="ReservationDetail.RoomStayHasNoMappings" />
                                                            </CustomToolTip>
                                                        </div>
                                                    :''}
                                                    <div className="d-flex align-items-center justify-content-end" style={{ width: `3%` }}>
                                                        <div className='cursor-pointer' id={'togglerdetails' + key}>
                                                            <i className="fas fa-chevron-down"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <UncontrolledCollapse toggler={'togglerdetails' + key}>
                                                <hr/>
                                                {global.isMobile ?
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="ReservationDetail.RatePlanCode"/>
                                                            </div>
                                                            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                                <span id={`SpanLocalRateCodeUncontrolledTooltip${item.id}`}>
                                                                    {`${item.hotelRateCode && item.hotelRateCode.length > 0 && item.hotelRateCode[0].ratePlanCode}`}
                                                                </span>
                                                                <CustomToolTip placement="bottom" target={`SpanLocalRateCodeUncontrolledTooltip${item.id}`}>
                                                                    {item.hotelRateCode && item.hotelRateCode.length > 0 && item.hotelRateCode[0].ratePlanCode}
                                                                    <br/> <br/>
                                                                    {rateCode ?
                                                                        <>
                                                                            {rateCode.localRateCode}
                                                                        </>
                                                                        : ''}
                                                                </CustomToolTip>
                                                            </div>
                                                        </div>
                                                        {item.amountCommission ?
                                                            <div>
                                                                <div className='text-muted'>
                                                                    <FormattedMessage id="ReservationDetail.Commission"/>
                                                                </div>
                                                                <div>
                                                                    <FormattedNumber value={item.amountCommission} style={'currency'} currency={data.currencyCode ?? global.hotelCurrency}/>
                                                                </div>
                                                            </div>
                                                        :''}
                                                        <div>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="ReservationDetail.Arrival"/>
                                                            </div>
                                                            <div>
                                                                {item.checkIn ?
                                                                    moment(item.checkIn).format("YYYY-MM-DD")
                                                                :'-'}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="ReservationDetail.Departure"/>
                                                            </div>
                                                            <div>
                                                                {item.checkOut ?
                                                                    moment(item.checkOut).format("YYYY-MM-DD")
                                                                :'-'}
                                                            </div>
                                                        </div>
                                                        {item.mealPlan ?
                                                            <div>
                                                                <div className='text-muted mb-1'>
                                                                    <FormattedMessage id="ReservationDetail.MealPlan"/>
                                                                </div>
                                                                <div>
                                                                    <FormattedMessage id={"OTA_MPT_" + item.mealPlan}/>
                                                                </div>
                                                            </div>
                                                        : ''}
                                                        {item.arrivalTime ?
                                                            <div>
                                                                <div className='text-muted'>
                                                                    <FormattedMessage id="ReservationDetail.ArrivalTime"/>
                                                                </div>
                                                                <div>
                                                                    {item.arrivalTime}
                                                                </div>
                                                            </div>
                                                            :''}
                                                        {item.departureTime ?
                                                            <div>
                                                                <div className='text-muted'>
                                                                    <FormattedMessage id="ReservationDetail.DepartureTime"/>
                                                                </div>
                                                                <div>
                                                                    {item.departureTime}
                                                                </div>
                                                            </div>
                                                        :''}
                                                    </div>
                                                :''}
                                                <Row>
                                                    <Col className="col-lg-6 col-12 mt-lg-0 mt-3">
                                                        <div className='text-muted mb-1'>
                                                            <FormattedMessage id="ReservationDetail.DailyPrice"/>
                                                        </div>
                                                        <div style={{ fontSize: '0.9em' }} className='d-flex align-items-center justify-content-between px-2'>
                                                            <div>
                                                                <FormattedMessage id="ReservationDetail.Date"/>
                                                            </div>
                                                            {item.hotelRateCode && item.hotelRateCode.find(el => el.availabilityGroupMapping) ?
                                                                <div>
                                                                    <FormattedMessage id="ReservationDetail.Contract"/>
                                                                </div>    
                                                            :''}
                                                            <div>
                                                                <FormattedMessage id="ReservationDetail.Value"/>
                                                            </div>
                                                        </div>
                                                        <div style={{ maxHeight: '100px', overflow: 'auto' }}>
                                                            {item.hotelRateCode && item.hotelRateCode.map((el, k1) =>
                                                                <div key={k1} className='px-2 pt-1 mt-1 d-flex align-items-center justify-content-between' style={{ borderTop: '1px solid #e5e5e5' }}>
                                                                    <div>
                                                                        {el.effectiveDate ?
                                                                            el.effectiveDate.substring(0, 10)
                                                                        :''}
                                                                    </div>
                                                                    {el.availabilityGroupMapping ?
                                                                        <div>
                                                                            {el.availabilityGroupMapping}
                                                                        </div>
                                                                    :''}
                                                                    <div>
                                                                        <FormattedNumber value={el.amountAfterTax} style={'currency'} currency={data.currencyCode ?? global.hotelCurrency}/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                    {item.servicesRphs && item.servicesRphs.length > 0 ?
                                                        <Col className="col-lg-6 col-12 mt-lg-0 mt-3">
                                                            <div className='text-muted mb-1'>
                                                                <FormattedMessage id="ReservationDetail.Services" />
                                                            </div>
                                                            <div className='px-2'>
                                                                <Row style={{ fontSize: '0.9em' }}>
                                                                    <Col><FormattedMessage id="ReservationDetail.ServiceCode" /></Col>
                                                                    <Col><FormattedMessage id="ReservationDetail.Service"/></Col>
                                                                    <Col><FormattedMessage id="ReservationDetail.Value"/></Col>
                                                                    <Col><FormattedMessage id="ReservationDetail.Type"/></Col>
                                                                    <Col><FormattedMessage id="ReservationDetail.Duration"/></Col>
                                                                    <Col><FormattedMessage id="ReservationDetail.Guests"/></Col>
                                                                </Row>
                                                                {item.servicesRphs.filter((rph) => rph).map((rs, key) => {
                                                                    const service = data.hotelService.find(({serviceRph}) => rs === serviceRph);
                                                                    return (
                                                                        service ?
                                                                            <Row key={key} className='mt-1 pt-1' style={{ borderTop: '1px solid #e5e5e5' }}>
                                                                                <Col>
                                                                                    {service.serviceCode ?? ""}
                                                                                </Col>
                                                                                <Col>
                                                                                    {service.description ?? ""}  
                                                                                </Col>
                                                                                <Col>
                                                                                    <FormattedNumber value={service.feeAmount} style="currency" currency={data.currencyCode ?? global.hotelCurrency} minimumFractionDigits={2}/>
                                                                                </Col>
                                                                                <Col>
                                                                                    {service.servicePricingType ?? ''}
                                                                                </Col>
                                                                                <Col>
                                                                                    {service.duration > 0 && (service.servicePricingType === "PerRoomPerNight" || service.servicePricingType === "PerPersonPerNight") ?
                                                                                        <>
                                                                                            <span className='mr-1'>{service.duration}</span>
                                                                                            <FormattedMessage id="ReservationDetail.Nights"/>
                                                                                        </>
                                                                                    :'-'}
                                                                                </Col>
                                                                                <Col>
                                                                                    {service.guestCount > 0 ? 
                                                                                        <span className='mr-1'>{service.guestCount}</span>
                                                                                    :'-'}
                                                                                </Col>
                                                                            </Row>
                                                                        :''
                                                                    )
                                                                })}
                                                            </div>
                                                        </Col>
                                                    :''}
                                                    {(item.specialRequest && item.specialRequest.length > 0) || (item.taxesCharges && item.taxesCharges.length > 0) ?
                                                        <Col className="col-lg-6 col-12 mt-lg-0 mt-3">
                                                            <div>
                                                                {(item.taxesCharges && item.taxesCharges.length > 0) ?
                                                                    <>
                                                                        <div className="text-muted">
                                                                            <FormattedMessage id="ReservationDetail.TaxesAndCharges"/>
                                                                        </div>
                                                                        <Row className="mt-1" style={{ fontSize: '0.9em' }}>
                                                                            <Col><FormattedMessage id={"ReservationDetail.TaxCode"}/></Col>
                                                                            <Col><FormattedMessage id={"ReservationDetail.ChargeFrequency"}/></Col>
                                                                            <Col><FormattedMessage id={"ReservationDetail.Description"}/></Col>
                                                                            <Col><FormattedMessage id={"ReservationDetail.Value"}/></Col>
                                                                            <Col><FormattedMessage id={"ReservationDetail.Type"}/></Col>
                                                                        </Row>
                                                                    </>
                                                                :''}
                                                                {item.taxesCharges && item.taxesCharges.map((sritem, srkey) =>
                                                                    <Row key={srkey} className="border-top mt-1 pt-1">
                                                                        <Col><FormattedMessage id={"generic.reservation.taxecode." + sritem.taxCode}/></Col>
                                                                        <Col><FormattedMessage id={"generic.reservation.chargefrequency." + sritem.chargeFrequency}/></Col>
                                                                        <Col>{sritem.description}</Col>
                                                                        <Col><FormattedNumber value={sritem.amount} style="currency" currency={sritem.currencyCode} minimumFractionDigits={2}/></Col>
                                                                        <Col>{sritem.type}</Col>
                                                                    </Row>
                                                                )}
                                                                {(item.specialRequest && item.specialRequest.length > 0) ?
                                                                    <div className="text-muted">
                                                                        <FormattedMessage id="ReservationDetail.NumSpecialRequests"/>
                                                                    </div>
                                                                :''}
                                                                {item.specialRequest.map((sritem, srkey) =>
                                                                    <Row className="border-top mt-1 pt-1" key={srkey}>
                                                                        {sritem.code ? <Col className="col-1">{sritem.code} </Col> : ''}
                                                                        <Col>{sritem.description}</Col>
                                                                    </Row>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        : ''}

                                                    {experience ?
                                                        <Col className="col-lg-6 col-12 mt-lg-3 mt-3">
                                                            <div className='text-muted mb-1'>
                                                                <FormattedMessage id="Experience.Experience" />
                                                            </div>
                                                            <Row style={{ fontSize: '0.9em' }} className='px-2'>
                                                                <Col>
                                                                    <FormattedMessage id="Experiences.Type" />
                                                                </Col>
                                                                <Col>
                                                                    <FormattedMessage id="CommonUIComponents.Title" />
                                                                </Col>
                                                                <Col>
                                                                    <FormattedMessage id="CommonUIComponents.Description" />
                                                                </Col>
                                                            </Row>
                                                            <Row className='px-2 pt-1 mt-1 ' style={{ borderTop: '1px solid #e5e5e5' }}>
                                                                <Col>
                                                                    <FormattedMessage id={`Experience.${experience.type}`} />
                                                                </Col>
                                                                <Col>
                                                                    {expTranslation.title}
                                                                </Col>
                                                                <Col>
                                                                    {expTranslation.description}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    : ''}
                                                </Row>
                                            </UncontrolledCollapse>
                                        </Card>
                                    );
                                })}
                            </div>
                        </Col>
                    :''}

                    {data.reservationProfile && data.reservationProfile.length > 0 ?
                        <Col className={`${data.hotelService && data.hotelService.length > 0 ? 'col-lg-6' : ''} col-12 mt-4 px-0 px-lg-3`}>
                            <div>
                                <h6>
                                    <span className="far fa-id-card mr-1" />
                                    <FormattedMessage id="ReservationDetail.Profiles" values={{ hospedes: data.reservationProfile && data.reservationProfile.length}} />
                                </h6>
                            </div>
                            <Row className="mt-1 py-2" style={{ flexWrap: 'nowrap', overflow: 'auto' }}>
                                {data.reservationProfile.sort(function (x, y) {
                                        return (x.isMainGuest === y.isMainGuest) ? 0 : x.isMainGuest ? -1 : 1;
                                    }).map((item, key) =>
                                    <Col key={key} className={`${data.hotelService && data.hotelService.length > 0 ? '' : 'col-lg-4'} col-9 h-auto`} style={{ borderRadius: '5px' }}>
                                        <Card className='p-3 shadow border-0 h-100' style={{ borderRadius: '5px', fontSize: '1em' }}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        {item.countryCode ?
                                                            <span style={{ borderRadius: '3px', fontSize: '12px' }} className={'mt-1 mr-1 flag-icon flag-icon-' + item.countryCode.toLowerCase()}/>
                                                        :''}
                                                        <h6 className='m-0'>
                                                            {!item.givenName && !item.surname ?
                                                                item.companyName
                                                            :
                                                                <>{item.givenName} {item.surname}</>
                                                            }
                                                        </h6>
                                                    </div>
                                                    {item.marketingConsent ?
                                                        <>
                                                            <span style={{ top: '7px', position: 'absolute', marginRight: '-15px', }} id="marketingConsent">
                                                                <i className="far fa-sm fa-envelope"></i>
                                                            </span>
                                                            <CustomToolTip placement="right" target={'marketingConsent'}>
                                                                <FormattedMessage id="ReservationDetail.marketingConsent"/>
                                                            </CustomToolTip>
                                                        </>
                                                    :''}
                                                </div>
                                                <div>
                                                    {item.isMainGuest ?
                                                        <i
                                                            className="fas fa-star fa-sm text-host"
                                                            style={{ position: 'absolute', top: '0', marginLeft: '-7px', marginTop: '5px' }}
                                                            title={intl.formatMessage({ id: "ManualReservation.MainGuest" })}
                                                        />
                                                    : ''}
                                                    {item.isNotGuest ?
                                                        <i
                                                            className="fas fa-user-slash"
                                                            style={{ position: 'absolute', top: '0', marginLeft: item.isMainGuest ? '24px' : '-13px', marginTop: '8px' }}
                                                            title={intl.formatMessage({ id: "ReservationDetail.notguest" })}
                                                        />
                                                    :''}
                                                    <span
                                                        title={(!item.profileType || item.profileType === "Person") ?
                                                            item.isChildren ?
                                                                intl.formatMessage({ id: "ReservationDetail.Child" })
                                                            : intl.formatMessage({ id: "ReservationDetail.Adult" })
                                                        : item.profileType === "Agency" ?
                                                            intl.formatMessage({ id: "ReservationDetail.Agency" })
                                                        : item.profileType === "Group" ?
                                                            intl.formatMessage({ id: "ReservationDetail.Group" })
                                                        : item.profileType === "Company" ?
                                                            intl.formatMessage({ id: "ReservationDetail.Company" })
                                                        : ''}
                                                        className={`fas fa-${
                                                            (!item.profileType || item.profileType === "Person") ?
                                                                item.isChildren ?
                                                                `child`
                                                                : `user`
                                                            : item.profileType === "Agency" ?
                                                                'plane'
                                                            : item.profileType === "Group" ?
                                                                'users'
                                                            : item.profileType === "Company" ?
                                                                'building'
                                                            : 'user'} fa-2x text-secondary`}
                                                    />
                                                </div>
                                            </div>

                                            {item.phoneNumber || item.email || item.loyaltyMemberId || item.externalId || item.addressLine ?
                                                <hr className='mt-0 mt-lg-3' />
                                            : ''}
                                            <Row>
                                                {item.phoneNumber && item.phoneNumber.length > 0 ?
                                                    <>
                                                        <Col className='col-3 pr-0 text-muted text-truncate' style={{ fontSize: '0.9em' }}>
                                                            <FormattedMessage id="ReservationDetail.Phone"/>
                                                        </Col>
                                                        <Col className={`col-9 text-truncate cursor-pointer`} onClick={_ => copyToClipboard(item.phoneNumber)} title={item.phoneNumber}>
                                                            {item.phoneNumber}
                                                        </Col>
                                                    </>
                                                :''}

                                                {item.email && item.email.length > 0 ?
                                                    <>
                                                        <Col className='col-3 mt-1 pr-0 text-muted text-truncate' style={{ fontSize: '0.9em' }}>
                                                            <FormattedMessage id="ReservationDetail.Email"/>
                                                        </Col>
                                                        <Col
                                                            className={`col-9 text-truncate cursor-pointer`}
                                                            onClick={_ => copyToClipboard(item.email)} title={item.email}>
                                                            {item.email}
                                                        </Col>
                                                    </>
                                                :''}

                                                {item.loyaltyMemberId || item.loyaltyProgram ?
                                                    <>
                                                        <Col className='col-3 mt-1 pr-0 text-muted text-truncate' style={{ fontSize: '0.9em' }}>
                                                            <FormattedMessage id="ReservationDetail.Loyalty"/>:
                                                        </Col>
                                                        <Col className='col-9 mt-1 text-truncate cursor-pointer' onClick={_ => copyToClipboard(item.loyaltyMemberId??item.loyaltyProgram)} title={item.loyaltyMemberId??item.loyaltyProgram}>
                                                            {item.loyaltyMemberId}
                                                            <span className='mx-1'>-</span>
                                                            {item.loyaltyProgram}
                                                        </Col>
                                                    </>
                                                :''}

                                                {item.externalId ?
                                                    <>
                                                        <Col className='col-3 mt-1 pr-0 text-muted text-truncate' style={{ fontSize: '0.9em' }}>
                                                            <FormattedMessage id="ReservationDetail.ExternalId" />:
                                                        </Col>
                                                        <Col className="col-9 mt-1 text-truncate cursor-pointer" onClick={_ => copyToClipboard(item.externalId)} title={item.externalId}>
                                                            {item.externalId}
                                                        </Col>
                                                    </>
                                                :''}
                                                
                                                {(item.addressLine && item.addressLine.length > 0) || (item.postalCode && item.postalCode.length > 0) || (item.cityName && item.cityName.length > 0) ?
                                                    <>
                                                        <Col className='col-3 mt-1 pr-0 text-muted text-truncate' style={{ fontSize: '0.9em' }}>
                                                            <FormattedMessage id="ReservationDetail.Address"/>:
                                                        </Col>
                                                        <Col
                                                            className={`col-9 text-truncate cursor-pointer`}
                                                            onClick={_ => copyToClipboard(
                                                                `${item.addressLine ? `${item.addressLine},` : ''}${item.cityName ? ` ${item.cityName} -` : ''}${item.postalCode ? ` ${item.postalCode}` : ''}`
                                                            )}
                                                            title={`${item.addressLine ? `${item.addressLine},` : ''}${item.cityName ? ` ${item.cityName} -` : ''}${item.postalCode ? ` ${item.postalCode}` : ''}`}
                                                        >
                                                            {`${item.addressLine ? `${item.addressLine},` : ''}${item.cityName ? ` ${item.cityName} -` : ''}${item.postalCode ? ` ${item.postalCode}` : ''}`}
                                                        </Col>
                                                    </>
                                                :''}
                                            </Row>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    :''}
                    
                    {data.hotelService && data.hotelService.length > 0 ?
                        <Col className="col-lg-6 col-12 mt-4 px-0 px-lg-3">
                            <div>
                                <h6>
                                    <span className="fas fa-hot-tub mr-1" />
                                    <FormattedMessage id="ReservationDetail.ReservationServices"/>
                                </h6>
                            </div>
                            <div className='shadow p-2 mt-3 px-4' style={{ overflow: global.isMobile ? 'auto' : ''}}>
                                <div className="d-flex align-items-center justify-content-between text-muted w-100" style={{ flexWrap: global.isMobile ? 'nowrap' : ''}}>
                                    <div style={{ minWidth: !global.isMobile ? '25%' : '35%' }}><FormattedMessage id="ReservationDetail.Room"/></div>
                                    <div style={{ minWidth: !global.isMobile ? '20%' : '25%' }}><FormattedMessage id="ReservationDetail.Service"/></div>
                                    <div style={{ minWidth: !global.isMobile ? '10%' : '20%' }}><FormattedMessage id="ReservationDetail.Value"/></div>
                                    <div style={{ minWidth: !global.isMobile ? '15%' : '25%' }}><FormattedMessage id="ReservationDetail.Type"/></div>
                                    <div style={{ minWidth: !global.isMobile ? '15%' : '25%' }}><FormattedMessage id="ReservationDetail.Duration"/></div>
                                    <div style={{ minWidth: !global.isMobile ? '15%' : '25%' }}><FormattedMessage id="ReservationDetail.Guests"/></div>
                                </div>
                                <div style={{ overflow: data.hotelService.length > 0 && !global.isMobile ? 'auto' : '', maxHeight: data.hotelService.length > 0 && !global.isMobile ? '120px' :'' }}>
                                    {data.hotelService.map((service, key) => {
                                        const room = data.hotelReservation.find(({servicesRphs}) => servicesRphs?.includes(service.serviceRph));
                                        const roomKey = room && (data.hotelReservation.findIndex(r => r.id === room.id) + 1);
                                        return (
                                            service ?
                                                <div key={key} className='w-100 mt-1 d-flex align-items-center justify-content-between' style={{ flexWrap: global.isMobile ? 'nowrap row' : ''}}>
                                                    <div style={{ borderTop: '1px solid #e5e5e5', minWidth: !global.isMobile ? '25%' : '35%' }} className={`pt-1 text-truncate`}
                                                    title={room ? `${intl.formatMessage({ id: "ReservationDetail.Room" })} ${roomKey} (${room.channelRoomTypeCode} - ${room.hotelRateCode?.length > 0 && room.hotelRateCode[0].ratePlanCode})` : ''}>
                                                        {room ?
                                                            <>
                                                                <FormattedMessage id="ReservationDetail.Room"/>
                                                                <span className='mx-1'>{roomKey}</span>
                                                                <span style={{ fonSize:'0.9em' }} className='text-muted'>
                                                                    ({`${room.channelRoomTypeCode} - ${room.hotelRateCode?.length > 0 && room.hotelRateCode[0].ratePlanCode}`})
                                                                </span>
                                                            </>
                                                        :<FormattedMessage id="ReservationDetail.Reservation"/>}
                                                    </div>
                                                    <div title={service.description ?? service.serviceCode} style={{ minWidth: !global.isMobile ? '20%' : '25%', borderTop: '1px solid #e5e5e5' }} onClick={_ => copyToClipboard(service.serviceCode)} className={`pt-1 text-truncate cursor-pointer`}>
                                                        {service.serviceCode ?? "-"} {service.description ?? ""}
                                                    </div>
                                                    <div style={{ borderTop: '1px solid #e5e5e5', minWidth: !global.isMobile ? '10%' : '20%' }} className={`text-truncate pt-1`}>
                                                        <FormattedNumber value={service.feeAmount} style="currency" currency={data.currencyCode ?? global.hotelCurrency} minimumFractionDigits={2}/>
                                                    </div>
                                                    <div style={{ borderTop: '1px solid #e5e5e5', minWidth: !global.isMobile ? '15%' : '25%' }} className={`text-truncate pt-1`}>
                                                        {service.servicePricingType ?? ''}
                                                    </div>
                                                    <div style={{ borderTop: '1px solid #e5e5e5', minWidth: !global.isMobile ? '15%' : '25%' }} className={`text-truncate pt-1`}>
                                                        {service.duration > 0 && (service.servicePricingType === "PerRoomPerNight" || service.servicePricingType === "PerPersonPerNight") ?
                                                            <>
                                                                <span className='mr-1'>{service.duration}</span>
                                                                <FormattedMessage id="ReservationDetail.Nights"/>
                                                            </>
                                                        :'-'}
                                                    </div>
                                                    <div style={{ borderTop: '1px solid #e5e5e5', minWidth: !global.isMobile ? '15%' : '25%' }} className={`text-truncate pt-1`}>
                                                        {service.guestCount > 0 ? 
                                                            <span className='mr-1'>{service.guestCount}</span>
                                                        :'-'}
                                                    </div>
                                                </div>
                                            :''
                                        )
                                    })}
                                </div>
                            </div>
                        </Col>
                    :''}

                    {data.reservationComments && data.reservationComments.length > 0 ?
                            <Col className="mt-4 col-12 px-0 px-lg-3">
                            <div>
                                <h6>
                                    <span className="far fa-comment mr-1" />
                                    <FormattedMessage id="ReservationDetail.Comments" />
                                </h6>
                            </div>
                            <div className='mt-3' style={{ fontSize: '1em' }}>
                                {data.reservationComments.map((item, key) =>
                                    <div style={{ borderRadius: '5px', maxHeight: '230px', overflow: 'auto' }} className={`mailboxballon shadow p-2 ${key ? 'mt-2' : ''}`} key={key}> {item.comment}</div>
                                )}
                            </div>
                        </Col>
                    :''}

                    {data.payments && data.payments.length > 0 ?
                        <Col className="col-12 mt-4 px-0 px-lg-3">
                            <div>
                                <h6>
                                    <i className="fas fa-dollar-sign mr-1"/>
                                    <FormattedMessage id="navbar.Payments"/>
                                </h6>
                            </div>
                            <div className='mt-3'>
                                {data.payments.map((payment, key) =>
                                    <ReservationPayment
                                        idx={key}
                                        checkPayment={checkPayment}
                                        currencyCode={data.currencyCode ?? global.hotelCurrency}
                                        payment={payment}
                                        updatePayment={updatePayment}
                                        updateRefunds={updateRefunds}
                                        addPayment={addPayment}
                                    />
                                )}
                            </div>
                        </Col>
                    :''}

                    <Col className="col-12 mb-4 px-0 px-lg-3" style={{ display: hidePmsData }}>
                        <ReservationLog
                            reservationId={data.id}
                            url={`/api/Price/Reservation/v1/Log/${data.id}`}
                        />
                    </Col>
                </Row>
            :''
        );
    }
}

export default injectIntl(ReservationDetailView);