import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Col, Row } from 'reactstrap';
import { getAPI } from "../Base/API";
import { ErrorAlert } from "../Common/ErrorAlert";
import { ChartLegend } from '../Base/Common/CommonUIComponents';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import ChartCard from "./Common/ChartCard";
import Chart from "react-google-charts";
import moment from 'moment';

class Payments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: false,
            paymentsAndRefunds: [],
            cancelationFees: [],
            totalPayments: 0,
            totalRefunds: 0,
            totalPotentials: 0,
            totalEffective: 0,
            paymentsAndRefundsAverages: [],
            cancelationFeesAverages: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.search === true && nextProps.search !== this.props.search) {
            this.getChartsData();
        }
    }

    componentDidMount() {
        this.getChartsData();
    }

    getChartsData = () => {
        this.setState({ block: true }, () => {

            getAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    const { dates } = this.props;

                    //Dates
                    let paymentsAndRefunds = [[this.props.intl.formatMessage({ id: "BEAnalitics.Date" }), this.props.intl.formatMessage({ id: "BEAnalitics.Payments" }), this.props.intl.formatMessage({ id: "BEAnalitics.Refunds" })]];
                    let cancelationFees = [[this.props.intl.formatMessage({ id: "BEAnalitics.Date" }), this.props.intl.formatMessage({ id: "BEAnalitics.Potentials" }), this.props.intl.formatMessage({ id: "BEAnalitics.Effective" })]];
                    
                    let paymentsAndRefundsAverages = [[this.props.intl.formatMessage({ id: "BEAnalitics.PaymentType" }), this.props.intl.formatMessage({ id: "BEAnalitics.Average" }), { role: 'style' }]];
                    let cancelationFeesAverages = [[this.props.intl.formatMessage({ id: "BEAnalitics.CancelFeeType" }), this.props.intl.formatMessage({ id: "BEAnalitics.Average" }), { role: 'style' }]];

                    let totalPayments = 0;
                    let totalRefunds = 0;
                    let totalPotentials = 0;
                    let totalEffective = 0;
                    let totalReservations = 0;

                    paymentsAndRefunds = paymentsAndRefunds.concat(dates.map(date => [date, 0, 0]));
                    cancelationFees = cancelationFees.concat(dates.map(date => [date, 0, 0]));
                    
                    paymentsAndRefundsAverages.push([this.props.intl.formatMessage({ id: "BEAnalitics.AveragePaymentsPerDay" }), 0, '#12b5cb']);
                    paymentsAndRefundsAverages.push([this.props.intl.formatMessage({ id: "BEAnalitics.AveragePaymentsPerReservation" }), 0, '#3e08dd']);
                    paymentsAndRefundsAverages.push([this.props.intl.formatMessage({ id: "BEAnalitics.AverageRefundsPerDay" }), 0, '#ffb703']);
                    paymentsAndRefundsAverages.push([this.props.intl.formatMessage({ id: "BEAnalitics.AverageRefundsPerReservation" }), 0, '#ff5703']);

                    cancelationFeesAverages.push([this.props.intl.formatMessage({ id: "BEAnalitics.AveragePotentialsPerDay" }), 0, '#12b5cb']);
                    cancelationFeesAverages.push([this.props.intl.formatMessage({ id: "BEAnalitics.AveragePotentialsPerReservation" }), 0, '#3e08dd']);
                    cancelationFeesAverages.push([this.props.intl.formatMessage({ id: "BEAnalitics.AverageEffectivePerDay" }), 0, '#ffb703']);
                    cancelationFeesAverages.push([this.props.intl.formatMessage({ id: "BEAnalitics.AverageEffectivePerReservation" }), 0, '#ff5703']);


                    data.response && data.response.length > 0 && data.response.forEach((date, idx) => {
                        const index = paymentsAndRefunds.findIndex(el => moment(el[0]).isSame(moment(date.value).format("YYYY-MM-DD")));

                        if (index > -1) {
                            date.subset && date.subset.length > 0 && date.subset.forEach(key => {
                                if(key.key === "TotalReservations" && key.value > 0) {
                                    totalReservations += key.value;
                                }else
                                if (key.key === "TotalPayment" && paymentsAndRefunds[index + 1]) {
                                    paymentsAndRefunds[index + 1][1] = key.value;
                                    totalPayments += key.value;
                                    
                                } else
                                if (key.key === "TotalRefund" && paymentsAndRefunds[index + 1]) {
                                    paymentsAndRefunds[index + 1][2] = key.value;
                                    totalRefunds += key.value;
                                } else
                                if (key.key === "TotalPreCheckInCancelationFees" && cancelationFees[index + 1]) {
                                    cancelationFees[index + 1][1] = key.value;
                                    totalPotentials += key.value;
                                } else
                                if (key.key === "TotalPostCheckInCancelationFees" && cancelationFees[index + 1]) {
                                    cancelationFees[index + 1][2] = key.value;
                                    totalEffective += key.value;
                                }
                            })
                        }

                        if(idx === data.response.length - 1) {
                            const paymentsAndRefundsLength = paymentsAndRefunds.length > 1 ? paymentsAndRefunds.length - 1 : 1;
                            const cancelationFeesLength = cancelationFees.length > 1 ? cancelationFees.length - 1 : 1;
                            
                            totalPayments = totalPayments ? parseFloat(totalPayments.toFixed(2)) : 0;
                            totalRefunds = totalRefunds ? parseFloat(totalRefunds.toFixed(2)) : 0;
                            totalPotentials = totalPotentials ? parseFloat(totalPotentials.toFixed(2)) : 0;
                            totalEffective = totalEffective ? parseFloat(totalEffective.toFixed(2)) : 0;

                            paymentsAndRefundsAverages[1][1] = parseFloat((totalPayments / paymentsAndRefundsLength)?.toFixed(2)); // Average Payments per day
                            paymentsAndRefundsAverages[2][1] = totalReservations && totalReservations > 0 ? parseFloat((totalPayments / totalReservations)?.toFixed(2)) : 0; // Average Payments per reservation
                            paymentsAndRefundsAverages[3][1] = parseFloat((totalRefunds / paymentsAndRefundsLength)?.toFixed(2)); // Average Refunds per day
                            paymentsAndRefundsAverages[4][1] = totalReservations && totalReservations > 0 ? parseFloat((totalRefunds / totalReservations)?.toFixed(2)) : 0; // Average Refunds per reservation

                            cancelationFeesAverages[1][1] = parseFloat((totalPotentials / cancelationFeesLength)?.toFixed(2)); // Average Potentials per day
                            cancelationFeesAverages[2][1] = totalReservations && totalReservations > 0 ? parseFloat((totalPotentials / totalReservations)?.toFixed(2)) : 0; // Average Potentials per reservation
                            cancelationFeesAverages[3][1] = parseFloat((totalEffective / cancelationFeesLength)?.toFixed(2)); // Average Effective per day
                            cancelationFeesAverages[4][1] = totalReservations && totalReservations > 0 ? parseFloat((totalEffective / totalReservations)?.toFixed(2)) : 0; // Average Effective per reservation
                        }
                    });

                    this.setState({ 
                        paymentsAndRefunds, 
                        cancelationFees, 
                        totalPayments,
                        totalRefunds,
                        totalPotentials,
                        totalEffective,
                        paymentsAndRefundsAverages,
                        cancelationFeesAverages,
                        block: false 
                    });
                }
            }, `/api/Price/Analytics/v1?fromDate=${moment(this.props.startDate).format("YYYY-MM-DD")}&toDate=${moment(this.props.endDate).format("YYYY-MM-DD")}&dataKeys=1&dataKeys=10&dataKeys=13&dataKeys=14&dataKeys=15&dataKeys=16`);
        });
    }

    render() {
        const { block, paymentsAndRefunds, cancelationFees, totalPayments, totalRefunds, totalPotentials, totalEffective, paymentsAndRefundsAverages, cancelationFeesAverages } = this.state;
        const { valuesCard } = this.props;

        return (
            <BlockUi  tag="div" blocking={block}>
                <ErrorAlert error={this.state.error} />
                <Row className='py-3'>
                    <Col>
                        {valuesCard(<FormattedMessage id="BEAnalitics.TotalPayments" />, `${totalPayments}€`)}
                    </Col>
                    <Col>
                        {valuesCard(<FormattedMessage id="BEAnalitics.TotalRefunds" />, `${totalRefunds}€`)}
                    </Col>
                    <Col>
                        {valuesCard(<FormattedMessage id="BEAnalitics.TotalPotentialCancelFees" />, `${totalPotentials}€`)}
                    </Col>
                    <Col>
                        {valuesCard(<FormattedMessage id="BEAnalitics.TotalEffectiveCancelFees" />, `${totalEffective}€`)}
                    </Col>
                </Row>
                <Row className='pb-3'>
                    <Col className='col-6'>
                        {paymentsAndRefundsAverages && paymentsAndRefundsAverages.length > 1 ?
                            <Card body className="shadow border-0 h-100 d-flex flex-column" >
                                <div>
                                    <Col className="p-0 text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="BEAnalitics.paymentsAndRefundsAverages" />
                                    </Col>
                                </div>
                                <Row className="pt-3">
                                    <Col>
                                        <Row>
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Payments" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                <FormattedMessage id="BEAnalitics.Amount" />
                                            </Col>
                                        </Row>
                                        <hr className="my-2" />
                                        <Row className="pb-2">
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Day" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                {paymentsAndRefundsAverages[1][1]}€
                                            </Col>
                                        </Row>
                                        <Row className="pb-2">
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Reservation" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                {paymentsAndRefundsAverages[2][1]}€
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Refunds" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                <FormattedMessage id="BEAnalitics.Amount" />
                                            </Col>
                                        </Row>
                                        <hr className="my-2" />
                                        <Row className="pb-2">
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Day" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                {paymentsAndRefundsAverages[3][1]}€
                                            </Col>
                                        </Row>
                                        <Row className="pb-2">
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Reservation" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                {paymentsAndRefundsAverages[4][1]}€
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        :''}
                    </Col>
                    <Col className='col-6'>
                        {cancelationFeesAverages && cancelationFeesAverages.length > 1 ?
                            <Card body className="shadow border-0 h-100 d-flex flex-column" >
                                <div>
                                    <Col className="p-0 text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="BEAnalitics.cancelationFeesAverages" />
                                    </Col>
                                </div>
                                <Row className="pt-3">
                                    <Col>
                                        <Row>
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Potentials" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                <FormattedMessage id="BEAnalitics.Amount" />
                                            </Col>
                                        </Row>
                                        <hr className="my-2" />
                                        <Row className="pb-2">
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Day" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                {cancelationFeesAverages[1][1]}€
                                            </Col>
                                        </Row>
                                        <Row className="pb-2">
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Reservation" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                {cancelationFeesAverages[2][1]}€
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Effective" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                <FormattedMessage id="BEAnalitics.Amount" />
                                            </Col>
                                        </Row>
                                        <hr className="my-2" />
                                        <Row className="pb-2">
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Day" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                {cancelationFeesAverages[3][1]}€
                                            </Col>
                                        </Row>
                                        <Row className="pb-2">
                                            <Col className="col-6">
                                                <FormattedMessage id="BEAnalitics.Reservation" />
                                            </Col>
                                            <Col className="col-6 text-right">
                                                {cancelationFeesAverages[4][1]}€
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        :''}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {paymentsAndRefunds && paymentsAndRefunds.length > 1 ?
                            <ChartCard
                                chartType='LineChart'
                                data={paymentsAndRefunds}
                                options={{ colors: ['#12b5cb', '#ffb703'] }}
                                title={<FormattedMessage id="BEAnalitics.PaymentsAndRefunds" />}
                            />
                        :''}
                    </Col>
                </Row>
                <Row className="py-3">
                    <Col>
                        {cancelationFees && cancelationFees.length > 1 ?
                            <ChartCard
                                chartType='LineChart'
                                data={cancelationFees}
                                options={{ colors: ['#12b5cb', '#ffb703'] }}
                                title={<FormattedMessage id="BEAnalitics.CancelationFees" />}
                            />
                        :''}
                    </Col>
                </Row>
            </BlockUi>
        )
    }
}

export default injectIntl(Payments);