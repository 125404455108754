import React, { Component } from 'react';
import { Form, Row, Col, Button, Badge, Card, InputGroupAddon, InputGroup, Input } from 'reactstrap';
import { getAPI, putAPI, deleteAPI } from "../../../Base/API";
import { handleNotification } from "../../../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { BlankCard, ReservationStatusBadge, SelectChannelInstanceByChannelCode } from '../../../Base/Common/CommonUIComponents';
import { DateRangePicker } from 'react-dates';
import ExpediaChatWindow from './ExpediaChatWindow';


const LastMsgCard = ({ lastMessage, traveler, isSelected, selectConversation }) => {
    const isMsgFromGuest = lastMessage.fromRole.toLowerCase() === 'traveler';

    return <Card className={"w-100 mb-1 mt-1 px-2 py-3 pointer " + (isSelected ? 'bg-disabled' : 'text-secondary')} onClick={selectConversation}>
        <Row>
            <Col className="col-8 text-truncate">
                <b>{isMsgFromGuest ? traveler.firstName + ' ' + traveler.lastName : 'property'}</b> 
            </Col>
            <Col className="col-4 text-right">
                {lastMessage.creationDateTimeUtc ?
                    moment(lastMessage.creationDateTimeUtc).format("YYYY-MM-DD") 
                : ''}
            </Col>
        </Row>

        {lastMessage.body ?
            <Row className="mt-2">
                <Col className="col-8 text-truncate">
                    {lastMessage.body.value ?
                        <span>{lastMessage.body.value}</span>
                        : <i>Automatic message of {lastMessage.type}</i>}
                </Col>
                <Col className="col-4 text-truncate d-flex align-items-center justify-content-end">
                    {isMsgFromGuest && lastMessage.body.value && 
                        <div style={{ height: '10px', width: '10px', backgroundColor: 'red', borderRadius: '10px' }}>  </div>
                    }
                </Col>
            </Row>
            : ''}
    </Card>
}

class ExpediaMailBoxCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            fromDate: moment().subtract(15, 'days'),
            toDate: moment(),
            channelInstanceList: [],
            selectedConnection: this.props.channelInstance || '',
            threads: null,
            selectedConversation: null,
            reservationData: null,
            reservationId: null
        };
    }

    getThreads = (event) => {
        if (event)
            event.preventDefault();


        let params = `?from=${moment(this.state.fromDate).format("YYYY-MM-DD")}&to=${moment(this.state.toDate).format("YYYY-MM-DD")}`;

        this.setState({ block: true, threads: null }, () => {
            getAPI(result => {
                const { data, error } = result;
                var errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0 || data.warnings && data.warnings.length > 0) {
                        handleNotification(data);
                        this.setState(({ block: false, error: errorMessage }));
                        return;
                    }

                    if (data.response && data.response.length > 0) {
                        var selectedConversation = this.state.selectedConversation;

                        if (this.state.selectedConversation?.id) {
                            selectedConversation = data.response[0].property?.messageThreads.elements.find(el => el.id === selectedConversation.id) ?? this.state.selectedConversation;
                        }

                        this.setState({ threads: data.response[0].property?.messageThreads?.elements, block: false, error: errorMessage, selectedConversation });
                        return;
                    }
                }
                this.setState({ block: false, threads: [] });
            }, `/api/Rate/MailBox/v1/expediaMessaging/${this.state.selectedConnection}/threads` + params);
        });
    }

    getReservationDetails = (reservationId) => {
        const { selectedConnection } = this.state;
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.data && data.data.length > 0) {
                this.setState({ block: false, reservationData: data.data[0] });
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Price/Reservation/v1/channelReserveId?channelInstanceId=${selectedConnection}&resId=${reservationId}`);
    }

    setComboStatus = (field, combo) => {
        this.setState({
            [field]: combo ? combo.value : null
        })
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState({ [name]: value })
    }

    doSearch = (evt) => {
        evt.preventDefault();
        this.setState({
            selectedConversation: null
        }, () => this.state.reservationId ? this.getConversationByReservation() : this.getThreads())
    }


    backgroundColor = (item) => {
        if (this.state.selectedConversation && this.state.selectedConversation.conversation.conversation_id === item.conversation_id) {
            return 'bg-disabled';
        }
        else {
            return 'text-secondary';
        }
    }

    setComboStatus = (field, combo) => {
        this.setState({
            [field]: combo ? combo.value : null
        })
    }

    selectConversation = (thread, reservationId) => {
        this.setState({
            selectedConversation: thread
        }, () => this.getReservationDetails(reservationId))
    }

    updateThread = (data, newMessage) => {
        const messageElements = [...this.state.selectedConversation.messages.elements];
        const threads = [...this.state.threads];

        const msgObject = {
            id: data?.sendMessage?.messageId,
            fromRole: "SUPPLIER",            
            type: "FREE_TEXT",
            creationDateTimeUtc: moment(),
            body: { value: newMessage }
        }

        //Update selectedConversation
        messageElements.push(msgObject);

        //Update message on thread
        const threadIndex = threads.findIndex(t => t.id === this.state.selectedConversation.id);

        if (threadIndex !== -1) {
            threads[threadIndex].messages.elements = messageElements;
        }


        this.setState({
            selectedConversation: {
                ...this.state.selectedConversation,
                messages: {
                    ...this.state.selectedConversation.messages,
                    elements: messageElements
                }
            }
        })

    }


    render() {
        const { block, error, reservationData, threads, fromDate, toDate, selectedConversation } = this.state;

        return (
            <BlankCard block={block} error={error}>
                <Form onSubmit={(e) => this.doSearch(e)}>
                    <Row className="align-items-center pb-3 mb-2 border-bottom">
                        <Col className="col-lg-3 pl-0">
                            <SelectChannelInstanceByChannelCode
                                name={'selectedConnection'}
                                icon={'fa fa-plug fa-fw'}
                                channelCode={'Expedia'}
                                onChangeFunc={this.setComboStatus}
                                value={this.state.selectedConnection}
                            />
                        </Col>
                        {/*<Col className="col-lg-3 pl-3">
                            <InputGroup size="sm text-right">
                                <InputGroupAddon addonType="prepend"><span className="input-group-text border-right-0"><span className="fas fa-key fa-fw"></span></span></InputGroupAddon>
                                <FormattedMessage id="ReservationList.ReservationId">
                                    {placeholder =>
                                        <Input className="border-left-0"
                                            type="text"
                                            name="reservationId"
                                            placeholder={placeholder}
                                            value={this.props.reservationId || ''}
                                            onChange={(e) => this.props.handleChange(e)}
                                        />
                                    }
                                </FormattedMessage>
                            </InputGroup>
                        </Col>*/}

                        <Col>
                            <DateRangePicker
                                required
                                startDate={fromDate ? moment(fromDate) : null}
                                startDateId="fromDate"
                                isOutsideRange={day => day > moment()}
                                endDate={toDate ? moment(toDate) : null}
                                endDateId="toDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                                focusedInput={this.state.focused}
                                onFocusChange={focusedInput => this.setState(({ focused: focusedInput }))}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-host shadow btn-sm" type="submit"><span className="fas fa-search"></span></Button>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col className="col-3 border-right scrollableDiv" style={{ overflowY: 'auto', maxHeight: '68vh' }}>
                        <div>
                            {threads?.map((item, key) =>
                                <Row key={key} >
                                    <Col className="nav-stacked col-12 pl-0" key={key}>
                                        <LastMsgCard
                                            traveler={item.primaryTraveler}
                                            lastMessage={item.messages.elements[item.messages.elements.length - 1]}
                                            isSelected={selectedConversation?.id === item.id}
                                            selectConversation={() => this.selectConversation(item, item.reservationSummary.id)}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>

                    {this.state.selectedConversation ?
                        <Col className="col-6 px-0" >
                            <ExpediaChatWindow
                                selectedConversation={this.state.selectedConversation}
                                selectedConnection={this.state.selectedConnection}
                                updateThread={this.updateThread}
                            />
                        </Col>
                        :
                        <Col className="col-6 d-flex align-items-center justify-content-center" >
                            <h6 style={{ textAlign: 'center' }}> <span className="ml-2 far fa-comments"> <FormattedMessage id="BookingMailBoxCard.SelectAConversation" /> </span > </h6>
                        </Col>
                    }

                    <Col className="col-3 border-left">
                        {this.state.selectedConversation ?
                            <Card body>
                                <b className="text-center mb-2"> <FormattedMessage id="BookingMailBoxCard.Reservation" /> {this.state.selectedConversation.reservationSummary.id} </b>
                                {reservationData ?
                                    <div>
                                        <Row>
                                            <Col>
                                                <b><FormattedMessage id="ManualReservation.MainGuest" />:</b> {reservationData.reservationProfile[0]?.givenName} {reservationData.reservationProfile[0].surname}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <b><FormattedMessage id="ReservationDashboard.Status" />:</b> <ReservationStatusBadge status={reservationData.status} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <b><FormattedMessage id="ManualReservation.CheckIn" />:</b> {reservationData.hotelReservation[0]?.checkIn ? moment(reservationData.hotelReservation[0].checkIn).format("YYYY-MM-DD") : ''}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <b><FormattedMessage id="ManualReservation.CheckOut" />:</b> {reservationData.hotelReservation[0]?.checkOut ? moment(reservationData.hotelReservation[0].checkOut).format("YYYY-MM-DD") : ''}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <b><FormattedMessage id="ReservationDetail.Amount" />:</b> {reservationData.amountAfterTax}
                                            </Col>
                                        </Row>
                                    </div>
                                : ''}
                            </Card>
                        : ''}
                    </Col>
                </Row>
            </BlankCard >
        );
    }
}


export default injectIntl(ExpediaMailBoxCard);