import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, Label, CardHeader, Button, CustomInput } from 'reactstrap';
import { getAPI, postAPI } from '../../Base/API';
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from "../../Base/Notification";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";

export class HotelChannelInfoDetail extends Component {

    constructor(props) {
        super(props);
        this.getChannelInstance = this.getChannelInstance.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
        this.handleActiveOrDeactiveChannelInstance = this.handleActiveOrDeactiveChannelInstance.bind(this);
        this.channelConfigStateForm = React.createRef();
        this.state = {
            block: true,
            channelInstance: {},
            availableOptions: []
        }
    }

    componentDidMount() {
        const { modalData, stateOptions } = this.props;
        const stateOptionsFiltered = stateOptions.filter(stateOption => modalData?.states && modalData.states.some(s => stateOption.value === s))
        const idx = modalData?.channelConfigState ? stateOptionsFiltered.findIndex(option => option.value === modalData.channelConfigState) : 0;

        if (idx < stateOptionsFiltered.length - 1 && (modalData.channelCode === "GOOGLEHOTELADS" || modalData.operationMode !== "PmsFull" || modalData.pmsType === 'BookingSpaceBeds' || (modalData.operationMode === "PmsFull" && modalData.sendCMRoomRate))) {
            const availableOptions = stateOptionsFiltered.slice(idx, modalData.channelConfigState ? idx + 2 : idx + 1);
            this.setState({ availableOptions: availableOptions });
        }

        this.getChannelInstance();
    }

    getChannelInstance() {
        if(!this.props.modalData?.channelInstanceId){
            this.setState({ block: false });
            return;
        }
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.length > 0) {
                const ciDto = {
                    id: data[0].id,
                    channelConfigState: data[0].channelConfigState,
                    channelConfigId: data[0].channelConfigId,
                    active: data[0].active
                }
                this.setState({ block: false, channelInstance: ciDto });
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/${this.props.modalData.channelInstanceId}`);
    }

    handleFieldChange = (name, combo) => {
        const value = combo ? combo.value : null;
        this.setState(prevState => (
            {
                channelInstance: {
                    ...prevState.channelInstance,
                    [name]: value
                }
            })
        );
    }    

    handleSave() {
        if (this.channelConfigStateForm.current.reportValidity()) {
            postAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ block: isLoading });
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                        this.setState(({ block: false, error: errorMessage }));
                    } else {
                        handleNotification(data, <FormattedMessage id="HotelChannelInfoDetail.SaveMessageSuccess" />, 'Success');
                        this.setState(({ block: false, channelInstance: data.response }));
                        this.props.toggleModal();
                    }                    
                }
            }, '/api/Rate/ChannelConfig/v1/ChannelInstance', JSON.stringify(this.state.channelInstance));
        }
    }

    handleStatus(evt) {
        if (evt && evt.target) {
            const { channelInstance } = this.state;
            const { name, checked } = evt.target;
            channelInstance[name] = checked;
            this.setState({ channelInstance });
        }
    }

    handleActiveOrDeactiveChannelInstance() {
        const { updateChannelStatus } = this.props;
        const { channelInstance } = this.state;

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="HotelChannelInfoDetail.SaveStatusSuccess" />, <FormattedMessage id="General.Success" />);
                    updateChannelStatus(channelInstance);
                }
            }
            this.setState({ block: false, error: errorMessage });
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/${channelInstance.id}/${channelInstance.active ? `Enable` : `Disable`}`);
    }

    render() {
        const { modal, toggleModal, modalData } = this.props;
        const { block, error, availableOptions, channelInstance } = this.state;

        return (
            <Modal isOpen={modal} toggle={toggleModal} fade={false} className="modal-lg">
                <ModalHeader toggle={toggleModal}>
                    <FormattedMessage id="HotelChannelInfoDetail.Title" />
                    {modalData && modalData.name}
                </ModalHeader>
                <ModalBody className="modal-lg px-0 pb-0">
                    <BlockUi tag="div" blocking={block}>
                        <ErrorAlert error={error} />
                        <Card className="shadow border-0 h-100">
                            <CardHeader>
                                <FormattedMessage id="HotelChannelInfoDetail.Intro" />
                                <strong>
                                    {modalData && modalData.propertyCode}
                                </strong>
                                <Button color=" btn-host btn-sm float-right" onClick={this.handleSave} disabled={availableOptions.length === 0}>
                                    <i className="fas fa-save" />
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <form ref={this.channelConfigStateForm}>
                                    <Row>
                                        <Col>
                                            <Label for="channelConfigState">
                                                <FormattedMessage id="HotelChannelInfoDetail.ChannelConfigState" />
                                            </Label>
                                        </Col>
                                        <Col>
                                            <CustomSelect name="channelConfigState" options={availableOptions} onChange={this.handleFieldChange.bind(this, 'channelConfigState')} value={availableOptions.find(s => s.value === channelInstance.channelConfigState)} required/>
                                        </Col>
                                    </Row>
                                    <hr className="mb-1" />
                                    <Row>
                                        <Col>
                                            <CustomInput
                                                id="active"
                                                name="active"
                                                type="switch"
                                                checked={channelInstance.active}
                                                onChange={this.handleStatus}
                                                label={<FormattedMessage id="HotelChannelInfo.Active" />}
                                            />
                                        </Col>
                                        <Col className="text-right">
                                            <Button color="btn btn-host btn-sm" onClick={this.handleActiveOrDeactiveChannelInstance}>
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}