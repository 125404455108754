import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from 'draftjs-to-html';
import { NameTitleAndDescriptionMultiLang, TitleAndDescriptionMultiLang, TitleAndEditorText } from '../../Base/Common/SupportedMultiLang';
import { BlankCard, CommonHelper } from '../../Base/Common/CommonUIComponents';
import { getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';

class RateCodeTextDescription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            texts: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.rateCodeId !== this.props.rateCodeId) {
            this.getRateCodeTexts(nextProps.rateCodeId);
        }
    }

    componentDidMount() {
        if (this.props.rateCodeId) {
            this.getRateCodeTexts(this.props.rateCodeId);
        }
    }

    getRateCodeTexts = (rateCodeId) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data && data.descriptions && data.descriptions.length > 0) {
                data.descriptions.map(description => {
                    const blocksFromHtml = htmlToDraft(description.description);
                    const { contentBlocks, entityMap } = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    description.editorState = EditorState.createWithContent(contentState);
                });
                this.setState({ error: errorMessage, block: false, texts: data.descriptions });
            }
            else {
                this.setState({ error: errorMessage, block: false, texts: [] });
            }
        }, `/api/Rate/Rate/v1/rateCode/${rateCodeId}/Language`);
    }

    handleChange = (evt, lang) => {
        if (evt && evt.target) {
            const { texts } = this.state;
            const { name, value } = evt.target;
            const text = texts.find(el => el.culture === lang);

            if (text) {
                text[name] = value;
            }
            else {
                const newText = { culture: lang, description: "", titleDescription: "", nameDescription: '' };
                newText[name] = value;
                texts.push(newText);
            }
            this.setState({ texts });
        }
    }

    onEditorStateChange = (newEditorState, lang) => {
        const { texts } = this.state;
        let desc = texts.descriptions ? texts.descriptions.find(el => el.culture === lang) : texts.find(el => el.culture === lang);

        if (desc) {
            desc.editorState = newEditorState;
        }
        else {
            let newItem = { culture: lang, description: "", titleDescription: "", nameDescription: '' };
            newItem.editorState = newEditorState;
            texts.push(newItem);
        }
        this.setState({ texts });
    };

    saveTexts = (evt) => {
        evt.preventDefault();
        const { rateCodes, intl, rateCodeDetail } = this.props;
        const { texts } = this.state;

        const descriptions = [];
        texts.map(description => {
            const desc = description.editorState && description.editorState.getCurrentContent() ? draftToHtml(convertToRaw(description.editorState.getCurrentContent())) : '';
            
            const newDescription = {
                culture: description.culture,
                titleId: description.titleId,
                titleDescription: description.titleDescription,
                descriptionId: description.descriptionId,
                description: (desc && desc.toLocaleLowerCase() !== "<p></p>" && desc.toLocaleLowerCase() !== "\n<p></p>" && desc.toLocaleLowerCase() !== "<p></p>\n") ? desc : null,
                type: description.type ? description.type : 'Description',
                nameId: description.nameId,
                nameDescription: description.nameDescription,
                nameId: description.nameId
            };
            descriptions.push(newDescription);
        });

        const englishTexts = descriptions.find(({culture}) => culture === "en-GB");
        if(!englishTexts || !englishTexts.titleDescription || !englishTexts.description) {
            handleNotification({warnings:[{message: intl.formatMessage({id: "RatesIntegrations.TitleDescriptionRequired"})}]});
            
            return;
        }

        this.setState({ block: true });
        postAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }

            if (data && data.descriptions && data.descriptions.length > 0) {
                handleNotification(data, <FormattedMessage id="RatesIntegrations.SaveTextsSuccess" />, <FormattedMessage id="General.Success" />);

                if (!this.props.fromBEConfig) {
                    const rateCode = rateCodes.find(rc => rc.value === rateCodeDetail.rateCodeId);
                    if (rateCode) {
                        rateCodeDetail.rateCodeName = rateCode.label;
                    }
                    data.descriptions.map(description => {
                        const blocksFromHtml = htmlToDraft(description.description);
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        description.editorState = EditorState.createWithContent(contentState);
                    });
                    this.setState({ texts: data.descriptions, rateCodeDetail, error: errorMessage, block: false }, _ => this.props.updateTable(rateCodeDetail));
                }
                else {
                    data.descriptions.map(description => {
                        const blocksFromHtml = htmlToDraft(description.description);
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        description.editorState = EditorState.createWithContent(contentState);
                    });
                    this.setState({ texts: data.descriptions, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/Rate/v1/rateCode/${rateCodeDetail.rateCodeId}/Language`, descriptions);
    }

    render() {
        const { rateCodeId } = this.props;
        const { block, error, texts } = this.state;

        return (
            <BlankCard block={block} error={error}>
                <Form onSubmit={this.saveTexts}>
                    <Row>
                        <Col>
                            <h4> <FormattedMessage id="RatesIntegrations.Texts" /> </h4>
                        </Col>
                        <Col className="text-right">
                            <Button color=" btn-host btn-sm" disabled={rateCodeId === undefined}>
                                <i className="fas fa-save" />
                            </Button>

                            <CommonHelper help={<FormattedHTMLMessage id="RatesIntegrations.Help" />} id={'RatesIntegrationsHelp'} />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col id="descriptionSelectNewCountryId">

                            {!this.props.fromBEConfig ?
                                <NameTitleAndDescriptionMultiLang
                                    data={{
                                        texts: texts,
                                        editorState: texts.editorState,
                                        handleChange: this.handleChange,
                                        onEditorStateChange: this.onEditorStateChange
                                    }}
                                    customToolbar={{
                                        options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                    }}
                                />
                                :
                                <TitleAndEditorText
                                    data={{
                                        texts: texts,
                                        editorState: texts.editorState,
                                        handleChange: this.handleChange,
                                        onEditorStateChange: this.onEditorStateChange
                                    }}
                                    customToolbar={{
                                        options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                    }}
                                />
                            }
                        </Col>
                    </Row>
                </Form>
            </BlankCard>
        );
    }
}
export default injectIntl(RateCodeTextDescription)