import React, { Component } from 'react';
import { Row, Col, Card, CardBody, UncontrolledCollapse, Progress, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, CardHeader, Label, Input } from 'reactstrap';
import { postAPI } from "../../../Base/API"
import BlockUi from 'react-block-ui'
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { handleNotification } from "../../../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../../Base/Common/CustomSelect';
import { getLanguages } from '../../../Base/Common/ReferenceDataFunctions';
import { StarRating } from '../../../Base/Common/CommonUIComponents';



class ExpediaReviewDetail extends Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            error: [],
            reviewResponse: null
        };

    }

    toggleModal() {
        this.props.action();
        this.setState({ error: [] })
    }


    addReply = (event) => {
        event.preventDefault();
        this.setState({ block: true });

        var body = {
            value: this.state.reviewResponse || this.props.review.response?.body?.value,
            locale: this.state.reviewResponseLocale || this.props.review.response?.body?.locale
        }

        postAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="BookingReviewDetail.SuccessfullySaved" />, <FormattedMessage id="BookingReviewDetail.Success" />);

                this.props.getReviews();
                this.props.toggleModal();
                this.setState({ reply: data, block: false, error: errorMessage });
            }
            else this.setState({ error: errorMessage, block: false });
        }, '/api/Rate/Review/v1/expediaReviews/' + this.props.channelInstance + '/review/' + this.props.review.id, body);
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        })
    }

    getLocaleLabel = (locale) => {
        return getLanguages().find(el => el.value.toLowerCase() === locale?.replace('_', '-').toLowerCase())?.label || locale
    }


    render() {
        const { review } = this.props;
        const { reviewResponse } = this.state;

        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} fade={false} className="modal-lg " >
                <Form onSubmit={this.addReply.bind(this)}>
                    <ModalHeader toggle={this.props.toggleModal} > <FormattedMessage id="BookingReviewDetail.ReviewDetails" /> </ModalHeader>
                    <ModalBody className="modal-lg px-2 pb-2">
                        <BlockUi tag="div" blocking={this.state.block}>
                            <ErrorAlert error={this.state.error} />

                            <Card className="mb-2 border-0">
                                <CardHeader className="small bg-white">
                                    <div className="inline">
                                        <strong> <span className="far fa-id-card"></span> <FormattedMessage id="BookingReviewDetail.Reviewer" /> </strong>
                                        <a id="togglerReview" className="float-right">
                                            <span className="fas fa-angle-down"></span>
                                        </a>
                                    </div>
                                </CardHeader>
                                <UncontrolledCollapse defaultOpen={true} toggler="#togglerReview">
                                    <CardBody className="pb-0 small border-1">
                                        <FormGroup sm="true" row>
                                            <Label className="col-12 col-md-2"> <FormattedMessage id="ExpediaReviewCard.Brand" /> </Label>
                                            <Col className="col-12 col-md-4">
                                                <Input type="text" bsSize="sm" className="text-sm" disabled value={review.brandName || ''} />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup sm="true" row>
                                            <Label className="col-12 col-md-2"> <FormattedMessage id="BookingReviewDetail.Name" /> </Label>
                                            <Col className="col-12 col-md-4">
                                                <Input type="text" name="Headline" id="Headline" bsSize="sm" className="text-sm" disabled value={this.props.review.reservation?.primaryGuest?.firstName} />
                                            </Col>

                                            <Label className="col-12 col-md-2"> <FormattedMessage id="ComplexProfile.lastName" /> </Label>
                                            <Col className="col-12 col-md-4">
                                                <Input type="text" name="Headline" id="Headline" bsSize="sm" className="text-sm" disabled value={this.props.review.reservation?.primaryGuest?.lastName} />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </UncontrolledCollapse>
                            </Card>

                            <Card className="mb-2  border-0">

                                <CardHeader className="small bg-white">
                                    <div className="inline">
                                        <strong> <span className="fas fa-award"> </span> <FormattedMessage id="BookingReviewDetail.Scoring" /></strong>
                                        <a id="togglerScoring" className="float-right">
                                            <span className="fas fa-angle-down"></span>
                                        </a>
                                    </div>
                                </CardHeader>
                                <UncontrolledCollapse defaultOpen={true} toggler="#togglerScoring">
                                    <CardBody className="pb-0 small border-1">
                                        <Row>
                                            {review.starRatings?.map((rating, key) =>
                                                <Col className="col-12 col-md-4 mb-2" key={key}>
                                                    <Row className='d-flex align-items-center'>
                                                        <Col className="col-lg-6 col-12"> <FormattedMessage id={`ExpediaReviewCard.${rating.category}`} /> </Col>
                                                        <Col className="col-lg-6 col-12">
                                                            <StarRating value={rating.value} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                        </Row>
                                    </CardBody>
                                </UncontrolledCollapse>
                            </Card>


                            <Card className="mb-2 border-0">
                                <CardHeader className="small bg-white">
                                    <div className="inline">
                                        <strong> <span className="far fa-edit"></span> <FormattedMessage id="BookingReviewDetail.ReviewContent" /></strong>
                                        <a id="togglerReviewContent" className="float-right">
                                            <span className="fas fa-angle-down"></span>
                                        </a>
                                    </div>
                                </CardHeader>
                                <UncontrolledCollapse defaultOpen={true} toggler="#togglerReviewContent">
                                    <CardBody className="pb-0 small border-1">
                                        <FormGroup sm="true" row>
                                            <Col className="col-12 col-md-2">
                                                <FormattedMessage id="ExpediaReviewCard.Language" />
                                            </Col>
                                            <Col className="text-muted">
                                                {this.getLocaleLabel(review.body?.locale)}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup sm="true" row>
                                            <Col className="col-12 col-md-2">
                                                <FormattedMessage id="ExpediaReviewCard.Review" />
                                            </Col>
                                            <Col>
                                                <Input type="textarea" bsSize="sm" className="text-sm" disabled value={review.body?.value} />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </UncontrolledCollapse>
                            </Card>

                            <Card className="mb-2 border-0">
                                <CardHeader className="small bg-white">
                                    <div className="inline">
                                        <strong> <span className="far fa-comment"></span> <FormattedMessage id="BookingReviewDetail.Reply" /></strong>
                                        <a id="togglerReply" className="float-right">
                                            <span className="fas fa-angle-down"></span>
                                        </a>
                                    </div>
                                </CardHeader>
                                <UncontrolledCollapse defaultOpen={true} toggler="#togglerReply">
                                    <CardBody className="pb-0 small border-1">
                                        <Row sm="true" className="mb-1">
                                            <Col>
                                                {review.isEligibleForResponse ?
                                                    <Row>
                                                        <Col className="col-12 col-md-2">
                                                            <FormattedMessage id="ExpediaReviewCard.Language" />
                                                        </Col>
                                                        <Col className="col-12 col-md-4 col-lg-3">
                                                            <CustomSelect
                                                                isClearable isSearchable
                                                                placeholder=''
                                                                options={getLanguages() || []}
                                                                value={getLanguages().find(el => el.value === this.state.reviewResponseLocale) || ''}
                                                                onChange={(combo) => this.handleChange({ target: { value: combo ? combo.value : null, name: 'reviewResponseLocale' } })}
                                                                required
                                                            />
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <FormGroup sm="true" row>
                                                        <Col className="col-12 col-md-2">
                                                            <FormattedMessage id="ExpediaReviewCard.Language" />
                                                        </Col>
                                                        <Col className="text-muted col-12 col-md-4"> {this.getLocaleLabel(this.props.review.response?.body?.locale)}</Col>
                                                        <Col className="col-12 col-md-2">
                                                            <FormattedMessage id="generic.Status" />
                                                        </Col>
                                                        <Col className="text-muted col-12 col-md-4"> {this.props.review.response?.status}</Col>
                                                    </FormGroup>
                                                }
                                            </Col>
                                        </Row>
                                        <FormGroup sm="true" row>
                                            <Col className="col-12 col-md-2">
                                                <FormattedMessage id="BookingReviewDetail.Reply" />
                                            </Col>
                                            <Col>
                                                <Input
                                                    type="textarea"
                                                    bsSize="sm"
                                                    className="text-sm"
                                                    name="reviewResponse"
                                                    required
                                                    value={reviewResponse || this.props.review.response?.body?.value}
                                                    onChange={this.handleChange}
                                                    disabled={!review.isEligibleForResponse}
                                                    onKeyPress={e => {
                                                        if (e.key === 'Enter')
                                                            e.preventDefault()
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </UncontrolledCollapse>
                            </Card>
                        </BlockUi>
                    </ModalBody>
                    <ModalFooter>
                        {review.isEligibleForResponse ?
                            <Button color="primary btn-host" type="submit" disabled={!reviewResponse}> <FormattedMessage id="BookingReviewDetail.Save" /></Button>
                        : ''}
                        <Button color="secondary" onClick={this.props.toggleModal}> <FormattedMessage id="BookingReviewDetail.Cancel" /></Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

export default injectIntl(ExpediaReviewDetail);