import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Card, CardBody, CardHeader, Col, CustomInput, Form, Input, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import Authorization from '../../Base/Authorization';
import { getExpTypes, getOffersServicePricingType, getOffersStatus } from '../../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../../Base/Common/CustomSelect';
import { CustomSingleDatePicker } from '../../Base/Common/CustomReactDates';
import moment from 'moment';
import ExperienceImageModal from './ExperienceImageModal';
import ImageGallery from 'react-image-gallery';
import { TitleAndEditorText } from '../../Base/Common/SupportedMultiLang';
import { getAPI } from "../../Base/API";
import { StyledCard } from '../../Base/Common/CommonUIComponents';

class ExperienceDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            experienceDetail: this.props.experienceDetail,
            status: getOffersStatus(),
            activeTab: '0',
            typeOptions: getExpTypes(this.props.intl),
            modal: false,
            selectedImage: null,
            itemModal: false,
            experienceRateCodes: this.props.experienceDetail.rates,
            expRateCodes: [],
            block: true,
            error: null,
            imagesGallery: this.props.experienceDetail?.image
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.experienceDetail !== nextProps.experienceDetail)
            this.setState({ experienceDetail: nextProps.experienceDetail }); 
    }

    componentDidMount() {
        this.getInvalidRates();
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    getInvalidRates() {
        const { experienceDetail } = this.state;
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                this.setState({ block: false, expRateCodes: data.response.map(x => x.rateCodeId), totalItems: data.count });
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, `/api/hotel/Experience/v1/experience/Rate${experienceDetail.id ? `?id=${experienceDetail.id}` : ''}`);
    }

    handleRates = (e, rateId) => {
        const { checked } = e.target;
        const { experienceDetail } = this.state;

        if(!experienceDetail.rates) experienceDetail.rates = [];

        if (!checked) {
            experienceDetail.rates = experienceDetail.rates.filter(el => el.rateCodeId !== rateId);
        }
        else {
            experienceDetail.rates.push({ rateCodeId: rateId });
        }

        this.setState({ experienceDetail });
    }

    handleAllRates = (isToRemove) => {
        let { experienceDetail } = this.state;

        if (isToRemove) {
            experienceDetail.rates = [];
        }
        else {
            experienceDetail.rates = this.props.rates.map(el => ({ rateCodeId: el.id }));
        }

        this.setState({ experienceDetail });
    }

    onChangeSelect = (combo, name) => {
        this.setState({
            experienceDetail: {
                ...this.state.experienceDetail,
                [name]: combo ? combo.value : null
            }
        });
    }

    handleDates = (name, date) => {
        if(name === 'startDate' && !date){
            this.setState({
                experienceDetail: {
                    ...this.state.experienceDetail,
                    endDate: null,
                    [name]: date
                }
            });
        }
        else{
            this.setState({
                experienceDetail: {
                    ...this.state.experienceDetail,
                    [name]: date
                }
            });
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const { experienceDetail } = this.state;
        if(name === 'maxStay' || name === 'minStay'){
            const el = document.getElementById('maxStay');
            if (el && experienceDetail.maxStay && experienceDetail.minStay) {
                if((name === "maxStay" && parseFloat(value) < parseFloat(experienceDetail.minStay)) || (name === "minStay" && parseFloat(experienceDetail.maxStay) < parseFloat(value))){
                    el.setCustomValidity(this.props.intl.formatMessage({ id: "Offers.MaxStayLessThanMinStay" }));
                }
                else{
                    el.setCustomValidity("");
                }
            }
        }
        
        this.setState({
            experienceDetail: {
                ...this.state.experienceDetail,
                [name]: value
            }
        });
    }

    addImagesBulk = (images) => {
        this.setState(prevState => {
            const updatedImagesGallery = prevState.imagesGallery ? [...prevState.imagesGallery, ...images] : [...images];
    
            return {
                imagesGallery: updatedImagesGallery
            };
        }, () => {
            images.forEach(img => this.handleImageModal(img, false));
        });
    }

    handleImageModal = (img, deleteImg) => {
        var expDetailsImages = this.props.experienceDetail?.image ?? this.state.imagesGallery;
        
        if(img && expDetailsImages && expDetailsImages.length > 0){
            if(expDetailsImages.find(el => img.id && el.id === img.id)){
                if(deleteImg){
                    expDetailsImages.splice(expDetailsImages.findIndex(el => el.id === img.id), 1);
                }else{
                    expDetailsImages.splice(expDetailsImages.findIndex(el => el.id === img.id), 1, img);
                }
                
            }else if(expDetailsImages.find(el => img.imagePreviewUrl && el.imagePreviewUrl === img.imagePreviewUrl)){
                if(deleteImg){
                    expDetailsImages.splice(expDetailsImages.findIndex(el => el.imagePreviewUrl === img.imagePreviewUrl), 1);
                }else{
                    expDetailsImages.splice(expDetailsImages.findIndex(el => img.imagePreviewUrl && el.imagePreviewUrl === img.imagePreviewUrl), 1, img);
                }
            }else{
                expDetailsImages.push(img);
            }
        }

        if(img && (expDetailsImages && expDetailsImages.length === 0 || !expDetailsImages) && !deleteImg){
            expDetailsImages = [];
            expDetailsImages.push(img);
        }

        if(expDetailsImages){
            this.setState({
                modal: !this.state.modal,
                selectedImage: img,
                imagesGallery: expDetailsImages
            }, () => this.props.associateImage(expDetailsImages));
        }else{
            this.setState({
                modal: !this.state.modal,
                selectedImage: img
            });
        }
        
    }

    selectImage = () => {
        var selectedImage = this.state.experienceDetail.image ? this.state.experienceDetail.image[this._imageGallery.getCurrentIndex()] : this.state.imagesGallery[this._imageGallery.getCurrentIndex()];
        this.setState({
            modal: !this.state.modal,
            selectedImage
        });
    }

    handleChangeMultiLang = (e, lang) => {
        const { name, value } = e.target;
        let descriptions = this.state.experienceDetail.descriptions ? [...this.state.experienceDetail.descriptions] : [];
        const index = descriptions.findIndex(el => el.cultureCode === lang);

        if (index !== -1) {
            descriptions[index][name] = value;
        }
        else {
            const newDescription = { culture: lang, cultureCode: lang, description: "", title: "" };
            newDescription[name] = value;

            descriptions.push(newDescription);
        }

        this.setState({
            experienceDetail: {
                ...this.state.experienceDetail,
                descriptions: descriptions
            }
        });
    }

    onEditorStateChange = (newEditorState, lang) => {
        const { experienceDetail } = this.state;
        let descriptions = experienceDetail.descriptions ? [...this.state.experienceDetail.descriptions] : [];
        const index = descriptions.findIndex(el => el.culture === lang);

        if (index !== -1) {
            descriptions[index].editorState = newEditorState;
        }
        else {
            const newDescription = { culture: lang, cultureCode: lang, description: "", title: "" };
            newDescription.editorState = newEditorState;

            descriptions.push(newDescription);
        }
        
        this.setState({ experienceDetail });
    }

    toggleItemModal = () => {
        this.setState({
            itemModal: !this.state.itemModal
        });
    }

    handleItemChange = (e, id) => {
        const { value } = e.target;
        const { experienceDetail } = this.state;
        
        experienceDetail.sellItems.find(({sellItemId}) => sellItemId === id).quantity = value;

        this.setState({ experienceDetail });
    }

    removeSellItem = (id) => {
        const { experienceDetail } = this.state;

        experienceDetail.sellItems = experienceDetail.sellItems.filter(({sellItemId}) => sellItemId !== id);

        this.setState({ experienceDetail })
    }

    selectSellItem = (id) => {
        const { experienceDetail } = this.state;

        if(!experienceDetail.sellItems)
            experienceDetail.sellItems = [];
        
        experienceDetail.sellItems.push({ sellItemId: id, quantity: 1 });

        this.setState({ experienceDetail, itemModal: false });
    }

    render() {
        const { experienceRateCodes, activeTab, experienceDetail, status, typeOptions, modal, selectedImage, imagesGallery, itemModal, expRateCodes, error, block } = this.state;
        const { rates, saveExperience, intl, sellItems } = this.props;

        const allRatesSelected = experienceDetail?.rates && rates?.every(r => experienceDetail.rates?.some(rc => rc.rateCodeId === r.id));
        const pricingType = getOffersServicePricingType();
        const filteredRates = rates.filter(r => (!expRateCodes.includes(r.id) || experienceRateCodes?.some(rc => rc.rateCodeId === r.id)) && r.active);
        
        return (
            <StyledCard block={block} error={error}>
                <Card className="shadow border-0 h-100">
                    <Form onSubmit={e => saveExperience(e, experienceDetail)}>
                        <CardHeader className="border-bottom bg-white py-2" style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <i className='fas fa-gift mr-1'/>
                                <FormattedMessage id='Experiences.ExperienceDetails'/>
                            </div>
                            <div>
                                <Authorization
                                    perform="experience:add"
                                    yes={() => (
                                        <Button className="btn btn-host btn-sm" type="submit">
                                            <span className="fas fa-save" />
                                        </Button>
                                    )}
                                    no={() => ""}
                                />
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Nav tabs>
                                <NavItem className="cursor-pointer">
                                    <NavLink className={classnames({ active: activeTab === '0' })} onClick={() => this.toggle('0')}>
                                        <FormattedMessage id="Experiences.Details"/>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="cursor-pointer">
                                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => this.toggle('1')}>
                                        <FormattedMessage id="Experiences.Rates"/>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="cursor-pointer">
                                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => this.toggle('2')}>
                                        <FormattedMessage id="Experiences.SellItems"/>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className="h-100">
                                <TabPane className="border-0" tabId='0'>
                                    <Row>
                                        <Col className="col-6">
                                            <Row>
                                                <Col className="col-12">
                                                    <div className='d-flex align-items-center justify-content-between mb-2'>
                                                        <FormattedMessage id="Experiences.Images"/>
                                                        <Button className="btn btn-host btn-sm" onClick={() => this.handleImageModal(null)} type="button">
                                                            <span className="fas fa-plus"/>
                                                        </Button>
                                                    </div>
                                                    <ImageGallery
                                                        onClick={this.selectImage}
                                                        ref={i => this._imageGallery = i}
                                                        showFullscreenButton={false}
                                                        showPlayButton={false}
                                                        showBullets={false}
                                                        items={imagesGallery?.sort((a, b) => a.order - b.order).map(i => ({ original: i.url ?? i.imagePreviewUrl , alt: i.description, thumbnail: i.url ?? i.imagePreviewUrl }))}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className="col-6">
                                                    <Label>
                                                        <FormattedMessage id="Experiences.Type" />
                                                    </Label>
                                                    <CustomSelect
                                                        options={typeOptions}
                                                        placeholder={''}
                                                        value={experienceDetail.type ? typeOptions.find(el => el.value === experienceDetail.type) : ''}
                                                        onChange={e => this.onChangeSelect(e, 'type')}
                                                        required
                                                    />
                                                </Col>
                                                <Col className="col-6">
                                                    <Label>
                                                        <FormattedMessage id="Experiences.Active" />
                                                    </Label>
                                                    <CustomSelect
                                                        options={status}
                                                        placeholder={''}
                                                        isSearchable={false}
                                                        value={status.find(el => el.value === (typeof experienceDetail.active === "string" ? experienceDetail.active : experienceDetail.active ? "Active" : "Inactive"))}
                                                        onChange={e => this.onChangeSelect(e, 'active')}
                                                        required
                                                    />
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <Label>
                                                        <FormattedMessage id="Experiences.MinStay" />
                                                    </Label>
                                                    <Input
                                                        id="minStay"
                                                        type="number"
                                                        placeholder={''}
                                                        value={experienceDetail.minStay}
                                                        onChange={this.handleChange}
                                                        min="1"
                                                        max='365'
                                                        name="minStay"
                                                    />
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <Label>
                                                        <FormattedMessage id="Experiences.MaxStay" />
                                                    </Label>
                                                    <Input
                                                        id="maxStay"
                                                        type="number"
                                                        placeholder={''}
                                                        value={experienceDetail.maxStay}
                                                        onChange={this.handleChange}
                                                        min="1"
                                                        max='365'
                                                        name="maxStay"
                                                    />
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <Label>
                                                        <FormattedMessage id="Experiences.StartDate" />
                                                    </Label>
                                                    <CustomSingleDatePicker
                                                        id="startDate"
                                                        showTodaysButton={true}
                                                        isOutsideRange={_ => false}
                                                        date={experienceDetail.startDate}
                                                        onDateChange={date => this.handleDates('startDate', date)}
                                                        showYearOptions={{ pastYears: true, futureYears: true }}
                                                        numberOfMonths={1}
                                                        showClearDate={true}
                                                        required={true}
                                                    />
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <Label>
                                                        <FormattedMessage id="Experiences.EndDate" />
                                                    </Label>
                                                    <CustomSingleDatePicker
                                                        id="endDate"
                                                        disabled={!experienceDetail.startDate}
                                                        isOutsideRange={day => day <= moment(experienceDetail.startDate)}
                                                        showYearOptions={{ pastYears: true, futureYears: true }}
                                                        date={experienceDetail.endDate}
                                                        onDateChange={date => this.handleDates('endDate', date)}
                                                        showClearDate={true}
                                                        numberOfMonths={1}
                                                        required={true}
                                                    />
                                                </Col>
                                                <Col className="col-12 mt-2">
                                                    <TitleAndEditorText
                                                        data={{
                                                            texts: experienceDetail.descriptions,
                                                            editorState: experienceDetail.descriptions?.editorState,
                                                            handleChange: this.handleChangeMultiLang,
                                                            onEditorStateChange: this.onEditorStateChange
                                                        }}
                                                        isTitle={true}
                                                        customToolbar={{
                                                            options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: true },
                                                            textAlign: { inDropdown: true },
                                                            link: { inDropdown: true },
                                                            history: { inDropdown: true },
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane className="border-0" tabId='1'>
                                    <Row className="mb-3">
                                        <Col className="text-right">
                                            <Button className="btn btn-host btn-sm" onClick={() => this.handleAllRates(allRatesSelected)}>
                                                {allRatesSelected ? <FormattedMessage id="generic.RemoveAll" /> : <FormattedMessage id="generic.SelectAll" />}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {filteredRates && filteredRates.map((rate, key) =>
                                            <Col key={key} className="col-4 pb-2 small">
                                                <CustomInput
                                                    type="checkbox"
                                                    name={rate.id}
                                                    id={rate.id}
                                                    label={rate.code}
                                                    checked={experienceDetail.rates && experienceDetail.rates.some(elem => elem.rateCodeId === rate.id)}
                                                    onChange={(e) => this.handleRates(e, rate.id)}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </TabPane>
                                <TabPane className="border-0" tabId='2'>
                                    <Row className="mb-3">
                                        <Col className="text-right">
                                            <Button className="btn btn-host btn-sm" onClick={this.toggleItemModal} type="button">
                                                <span className="fas fa-plus"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', flexWrap: 'wrap' }}>
                                        {experienceDetail?.sellItems && experienceDetail?.sellItems.map((item, key) =>{
                                            const sellItem = sellItems?.find(s => s.id === item.sellItemId);
                                            const descriptions = sellItem?.descriptions?.find(({culture}) => culture?.includes(intl.locale))??
                                                sellItem?.descriptions?.find(({culture}) => culture === "en-GB");
                                        
                                            return (
                                                sellItem ?
                                                    <Card key={key} className="shadow border-0 m-2"
                                                        style={{
                                                            width: '240px',
                                                            height: "240px",
                                                            backgroundImage: `url("${sellItem.imageUrl}")`,
                                                            color: 'white',
                                                            backgroundSize: 'cover',
                                                            transition: 'all .2s ease-out',
                                                            borderRadius: '8px'
                                                        }}>
                                                        <div onClick={e => this.removeSellItem(item.sellItemId)} style={{ position: 'absolute', top: '-10px', right: '-10px', color: 'white', background: 'black', width: '20px', height: '20px',
                                                            fontSize: '0.8em', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px', cursor: 'pointer' }}>
                                                            <i className='fas fa-times' style={{ margin: '2px 0 0 0' }}/>
                                                        </div>
                                                        <CardBody style={{ backgroundColor: '#0000007d', borderRadius: '8px', overflow: 'auto' }}>
                                                            <Row className="mt-2">
                                                                <Col>
                                                                    <div style={{ fontSize: '1.1em' }}>{descriptions?.title ? descriptions.title : sellItem.serviceCode}</div>
                                                                    <div>
                                                                        {pricingType.find(el => el.value === sellItem.servicePricingType)?.label??sellItem.servicePricingType}
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div style={{ fontSize: '1.1em' }}>
                                                                        <FormattedMessage id="Experience.Quantity"/>
                                                                    </div>
                                                                    <div>
                                                                        <Input
                                                                            type="number"
                                                                            placeholder={''}
                                                                            value={item.quantity}
                                                                            onChange={e => this.handleItemChange(e, item.sellItemId)}
                                                                            min="1"
                                                                            max='99'
                                                                            name="quantity"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mt-2">
                                                                <Col>
                                                                    {descriptions?.description}
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                :''
                                            )}
                                        )}
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Form>
                    {modal ?
                        <ExperienceImageModal
                            toggle={this.handleImageModal}
                            selectedImage={selectedImage}
                            experienceId={experienceDetail.id}
                            handleChangeImageGallerySize={this.props.handleChangeImageGallerySize}
                            totalImagesSizes={this.props.totalImagesSizes}
                            addImagesBulk={this.addImagesBulk}
                            intl={intl}
                        />
                    :''}
                    {itemModal ?
                        <Modal isOpen={true} toggle={this.toggleItemModal}>
                            <ModalHeader toggle={this.toggleItemModal}>
                                <FormattedMessage id="Experiences.SellItems"/>
                            </ModalHeader>
                            <ModalBody>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                                    {sellItems.filter(({id, status}) => status === "Active" && (!experienceDetail?.sellItems || !experienceDetail.sellItems.find(({sellItemId}) => sellItemId === id)))
                                    .map((sellItem, key) => {
                                        const descriptions = sellItem?.descriptions?.find(({culture}) => culture?.includes(intl.locale))??
                                            sellItem?.descriptions?.find(({culture}) => culture === "en-GB");
                                        return(
                                            <Card key={key} className="shadow border-0 m-2" onClick={_ => this.selectSellItem(sellItem.id)}
                                                style={{
                                                    width: '240px',
                                                    height: "240px",
                                                    backgroundImage: `url("${sellItem.imageUrl}")`,
                                                    color: 'white',
                                                    backgroundSize: 'cover',
                                                    transition: 'all .2s ease-out',
                                                    borderRadius: '8px',
                                                    cursor: 'pointer'
                                                }}>
                                                <CardBody style={{ backgroundColor: '#0000007d', borderRadius: '8px', overflow: 'auto' }}>
                                                    <Row className="mt-2">
                                                        <Col>
                                                            <div style={{ fontSize: '1.1em' }}>{descriptions?.title ? descriptions.title : sellItem.serviceCode}</div>
                                                            <div>
                                                                {pricingType.find(el => el.value === sellItem.servicePricingType)?.label??sellItem.servicePricingType}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col>
                                                            {descriptions?.description}
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        )
                                    })}
                                </div>
                            </ModalBody>
                        </Modal>
                    :''}
                </Card>
            </StyledCard>
        )
    }
}

export default injectIntl(ExperienceDetail)