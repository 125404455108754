import React, { Component } from 'react';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { Col, Row, Input, Form, Button } from 'reactstrap';
import { CountryCustomSelect, StyledCard } from '../../Base/Common/CommonUIComponents';
import { getBELangs, getFonts } from '../../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../../Base/Common/CustomSelect';
import CreatableSelect from 'react-select/creatable';
import { getAPI } from '../../Base/API';
import CustomToolTip from '../../../Utils/CustomToolTip';

class ConfigWidget extends Component {
    static displayName = ConfigWidget.name;

    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: true,
            hotelList: [],
            hotelIds: [],
            Lang:'en',
            mainColor: "#07294C",
            secColor: "white",
            thirdColor: "#4174F8",
            font: 'Roboto',
            hotel: global.hotel,
            modal: false,
            selectedTags: [],
            inputValue: '',
            selectedCategory: [],
            selectedPropety: [],
            DiscountCode: null,
            promoCodes: [],
            tagsOptions: [],
            hiddeEditReservation: false,
            hiddePromoCode: false,
            hiddeMaxSize: '',
            lock: null,
            ExtraCombo: null
        }
    }

    componentDidMount(){
        this.getPromoCodeList();
    }

    getTags = () => {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data?.response) {
                const tagsOptions = [];

                data.response.forEach(({tags}) => {
                    if(tags){
                        tags.forEach((code) => {
                            if(!tagsOptions.find(({value}) => value === code)){
                                tagsOptions.push({
                                    label: code,
                                    value: code
                                });
                            };
                        });
                    };
                });
                
                this.setState({ tagsOptions, block: false });
                return
            }
            this.setState(({ block: false }));
        }, `/api/hotel/RoomCategory/v1/GetRoomCategorysTags`);
    }

    getPromoCodeList = () => {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ promoCodes: data.response?.filter(({active}) => active).map(({code}) => {
                    return({
                        label: code,
                        value: code
                    })
                })}, () => this.getTags());
                return
            }
            this.setState(({ block: false }));
        }, `/api/Rate/Promotion/v1/promoCode`);
    }

    updateWidget = () => {
        const script = document.createElement("script");

        script.src = process.env.REACT_APP_CONFIG_WIDGET_JS_URL
        script.async = true;

        document.body.appendChild(script);
        

        const link = document.createElement("link");

        link.src = process.env.REACT_APP_CONFIG_WIDGET_CSS_URL;
        link.async = true;

        document.body.appendChild(link);
    }

    selectTags = (value) => {
        this.setState({
            selectedTags: value ? value : []
        });
    }

    createOption = (label) => ({
      label: label,
      value: label,
    });

    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    }

    handleKeyDown = (event) => {
        const { inputValue, selectedTags } = this.state;
        if (!inputValue) return;
        if(inputValue.includes(',')) return;
        switch (event.key) {
        case 'Enter':
        case 'Tab':
            this.setState({
            inputValue: '',
            selectedTags: [...selectedTags, this.createOption(inputValue)],
            });
            event.preventDefault();
        }
    };

    setSelectedTags = (combo) => {
        this.setState({ selectedTags: combo });
    }

    changeExtraComboLabel = (e) => {
        let { ExtraCombo } = this.state;
        if(!ExtraCombo) ExtraCombo = {};

        ExtraCombo.label = e?.target?.value;

        this.setState({ ExtraCombo });
    }

    changeExtraComboSelect = (combo, name) => {
        let { ExtraCombo } = this.state;
        if(!ExtraCombo) ExtraCombo = {};
        
        ExtraCombo[name] = combo?.value;

        this.setState({ ExtraCombo });
    }

    changeExtraComboMultiSelect = (combo) => {
        let { ExtraCombo } = this.state;
        if(!ExtraCombo) ExtraCombo = {};
        
        ExtraCombo.options = combo ? combo.map(({label}) => label) : null;

        this.setState({ ExtraCombo });
    }
    
    render() {
        const { error, block, selectedTags, font, hotel, mainColor, secColor, thirdColor, Lang, selectedPropety, selectedCategory, DiscountCode, promoCodes, tagsOptions,
        hiddeEditReservation, hiddePromoCode, hiddeMaxSize, lock, ExtraCombo } = this.state;
        const { properties, categories, intl } = this.props;
        
        const fonts = getFonts();
        const Langs = getBELangs();

        let code = `
            <div>
                <link href="${process.env.REACT_APP_CONFIG_WIDGET_CSS_URL}" rel="stylesheet" /> 
                <script src="${process.env.REACT_APP_CONFIG_WIDGET_JS_URL}" defer ></script>
                <div
                    Hotel='[{ "id": "${hotel.id}", "name":"${hotel.name}" }]'
                    Font='${font}'
                    Colors='{
                        "MainColor": "${mainColor}",
                        "SecColor": "${secColor}", 
                        "ThirdColor": "${thirdColor}"
                    }'
                    langu="${Lang}"
                    Link="${process.env.REACT_APP_BE_URL}"
                    Filters="${selectedTags?.map(tag => tag.value).toString()}"
                    ComplexId="${selectedPropety}"
                    CategoryId="${selectedCategory}"
                    ${DiscountCode ? `DiscountCode="${DiscountCode}"` : ''}
                    id="HomePageWidget"
                    visualParams='{
                        "holder":""
                        ${hiddeEditReservation ? `,"hiddeEditReservation": "${hiddeEditReservation}"` : ''}
                        ${hiddePromoCode ? `,"hiddePromoCode": "${hiddePromoCode}"` : ''}
                        ${hiddeMaxSize ? `,"hiddeMaxSize": "${hiddeMaxSize}"` : ''}
                        ${lock === "lockedUp" ? `,"lockedUp": "true"` : ''}
                        ${lock === "lockedDown" ? `,"lockedDown": "true"` : ''}
                    }'
                    ${ExtraCombo ? 
                        `extraCombo='{
                            "options": "${ExtraCombo.options?.toString()}",
                            "name": "filters",
                            "label": "${ExtraCombo.label}"
                            ${ExtraCombo.required ? `,"required": "true"` : ''}
                            ${ExtraCombo.isMulti ? `,"isMulti": "${ExtraCombo.isMulti}"` : ''}
                        }'`
                    :''}
                >
                </div>
            </div>`

        const categoryOptions = categories ? categories.map(({ id, code }) => {
            return({
                value: id,
                label: code
            })
        }) : [];

        const propetyOptions = properties ? properties.map(({ id, description }) => {
            return({    
                value: id,
                label: description
            })
        }) : [];

        const showOptions = [
            { value:  false, label: intl.formatMessage({ id: "ConfigWidget.show" }) },
            { value:  true, label: intl.formatMessage({ id: "ConfigWidget.hide" }) }
        ];
        
        const boolOptions = [
            { value:  false, label: intl.formatMessage({ id: "CustomPolicy.true" }) },
            { value:  true, label: intl.formatMessage({ id: "CustomPolicy.false" }) }
        ];

        const multiOptions = [
            { value:  true, label: intl.formatMessage({ id: "CustomPolicy.true" }) },
            { value:  false, label: intl.formatMessage({ id: "CustomPolicy.false" }) }
        ]

        const lockOptions = [
            { label: intl.formatMessage({ id: "ConfigWidget.lockedUp" }), value: "lockedUp" },
            { label: intl.formatMessage({ id: "ConfigWidget.lockedDown" }), value: "lockedDown" }
        ];
        
        return (
            <div>
                <StyledCard block={block} error={error} icon={'fas fa-cog'} title={'navbar.ConfigWidget'} help={<FormattedHTMLMessage id="ConfigWidget.Help" />}>
                    <Form className="mb-4" onSubmit={(e) => { e.preventDefault(); navigator.clipboard.writeText(code); }}>
                        <Row>
                            <Col>
                                <b><FormattedMessage id="ConfigWidget.Config"/></b>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host mr-2" type="button" onClick={() => this.updateWidget()}><i className="fas fa-eye"/></Button>
                                <div className="d-inline">
                                    <Button className="btn-sm btn-host" type="submit" id="copyCode">
                                        <i className="fas fa-code"/>
                                    </Button>
                                    <CustomToolTip target="copyCode">
                                        <FormattedMessage id="ConfigWidget.copyCode"/>
                                    </CustomToolTip>
                                </div>  
                            </Col>
                        </Row>
                        <hr className="mt-2"/>
                        <Row className="mb-3">
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.Tags"/>:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.Tags"/>}
                                        options={tagsOptions}
                                        onChange={(e) => this.setSelectedTags(e)}
                                        isMulti={true}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.Propety"/>:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.Propety"/>}
                                        options={propetyOptions}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(combo) => this.setState({ selectedPropety: combo && combo.value })}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.Category"/>:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.Category"/>}
                                        options={categoryOptions}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(combo) => this.setState({ selectedCategory: combo && combo.value })}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.PromoCode" />:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.PromoCode" />}
                                        options={promoCodes}
                                        onChange={(combo) => this.setState({ DiscountCode: combo && combo.value })}
                                        value={promoCodes?.find(({value}) => value === DiscountCode)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <b><FormattedMessage id="ConfigWidget.Style"/></b>
                            <hr className="mt-2"/>
                        </div>
                        <Row className="mb-3">
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.TextColor" />:
                                </div>
                                <div>
                                    <Input type="color" value={mainColor} placeholder={<FormattedMessage id="ConfigWidget.TextColor" />}
                                    onChange={({target}) => this.setState({ mainColor: target?.value })} required/>
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.BackgroundColor" />:
                                </div>
                                <div>
                                    <Input type="color" value={secColor} placeholder={<FormattedMessage id="ConfigWidget.BackgroundColor" />}
                                    onChange={({target}) => this.setState({ secColor: target?.value })} required/>
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.ButtonColor" />:
                                </div>
                                <div>
                                    <Input type="color" value={thirdColor} placeholder={<FormattedMessage id="ConfigWidget.ButtonColor" />}
                                    onChange={({target}) => this.setState({ thirdColor: target.value })} required/>
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.Font" />:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.Font" />}
                                        options={fonts} required
                                        onChange={(combo) => this.setState({ font: combo && combo.value })}
                                        value={fonts.find(({value}) => value === font)}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.Lang"/>:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.Lang" />}
                                        options={Langs} required
                                        onChange={(combo) => this.setState({ Lang: combo && combo.value })}
                                        value={Langs.find(({value}) => value === Lang)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <b><FormattedMessage id="ConfigWidget.Visibility"/></b>
                            <hr className="mt-2"/>
                        </div>
                        <Row className="mb-3">
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.hiddeEditReservation" />:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.hiddeEditReservation" />}
                                        options={showOptions}
                                        isSearchable={false}
                                        onChange={(combo) => this.setState({ hiddeEditReservation: combo && combo.value })}
                                        value={showOptions?.find(({value}) => value === hiddeEditReservation)}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.hiddePromoCode" />:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.hiddePromoCode" />}
                                        options={showOptions}
                                        isSearchable={false}
                                        onChange={(combo) => this.setState({ hiddePromoCode: combo && combo.value })}
                                        value={showOptions?.find(({value}) => value === hiddePromoCode)}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.hiddeMaxSize" />:
                                </div>
                                <div>
                                    <Input
                                        type="number"
                                        max="100"
                                        min="0"
                                        placeholder={intl.formatMessage({ id: "ConfigWidget.hiddeMaxSize" })}
                                        onChange={(e) => this.setState({ hiddeMaxSize: e?.target?.value })}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.lock" />:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.lock" />}
                                        options={lockOptions}
                                        isSearchable={false}
                                        isClearable={true}
                                        onChange={(combo) => this.setState({ lock: combo && combo.value })}
                                        value={lockOptions?.find(({value}) => value === lock)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <b><FormattedMessage id="ConfigWidget.ExtraCombo"/></b>
                            <hr className="mt-2"/>
                        </div>
                        <Row className="mb-3">
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.label" />:
                                </div>
                                <div>
                                    <Input
                                        placeholder={intl.formatMessage({ id: "ConfigWidget.label" })}
                                        onChange={this.changeExtraComboLabel}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.required" />:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.required" />}
                                        options={multiOptions}
                                        isClearable={true}
                                        isSearchable={false}
                                        onChange={combo => this.changeExtraComboSelect(combo, 'required')}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.isMulti" />:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.isMulti" />}
                                        options={multiOptions}
                                        isClearable={true}
                                        isSearchable={false}
                                        onChange={combo => this.changeExtraComboSelect(combo, 'isMulti')}
                                    />
                                </div>
                            </Col>
                            <Col className="col-3 mt-2">
                                <div>
                                    <FormattedMessage id="ConfigWidget.options" />:
                                </div>
                                <div>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="ConfigWidget.options"/>}
                                        options={tagsOptions}
                                        isSearchable={false}
                                        onChange={(e) => this.changeExtraComboMultiSelect(e, 'isMulti')}
                                        isMulti={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <b><FormattedMessage id="ConfigWidget.PreviewWidget" /></b>
                    <hr className="mt-2" />
                    <div dangerouslySetInnerHTML={{ __html: code }}/>
                </StyledCard>
            </div>
        )
    }
}

export default injectIntl(ConfigWidget);