import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, Label, Row, Table, CustomInput, Button } from 'reactstrap';
import { getOperationModes, getPostTypeList, getPriceTypeList } from '../../../Base/Common/ReferenceDataFunctions';
import { ActiveInactiveStatusBadge } from '../../../Base/Common/CommonUIComponents';
import { getAPI, postAPI, deleteAPI, putAPI } from "../../../Base/API";
import CustomSelect from "../../../Base/Common/CustomSelect";
import { handleNotification } from "../../../Base/Notification";
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { PackageComponentDetail } from "./PackageComponentDetail";
import CustomToolTip from '../../../../Utils/CustomToolTip';

export class PackageSetup extends Component {

    state = {
        activeTab: '1',
        activeAddressTab: '1',
        PackageData: {},
        selectedPack: {},
        error: null,
        block: true,
        blockMeaPlan : true,
        //blockCancelPolicies: true,
        //cancelPolicies: [],
        mealPlan: [],
        //cancelPolicieDetail : []
        modal: false,
        selectedPackageComponent: null,
        packageComponentList: [],
        isManagedByHey: false
    };
    

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.getPackageData();
        //this.getCancelPolicies();
        this.getMealPlans();

        window.setTimeout(
            () => {
                this.setState({
                    //getOperationModes()[1].value - PmsLimited
                    //getOperationModes()[2].value - None
                    isManagedByHey: global.operationMode === getOperationModes()[1].value || global.operationMode === getOperationModes()[2].value
                });
            },
            global.operationMode ? 0 : 2500
        );
    }

    getPackageData = () => {
        let defaultSelectedPack = {
            id: null,
            code: '',
            description: '',
            //cancelationPolicyId: null,
            packageMealPlanId: null,
            active: true
        }

        if (this.props.selectedPackage.id) {
            getAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ block: isLoading });
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.response) {
                        defaultSelectedPack.id = data.response.id;
                        defaultSelectedPack.code = data.response.code;
                        defaultSelectedPack.description = data.response.description;
                        //defaultSelectedPack.cancelationPolicyId = data.data.cancelationPolicyId;
                        defaultSelectedPack.packageMealPlanId = data.response.packageMealId;
                        defaultSelectedPack.isPriceModeComponent = data.response.isPriceModeComponent;
                        defaultSelectedPack.active = data.response.active;

                        this.setState({ PackageData: data.response, selectedPack: defaultSelectedPack, packageComponentList: data.response.packageComponents, block: false });
                    }

                }
            }, '/api/hotel/Package/v1/' + this.props.selectedPackage.id);
        } else {
            this.setState({ block: false, PackageData: {}, selectedPack: defaultSelectedPack });
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleAddress(tab) {
        if (this.state.activeAddressTab !== tab) {
            this.setState({
                activeAddressTab: tab
            });
        }
    }

   /* getCancelPolicies() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockCancelPolicies: false });

                return;
            }
            if (data) {
                if (data.response) {
                    let list = [];
                    data.response.sort((a, b) => ((a.daysBefore * 100 + a.percentageAfter) - (b.daysBefore * 100 + b.percentageAfter) ));
                    data.response.map((el) => list.push({ 'value': el.id, 'label': el.name }));

                    this.setState({ cancelPolicies: list, cancelPolicieDetail: data.response, blockCancelPolicies: false });
                }
            }
        }, '/api/Rate/Package/v1/cancelpolicies');
        
    }*/

    getMealPlans() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockMeaPlan: false });

                return;
            }
            if (data) {
                if (data.response) {
                    let list = [];
                    data.response.map((el) => list.push({ 'value': el.id, 'label': el.description }));
                    this.setState({ mealPlan: list, blockMeaPlan: false });

                }
                    
            }
        }, '/api/Rate/Package/v1/mealplans');

    }

    savePackage(event) {
        event.preventDefault();

        if (!this.form.current.reportValidity()) {
            return;
        }
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
           
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, 'Package updated Sucessfuly', 'Success');  
                this.setState({ block: false }, () => this.props.getPackages());

                }

            
        }, '/api/hotel/Package/v1', this.state.selectedPack);   

    }

    removePackage = (evt) => {
        evt.preventDefault();

        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    handleNotification(data, <FormattedMessage id="PackageSetup.PackageRemovedSucessfuly" />, <FormattedMessage id="General.Success" />);
                    this.setState({ error: errorMessage, block: false }, () => this.props.getPackages());
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/hotel/Package/v1/${this.state.selectedPack.id}`);
    }

    deletePackageComponent = (event, id) => {
        event.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            else {
                handleNotification(data, <FormattedMessage id="PackageSetup.PackageComponentDeleted" />, <FormattedMessage id="General.Success" />);
                this.setState({ block: false }, () => this.getPackageData());
            }            
        }, `/api/Rate/Package/v1/PackageComponent/${id}`);
    }

    toggleModal = (item) => {
        if (global.operationMode === 'PmsLimited' || global.operationMode === 'None') {
            this.setState(prevState => ({
                modal: !prevState.modal,
                selectedPackageComponent: item
            }));
        }
    }

    updateselectedPackState(evt) {

        let val = evt.target.value;
        let name = evt.target.name;

        this.setState(prevState => ({
            ...prevState,
            selectedPack : { ...prevState.selectedPack, [name]  : val}
        }));
    }

    updateselectedPackComboState(el, evt) {
        if (evt) {
        let val = el ? el.value : null;
        let name = evt.name;

        this.setState(prevState => ({
            ...prevState,
            selectedPack: { ...prevState.selectedPack, [name]: val }
        }));
        }
    }

    handleSwitch = (evt) => {
        const { name, checked } = evt.target;

        this.setState(prevState => ({
            ...prevState,
            selectedPack: {
                ...prevState.selectedPack,
                [name]: checked
            }
        }));
    }

    togglePackageStatus = (event) => {
        event.preventDefault();
        this.setState({ block: true });

        const action = this.state.selectedPack.active ? 'disable' : 'enable';

        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id={`PackageSetup.${action}Status`} />, <FormattedMessage id="General.Success" />);

                    this.setState( prevState => ({
                        selectedPack: { ...prevState.selectedPack, active: !prevState.selectedPack.active }
                    }), () => this.props.updatePackage(this.state.selectedPack));
                }
            }
            this.setState({ block: false });

        }, `/api/hotel/Package/v1/${this.state.selectedPack.id}/status/${action}`);

    }

    render() {
        const { isManagedByHey, selectedPack } = this.state;

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <CardBody className="small">
                        <form ref={this.form} >
                            <Row className="mb-2">
                                <Col>
                                    <ActiveInactiveStatusBadge status={this.state.selectedPack.active} />
                                </Col>
                                <Col className="text-right">
                                    {
                                        isManagedByHey ?
                                            <span>
                                                <span>
                                                    <button className="btn btn-sm btn-host" onClick={this.togglePackageStatus} id="togglePackStatus">
                                                        <i className="fas fa-power-off" />
                                                    </button>
                                                    <CustomToolTip placement="bottom" target="togglePackStatus">
                                                        <FormattedMessage id={'PackageSetup.' + (this.state.selectedPack.active ? 'DisablePackage' : 'EnablePackage')} />
                                                    </CustomToolTip>
                                                </span>
                                                {
                                                    selectedPack.id ?
                                                        <button className="btn btn-sm btn-host ml-2" onClick={this.removePackage.bind(this)}><i className="fas fa-trash-alt"></i></button>
                                                        :
                                                        ''
                                                }
                                                <button className="btn btn-sm btn-host ml-2" onClick={this.savePackage.bind(this)}><i className="fas fa-save"></i></button>
                                            </span>
                                            :
                                            ''
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="localratecode" sm={4}><FormattedMessage id="PackageSetup.Code" /> </Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                    <FormattedMessage id="PackageSetup.Code">{placeholder =>
                                                    <Input name="code" required placeholder={placeholder} maxLength="50" value={this.state.selectedPack.code} onChange={this.updateselectedPackState.bind(this)} disabled={global.operationMode === 'PmsFull'}/>
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                <FormGroup row>
                                    <Label for="packagename" sm={4}> <FormattedMessage id="PackageSetup.Description" /> </Label>
                                    <Col sm={8}>
                                        <InputGroup size="sm">
                                                <FormattedMessage id="PackageSetup.PackageName">{placeholder =>
                                                    <Input type="textarea" maxLength="500" name="description" required placeholder={placeholder} value={this.state.selectedPack.description} onChange={this.updateselectedPackState.bind(this)} disabled={global.operationMode === 'PmsFull'}/>
                                            }</FormattedMessage>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                            </Col>

                            </Row>

                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="localratecode" sm={4}><FormattedMessage id="PackageSetup.MealType" /> </Label>
                                        <Col sm={8}>
                                            <BlockUi tag="div" blocking={this.state.blockMeaPlan}>
                                                <CustomSelect required name="packageMealPlanId" options={this.state.mealPlan} value={this.state.mealPlan.filter(el => el.value === this.state.selectedPack.packageMealPlanId)} onChange={this.updateselectedPackComboState.bind(this)} isDisabled={global.operationMode === 'PmsFull'}/>
                                            </BlockUi>
                                         
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="isPriceModeComponent" sm={6}><FormattedMessage id="PackageSetup.IsPriceModeComponent" /> </Label>
                                        <Col sm={6} className={`d-flex align-items-center ${!global.isMobile ? 'justify-content-end text-right' : ''} `}>
                                            <CustomInput
                                                name="isPriceModeComponent"
                                                type="switch"
                                                id="isPriceModeComponent"
                                                checked={this.state.selectedPack.isPriceModeComponent}
                                                onChange={(e) => this.handleSwitch(e)}
                                                disabled={global.operationMode === 'PmsFull'}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {/*
                                      <FormGroup row>
                                        <Label for="packagename" sm={4}> <FormattedMessage id="PackageSetup.CancelPolicy" /> </Label>
                                        <Col sm={8}>
                                            <BlockUi tag="div" blocking={this.state.blockCancelPolicies}>
                                                <CustomSelect  required name="cancelationPolicyId" isClearable options={this.state.cancelPolicies} value={this.state.cancelPolicies.filter(el => el.value === this.state.selectedPack.cancelationPolicyId)} onChange={this.updateselectedPackComboState.bind(this)} />
                                            </BlockUi>
                                        </Col>
                                    </FormGroup>
                                    */}
                                </Col>

                            </Row>
                            
                            {/*selectedPol ? 
                            <div className="mb-2">
                                    <StyledCard title={'Cancel Policy'} icon={'fas fa-gavel'}>
                                        <CancelPolicyText policy={selectedPol} />
                                </StyledCard>
                            </div>
                                : ''

                           
                                <div className="mb-2">
                                 <StyledCard title={'Meal Plan'} icon={'fas fa-utensils'}>
                                    <MealPolicyText policy={''} />
                                        
                                    </StyledCard>
                                </div>*/}

                            {this.props.selectedPackage && Object.keys(this.props.selectedPackage).length > 0 && this.state.selectedPack.isPriceModeComponent ?
                                <Row className="mb-2">
                                    <Col className='col-12'>
                                        <Card className="shadow border-0">
                                            <CardHeader className="bg-white border-0">
                                                <Row>
                                                    <Col className='col-8'>
                                                        <h6><span className="fas fa-gift"></span> <FormattedMessage id="PackageSetup.PackageComponents" />  </h6>
                                                    </Col>
                                                    {global.operationMode === 'PmsLimited' || global.operationMode === 'None' ?
                                                        <Col className="text-right">
                                                            <Button className="btn btn-sm btn-host float-right" onClick={() => this.toggleModal(null)}><i className="fas fa-plus"></i></Button>
                                                        </Col>
                                                    : ''}
                                                </Row>
                                            </CardHeader>
                                            <CardBody style={{maxHeight: '50vh', overflow: 'auto'}}>
                                                <Table size="sm" responsive>
                                                    <thead>
                                                        <tr>
                                                            <th style={{fontSize: 'small'}}> <FormattedMessage id="PackageSetup.ChargeCode" /> </th>
                                                            <th style={{fontSize: 'small'}}> <FormattedMessage id="PackageSetup.ChargeDescription" /> </th>
                                                            <th style={{fontSize: 'small'}}> <FormattedMessage id="PackageSetup.ComponentType" /> </th>
                                                            <th style={{fontSize: 'small'}}> <FormattedMessage id="PackageSetup.ComponentPostType" /> </th>
                                                            <th style={{fontSize: 'small'}}> <FormattedMessage id="PackageSetup.Quantity" /> </th>
                                                            <th style={{fontSize: 'small'}}> <FormattedMessage id="PackageSetup.ComponentPrice" /> </th>
                                                            <th style={{fontSize: 'small'}}> <FormattedMessage id="PackageSetup.ComponentPriceChildrenGroup2" /> </th>
                                                            {global.operationMode === 'PmsLimited' || global.operationMode === 'None' ? <th>  </th> : ''}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.packageComponentList != null && this.state.PackageData.packageComponents && this.state.PackageData.packageComponents.map((item, key) =>
                                                            <tr key={key} className="overbglight" style={global.operationMode === 'PmsLimited' || global.operationMode === 'None' ? { cursor: 'pointer' } : {}}>
                                                                <td onClick={() => this.toggleModal(item) } style={{fontSize: 'small'}}>
                                                                    {item.chargeCode}
                                                                </td>
                                                                <td onClick={() => this.toggleModal(item) } style={{fontSize: 'small'}}>
                                                                    {item.chargeDescription}
                                                                </td>
                                                                <td onClick={() => this.toggleModal(item) } style={{fontSize: 'small'}}>
                                                                    {getPriceTypeList().find(el => el.value == item.componentType) ? getPriceTypeList().find(el => el.value == item.componentType).label : ''}
                                                                </td>
                                                                <td onClick={() => this.toggleModal(item) } style={{fontSize: 'small'}}>
                                                                    {getPostTypeList().find(el => el.value == item.componentPostType) ? getPostTypeList().find(el => el.value == item.componentPostType).label : ''}
                                                                </td>
                                                                <td onClick={() => this.toggleModal(item)} style={{fontSize: 'small'}}>
                                                                    {item.quantity}
                                                                </td>
                                                                <td onClick={() => this.toggleModal(item) } style={{fontSize: 'small'}}>
                                                                    {item.componentPrice}
                                                                </td>
                                                                <td onClick={() => this.toggleModal(item)} style={{fontSize: 'small'}}>
                                                                    {item.componentPriceChildrenGroup2}
                                                                </td>
                                                                {global.operationMode === 'PmsLimited' || global.operationMode === 'None' ? <td><i className="fas fa-trash-alt" style={{ color: 'red' }} onClick={(e) => this.deletePackageComponent(e, item.id)}></i></td> : ''}
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            : ''}
                           
                            <Row className="mb-2">
                                <Col className='col-12'>
                                    <Card className="shadow border-0">
                                        <CardHeader className="bg-white border-0"><h6><span className="fas fa-suitcase"></span> <FormattedMessage id="PackageSetup.Rates" />  </h6></CardHeader>
                                        <CardBody style={{maxHeight: '35vh', overflow: 'auto'}}>
                                            <Table size="sm">
                                                    <thead><tr>
                                                        <th width="50%" style={{fontSize: 'small'}}><FormattedMessage id="PackageSetup.Code" /> </th>
                                                        <th style={{fontSize: 'small'}}> <FormattedMessage id="PackageSetup.Description" /> </th>
                                                    </tr></thead>
                                                    <tbody>
                                                    
                                                        {
                                                            this.state.PackageData.rateCodesResponse != null && this.state.PackageData.rateCodesResponse.map((item, key) =>
                                                                    <tr key={key}>
                                                                        <td style={{fontSize: 'small'}}>{item.code}</td>
                                                                        <td style={{fontSize: 'small'}}>{item.description}</td>
                                                                    </tr>
                                                            )
                                                        }
                                                        </tbody>
                                                </Table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            
                        <Row>
                                <Col className='col-12'>
                                    <Card className="shadow border-0">
                                        <CardHeader className="bg-white border-0"><h6><span className="fas fa-utensils"></span> <FormattedMessage id="PackageSetup.Meals" /> </h6></CardHeader>
                                        <CardBody  style={{maxHeight: '35vh', overflow: 'auto'}}>
                                            <Table size="sm">
                                                <thead><tr>
                                                    <th width="50%" style={{fontSize: 'small'}}> <FormattedMessage id="PackageSetup.Code" /> </th>
                                                        <th style={{fontSize: 'small'}}><FormattedMessage id="PackageSetup.Description" /></th>
                                                </tr></thead>
                                                <tbody>
                                                {
                                                            this.state.PackageData.packageMealResponse != null ? this.state.PackageData.packageMealResponse.map((item, key) =>
                                                            <tr key={key}>
                                                                <td style={{fontSize: 'small'}}>{item.code}</td>
                                                                <td style={{fontSize: 'small'}}>{item.description}</td>
                                                            </tr>
                                                            ) :
                                                                <tr >
                                                                    <td style={{fontSize: 'small'}}><FormattedMessage id="PackageSetup.NoDataAvailable" /></td>
                                                                </tr> 
                                                    }

                                                </tbody>
                                            </Table>
                                        </CardBody>
                                </Card>
                            </Col>
                        </Row>
                            {/*<br/>
                        <Row>
                            <Col className='col-12'>
                                    <Card className="shadow border-0">
                                        <CardHeader className="bg-white border-bottom-1"><div><span className="fas fa-hot-tub"></span> <FormattedMessage id="PackageSetup.Services" /> </div></CardHeader>

                                    <CardBody></CardBody>
                                </Card>
                            </Col>
                            </Row>
                            */}
                        </form>
                        </CardBody>

                </BlockUi>

                {this.state.modal ?
                    <PackageComponentDetail
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        packageComponent={this.state.selectedPackageComponent}
                        packageId={this.props.selectedPackage.id}
                        updateTable={this.updateTable}
                        getPackageData={this.getPackageData}
                    />
                    : ''}
            </div>


        );
    }
}