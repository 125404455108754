import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardHeader, Label, FormGroup, Input, InputGroup, Button, Form } from 'reactstrap';
import { postMultiPartAPI, getAPI, deleteAPI } from "../../../Base/API"
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { StyledCard, CommonHelper } from "../../../Base/Common/CommonUIComponents";
import { handleNotification } from "../../../Base/Notification";
import BlockUi from 'react-block-ui';
import ImageGallery from 'react-image-gallery';
import { FormattedMessage } from 'react-intl';
import "react-image-gallery/styles/css/image-gallery.css";
import '../../../../css/image-galery.css';

export class ComplexPhotos extends Component {

    state = {
        blocking: false,
        notification: { type: 'Success', message: '' },
        error: [],
        errorModal: [],
        hotelPhotos: [],
        selectedPhoto: { 'description': '', 'order': 100 },
        selectedComplex: {},
        block: false,
        
        modal : false
    };

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.toggleModal = this.toggleModal.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedComplex && nextProps.selectedComplex !== this.props.selectedComplex) {
            this.getPhotos(nextProps.selectedComplex);
        }
    }

    componentDidMount() {
        this.getPhotos(this.props.selectedComplex);
    }

    getPhotos = (selectedComplex) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const logo = data.find(el => el.imageType === 'Logo');
                const photos = data.filter(el => el.imageType !== 'Logo')

                this.setState({
                    hotelPhotos: photos,
                    logoPreview: logo ? logo.imageUrl : null, logoDescription: logo ? logo.description : '',
                    selectedComplex: selectedComplex,
                    block: false, error: errorMessage
                });

            }
        }, '/api/hotel/Complexes/v1/' + selectedComplex.id + '/Photos');
    }

    handleDelete(e) {
        e.preventDefault();
        this.setState({ block: true });
        var url = '/api/hotel/Complexes/v1/' + this.props.selectedComplex.id +'/Photos/Upload/' + this.state.selectedPhoto.id ;
        
        deleteAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ errorModal: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                }
                else {
                    if (this.state.selectedComplex) this.getPhotos(this.state.selectedComplex);
                    handleNotification(data, <FormattedMessage id="ComplexPhotos.PhotoDeleted" />, <FormattedMessage id="General.Success" />);
                    this.setState({ errorModal: errorMessage, modal: false, block: false, selectedPhoto: {}, imagePreviewUrl: {} });
                }
            }
        },  url);

    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ block: true });
        if (e) {
            e.preventDefault();
            if (!this.form.current.reportValidity()) {
                return;
            }

        }

        const formData = new FormData();
            formData.append('file', this.state.file);
            formData.append('description', this.state.selectedPhoto.description);
            formData.append('order', this.state.selectedPhoto.order);

        var url = '/api/hotel/Complexes/v1/' + this.props.selectedComplex.id+'/Photos/Upload';

        if (this.state.selectedPhoto.id)
            url = url + '/' + this.state.selectedPhoto.id;

            postMultiPartAPI(result => {
                const { data, error } = result;
                var errorMessage = []
                if (error) {

                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                    this.setState({ errorModal: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState(({ block: false, error: errorMessage }));
                    }
                    else {
                        if (this.state.selectedComplex) this.getPhotos(this.state.selectedComplex);

                        handleNotification(data, <FormattedMessage id="ComplexPhotos.PhotoSaved" />, <FormattedMessage id="General.Success" />);
                        this.setState({ errorModal: errorMessage, modal: false, block: false, selectedPhoto: {}, imagePreviewUrl: {} });
                    }
                }
            },  url, formData);
        
    }

    saveLogo = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const formData = new FormData();
        formData.append('file', this.state.logo);
        formData.append('description', this.state.logoDescription);

        postMultiPartAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ errorModal: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                }
                else if (data.success === "OK") {
                    handleNotification(data, <FormattedMessage id="ComplexPhotos.LogoUpdatedSuccessfully" />, <FormattedMessage id="General.Success" />);
                    this.setState({ errorModal: errorMessage, block: false });
                }
            }
        }, '/api/hotel/Complexes/v1/' + this.props.selectedComplex.id + '/Logo/Upload', formData);
    }

    handleImageChange(e) {
        e.preventDefault();

        let file = e.target.files[0];

        if (file) {
            if (file.size <= 1048576) { //1MB
                let reader = new FileReader();

                reader.onloadend = () => {
                    this.setState({
                        file: file,
                        imagePreviewUrl: reader.result
                    });
                }

                if (file) {
                    reader.readAsDataURL(file);
                }
            }
            else {
                document.getElementById('multi').value = "";
                handleNotification('', <FormattedMessage id="BookingChatWindow.PleaseSelectAFileLessThan" />, <FormattedMessage id="BookingChatWindow.SelectedFileIsTooBig" />, 'info');
            }
        }
        else {
            document.getElementById('multi').value = "";
            this.setState({ fileContent: '' })
        }
    }

    handleLogoImageChange = (e) => {
        e.preventDefault();

        let file = e.target.files[0];

        if (file) {
            if (file.size <= 1048576) { //1MB
                let reader = new FileReader();

                reader.onloadend = () => {
                    this.setState({
                        logo: file,
                        logoPreview: reader.result
                    });
                }

                reader.readAsDataURL(file);
            }
            else {
                document.getElementById('logo').value = "";
                handleNotification('', <FormattedMessage id="BookingChatWindow.PleaseSelectAFileLessThan" />, <FormattedMessage id="BookingChatWindow.SelectedFileIsTooBig" />, 'info');
            }
        }
        else {
            document.getElementById('logo').value = "";
            this.setState({ fileContent: '' })
        }
    }

    
    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedPhoto: !prevState.modal ? prevState.selectedPhoto : {},
            imagePreviewUrl: !prevState.modal ? prevState.imagePreviewUrl : {}
        }));
    }
    handleDescriptionChange(e) { 
        this.setState({
            selectedPhoto: {
                ...this.state.selectedPhoto,
                description: e.target.value
        }
    })
    }
    handleOrderChange(e) {
        this.setState({
            selectedPhoto: {
                ...this.state.selectedPhoto,
                order: e.target.value
            }
        })
    }
    handleImageSelection(e) {

        var item = this.state.hotelPhotos[this._imageGallery.getCurrentIndex()];
        var _selectedPhoto = item;

        this.setState({
            selectedPhoto: _selectedPhoto,
            modal: true
        });
     
    }

    render() {

        const images = [];
        this.state.hotelPhotos  && this.state.hotelPhotos.map((item, key) =>
            images.push({ 'original': item.imageUrl, 'thumbnail': item.imageUrl, 'description': item.description   })
        );
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;

        if (this.state.selectedPhoto.imageUrl) {
            $imagePreview = (<img style={{ maxHeight: '650px', maxWidth: '650px' }} src={this.state.selectedPhoto.imageUrl} alt='' />);
        }
        else if (imagePreviewUrl) {
            $imagePreview = (<img style={{ maxHeight: '650px', maxWidth: '650px' }} src={imagePreviewUrl} alt='' />);
        }  else {
            $imagePreview = (<div><FormattedMessage id="ComplexPhotos.SelectAnImageForPreview" /></div>);
        }



        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Card className="small border-0 shadow mb-3">
                        <Form onSubmit={this.saveLogo}>
                            <CardHeader className="border-bottom bg-white pb-3" >
                                <Row>
                                    <Col><span className="fas fa-image"> </span> Logo </Col>
                                    <Col className="text-right">
                                        <Button className="btn btn-host btn-sm" type="submit">
                                            <i className="fas fa-save"></i>
                                        </Button>

                                        <CommonHelper help={<FormattedMessage id="ComplexPhotos.Help" />} id="ComplexPhotosHelp" />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col className="col-6">
                                        <Row>
                                            <Col>
                                                <FormGroup className="form-control-sm" row>
                                                    <Label sm={4}> <FormattedMessage id="ComplexPhotos.PhotoDescription" /> </Label>
                                                    <Col sm={8}>
                                                        <InputGroup size="sm">
                                                            <FormattedMessage id="ComplexPhotos.InsertPhotoDescription">{placeholder =>
                                                                <Input placeholder={placeholder} 
                                                                    value={this.state.logoDescription}
                                                                    onChange={(e) => this.setState({ logoDescription: e.target.value })} />
                                                            }</FormattedMessage>
                                                        </InputGroup>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup className="form-control-sm" row>
                                                    <Label sm={4}><FormattedMessage id="ComplexPhotos.ChoosePhoto" /> </Label>
                                                    <Col sm={8}>
                                                        <InputGroup size="sm">
                                                            <input id='logo' required={true} type="file" accept="image/*" onChange={this.handleLogoImageChange} />
                                                        </InputGroup>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-6 text-center d-flex align-items-center justify-content-center">
                                        {this.state.logoPreview ?
                                            <img style={{ maxHeight: '100px' }} src={this.state.logoPreview} alt='Logo' />
                                        : ''}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Form>
                    </Card>

                    <Card className="small border-0 shadow">
                        <CardHeader className="border-bottom bg-white" >
                            <div><span className="fas fa-images"> </span> <FormattedMessage id="ComplexSetup.PropertyPhotos" />
                                <Button className="btn btn-host mr-1 btn-sm float-right" onClick={this.toggleModal}> <i className="fas fa-plus"></i> </Button>
                            </div>
                        </CardHeader>
                        <CardBody> 
                            <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                                <form ref={this.form} >
                                     <ModalHeader toggle={this.toggleModal}  > <FormattedMessage id="ComplexPhotos.ManagePropertyPhotos" /> </ModalHeader>
                                    <ModalBody className="modal-lg">
                                        <Card className="bg-white small">
                                            <Row>
                                                <Col className="Col-12">
                                                    <ErrorAlert error={this.state.errorModal} />
                                                    <StyledCard icon="fas fa-upload" title="ComplexPhotos.CreateUpdatePhoto">                                      
                                            
                                                        <Row>
                                                            <Col className="col-6">
                                                                <FormGroup className="form-control-sm" row>
                                                                    <Label for="Desc" sm={4}> <FormattedMessage id="ComplexPhotos.PhotoDescription" /> </Label>
                                                                <Col sm={8}>
                                                                <InputGroup size="sm">
                                                                    <FormattedMessage id="ComplexPhotos.InsertPhotoDescription">{placeholder =>
                                                                        <Input name="Desc" placeholder={placeholder} value={this.state.selectedPhoto.description} onChange={this.handleDescriptionChange.bind(this)} />
                                                                    }</FormattedMessage>
                                                                </InputGroup>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="col-6">
                                                        <FormGroup className="form-control-sm" row>
                                                                <Label for="Desc" sm={4}> <FormattedMessage id="ComplexPhotos.Order" /> </Label>
                                                            <Col sm={8}>
                                                                <InputGroup size="sm">
                                                                    <FormattedMessage id="ComplexPhotos.ListingOrder">{placeholder =>
                                                                        <Input type="number" name="Desc" max='100' min='1' placeholder={placeholder} required value={this.state.selectedPhoto.order} onChange={this.handleOrderChange.bind(this)} />
                                                                    }</FormattedMessage>
                                                                </InputGroup>
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                            </Row>

                                                        {this.state.selectedPhoto.id ? '' : 
                                                            <Row>
                                                                <Col className="Col-6">
                                                                    <FormGroup className="form-control-sm" row>
                                                                        <Label for="Desc" sm={4}><FormattedMessage id="ComplexPhotos.ChoosePhoto" /> </Label>
                                                                        <Col sm={8}>
                                                                            <InputGroup size="sm">

                                                                                <input id='multi' required={true} type="file" accept="image/*" onChange={this.handleImageChange} />

                                                                            </InputGroup>
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col className="Col-6">                                                    
                                                                </Col>
                                                            </Row>
                                                        }
                                                        <Row>
                                                            <Col>
                                                                <div className="text-center mt-3">
                                                                    {$imagePreview}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </StyledCard>                                
                                                </Col>
                                            </Row>
                                        </Card>
                                    </ModalBody>
                                    <ModalFooter>
                                        {this.state.selectedPhoto.id ?
                                            <Button color="primary btn-host " onClick={this.handleDelete} > <i className="fas fa-trash-alt"></i> </Button>
                                        : ''}
                                        <Button color="primary btn-host " onClick={this.handleSubmit} ><i className="fas fa-save"></i> </Button>{' '}
                                        <Button color="secondary" onClick={this.toggleModal}> <FormattedMessage id="ComplexPhotos.Cancel" /> </Button>
                                    </ModalFooter>
                                </form>
                            </Modal>

                            <Row>
                                <div className="complexPhotos w-75 h-75">
                                    {images && images.length > 0 ?
                                        <ImageGallery ref={i => this._imageGallery = i} thumbnailPosition={'left'} lazyLoad={true} items={images} onClick={this.handleImageSelection.bind(this)} />
                                    : ''}
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </BlockUi>
            </div>
        );
    }
}