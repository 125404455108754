import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage } from 'react-intl';
import { Badge, Button, CustomInput, } from 'reactstrap';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import ChatGPTWhite from '../../../img/chatgptwhite.png';
import Authorization from '../../Base/Authorization';
import { getTemperatureOptions } from '../../Base/Common/ReferenceDataFunctions';

export default class InputGpt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            showDetails: true,
            menuVisible: false,
            answerSize: "50",
            replyModal: false,
            gptReply: null,
            tempOptions: getTemperatureOptions(),
            temp: 0
        };
    }

    toggleDetails = () => {
        this.setState(prev => ({ showDetails: !prev.showDetails, menuVisible: false }));
    }

    toggleMenuVisible = () => {
        this.setState(prev => ({ showDetails: !prev.showDetails, menuVisible: false }));
    }

    getReply = () => {
        const { answerSize, temp} = this.state;
        const { name, onChange, conversation } = this.props;

        const finalMessage = conversation.filter(obj => {
            return obj.role === 'user'
        })?.pop()?.content ?? '';

        const chatCompletion = {
            input: finalMessage,
            conversation: conversation.splice(-5, 5),
            max_tokens: answerSize * 3, // one word is around 3 tokens
            temperature: temp,
        };
        this.setState({ block: true, menuVisible: false }, () => {

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data?.errors && data.errors.length > 0) {
                    this.setState({ block: false, error: data.errors }, () => handleNotification(data));
                    return;
                };
                const gptReply = data?.response && data?.response[0];

                if (gptReply) {
                    const e = {
                        target: {
                            value: gptReply,
                            name
                        }
                    }
                    this.setState({ block: false, error: null, gptReply }, () => onChange(e));
                }
                else {
                    this.setState({ block: false, error: null }, () => handleNotification(data));
                }
            }, `/api/hotel/FaqsManagement/v1/CreateChat`, chatCompletion);
        });
    }

    handleSize = (e) => {
        this.setState({ answerSize: e?.target?.value ?? '10' });
    }

    handleTemp = (temp) => {
        this.setState({ temp });
    }

    render() {
        const { block, showDetails, answerSize, tempOptions, temp } = this.state;

        return (
            <Authorization
                perform="heyTravelAI:view"
                yes={() => (
                    <BlockUi blocking={block}>
                        <div style={{
                            minHeight: '50px',
                        }}>
                            <div style={{
                                width: '100%',
                                height: '100%',
                            }}>
                                {showDetails ?
                                    <div className='pt-3 px-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '2rem' }}>
                                            <div className={`${!global.isMobile ? 'd-flex' : ''}`}>
                                                <CustomInput
                                                    type="range"
                                                    name="answerSize"
                                                    onChange={this.handleSize}
                                                    id="answerSize"
                                                    className='gptRange'
                                                    min="10"
                                                    max="1000"
                                                    step="10"
                                                    value={answerSize}
                                                />
                                                <div className='ml-2 text-muted' style={{ fontSize: 'smaller' }}>
                                                    <span className='mr-1'>{answerSize}</span>
                                                    <FormattedMessage id="GPT.Words" />
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '1rem' }}>
                                                {tempOptions.map((option, key) =>
                                                    <Badge key={key} className={temp === option.value ? "activeTemp" : "inactiveTemp"} onClick={_ => this.handleTemp(option.value)}>
                                                        <FormattedMessage id={`OpenAI.${option.label}`} />
                                                    </Badge>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <Button style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', fontSize: '0.8em' }} onClick={this.getReply} className="activeTemp generate">
                                                <img className="white" src={ChatGPTWhite} alt="gpt" style={{ width: '15px' }} />
                                                <FormattedMessage id="GPT.Generate" />
                                            </Button>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </BlockUi>
                )}
                no={() => ''}
            />
        )
    }
}