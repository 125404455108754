import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, CustomInput, Input, Row, Badge } from 'reactstrap';
import { BadgeWithRestriction } from './BadgeWithRestriction';

export class ConfigCredentialsForm extends Component {

    render() {
        const { configCredentials, handleChangeOnConfigCredentials, handleChangeOnConfigStatus, saveConfigCredentials, handleChangeOnConfigInfo, getNewConfigCredentials, toggleChangePasswordModal, isDisabled, hasPmsType, hasRmsType } = this.props;

        const pmsData = ["availability", "price", "cta", "ctd", "maxStay", "minStay", "stopSales"];

        const rmsData = ["rmsAvailability", "rmsPrice", "rmsCta", "rmsCtd", "rmsMaxStay", "rmsMinStay", "rmsStopSales"];

        return (
            <div>
                <hr />
                <Row className="pt-2">
                    <Col>
                        <h5>
                            <FormattedMessage id="HotelLicenses.ExternalConfig" />
                        </h5>
                    </Col>
                    <Col className="text-right">
                        {
                            isDisabled ?
                                <Button className="btn btn-host btn-sm" onClick={saveConfigCredentials}>
                                    <i className="fas fa-save" />
                                </Button>
                                :
                                <Button className="btn btn-host btn-sm" onClick={getNewConfigCredentials}>
                                    <FormattedMessage id="HotelLicenses.GenerateCredentials" />
                                </Button>
                        }
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col className="col-2">
                        <FormattedMessage id="ChannelList.PropertyCode" />
                    </Col>
                    <Col className="col-4">
                        <Input type="text" name="propertyCode" value={configCredentials.propertyCode} onChange={handleChangeOnConfigCredentials} required maxLength="20" disabled={isDisabled} />
                    </Col>
                    <Col className="col-2">
                        <FormattedMessage id="HotelLicenses.Active" />
                    </Col>
                    <Col className="col-4">
                        <CustomInput type="switch" checked={configCredentials.active} id="active" name="active" onChange={handleChangeOnConfigStatus} />
                    </Col>
                </Row>
                {
                    hasPmsType ?
                        <div>
                            <Row className="pt-2">
                                <Col>
                                    <h6>
                                        <FormattedMessage id="HotelLicenses.PmsCredentials" />
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col className="col-2">
                                    <FormattedMessage id="HotelLicenses.User" />
                                </Col>
                                <Col className="col-4">
                                    <Input type="text" id="user" name="user" value={configCredentials.user} onChange={handleChangeOnConfigCredentials} required />
                                </Col>
                                <Col className="col-2">
                                    <FormattedMessage id="HotelLicenses.Password" />
                                </Col>
                                <Col className="col-4">
                                    {
                                        isDisabled ?
                                            <Button className="btn btn-host btn-sm mr-1" onClick={_ => toggleChangePasswordModal('password')}>
                                                <FormattedMessage id="HotelLicenses.ChangePassword" />
                                            </Button>
                                            :
                                            <Input type="password" id="password" name="password" value={configCredentials.password} onChange={handleChangeOnConfigCredentials} required disabled={isDisabled} />
                                    }
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col className="col-2">
                                    <FormattedMessage id="HotelLicenses.Url" />
                                </Col>
                                <Col className="col-10">
                                    <Input type="text" id="url" name="url" value={configCredentials.url} onChange={handleChangeOnConfigCredentials} required />
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col className="col-2 pr-1">
                                    <FormattedMessage id="ChannelList.InformationSentToChannel" />
                                </Col>
                                <Col>
                                    {
                                        pmsData.map((el, key) => <BadgeWithRestriction
                                            key={key}
                                            configCredentials={configCredentials}
                                            el={el}
                                            data={rmsData}
                                            idx={key}
                                            handleChangeOnConfigInfo={handleChangeOnConfigInfo}
                                        />)
                                    }
                                </Col>
                            </Row>
                        </div>
                        :
                        <div />
                }
                {
                    hasRmsType ?
                        <div>
                            <Row className="pt-2">
                                <Col>
                                    <h6>
                                        <FormattedMessage id="HotelLicenses.RmsCredentials" />
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col className="col-2">
                                    <FormattedMessage id="HotelLicenses.User" />
                                </Col>
                                <Col className="col-4">
                                    <Input type="text" id="rmsUser" name="rmsUser" value={configCredentials.rmsUser} onChange={handleChangeOnConfigCredentials} required />
                                </Col>
                                <Col className="col-2">
                                    <FormattedMessage id="HotelLicenses.Password" />
                                </Col>
                                <Col className="col-4">
                                    {
                                        isDisabled ?
                                            <Button className="btn btn-host btn-sm mr-1" onClick={_ => toggleChangePasswordModal('rmsPassword')}>
                                                <FormattedMessage id="HotelLicenses.ChangePassword" />
                                            </Button>
                                            :
                                            <Input type="password" id="rmsPassword" name="rmsPassword" value={configCredentials.rmsPassword} onChange={handleChangeOnConfigCredentials} required disabled={isDisabled} />
                                    }
                                </Col>
                            </Row>
                            <Row className="pt-2">
                                <Col className="col-2 pr-1">
                                    <FormattedMessage id="ChannelList.InformationSentToChannel" />
                                </Col>
                                <Col>
                                    {
                                        rmsData.map((el, key) => <BadgeWithRestriction
                                            key={key}
                                            configCredentials={configCredentials}
                                            el={el}
                                            data={pmsData}
                                            idx={key}
                                            handleChangeOnConfigInfo={handleChangeOnConfigInfo}
                                        />)
                                    }
                                </Col>
                            </Row>
                        </div>
                        :
                        <div />
                }
            </div>
        );
    }
}