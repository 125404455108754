import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Form, Button, Label, Input, CardHeader } from 'reactstrap';
import { TitleAndDescriptionMultiLang } from '../../Base/Common/SupportedMultiLang';
import CustomSelect from '../../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import Authorization from "../../Base/Authorization";
import moment from 'moment';
import OfferCard from './OfferCard';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Common/ErrorAlert';
import { CustomSingleDatePicker } from '../../Base/Common/CustomReactDates';

class OfferDetailsCardMobile extends Component {
    render() {
        const { sellItem, status, pricingType, block, error, uploadedImg } = this.props;

        return (
            <Card className="shadow border-0 h-100">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Form onSubmit={this.props.saveOffer}>
                        <CardHeader className="border-bottom bg-white py-2" style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <i className='fas fa-gift mr-1'/>
                                <FormattedMessage id='Offers.OfferDetails'/>
                            </div>
                            <div>
                                <Authorization
                                    perform="offers:save"
                                    yes={() => (
                                        <Button className="btn btn-host btn-sm" type="submit">
                                            <span className="fas fa-save" />
                                        </Button>
                                    )}
                                    no={() => ""}
                                />
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col className="col-6 mb-2">
                                    <Label>
                                        <FormattedMessage id="Offers.serviceCode" />
                                    </Label>
                                    <Input
                                        type="text" required name="serviceCode"
                                        onChange={(e) => this.props.handleChange(e)}
                                        value={sellItem.serviceCode || ''}
                                        maxLength="50"
                                    />
                                </Col>
                                <Col className="col-6 mb-2">
                                    <Label style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', margin: '0' }}>
                                        <FormattedMessage id="Offers.servicePricingType" />
                                    </Label>
                                    <CustomSelect
                                        options={pricingType}
                                        placeholder={''}
                                        value={sellItem.servicePricingType ? pricingType.find(el => el.value === sellItem.servicePricingType) : ''}
                                        onChange={this.props.onChangeSelect.bind(this, 'servicePricingType')}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <Label>
                                        <FormattedMessage id="home.status" />
                                    </Label>
                                    <CustomSelect
                                        options={status}
                                        placeholder={''}
                                        value={sellItem.status ? status.find(el => el.value === sellItem.status) : ''}
                                        onChange={this.props.onChangeSelect.bind(this, 'status')}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <Label>
                                        <FormattedMessage id="Offers.isMandatory" />
                                    </Label>
                                    <CustomSelect
                                        options={this.props.mandatoryOptions}
                                        placeholder={''}
                                        value={this.props.mandatoryOptions.find(el => el.value === sellItem.isMandatory)}
                                        onChange={this.props.onChangeSelect.bind(this, 'isMandatory')}
                                        required
                                    />
                                </Col>
                            </Row>
                            {sellItem?.servicePricingType && sellItem?.serviceCode ?
                                <div>
                                    <Row className='mt-3'>
                                        <Col className='col-12 mb-3'>
                                            <div>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
                                                    <OfferCard
                                                        rates={this.props.rates}
                                                        uploadedImg={uploadedImg}
                                                        item={sellItem}
                                                        hasChildren={!isNaN(sellItem.feeChildAmount)}
                                                    />
                                                </div>
                                                <div className="custom-file mt-4">
                                                    <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        lang={this.props.intl.locale}
                                                        accept="image/*"
                                                        onChange={this.props.addAttach.bind(this)}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        <FormattedMessage id="generic.ChooseFile" />
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className='col-6'>
                                                    <Label>
                                                        <FormattedMessage id="Offers.MinStay" />
                                                    </Label>
                                                    <Input
                                                        id="minStay"
                                                        type="number"
                                                        placeholder={''}
                                                        value={sellItem.minStay}
                                                        onChange={this.props.handleChange}
                                                        min="1"
                                                        max='365'
                                                        name="minStay"
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label>
                                                        <FormattedMessage id="Offers.MaxStay" />
                                                    </Label>
                                                    <Input
                                                        id="maxStay"
                                                        type="number"
                                                        placeholder={''}
                                                        value={sellItem.maxStay}
                                                        onChange={this.props.handleChange}
                                                        min="1"
                                                        max='365'
                                                        name="maxStay"
                                                    />
                                                </Col>
                                                <Col className='col-6'>
                                                    <Label>
                                                        <FormattedMessage id="Offers.DaysBefore" />
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        placeholder={''}
                                                        value={sellItem.daysBefore}
                                                        onChange={this.props.handleChange}
                                                        min="0"
                                                        max='100'
                                                        name="daysBefore"
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label>
                                                        <FormattedMessage id="Offers.offerOrder" />
                                                    </Label>
                                                    <Input
                                                        type="number"
                                                        placeholder={''}
                                                        value={sellItem.sortOrder}
                                                        onChange={this.props.handleChange}
                                                        required
                                                        min="0"
                                                        max='100'
                                                        name="sortOrder"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='col-12 reservationGuests mt-2'>
                                                    <Label>
                                                        <FormattedMessage id="Offers.startDate" />
                                                    </Label>
                                                    <CustomSingleDatePicker
                                                        id="startDate"
                                                        showTodaysButton={true}
                                                        isOutsideRange={_ => false}
                                                        date={sellItem.startDate}
                                                        onDateChange={date => this.props.handleDates('startDate', date)}
                                                        showYearOptions={{ pastYears: true, futureYears: true }}
                                                        numberOfMonths={1}
                                                        showClearDate={true}
                                                    />
                                                </Col>
                                                <Col className='col-12 reservationGuests mt-2'>
                                                    <Label>
                                                        <FormattedMessage id="Offers.endDate" />
                                                    </Label>
                                                    <CustomSingleDatePicker
                                                        id="endDate"
                                                        disabled={!sellItem.startDate}
                                                        isOutsideRange={day => day <= moment(sellItem.startDate)}
                                                        showYearOptions={{ pastYears: true, futureYears: true }}
                                                        date={sellItem.endDate}
                                                        onDateChange={date => this.props.handleDates('endDate', date)}
                                                        showClearDate={true}
                                                        numberOfMonths={1}
                                                    />
                                                </Col>
                                                {sellItem.servicePricingType === 'PerRoom' || sellItem.servicePricingType === 'PerRoomPerNight'
                                                || sellItem.servicePricingType === 'PerStay' ?
                                                    <Col className='col-6 mt-2'>
                                                        <Label>
                                                            <FormattedMessage id="Offers.feeAmount" />
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            name="feeAmount"
                                                            onChange={(e) => this.props.handleChange(e)}
                                                            value={sellItem.feeAmount || ''}
                                                            required
                                                        />
                                                    </Col>
                                                :''}
                                                {sellItem.servicePricingType === 'PerPerson' || sellItem.servicePricingType === 'PerPersonPerNight' ?
                                                    <>
                                                        <Col className='col-6 mt-2'>
                                                            <Label>
                                                                <FormattedMessage id="Offers.adultFeeAmount" />
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                name="feeAmount"
                                                                onChange={(e) => this.props.handleChange(e)}
                                                                value={sellItem.feeAmount || ''}
                                                                required
                                                            />
                                                        </Col>
                                                        <Col className='col-6 mt-2'>
                                                            <Label style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', margin: '0' }}>
                                                                <FormattedMessage id="Offers.feeChildAmount" />
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                name="feeChildAmount"
                                                                required
                                                                onChange={(e) => this.props.handleChange(e)}
                                                                value={sellItem.feeChildAmount}
                                                            />
                                                        </Col>
                                                    </>
                                                :''}
                                                {!sellItem.isMandatory ?
                                                    <>
                                                        <Col className='col-6 mt-2'>
                                                            <Label>
                                                                <FormattedMessage id="Offers.maxElements" />
                                                            </Label>
                                                            <Input
                                                                type="number"
                                                                name="maxElements"
                                                                onChange={(e) => this.props.handleChange(e)}
                                                                value={sellItem.maxElements || ''}
                                                            />
                                                        </Col>
                                                        {sellItem.servicePricingType === 'PerRoom' || sellItem.servicePricingType === 'PerRoomPerNight' ?
                                                            <Col className='col-6 mt-2'>
                                                                <Label>
                                                                    <FormattedMessage id="Offers.maxElementsPerRooms" />
                                                                </Label>
                                                                <Input
                                                                    type="number"
                                                                    name="maxElementsPerRooms"
                                                                    id="maxElementsPerRooms"
                                                                    onChange={(e) => this.props.handleChange(e)}
                                                                    value={sellItem.maxElementsPerRooms || ''}
                                                                />
                                                            </Col>
                                                        :''}
                                                    </>
                                                :''}
                                            </Row>
                                            <Row className="mt-4">
                                                <Col>
                                                    <TitleAndDescriptionMultiLang
                                                        data={{
                                                            texts: sellItem.descriptions,
                                                            handleChange: this.props.handleChangeMultiLang
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            :''}
                        </CardBody>
                    </Form>
                </BlockUi>
            </Card>
        )
    }
}

export default injectIntl(OfferDetailsCardMobile);
