import React, { Component } from 'react';
import { Badge, Row, Col, Input, InputGroup, FormGroup, Label, Button, Card, CardBody, CardHeader, CardFooter, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput } from 'reactstrap';
import { deleteAPI, getAPI, postAPI, putAPI } from "../../../Base/API";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../../../Base/Notification";
import CustomSelect from '../../../Base/Common/CustomSelect';
import { getBeTemplates } from '../../../Base/Common/ReferenceDataFunctions';
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';

export class ComplexClassification extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            error: null,
            block: true,
            HotelClassificationReferenceData: [],
            selectedComplex: this.props.selectedComplex ? this.props.selectedComplex : {},
            colors: [],
            gradients: [],
            hasBE: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedComplex && nextProps.selectedComplex !== this.props.selectedComplex) {
            this.setState({
                selectedComplex: nextProps.selectedComplex
            })
        }
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    hasBE: global.modules && global.modules.some(m => m === 'BookingEngine')
                });
            },
            global.modules ? 0 : 2500
        );


        let { colors, gradients, selectedComplex } = this.state;
        if (selectedComplex.beTemplate) {
            getBeTemplates()
                .filter(bt => bt.value === selectedComplex.beTemplate)
                .forEach(bt => {
                    colors = bt.colors;
                    gradients = bt.gradients;
                });
            this.setState({ colors, gradients });
        }
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ blocking: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ blocking: false, error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ HotelClassificationReferenceData: data ? data : [] }, () => this.getHotelChains());
            }
        }, '/api/hotel/ReferenceData/v1/PCT');    
    }

    getHotelChains = () => {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const hotelChains = data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ hotelChains, block: false });
            }
        }, '/api/hotel/v1/HotelChains');
    }

    handleSubmit(e) {
        e.preventDefault();

        const propertyClassification = document.getElementById('PropertyClassification');

        if (propertyClassification) {
            if (this.state.selectedComplex.propertyClassification && this.state.selectedComplex.propertyClassification < 1 || this.state.selectedComplex.propertyClassification > 5) {
                propertyClassification.setCustomValidity(this.props.intl.formatMessage({ id: "ComplexClassification.InvalidClassification" }));
            } else {
                propertyClassification.setCustomValidity("");
            }
        }

        if (this.form.current.reportValidity()) {
            this.setState({
                block: true
            });

            const params = {
                description: this.state.selectedComplex.description,
                code: this.state.selectedComplex.code,
                classification: this.state.selectedComplex.propertyClassification ? parseInt(this.state.selectedComplex.propertyClassification) : null,
                propertyClassificationId: this.state.selectedComplex.propertyTypeId ? parseInt(this.state.selectedComplex.propertyTypeId) : null,
                rentalLicenceNumber: this.state.selectedComplex.rentalLicenceNumber,
                beTemplate: this.state.selectedComplex.beTemplate
            };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="ComplexClassification.SaveGeralDataSuccess" />, <FormattedMessage id="General.Success" />);
                        if (this.props.updateComplex) {
                            this.props.updateComplex(this.state.selectedComplex);
                        }
                    }
                }
                this.setState({ block: false, error: errorMessage });
            }, '/api/hotel/Complexes/v1/' + this.props.selectedComplex.id + '/Classification', params);
        }
    }

    createComplex = (e) => {
        e.preventDefault();

        const propertyClassification = document.getElementById('PropertyClassification');
        if (this.state.selectedComplex.propertyClassification && this.state.selectedComplex.propertyClassification < 1 || this.state.selectedComplex.propertyClassification > 5) {
            propertyClassification.setCustomValidity(this.props.intl.formatMessage({ id: "ComplexClassification.InvalidClassification" }));
        } else {
            propertyClassification.setCustomValidity("");
        }

        if (this.form.current.reportValidity()) {
            this.setState({ block: true });

            const body = {
                request: {
                    code: this.state.selectedComplex.code,
                    description: this.state.selectedComplex.description,
                    propertyClassification: this.state.selectedComplex.propertyClassification ? parseInt(this.state.selectedComplex.propertyClassification) : null,
                    propertyTypeId: this.state.selectedComplex.propertyTypeId ? parseInt(this.state.selectedComplex.propertyTypeId) : null,
                    rentalLicenceNumber: this.state.selectedComplex.rentalLicenceNumber,
                    beTemplate: this.state.selectedComplex.beTemplate
                }
            };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                        this.setState({ block: false, error: errorMessage });
                        return;
                    }
                    if (data.response) {
                        handleNotification(data, <FormattedMessage id="ComplexClassification.ComplexCreated" />, <FormattedMessage id="General.Success" />);

                        if (this.props.updateComplex)  this.props.updateComplex(data.response[0]);
                        if (this.props.toggle) this.props.toggle();
                    }
                }
                this.setState({ block: false, error: errorMessage });
            }, '/api/hotel/Complexes/v1', JSON.stringify(body));
        }
    }

    setCombo = (name, combo) => {
        let { colors, gradients, selectedComplex } = this.state;
        let value = null;

        if (combo) {
            value = combo.value;
            colors = combo.colors;
            gradients = combo.gradients;
        }
        else {
            colors = [];
            gradients = [];
        }
        selectedComplex[name] = value;
        this.setState({ colors, gradients, selectedComplex });
    }

    verifyStatusOnBE = () => {
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, error: errorMessage });
                }
                else if (data.response) {
                    handleNotification(data, <FormattedMessage id="ComplexClassification.VerifyStatusWithSuccess" />, <FormattedMessage id="General.Success" />);

                    if (this.props.updateComplex) {
                        this.props.updateComplexs(data.response);
                    }
                    const { selectedComplex } = this.state;
                    data.response.forEach(complex => {
                        if (selectedComplex.id === complex.id) {
                            Object.assign(selectedComplex, complex);
                        }
                    });

                    this.setState({ selectedComplex, block: false, error: errorMessage });
                }
                else {
                    this.setState({ block: false, error: errorMessage });
                }
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, `/api/hotel/Complexes/v1/Hotel/${global.hotel.id}/BE`);
    }

    removeComplexOnBE = () => {
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, error: errorMessage });
                }
                else if (data.response) {
                    handleNotification(data, <FormattedMessage id="ComplexClassification.RemoveComplexWithSuccess" />, <FormattedMessage id="General.Success" />);

                    if (this.props.updateComplex) {
                        this.props.updateComplexs(data.response);
                    }
                    const { selectedComplex } = this.state;
                    data.response.forEach(complex => {
                        if (selectedComplex.id === complex.id) {
                            Object.assign(selectedComplex, complex);
                        }
                    });

                    this.setState({ selectedComplex, block: false, error: errorMessage });
                }
                else {
                    this.setState({ block: false, error: errorMessage });
                }
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
            this.setState({ block: false, error: errorMessage });
            
        }, `/api/hotel/Complexes/v1/Hotel/${global.hotel.id}/BE`);
    }
    
    render() {
        const { colors, gradients, selectedComplex } = this.state;

        return (          
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <form ref={this.form}>                    
                    <Card className="small border-0 shadow mb-2">
                        <CardHeader className="border-bottom bg-white">
                            <Row>
                                <Col>
                                    <span className="fas fa-medal " /> <FormattedMessage id="ComplexSetup.Classification" />
                                </Col>
                                <Col className="d-flex justify-content-end align-items-center">
                                    <Badge className="shadow text-white mr-2 mt-2" color={selectedComplex.isPublishedOnBe ? 'success' : 'secondary'}>
                                        {this.props.fromBEConfig || this.state.hasBE ?
                                            selectedComplex.isPublishedOnBe ?
                                                <FormattedMessage id="ComplexClassification.ComplexIsPublishedOnBE" />
                                                : <FormattedMessage id="ComplexClassification.ComplexIsNotPublishedOnBE" />
                                            : ''}
                                    </Badge>

                                    {this.props.fromBEConfig || this.state.hasBE ?
                                        <UncontrolledButtonDropdown className="mr-2">
                                            <DropdownToggle className="btn-host ml-1" caret size="sm">
                                                <FormattedMessage id="ComplexClassification.Actions" />
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem onClick={this.verifyStatusOnBE} >
                                                    <FormattedMessage id="ComplexClassification.VerifyStatus" />
                                                </DropdownItem>
                                                <DropdownItem onClick={this.removeComplexOnBE} disabled={!selectedComplex.isPublishedOnBe}>
                                                    <FormattedMessage id="ComplexClassification.RemoveComplex" />
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    : ''}

                                    <Button color="primary btn-host btn-sm" onClick={this.props.creationMode ? this.createComplex : this.handleSubmit}>
                                        <i className="fas fa-save" />
                                    </Button>

                                    {this.props.fromBEConfig ?
                                        <CommonHelper help={<FormattedMessage id="ComplexClassification.Help" />} id={'ComplexClassificationHelp'} />
                                    : ''}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>        
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="PropertyCode" sm={4}> <FormattedMessage id="ComplexClassification.PropertyCode" /> </Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ComplexClassification.PropertyCode">{placeholder =>
                                                    <Input
                                                        type="text"
                                                        id="PropertyCode"
                                                        name="PropertyCode"
                                                        placeholder={placeholder}
                                                        value={this.state.selectedComplex.code ? this.state.selectedComplex.code : ''}
                                                        onChange={(e) => this.setState({ selectedComplex: { ...this.state.selectedComplex, code: e.target.value } })}
                                                        disabled={global.operationMode !== 'None'}
                                                        required
                                                    />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="PropertyDescription" sm={4}> <FormattedMessage id="ComplexClassification.PropertyDescription" /> </Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ComplexClassification.PropertyDescription">{placeholder =>
                                                    <Input
                                                        type="text"
                                                        id="PropertyDescription"
                                                        name="PropertyDescription"
                                                        placeholder={placeholder}
                                                        value={this.state.selectedComplex.description ? this.state.selectedComplex.description : ''}
                                                        onChange={(e) => this.setState({ selectedComplex: { ...this.state.selectedComplex, description: e.target.value } })}
                                                        disabled={global.operationMode !== 'None'}
                                                        required
                                                    />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="PropertyType" sm={4}> <FormattedMessage id="ComplexClassification.PropertyType" /> </Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ComplexClassification.PropertyType">{placeholder =>
                                                    <Input type="select" name="PropertyType" placeholder={placeholder} value={this.state.selectedComplex.propertyTypeId ? this.state.selectedComplex.propertyTypeId : ''} onChange={(e) => this.setState({ selectedComplex: { ...this.state.selectedComplex, propertyTypeId: e.target.value } })}>
                                                        <option value="" >  </option>
                                                        {this.state.HotelClassificationReferenceData.map((item, key) =>
                                                            <option key={key} value={item.id}>{item.description}</option>
                                                        )}
                                                    </Input>
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="PropertyClassification" sm={4}> <FormattedMessage id="ComplexClassification.PropertyClassification" /> </Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ComplexClassification.PropertyClassification">{placeholder =>
                                                    <Input type="number" min="1" max="5" maxLength="1" id="PropertyClassification" name="PropertyClassification" placeholder={placeholder} value={this.state.selectedComplex.propertyClassification ? this.state.selectedComplex.propertyClassification : ''} onChange={(e) => this.setState({ selectedComplex: { ...this.state.selectedComplex, propertyClassification: e.target.value } })} />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="hotelChain" sm={4}> <FormattedMessage id="HotelLicenses.hotelChain" /> </Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="HotelLicenses.hotelChain">{placeholder =>
                                                    <Input
                                                        type="text"
                                                        id="chainId"
                                                        name="chainId"
                                                        placeholder={placeholder}
                                                        value={this.state.selectedComplex.chainId ? this.state.hotelChains?.find(({value}) => value === this.state.selectedComplex.chainId)?.label : ''}
                                                        disabled={true}
                                                        required
                                                    />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="PropertyRnt" sm={4}> <FormattedMessage id="ComplexClassification.PropertyRnt" /> </Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ComplexClassification.PropertyRnt">
                                                    {placeholder => <Input name="PropertyRnt" placeholder={placeholder} value={this.state.selectedComplex.rentalLicenceNumber ? this.state.selectedComplex.rentalLicenceNumber : ''} onChange={(e) => this.setState({ selectedComplex: { ...this.state.selectedComplex, rentalLicenceNumber: e.target.value } })} />}
                                                </FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {this.props.fromBEConfig || this.state.hasBE ?
                                <>
                                    <hr />

                                    <Row className="mb-3">
                                        <Col>
                                            <h5>
                                                <FormattedMessage id="ComplexClassification.BETEmplate" />
                                            </h5>
                                        </Col>
                                        <Col>
                                            <CustomSelect options={getBeTemplates()} value={this.state.selectedComplex && this.state.selectedComplex.beTemplate ? getBeTemplates().find(t => t.value === this.state.selectedComplex.beTemplate) : ''} onChange={this.setCombo.bind(this, 'beTemplate')} required />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        {
                                            colors.map((c, key) =>
                                                <Col className="col-2 mb-3" key={key}>
                                                    <Card className="p-0">
                                                        <CardBody style={{ backgroundColor: `${c.hex}` }}>
                                                        </CardBody>
                                                        <CardFooter>
                                                            {c.description}
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                        {
                                            gradients.map((c, key) =>
                                                <Col className="col-2 mb-3" key={key}>
                                                    <Card className="p-0">
                                                        <CardBody style={{ background: `${c.hex}` }}>
                                                        </CardBody>
                                                        <CardFooter>
                                                            {c.description}
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </>
                            : ''}
                        </CardBody>
                    </Card>
                </form>           
            </BlockUi>
        );
    }
}
export default injectIntl(ComplexClassification)