import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody, Badge, Row, Col } from 'reactstrap';
import { FormattedMessage, FormattedDate } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { getAPI } from "../../../Base/API"
import { RoomCategorySetupDetails } from './RoomCategorySetupDetails';
import { CustomTable } from '../../../Base/Common/CustomTable';
import { CheckAuthorization } from '../../../Base/Authorization';
import { StyledCard } from '../../../Base/Common/CommonUIComponents';


export class RoomCategorySetup extends Component {

    state = {
        error: null,
        block: true,
        activeTab: '1',
        activeAddressTab: '1',
        RoomData: [],
        selectedRoom: {},
        creationMode: false
    };

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }
    

    toggleModal(creationMode) {
        this.setState(prevState => ({
            modal: !prevState.modal,
            creationMode
        }));
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const rooms = data.response ? data.response.sort((rc1, rc2) => rc1.sortOrder - rc2.sortOrder) : [];

                this.setState({ RoomData: rooms, block: false });
            }
        },  '/api/hotel/RoomCategory/v1/Info');

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleAddress(tab) {
        if (this.state.activeAddressTab !== tab) {
            this.setState({
                activeAddressTab: tab
            });
        }
    }
    
    onToggleDropDown = (toggleDropDown) => {
        toggleDropDown();
    }

    handleRowSelect = (row, isSelected, e) => {
        if (isSelected)
            this.setState({ modal: true, selectedRoom: row })
    }

    priceFormatter(cell, row) {   // String example
        return <FormattedDate value={cell} />;
    }

    showTotalRates(cell, row) {
        return <div>{row.configRates.length}</div>;
    }

    showChannels(cell, row) {
        return <div >{row.configChannels.map((item, key) =>
            <Badge color="primary mr-1">{item.code}</Badge>
        )}</div>;
    }

    updateRoom = (room) => {
        const roomsList = [ ...this.state.RoomData ];
        const idx = roomsList.indexOf(roomsList.find(r => r.id === room.id));

        if (idx >= 0) {
            roomsList[idx].code = room.code;
            roomsList[idx].description = room.description;
            roomsList[idx].sortOrder = room.sortOrder;
        }
        else {
            roomsList.push(room);
        }

        this.setState({ RoomData: roomsList, creationMode: false, selectedRoom: room });
    }

    removeRoom = (id) => {
        const roomsList = [...this.state.RoomData];
        const idx = roomsList.findIndex(r => r.id === id);

        if (idx !== -1) {
            roomsList.splice(idx, 1);
        }

        this.setState({ RoomData: roomsList });
    }

    render() {

        const columns = [
            {
                dataField: 'id',
                text: <FormattedMessage id="hotelconf.room.RoomID" />,
                sort: true
            }, {
                dataField: 'code',
                text: <FormattedMessage id="hotelconf.room.Code" />,
                sort: true
            }, {
                dataField: 'description',
                text: <FormattedMessage id="hotelconf.room.Description" />,
                sort: true
            },{
                dataField: 'defPax',
                text: <FormattedMessage id="hotelconf.room.defPax" />,
                sort: true
            },{
                dataField: 'sortOrder',
                text: <FormattedMessage id="RoomCategorySetupConfig.sortOrder"/>,
                sort: true
            }


            /*, {
                dataField: 'totalDesc',
                text: <FormattedMessage id="hotelconf.room.TotalDescription" />,
                sort: true
            }, {
                dataField: 'totalAmeneties',
                text: <FormattedMessage id="hotelconf.room.TotalAmeneties" />,
                sort: true
            }, {
                dataField: 'totalImages',
                text: <FormattedMessage id="hotelconf.room.Totalimages" />,
                sort: true
            }, {
                dataField: 'TotalRatesMapped',
                text: <FormattedMessage id="ComplexList.complex.totalrates" />,
                sort: true,
                formatter: (cell, row) => <div>{row.configRates.length}</div>
                
            }, {
                dataField: 'configChannels.code',
                text: <FormattedMessage id="ComplexList.complex.totalchannels" />,
                sort: true,
                formatter: (cell, row) => <div >{row.configChannels.map((item, key) =>
                    <Badge color="primary mr-1">{item.code}</Badge>
                )}</div>
            }*/];
        
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                if (CheckAuthorization("category:view", null)) {
                    this.setState({ modal: true, selectedRoom: row, creationMode: false })
                }
                
            }
        };

        if (this.state.RoomData) {
            return (
                <StyledCard block={this.state.block} error={this.state.error} icon="fas fa-bed mr-2" title="RoomCategorySetup.RoomConfiguration"
                    id="RoomCategorySetup" help={<FormattedMessage id="RoomCategorySetup.HelpText" />} >
                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                        <ModalHeader toggle={this.toggleModal}>
                            <FormattedMessage id="RoomCategorySetup.RoomCategory" />
                        </ModalHeader>
                        <ModalBody className="modal-lg px-0 pb-0">
                            <RoomCategorySetupDetails
                                selectedRoom={this.state.selectedRoom}
                                creationMode={this.state.creationMode}
                                toggle={this.toggleModal}
                                updateRoom={this.updateRoom}
                                modal={this.state.modal}
                                removeRoom={this.removeRoom}
                            />
                        </ModalBody>

                </Modal>
                    <Row>
                        <Col className="text-right">
                            {global.operationMode === 'None' ?
                                <button className="btn btn-host btn-sm ml-1" onClick={() => this.toggleModal(true)}><i className="fas fa-plus"></i></button>
                            : ''}
                        </Col>
                    </Row>
                    <CustomTable
                        data={this.state.RoomData}
                        columns={columns}
                        page={this.state.currentPage}
                        sizePerPage={this.state.resultsPerPage}
                        totalSize={this.state.RoomData && this.state.RoomData.length}
                        onTableChange={this.handleTableChange}
                        shadow={false}
                        selectRow={selectRow}
                        search={true}
                        remote={false}
                    />                
                </StyledCard>

            );
        } else {
            return (<BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} /></BlockUi>)
        }
    }
}