import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, FormGroup, Input, InputGroup, Label, Row, Button, CustomInput, Form } from 'reactstrap';
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import CustomSelect from '../../Base/Common/CustomSelect';
import moment from "moment";
import 'moment-timezone';
import { getLanguages } from '../../Base/Common/ReferenceDataFunctions';

export class HotelConfigForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timezones: [],
            beConfigurationModeTypes: [
                {
                    value: 'SinglePage',
                    label: <FormattedMessage id="HotelConfigForm.SinglePage" />
                },
                {
                    value: 'SinglePageMultipleComplex',
                    label: <FormattedMessage id="HotelConfigForm.SinglePageMultipleComplex" />,
                    isDisabled: false
                },
                {
                    value: 'PagePerComplex',
                    label: <FormattedMessage id="HotelConfigForm.PagePerComplex" />,
                    isDisabled: false
                }
            ]
        };
    }

    componentDidMount() {
        const timezones = moment.tz.names();
        const timezoneOptions = timezones.map(timezone => ({ value: timezone, label: `(UTC${moment.tz(timezone).format('Z')}) ${timezone}` }));
        
        this.setState({ timezones: timezoneOptions });    
    }

    render() {
        var emails = this.props.hoteldata.notificationsEmail ? this.props.hoteldata.notificationsEmail.split(';') : [];
        var lowInventoryEmails = this.props.hoteldata.lowInventoryEmail ? this.props.hoteldata.lowInventoryEmail.split(';') : [];

        if (this.props.hoteldata.hotelChainsId) {
            this.state.beConfigurationModeTypes[1].isDisabled = true;
            this.state.beConfigurationModeTypes[2].isDisabled = true;
        }  

        return (
            <StyledCard icon={'fas fa-screwdriver'} title={'PropertySetup.Configuration'}>
                <Row>
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm" onClick={this.props.updateHotel}>
                            <i className="fas fa-save" />
                        </Button>
                    </Col>
                </Row>
                <Row className="py-1">
                    <Col className="col-6">
                        <FormGroup  row>
                            <Label for="defaultCurrencyCode" sm={4}> <FormattedMessage id="HotelConfigForm.CurrencyCode" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="HotelConfigForm.CurrencyCode">{placeholder =>
                                        <Input name="defaultCurrencyCode" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.defaultCurrencyCode} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup  row>
                            <Label for="defaultCultureCode" sm={4}> <FormattedMessage id="HotelConfigForm.CultureCode" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="HotelConfigForm.CultureCode">{placeholder =>
                                        <Input name="defaultCultureCode" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.defaultCultureCode} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup  row>
                            <Label for="defaultTimeZone" sm={4}> <FormattedMessage id="HotelConfigForm.TimeZone" /> </Label>
                            <Col sm={8}>
                                <CustomSelect
                                    isSearchable isClearable
                                    placeholder={"Timezone"}
                                    options={this.state.timezones}
                                    onChange={this.props.handleCombo.bind(this, 'timezone')}
                                    value={this.props.hoteldata && this.props.hoteldata.timezone ? this.state.timezones.find(el => el.value === this.props.hoteldata.timezone) : ''}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="SupportedLang" sm={4}> <FormattedMessage id="HotelConfigForm.SupportedLanguages" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="HotelConfigForm.SupportedLanguages">{placeholder =>
                                        <Input name="SupportedLang" readOnly placeholder={placeholder} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="defaultTimeZone" sm={4}>
                                <FormattedMessage id="HotelConfigForm.minPrice" />
                            </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="HotelConfigForm.minPrice">{placeholder =>
                                        <Input id="minPrice" name="minPrice" placeholder={placeholder} value={this.props.hoteldata.minPrice ? this.props.hoteldata.minPrice : ''} onChange={this.props.handleIntField} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="defaultTimeZone" sm={4}>
                                <FormattedMessage id="HotelConfigForm.ReservationPrefix" />
                            </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="HotelConfigForm.ReservationPrefix">{placeholder =>
                                        <Input id="reservationPrefix" name="reservationPrefix" placeholder={placeholder} value={this.props.hoteldata.reservationPrefix ? this.props.hoteldata.reservationPrefix : ''} onChange={this.props.handleText} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="beConfigurationMode" sm={4}> <FormattedMessage id="HotelConfigForm.BeConfigurationMode" /> </Label>
                            <Col sm={8}>
                                <CustomSelect
                                    isSearchable
                                    options={this.state.beConfigurationModeTypes}
                                    onChange={this.props.handleCombo.bind(this, 'beConfigurationMode')}
                                    value={this.state.beConfigurationModeTypes.find(el => el.value === this.props.hoteldata.beConfigurationMode)}
                                    isClearable
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label sm={4}> <FormattedMessage id="HotelConfigForm.NotificationLanguage" /> </Label>
                            <Col sm={8}>
                                <CustomSelect
                                    isSearchable isClearable
                                    placeholder={<FormattedMessage id="HotelConfigForm.NotificationLanguage" />}
                                    options={getLanguages()}
                                    onChange={this.props.handleCombo.bind(this, 'notificationLanguage')}
                                    value={getLanguages().find(el => el.value === this.props.hoteldata.notificationLanguage) || ''}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label sm={10}>
                                <FormattedMessage id="HotelConfigForm.ReceiveReservationNotif" />
                            </Label>
                            <Col sm={2} className="d-flex align-items-center justify-content-end">
                                <CustomInput
                                    name="receiveReservationNotif"
                                    type="switch"
                                    id="receiveReservationNotif"
                                    checked={this.props.hoteldata.receiveReservationNotif || false}
                                    onChange={(e) => this.props.handleSwitch(e)}
                                />
                        </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label sm={4}>
                                <FormattedMessage id="HotelConfigForm.Email" />
                            </Label>
                            <Col className="col-8 d-flex align-items-center justify-content-end">
                                <FormattedMessage id="HotelConfigForm.EmailToReceiveNotif">{placeholder =>
                                    <Input
                                        name="reservationNotifEmail"
                                        placeholder={placeholder}
                                        value={this.props.hoteldata.reservationNotifEmail ? this.props.hoteldata.reservationNotifEmail : ''}
                                        onChange={this.props.handleText}
                                        required={this.props.hoteldata.receiveReservationNotif || false}
                                    />
                                }</FormattedMessage>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label sm={10}>
                                <FormattedMessage id="HotelConfigForm.ReceiveActivitySummary" />
                            </Label>
                            <Col sm={2} className="d-flex align-items-center justify-content-end">
                                <CustomInput
                                    name="receiveActivitySummary"
                                    type="switch"
                                    id="receiveActivitySummary"
                                    checked={this.props.hoteldata.receiveActivitySummary ? this.props.hoteldata.receiveActivitySummary : false}
                                    onChange={(e) => this.props.handleSwitch(e)}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <Form onSubmit={(e) => this.props.handleEmails(e, 'add', null, 'notificationsEmail')}>
                            <FormGroup row>
                                <Label for="defaultTimeZone" sm={4}>
                                    <FormattedMessage id="HotelConfigForm.NotificationsEmail" />
                                </Label>
                                <Col sm={7}>
                                    <InputGroup size="sm">
                                        <FormattedMessage id="HotelConfigForm.NotificationsEmail">{placeholder =>
                                            <Input
                                                type="email"
                                                name="email"
                                                maxLength="100"
                                                placeholder={placeholder}
                                                onChange={this.props.handleChange}
                                                disabled={!this.props.hoteldata.receiveActivitySummary}
                                                required
                                            />
                                        }</FormattedMessage>
                                    </InputGroup>
                                </Col>
                                <Col sm={1} className="text-right pl-0">
                                    <Button className="btn btn-host btn-sm" type="submit" disabled={!this.props.hoteldata.receiveActivitySummary}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                    </Col>
                    <Col className="col-6">
                        <Row>
                        <Col/>
                        <Col className="col-8">
                            <ul>
                                    {emails.length > 0 && emails.map((el, key) =>
                                        <li key={key}>
                                        <Row>
                                            <Col> {el} </Col>
                                                <Col className="col-2 text-right pr-4" onClick={(e) => this.props.handleEmails(e, 'delete', el, 'notificationsEmail')} style={{ color: 'red', cursor: 'pointer' }}>
                                                <i className="fas fa-times"></i>
                                            </Col>
                                        </Row>
                                    </li>
                                )}
                            </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {global.operationMode === 'None' || this.props.hoteldata?.receiveLowInventoryNotif ?
                    <>
                        <Row>
                            <Col className="col-6">
                                <FormGroup row>
                                    <Label sm={10}>
                                        <FormattedMessage id="HotelConfigForm.ReceiveLowInventoryNotif" />
                                    </Label>
                                    <Col sm={2} className="d-flex align-items-center justify-content-end">
                                        <CustomInput
                                            name="receiveLowInventoryNotif"
                                            type="switch"
                                            id="receiveLowInventoryNotif"
                                            checked={this.props.hoteldata.receiveLowInventoryNotif ? this.props.hoteldata.receiveLowInventoryNotif : false}
                                            onChange={(e) => this.props.handleSwitch(e)}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col className="col-6">
                                <Form onSubmit={(e) => this.props.handleEmails(e, 'add', null, 'lowInventoryEmail')}>
                                    <FormGroup row>
                                        <Label for="defaultTimeZone" sm={4}>
                                            <FormattedMessage id="HotelConfigForm.NotificationsEmail" />
                                        </Label>
                                        <Col sm={7}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="HotelConfigForm.NotificationsEmail">{placeholder =>
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        placeholder={placeholder}
                                                        onChange={this.props.handleChange}
                                                        disabled={!this.props.hoteldata.receiveLowInventoryNotif}
                                                        required
                                                    />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={1} className="text-right pl-0">
                                            <Button className="btn btn-host btn-sm" type="submit" disabled={!this.props.hoteldata.receiveLowInventoryNotif}>
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-6">
                            </Col>
                            <Col className="col-6">
                                <Row>
                                    <Col />
                                    <Col className="col-8">
                                        <ul>
                                            {lowInventoryEmails.length > 0 && lowInventoryEmails.map((el, key) =>
                                                <li key={key}>
                                                    <Row>
                                                        <Col> {el} </Col>
                                                        <Col className="col-2 text-right pr-4" onClick={(e) => this.props.handleEmails(e, 'delete', el, 'lowInventoryEmail')} style={{ color: 'red', cursor: 'pointer' }}>
                                                            <i className="fas fa-times"></i>
                                                        </Col>
                                                    </Row>
                                                </li>
                                            )}
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                :''}
            </StyledCard>
        );
    }
}