import React, { Component } from 'react'
import { ErrorAlert } from '../Common/ErrorAlert';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { Button, Col, Input, Row } from 'reactstrap';
import { CustomDateRangePicker } from '../Base/Common/CustomReactDates';
import CustomSelect from '../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ResultsPerPage, TotalResults, Pagination } from '../Base/Common/PaginationComponents';
import { postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import CustomToolTip from '../../Utils/CustomToolTip';

class EmailSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            emails: this.props.emails,
            statusOptions: [
                { value: 'DataExtracted', label: this.props.intl.formatMessage({ id: 'EmailReservation.DataExtracted' }) },
                { value: 'Ignored', label: this.props.intl.formatMessage({ id: 'EmailReservation.Ignored' }) },
                { value: 'Error', label: this.props.intl.formatMessage({ id: 'EmailReservation.Error' }) },
                { value: 'Received', label: this.props.intl.formatMessage({ id: 'EmailReservation.Received' }) },
                { value: 'NoReservation', label: this.props.intl.formatMessage({ id: 'EmailReservation.NoReservation' }) },
                { value: 'PossibleReservation', label: this.props.intl.formatMessage({ id: 'EmailReservation.PossibleReservation' }) },
                { value: 'Modification', label: this.props.intl.formatMessage({ id: 'EmailReservation.Modification' }) },
                { value: 'AskingInformation', label: this.props.intl.formatMessage({ id: 'EmailReservation.AskingInformation' }) },
                { value: 'ReservationCreated', label: this.props.intl.formatMessage({ id: 'EmailReservation.ReservationCreated' }) }
            ]
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.emails !== this.props.emails)
            this.setState({ emails: nextProps.emails });
    }

    toggleIsSelected = (key) => {
        const arr = this.state.emails;
        arr[key].isSelected = !arr[key].isSelected;
        this.setState({ emails: arr });
    }

    toggleAll = (selectAll) => {
        this.setState({ emails: this.state.emails.map(email => {email.isSelected = selectAll; return email}) });
    }

    changeEmailStatus = (status) => {
        this.setState({ block: true }, () => {
            const body = this.state.emails.filter(({isSelected}) => isSelected)?.map(({id}) => id);

            if(!body || !body.length){
                this.setState({ block: false });
                return;
            }

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(data){
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(err => errorMessage.push({ message: err.message, stack: err.stack, messageType: 'danger' }));
                        this.setState({ block: false, error: errorMessage });
                        return;
                    }
                    else if (data.response && data.response.length > 0) {
                        this.setState({ block: false }, () => {
                            handleNotification(data, <FormattedMessage id="EmailReservation.EmailUpdated" />, <FormattedMessage id="General.Success" />);
                            this.props.clearEmailDetail();
                        });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/Price/EmailReservation/EmailReservation/v1/ChangeEmailStatus?status=${status}`, body);
        });
    }

    render() {
        const { searchFrom, startDate, endDate, pageIndex, pageSize, total, handlePagination, changePageSize, handleFilterCombo, searchEmails, changeDates, changeSearchFrom } = this.props;
        const { block, error, emails, statusOptions } = this.state;

        const anySelected = emails.some(({isSelected}) => isSelected);
        const allSelected = anySelected && !emails.some(({isSelected}) => !isSelected);

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <div>
                    <div>
                        <div>
                            <div className='d-flex align-items-center justify-content-between text-muted' style={{ fontSize: '0.9em' }}>
                                <div style={{ width: '20%' }}>
                                    <div>
                                        <FormattedMessage id="EmailReservation.Date"/>
                                    </div>
                                </div>
                                <div style={{ width: '50%', margin: '0 2.5%' }}>
                                    <div>
                                        <FormattedMessage id="EmailReservation.Search"/>
                                    </div>
                                </div>
                                <div style={{ width: '20%' }}>
                                    <div>
                                        <FormattedMessage id="EmailReservation.Status"/>
                                    </div>
                                </div>
                                <div style={{ width: '5%' }} className='text-right'>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div style={{ width: '20%' }}>
                                    <CustomDateRangePicker
                                        startDate={startDate}
                                        startDateId="startDateId"
                                        isOutsideRange={day => day.isAfter(moment())}
                                        endDate={endDate}
                                        endDateId="endDateId"
                                        onDatesChange={(startDate, endDate)  => changeDates(startDate, endDate)}
                                    />
                                </div>
                                <div style={{ width: '50%', margin: '0 2.5%' }}>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        onChange={changeSearchFrom}
                                        value={searchFrom}
                                    />
                                </div>
                                <div style={{ width: '20%' }}>
                                    <CustomSelect
                                        isClearable
                                        placeholder={''}
                                        options={statusOptions}
                                        onChange={e => handleFilterCombo(e, 'status')}
                                    />
                                </div>
                                <div style={{ width: '5%' }} className='text-right'>
                                    <Button className="btn-sm btn-host" onClick={searchEmails}>
                                        <i className="fas fa-search"/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <div>
                                {emails && emails.length > 0 ?
                                    <>
                                        <div className={`text-muted d-flex align-items-center ${anySelected ? 'justify-content-start' : 'justify-content-between'} px-3`} style={{ fontSize: '0.9em' }}>
                                            <div style={{ width: anySelected ? '3%' : '5%', borderRight: anySelected ? '1px solid' : 'none' }}>
                                                <div onClick={_ => this.toggleAll(!anySelected)} className='cursor-pointer'>
                                                    {allSelected ?
                                                        <i className="far fa-check-square"/>
                                                    : anySelected ?
                                                        <i className="far fa-minus-square"/>
                                                    :
                                                        <i className="far fa-square"/>
                                                    }
                                                </div>
                                            </div>
                                            {anySelected ?
                                                <div className='d-flex ' style={{paddingLeft: '30px'}}>
                                                    <div id='markAsUnreadBtn' onClick={_ => this.changeEmailStatus('DataExtracted')} className='mr-4 cursor-pointer'>
                                                        <i className="far fa-envelope"></i>
                                                        <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={'markAsUnreadBtn'} >
                                                            <FormattedMessage id="EmailReservation.MarkAsUnread" />
                                                        </CustomToolTip>
                                                    </div>
                                                    <div id='ignoreEmailBtn' onClick={_ => this.changeEmailStatus('Ignored')} className='cursor-pointer'>
                                                        <i className="far fa-envelope-open"></i>
                                                        <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={'ignoreEmailBtn'} >
                                                            <FormattedMessage id="EmailReservation.IgnoreEmail" />
                                                        </CustomToolTip>
                                                    </div>
                                                </div>
                                            :
                                                <>
                                                    <div style={{ width: '65%', gap: '5%' }} className='d-flex align-items-center justify-content-start'>
                                                        <div style={{ width: '20%' }}>
                                                            <FormattedMessage id="EmailReservation.From"/>
                                                        </div>
                                                        <div>
                                                            <FormattedMessage id="EmailReservation.Subject"/>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '10%' }} className='d-flex align-items-center justify-content-start'>
                                                        <div>
                                                            <FormattedMessage id="EmailReservation.Status"/>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '15%' }} className='d-flex align-items-center justify-content-between'>
                                                        <div>
                                                            <FormattedMessage id="EmailReservation.ReservationId"/>
                                                        </div>
                                                        <div style={{ minWidth: '10%' }}>
                                                            <FormattedMessage id="EmailReservation.Date"/>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className='mt-1' style={{ fontSize: '0.9em', border: '1px solid #bbc1c9ab', borderRadius: '4px' }}>
                                            {emails.map((email, key) => 
                                                <div onClick={_ => this.props.toggleTab("EmailDetails", email.id)} key={key} className={`EmailSearchLine px-3 py-2 ${email.isSelected ? 'isSelected' : ''}`} style={{ borderTop: key ? '1px solid #d7d8dd' : '' }}>
                                                    <div style={{ width: '5%' }}>
                                                        <div onClick={e => {e.stopPropagation();this.toggleIsSelected(key)}}>
                                                            {email.isSelected ?
                                                                <i className="far fa-check-square"/>
                                                            :
                                                                <i className="far fa-square"/>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '65%', gap: '5%' }} className='d-flex align-items-center justify-content-start cursor-pointer'>
                                                        <div className='text-truncate' style={{ fontWeight: '500', width: '20%' }}>
                                                            <div>{email.from}</div>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-start'>
                                                            <div className='text-truncate'>
                                                                <div>{email.subject}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '10%' }} className='d-flex align-items-center justify-content-start cursor-pointer'>
                                                        <div className="text-right mr-2 text-muted text-truncate">
                                                            {statusOptions.find(({value}) => value === email.status)?.label ?? '-'}
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '15%' }} className='d-flex align-items-center justify-content-between cursor-pointer'>
                                                        <div className="text-right text-muted text-truncate" onClick={e => e.stopPropagation()}>
                                                            {email.resIdValue}
                                                        </div>
                                                        <div className="text-right text-muted text-truncate">
                                                            {moment(email.createdAt).isSame(moment(), 'd') ?
                                                                moment(email.createdAt).format('hh:mm')
                                                            :
                                                                moment(email.createdAt).format('D MMM')
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                :
                                    <div className='text-center'>
                                        <FormattedMessage id="EmailReservation.NoEmails"/>
                                    </div>
                                }
                            </div>
                        
                            <Row className="py-4 smallPagination">
                                <Col>
                                    <ResultsPerPage
                                        changeSizePerPage={changePageSize}
                                        pageSize={pageSize}
                                    />
    
                                    <span className="ml-2">
                                        <TotalResults
                                            end={pageIndex * pageSize + emails.length}
                                            start={pageIndex * pageSize + 1}
                                            total={total}
                                        />
                                    </span>
                                </Col>
                                <Col className="text-right">
                                    <Pagination
                                        isPrevDisabled={pageIndex === 0}
                                        isNextDisabled={total <= ((pageIndex + 1) * pageSize)}
                                        currentPage={pageIndex + 1}
                                        handlePrevButton={() => handlePagination(-1)}
                                        handleNextButton={() => handlePagination(1)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </BlockUi>
        )
    }
}

export default injectIntl(EmailSearch);