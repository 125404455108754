import React, { Component } from 'react';
import moment from 'moment';
import { Table, Button, Card, Row, Col, CardBody } from 'reactstrap';
import { getAPI } from "../Base/API";
import { Pie, Line, Bar } from 'react-chartjs-2';
import { getCountryISO3, getCountryISO2, getChannelConfig } from "../Base/Common/ReferenceDataFunctions"
import { SelectChannelInstance, SelectRoomCategory, SelectRate, CommonHelper } from "../Base/Common/CommonUIComponents"
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { CSVLink } from "react-csv";
import Chart from "react-google-charts";
import getValueList from 'react-select-country-list';
import DataByChannel from './DataByChannel';
import { CustomDateRangePicker } from '../Base/Common/CustomReactDates';


export class AnalyticsDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blockCountry: true,
            blockChannel: true,
            blockChannel: true,
            block: true,
            startDate: moment().add(-15, 'days'),
            endDate: moment().add(+15, 'days'),
            countriesRoomNights: [],
            selectCountryChannel : [],
            data: {},
            revenue: null,
            adr: null,
            occupation: null,
            datamap: null,
            channelCancelTable : [],
            MapData:[],
            CurrContinent: 'world',
            roomCategoryIds: [],
            reservationSummary: [],
            nightsPerChannel: { "datasets": [], "labels": [] },
            dataToExport: null
        };
        this.element = React.createRef()
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.search();
            },
            global.operationMode ? 0 : 2500
        );
    }

    search() {
        this.getRevenueData();
        this.getRoomNightsPerCountry();
        this.getReservationsSummary();
    }

    getRevenueData() {

        let param = '?bookingRevenue=true&fromDate=' + this.state.startDate.format('YYYY-MM-DD') + '&toDate=' + this.state.endDate.format('YYYY-MM-DD');
        if (this.state.roomCategoryIds && this.state.roomCategoryIds.length > 0)
            param += this.state.roomCategoryIds.reduce((acc, curr) => `${acc}&roomCategoryIds=${curr}`, ``);
        if (this.state.rateCodeId)
            param += '&rateCodeId=' + this.state.rateCodeId
        if (this.state.channel)
            param += '&channel=' + this.state.channel


        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage});
                return;
            }
            if (data) {

                let reveneuSum = 0;
                let occupancySum = 0;
                let totalRoomsSum = 0;


                var sumRev = new Map();
                var sumOccupancy = new Map();
                var sumTotalRooms = new Map();


                if (data.data && data.data.length > 0) {
                    data.data.map((el) => {
                        reveneuSum += el.revenue;
                        occupancySum += el.occupancy
                        totalRoomsSum += el.totalRooms

                        if (sumRev.get(el.hotelDate.substring(0, 10)) === undefined) {
                            sumRev.set(el.hotelDate.substring(0, 10), el.revenue);
                            sumTotalRooms.set(el.hotelDate.substring(0, 10), el.totalRooms);
                            sumOccupancy.set(el.hotelDate.substring(0, 10), el.occupancy);
                        } else {
                            sumRev.set(el.hotelDate.substring(0, 10), el.revenue + sumRev.get(el.hotelDate.substring(0, 10)));
                            sumTotalRooms.set(el.hotelDate.substring(0, 10), el.totalRooms + sumTotalRooms.get(el.hotelDate.substring(0, 10)));
                            sumOccupancy.set(el.hotelDate.substring(0, 10), el.occupancy + sumOccupancy.get(el.hotelDate.substring(0, 10)));
                        }
                        return null;
                    });

                }

                let channels = [...sumRev.keys()];
                let channelRevSum = [];
                let occupancyPercent = [];
                let ADR = [];
                let roomNights = [];
                let revPar = [];
                channels.forEach((item, key) => {
                    channelRevSum.push(sumRev.get(item));
                    occupancyPercent.push(parseFloat((sumOccupancy.get(item) / sumTotalRooms.get(item)) * 100).toFixed(2)  );
                    ADR.push(sumRev.get(item) === 0 ? 0 : parseFloat(sumRev.get(item) / sumOccupancy.get(item)).toFixed(2)   );
                    roomNights.push(sumOccupancy.get(item))
                    revPar.push(parseFloat(sumRev.get(item) / sumTotalRooms.get(item)).toFixed(2));

                });


                // Middle page graph with all info
                let obj = {
                    "drawOnChartArea": false,
                    "datasets":
                    [
                        {
                            "label": this.props.intl.formatMessage({ id: "AnalyticsDashboard.ThisYear" }),
                            "data": [],
                            "borderColor": "#4EEBB5",
                            "fill": false,
                            "type": "line",
                            "yAxisID": 'OccupationScale',
                            "borderWidth": "1",
                            "lineTension": "0.5"
                        },
                        {
                            "label": this.props.intl.formatMessage({ id: "AnalyticsDashboard.ADR" }),
                            "data": [],
                            "fill": true,
                            "backgroundColor": "#5FD4FA",
                            "borderWidth": "1"
                        },
                        {
                            "label": this.props.intl.formatMessage({ id: "AnalyticsDashboard.RevPAr" }),
                            "data": [],
                            "fill": true,
                            "backgroundColor": "#FFCA65",
                            "borderWidth": "1"
                        },
                        {
                            "label": this.props.intl.formatMessage({ id: "AnalyticsDashboard.RoomNights" }),
                            "data": [],
                            "fill": true,
                            "backgroundColor": "#95B3F9",
                            "borderWidth": "1"
                        }
                    ],
                    "labels": []
                };

                obj.datasets[0].data = channelRevSum
                obj.datasets[0].label = this.props.intl.formatMessage({ id: "AnalyticsDashboard.Revenue" })

                obj.labels = channels

                obj.datasets[1].data = ADR
                obj.datasets[2].data = revPar
                obj.datasets[3].data = roomNights

                if (global.operationMode !== 'None') {
                    obj.datasets.unshift(
                        {
                            "label": this.props.intl.formatMessage({ id: "AnalyticsDashboard.OccupancyPercentage" }),
                            "data": occupancyPercent,
                            "borderColor": "#4F80F6",
                            "fill": false,
                            "type": "line",
                            "yAxisID": 'RevenueScale',
                            "borderWidth": "1",
                            "lineTension": "0.5",
                        }
                    );
                }

                let objRevenew = {
                    "drawOnChartArea": false,
                    "datasets":
                    [
                        {
                            "label": this.props.intl.formatMessage({ id: "AnalyticsDashboard.OccupancyPercentage" }),
                            "data": [],
                            "fill": false,
                            "borderColor": "#4EEBB5",
                            "backgroundColor": "#4EEBB5",
                            "borderWidth": "1",
                            "lineTension": "0.2"
                        }
                    ],
                    "labels": []
                };
                objRevenew.datasets[0].data = channelRevSum
                objRevenew.labels = channels

                let objADR = {
                    "drawOnChartArea": false,
                    "datasets":
                    [
                        {
                            "label": this.props.intl.formatMessage({ id: "AnalyticsDashboard.OccupancyPercentage" }),
                            "data": [],
                            "fill": false,
                            "borderColor": "#5FD4FA",
                            "backgroundColor": "#5FD4FA",
                            "borderWidth": "1",
                            "lineTension": "0.2"
                        }
                    ],
                    "labels": []
                };
                objADR.datasets[0].data = ADR
                objADR.labels = channels

                let objOccupancy = {
                    "drawOnChartArea": false,
                    "datasets":
                    [
                        {
                            "label": this.props.intl.formatMessage({ id: "AnalyticsDashboard.OccupancyPercentage" }),
                            "data": [],
                            "fill": false,
                            "borderColor": "#4F80F6",
                            "backgroundColor": "#4F80F6",
                            "borderWidth": "1",
                            "lineTension": "0.2"
                        }
                    ],
                    "labels": []
                };
                objOccupancy.datasets[0].data = occupancyPercent
                objOccupancy.labels = channels

                let objRoomNights = {
                    "drawOnChartArea": false,
                    "datasets":
                    [
                        {
                            "label": this.props.intl.formatMessage({ id: "AnalyticsDashboard.OccupancyPercentage" }),
                            "data": [],
                            "fill": false,
                            "borderColor": "#95B3F9",
                            "backgroundColor": "#95B3F9",
                            "borderWidth": "1",
                            "lineTension": "0.2"
                        }
                    ],
                    "labels": []
                };
                objRoomNights.datasets[0].data = roomNights
                objRoomNights.labels = channels
               

                this.setState(
                    {
                        block: false,
                        revenue: parseFloat(reveneuSum).toFixed(2),
                        revPar: totalRoomsSum == 0 ? 0 : parseFloat(reveneuSum / totalRoomsSum).toFixed(2),
                        adr: occupancySum == 0 ? 0 : parseFloat(reveneuSum / occupancySum).toFixed(2), 
                        occupancy: totalRoomsSum == 0 ? 0 : Math.round((occupancySum / totalRoomsSum) * 100),
                        roomNights: occupancySum,
                        totalRoomsSum: totalRoomsSum,
                        revenewStats: objRevenew,
                        adrStats: objADR,
                        ocuppancyStats :objOccupancy,
                        roomNightsStats: objRoomNights,
                        stats: obj,
                        resCurrencyCode: data.data.find(el => el.currencyCode)?.currencyCode
                     });
            }
        }, '/api/Price/v1/Dashboard/getRevenueData'+param);
        
    }

    getRoomNightsPerCountry (){

        let param = '?bookingRevenue=true&fromDate=' + this.state.startDate.format('YYYY-MM-DD') + '&toDate=' + this.state.endDate.format('YYYY-MM-DD');
        if (this.state.roomCategoryIds && this.state.roomCategoryIds.length > 0)
            param += this.state.roomCategoryIds.reduce((acc, curr) => `${acc}&roomCategoryIds=${curr}`, ``);
        if (this.state.rateCodeId)
            param += '&rateCodeId=' + this.state.rateCodeId
        if (this.state.channel)
            param += '&channel=' + this.state.channel
        this.setState({ blockCountry: true });
        getAPI(result => {
            const { data, error } = result;
            
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage });
                return;
            }
            if (data) {

                let arr = {};

                if(data.countriesRoomNights)
                    data.countriesRoomNights.map((el) => {
                        var iso3 = el.countryCode ? getCountryISO3(el.countryCode) : null;
                        let cc = iso3 ? iso3 : 'None'

                        if(arr[cc] === undefined)
                            arr[cc] = {'roomNights': el.roomNights}
                        else
                            arr[cc].roomNights = arr[cc].roomNights + el.roomNights
                        return null;
                    });
                
                var orderedData = Object.keys(arr)
                    .sort((a, b) => arr[b].roomNights - arr[a].roomNights)
                    .reduce((od, el) => {
                        od[el] = arr[el];
                        return od;
                }, {});

                const MapData = [[this.props.intl.formatMessage({ id: "AnalyticsDashboard.Country" }), this.props.intl.formatMessage({ id: "AnalyticsDashboard.RoomNights" }) ]];
                
                var CountryList = getValueList().valueMap

                Object.keys(orderedData).forEach(key => {
                    if(key !== 'None'){
                        var iso = getCountryISO2(key).toLowerCase();
                        MapData.push([{ v: iso, f: CountryList[iso] }, orderedData[key].roomNights]);
                    }
                });

                this.setState(
                    {
                        countriesRoomNights: data.countriesRoomNights,
                        blockCountry: false,
                        data: orderedData,
                        MapData: MapData
                    });
            }
        }, '/api/Price/v1/Dashboard/GetRoomNightsPerCountry' + param);

    }

    getReservationsSummary = () => {
        this.setState({ blockChannel: true });

        let param = '?bookingRevenue=false&fromDate=' + this.state.startDate.format('YYYY-MM-DD') + '&toDate=' + this.state.endDate.format('YYYY-MM-DD');

        if (this.state.roomCategoryIds && this.state.roomCategoryIds.length > 0)
            param += this.state.roomCategoryIds.reduce((acc, curr) => `${acc}&roomCategoryIds=${curr}`, ``);
        if (this.state.rateCodeId)
            param += '&rateCodeId=' + this.state.rateCodeId
        if (this.state.channel)
            param += '&channelInstanceId=' + this.state.channel


        getAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                let dataToExport = null;
                let nightsPerChannel = {
                    "datasets": [{ "label": "RoomNights", "data": [], "backgroundColor": [] }],
                    "labels": []
                };


                if (data.response) {
                    dataToExport = [[
                        this.props.intl.formatMessage({ id: "AnalyticsDashboard.Channel" }), this.props.intl.formatMessage({ id: "AnalyticsDashboard.TotalRoomNight" }),
                        this.props.intl.formatMessage({ id: "AnalyticsDashboard.CancelRoomNights" }), this.props.intl.formatMessage({ id: "AnalyticsDashboard.CancelationInChannel" }),
                        this.props.intl.formatMessage({ id: "AnalyticsDashboard.CancelationTotal" }), this.props.intl.formatMessage({ id: "AnalyticsDashboard.Revenue" }),
                        this.props.intl.formatMessage({ id: "AnalyticsDashboard.RoomRevenue" }), `${this.props.intl.formatMessage({ id: "AnalyticsDashboard.Reservations" })} ${this.props.intl.formatMessage({ id: "AnalyticsDashboard.NonCanceled" })}`,
                        this.props.intl.formatMessage({ id: "AnalyticsDashboard.AvgLenStay" }), this.props.intl.formatMessage({ id: "AnalyticsDashboard.ADR" }),
                        this.props.intl.formatMessage({ id: "AnalyticsDashboard.AvgLedStay" }), this.props.intl.formatMessage({ id: "AnalyticsDashboard.TotalReservas" }),
                    ]]

                    nightsPerChannel.datasets[0].backgroundColor = this.getColors(data.response.length);

                    data.response.forEach((el) => {
                        const totalNights = el.totalNights + el.canceledNights;

                        nightsPerChannel.labels.push(el.channelCode);
                        nightsPerChannel.datasets[0].data.push(el.totalNights);


                        dataToExport.push([
                            el.channelCode, totalNights, el.canceledNights, `${parseFloat((el.canceledNights / totalNights) * 100).toFixed(2)} %`,
                            `${parseFloat((el.canceledNights / data.response.reduce((a, b) => a + b.canceledNights, 0)) * 100).toFixed(2)} %`,
                            parseFloat(el.revenue).toFixed(2), parseFloat(el.roomRevenue).toFixed(2), el.totalNonCanceledReservations,
                            el.avgLenStay, parseFloat(el.adr).toFixed(2), el.avgLedTime, el.totalReservations
                        ])
                    });
                }


                this.setState({
                    nightsPerChannel,
                    reservationSummary: data.response?.sort((a, b) => b.roomRevenue - a.roomRevenue)??[],
                    dataToExport,
                    blockChannel: false
                });
            }
            else {
                this.setState({ nightsPerChannel: { "datasets": [], "labels": [] }, reservationSummary: [], blockChannel: false });
            }
        }, '/api/Price/v1/Dashboard/ReservationsSummary' + param);
    }

    getColors = (length) => {
        const colors = ["rgb(0, 214, 255)", "rgb(0, 177, 219)", "rgb(2, 141, 182)", "rgb(2, 108, 145)",
            "rgb(0, 136, 124)", "rgb(0, 167, 151)", "rgb(0, 202, 183)", "rgb(0, 236, 214)",
            "rgb(0, 236, 144)", "rgb(0, 223, 136)", "rgb(0, 204, 124)", "rgb(0, 176, 108)",
            "rgb(133, 176, 0)", "rgb(194, 184, 0)", "rgb(221, 210, 0)", "rgb(236, 198, 0)",
            "rgb(149, 179, 249)", "rgb(128, 155, 217)", "rgb(95, 114, 159)", "rgb(90, 91, 150)"
        ];

        if (length > colors.length) {
            const aux = ['rgb(181, 228, 255)', 'rgb(184, 255, 181)', 'rgb(255, 236, 138)', "rgb(216, 155, 249)"];

            for (var i = 4; i <= length; i++) {
                const idx = i % 4;
                const prevColor = aux[idx].substring(4, aux[idx].length - 1).replace(/ /g, '').split(',');

                colors.push(aux[idx]);

                const newColor = `rgb(${parseInt(prevColor[0]) - 11}, ${parseInt(prevColor[1]) - 15}, ${parseInt(prevColor[2]) - 5})`;

                aux[idx] = newColor;
            }
        }

        return colors;
    }

    setComboStatus = (evt, inputValue, actionMeta) => {
        this.setState({
            [evt]: inputValue ? inputValue.value : null
        })
    }

    setRoomCategory = (_, combo) => {
        this.setState({ roomCategoryIds: combo ? combo.map(cmb => cmb.value) : [] });
    }

    render() {
        let optionsClean = {
            elements: {
                point: {
                    radius: 0
                }
            },
            tooltips: {
                Align: 'top'
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    display: false,
                }],
                yAxes: [{
                    display: false,

                }]
            }
        }

        let options = {
            elements: {
                point: {
                    radius: 0
                }
            },
            legend: {
                position: "top"
            },
            scales: {
                xAxes: [{
                    display: true,
                    ticks: { beginAtZero: true, min: 0 },
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
               yAxes: [
                    {
                        type: 'linear',
                        ticks: { beginAtZero: true, precision: 0, min: 0 },
                        display: true,
                        position: 'right',
                        id: 'RevenueScale',
                        gridLines: {
                            display: false
                        }
                    },
                    {
                        ticks: { beginAtZero: true, precision: 0, min: 0 },
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'OccupationScale',
                        gridLines: {
                            display: false
                        },
                        labels: {
                            show: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: this.props.intl.formatMessage({ id: "AnalyticsDashboard.Revenue" })
                        }
                    }]
                }
            }

        const data = [
            {
                label: this.props.intl.formatMessage({ id: "AnalyticsDashboard.Date" }),
                values: this.state.stats && this.state.stats.labels
            }
        ];

        this.state.stats && this.state.stats.datasets.map(dataset => {
            const obj = {
                label: dataset.label,
                values: dataset.data
            };
            data.push(obj);
            return null;
        });

        const GetContinent = (country) => {
            
            var list = {
                '002':[
                    'AO', 'BF', 'BI', 'BJ', 'BW', 'CD', 'CF', 'CG', 'CI', 'CM', 'CV', 'DJ', 'DZ', 'EG', 'EH', 'ER', 'ET',
                    'GA', 'GH', 'GM', 'GN', 'GQ', 'GW', 'KE', 'KM', 'LR', 'LS', 'LY', 'MA', 'MG', 'ML', 'MR', 'MU', 'MW',
                    'MZ', 'NA', 'NE', 'NG', 'RE', 'RW', 'SC', 'SD', 'SH', 'SL', 'SN', 'SO', 'ST', 'SZ', 'TD', 'TG', 'TN',
                    'TZ', 'UG', 'YT', 'ZA', 'ZM', 'ZW',
                ],
                '142':[
                    'AE', 'AF', 'AM', 'AP', 'AZ', 'BD', 'BH', 'BN', 'BT', 'CC', 'CN', 'CX', 'CY', 'GE', 'HK', 'ID', 'IL',
                    'IN', 'IO', 'IQ', 'IR', 'JO', 'JP', 'KG', 'KH', 'KP', 'KR', 'KW', 'KZ', 'LA', 'LB', 'LK', 'MM', 'MN',
                    'MO', 'MV', 'MY', 'NP', 'OM', 'PH', 'PK', 'PS', 'QA', 'SA', 'SG', 'SY', 'TH', 'TJ', 'TL', 'TM','TR',
                    'TW', 'UZ', 'VN', 'YE',
                ],
                '150':[
                    'AD', 'AL', 'AT', 'AX', 'BA', 'BE', 'BG', 'BY', 'CH', 'CZ', 'DE', 'DK', 'EE', 'ES', 'EU', 'FI', 'FO',
                    'FR', 'FX', 'GB', 'GG', 'GI', 'GR', 'HR', 'HU', 'IE', 'IM', 'IS', 'IT', 'JE', 'LI', 'LT', 'LU', 'LV',
                    'MC', 'MD', 'ME', 'MK', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'RS', 'RU', 'SE', 'SI', 'SJ', 'SK', 'SM',
                    'UA', 'VA',
                ],
                '019':[
                    'AG', 'AI', 'AN', 'AW', 'BB', 'BL', 'BM', 'BS', 'BZ', 'CA', 'CR', 'CU', 'DM', 'DO', 'GD', 'GL', 'GP',
                    'GT', 'HN', 'HT', 'JM', 'KN', 'KY', 'LC', 'MF', 'MQ', 'MS', 'MX', 'NI', 'PA', 'PM', 'PR', 'SV', 'TC',
                    'TT', 'US', 'VC', 'VG', 'VI', 'AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GF', 'GY', 'PE', 'PY', 'SR',
                    'UY', 'VE',
                ],
                '009':[
                    'AS', 'AU', 'CK', 'FJ', 'FM', 'GU', 'KI', 'MH', 'MP', 'NC', 'NF', 'NR', 'NU', 'NZ', 'PF', 'PG', 'PN',
                    'PW', 'SB', 'TK', 'TO', 'TV', 'UM', 'VU', 'WF', 'WS',
                ]
            };

            const GeoChart = document.getElementsByClassName('ChartGeoChart')[0]

            for(let c in list){
                if (list[c].includes(country.toUpperCase())){
                    if(this.state.CurrContinent !== c){
                        GeoChart.style.cursor = 'zoom-out'
                        this.setState({CurrContinent: c});
                    }
                    else{
                        GeoChart.style.cursor = 'zoom-in'
                        this.setState({CurrContinent: 'world'});
                    }
                    return
                }
            }
        }

        const exportMapData = [ [this.props.intl.formatMessage({ id: "AnalyticsDashboard.Country" }), this.props.intl.formatMessage({ id: "AnalyticsDashboard.RoomNights" }) ]];

        Object.keys(this.state.data).forEach(key => {
            const array = [ key, this.state.data[key].roomNights ];
            exportMapData.push(array);
        });

        const { MapData, CurrContinent, roomCategoryIds } = this.state;

        return (
            <Card className="border-0">
                <Row className="mb-2 ">
                    <Col>
                        <Card className="shadow ">
                            <CardBody>
                                <Row className="mb-2">
                                    <Col>
                                        <SelectChannelInstance icon={'fa fa-plug fa-fw'} isMulti={false} name={'channel'} onChangeFunc={this.setComboStatus} placeholder={<FormattedMessage id="AnalyticsDashboard.SelectChannel" />} value={this.state.channel}/>
                                    </Col>
                                    <Col>
                                        <SelectRoomCategory icon={'fa fa-bed fa-fw'} isMulti={true} name={'roomCategoryId'} onChangeFunc={this.setRoomCategory} placeholder={<FormattedMessage id="AnalyticsDashboard.SelectRoomCategory" />} />
                                    </Col>
                                    <Col>
                                        <SelectRate icon={'fa fa-gift fa-fw'} isMulti={false} name={'rateCodeId'} onChangeFunc={this.setComboStatus} placeholder={<FormattedMessage id="AnalyticsDashboard.SelectRate" />} value={this.state.rateCodeId}/>
                                    </Col>
                                    <Col>
                                        <CustomDateRangePicker
                                            startDate={this.state.startDate}
                                            startDateId="your_unique_start_date_id_announcement"
                                            isOutsideRange={day => day <= moment().subtract(12, 'month') || day > moment().add(12, 'month')}
                                            showYearOptions={{ pastYears: true, futureYears: true }}    
                                            endDate={this.state.endDate}
                                            endDateId="your_unique_end_date_id_announcement"
                                            onDatesChange={(startDate, endDate) => this.setState({ startDate: startDate, endDate: endDate })}
                                            numberOfMonths={1}         
                                        />
                                    </Col>
                                    <Col>
                                        <CommonHelper help={<FormattedMessage id="AnalyticsDashboard.Help" />} id={'analyticsdashboardhelp'} /> 
                                        <Button className="float-right btn btn-sm btn-host" onClick={this.search.bind(this)}>
                                            <i className="fa fa-search" />
                                        </Button>                
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-2" >
                    <Col className="col-xl-3 my-1">
                        <Card className="shadow h-100" body>
                            <BlockUi tag="div" blocking={this.state.block}>
                                <Row className="row no-gutters align-items-center">
                                    <Col className="mr-1">
                                        <Row>
                                            <Col className="col-4">
                                                <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                    <FormattedMessage id="AnalyticsDashboard.Revenue" />
                                                </div>
                                            </Col>
                                            <Col className="pl-0 mb-1 text-right">
                                                <span className="text-xs font-weight-bold text-secondary text-uppercase" style={{ fontSize: '1.6rem' }}>
                                                    {this.state.revenue} 
                                                </span>
                                                <span style={{fontSize:'1.6rem'}} className="pl-2 text-xs font-weight-bold text-secondary text-uppercase">
                                                    {this.state.resCurrencyCode || global.hotelCurrency}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Line data={this.state.revenewStats} height={'50px'} options={optionsClean} /> 
                                    </Col>
                                </Row>
                            </BlockUi>
                        </Card>
                    </Col>
                    <Col className="col-xl-3 my-1">
                        <Card className="shadow h-100" body>
                            <BlockUi tag="div" blocking={this.state.block}>
                                <Row className="row no-gutters align-items-center">
                                    <Col className="mr-1">
                                        <Row>
                                            <Col>
                                                <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                    <FormattedMessage id="AnalyticsDashboard.ADR" />
                                                </div>
                                            </Col>
                                            <Col className="pl-0 mb-1 text-right">
                                                <span className="text-xs font-weight-bold text-secondary text-uppercase" style={{ fontSize: '1.6rem' }}>
                                                    {this.state.adr}
                                                </span>
                                                <span style={{fontSize:'1.6rem'}} className="pl-2 text-xs font-weight-bold text-secondary text-uppercase">
                                                    {this.state.resCurrencyCode || global.hotelCurrency}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Line data={this.state.adrStats} height={'50px'} options={optionsClean} />
                                    </Col>
                                </Row>
                            </BlockUi>
                        </Card>
                    </Col>
                    <Col className="col-xl-3 my-1">
                        <Card className="shadow h-100" body>
                            <BlockUi tag="div" blocking={this.state.block} className="h-100">
                                <Row className="row no-gutters align-items-center h-100">
                                    <Col className="mr-1 h-100">
                                        {global.operationMode === 'None' ?
                                            <div className="h-100 d-flex flex-column justify-content-between">
                                                <Row>
                                                    <Col className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                        <FormattedMessage id="AnalyticsDashboard.Occupancy" />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-xs font-weight-bold text-secondary  mb-2 text-right">
                                                        <FormattedMessage id="AnalyticsDashboard.NotApplicable" />
                                                        
                                                    </Col>
                                                </Row>
                                            </div>
                                        :
                                            <>
                                                <Row>
                                                    <Col>
                                                        <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                            <FormattedMessage id="AnalyticsDashboard.Occupancy" />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div style={{ fontSize: '1.6rem' }} className="text-xs font-weight-bold text-secondary text-uppercase mb-1 text-right">
                                                            {this.state.occupancy} %
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Line data={this.state.ocuppancyStats} height={'50px'} options={optionsClean} />
                                            </>
                                        }
                                    </Col>
                                </Row>   
                            </BlockUi>
                        </Card>
                    </Col>
                    <Col className="col-xl-3 my-1">
                        <Card className="shadow h-100" body>
                            <BlockUi tag="div" blocking={this.state.block}>
                                <Row className="row no-gutters align-items-center">
                                    <Col className="mr-1">
                                        <Row>
                                            <Col>
                                                <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                    <FormattedMessage id="AnalyticsDashboard.RoomNights" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div style={{fontSize:'1.6rem'}} className="text-xs font-weight-bold text-secondary text-uppercase mb-1 text-right">
                                                    # {this.state.roomNights}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Line data={this.state.roomNightsStats} height={'50px'} options={optionsClean} />
                                    </Col>
                                </Row>
                            </BlockUi>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="AnalyticsBox h-100 col-6" style={{ height: '490px' }}>
                        <BlockUi className="h-100" tag="div" blocking={this.state.block}>
                            <Card className="shadow h-100">
                                <CardBody>
                                    <Row>
                                        <Col className="text-right">
                                            {
                                                data && data.length > 1 ?
                                                    <CSVLink className="btn btn-sm btn-host mr-1" data={data}>
                                                        <i className="fas fa-file-download" />
                                                    </CSVLink>
                                                    :
                                                    <div/>
                                            }
                                            <CommonHelper help={<FormattedMessage id="AnalyticsDashboard.LineChartHelp" />} id={'LineChartHelp'} /> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {/* <Line data={this.state.stats} height={'120px'} options={options} /> */}
                                            <div className="chartWrapper">
                                                <div className="chartAreaWrapper">
                                                    <Bar
                                                    height={142}
                                                    data={this.state.stats}
                                                    options={options}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </BlockUi>
                    </Col>
                    <Col className="AnalyticsBox h-100 col-6" style={{ height: '490px' }}>
                        <Card className="shadow h-100 my-0 py-0">
                            <BlockUi tag="div" blocking={this.state.blockCountry}>
                                <CardBody>
                                    <Row>
                                        <Col className="text-right mb-2">
                                            {
                                                exportMapData && exportMapData.length > 1 ?
                                                    <CSVLink className="btn btn-sm btn-host mr-1" data={exportMapData} filename={"WorldMap.csv"}>
                                                        <i className="fas fa-file-download" />
                                                    </CSVLink>
                                                    :
                                                    <div />
                                            }
                                            <CommonHelper help={<FormattedMessage id="AnalyticsDashboard.WorldMapHelp" />} id={'WorldMapHelp'} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-9">
                                            <Chart
                                                ref={this.element}
                                                width='100%'
                                                height='auto'
                                                chartType="GeoChart"
                                                data={MapData}
                                                options={{
                                                    region: CurrContinent,
                                                    colorAxis: {colors: ['#ccdbe2', '#004c6d']},
                                                    enableRegionInteractivity:true
                                                }}
                                                style={{
                                                    cursor:'zoom-in'
                                                }}
                                                className='ChartGeoChart'
                                                chartEvents={[{
                                                eventName: "select",
                                                    callback(e) {
                                                        const {chartWrapper} = e
                                                        const selectedId = chartWrapper.getChart().getSelection();
                                                        if (selectedId.length) {
                                                            GetContinent(MapData[selectedId[0].row + 1][0].v)
                                                        }
                                                    }
                                                }]}
                                            />
                                        </Col>
                                        <Col className="col-3 scrollableDiv roomNightsByCountry">
                                            <Row>
                                                {
                                                    Object.keys(this.state.data).map((key) => {
                                                        let c = getCountryISO2(key);
                                                        let cc = c ? c.toLowerCase() : '';
                                                        return (
                                                            <Col key={key} className="col-6 pb-2 px-1">
                                                                <div style={{fontWeight:'500', fontSize:'1.4rem'}}>
                                                                    <span className={' mb-1 mr-1 flag-icon flag-icon-' + cc} /> {this.state.data[key].roomNights}
                                                                </div>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Col>
                                    </Row>                            
                                </CardBody>
                            </BlockUi>
                        </Card>                            
                    </Col>
                </Row>

                <DataByChannel
                    nightsPerChannel={this.state.nightsPerChannel}
                    reservationSummary={this.state.reservationSummary}
                    dataToExport={this.state.dataToExport}
                    blockChannel={this.state.blockChannel}
                />
            </Card>
        );
    }
}
export default injectIntl(AnalyticsDashboard)