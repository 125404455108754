import React, { Component } from 'react';
import { Card, Col, Row, Button, Form, Input, Popover, PopoverBody, Modal, ModalBody, ButtonGroup } from 'reactstrap';
import { putAPI, postMultiPartAPI, deleteAPI, getAPI } from "../../Base/API";
import { FormattedMessage } from 'react-intl';
import { handleNotification } from "../../Base/Notification";
import { ErrorAlert } from "../../Common/ErrorAlert";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import InputGPT from './InputGpt';
import ChatGPTWhite from '../../../img/chatgptwhite.png';
import Authorization from '../../Base/Authorization';

export class BookingChatWindow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            message: null,
            conversation: null,
            popoverOpen: false,
            fileContent: '',
            file: null,
            conversationId: null,
            modal: false,
            attachment: null,
            openGpt: false,
            senderList: []
        }
    }

    componentDidMount() {
        const messages = this.props.selectedConversation && this.props.selectedConversation.conversation.messages && this.props.selectedConversation.conversation.messages.reverse();

        this.setState({
            conversation: messages,
            conversationId: this.props.selectedConversation && this.props.selectedConversation.conversation && this.props.selectedConversation.conversation.conversation_id
        }, this.scrollToLastMessage)
    }

    scrollToLastMessage = () => {
        const chatWindow = document.getElementById("chatWindow");

        if (chatWindow) {
            chatWindow.scrollTo(0, chatWindow.scrollHeight);
        }
    }

    componentWillReceiveProps(nextProps) {
        if ((this.props && this.props.selectedConversation) !== (nextProps && nextProps.selectedConversation)) {
            const messages = nextProps.selectedConversation && nextProps.selectedConversation.conversation.messages && nextProps.selectedConversation.conversation.messages.reverse();
            this.setState({
                conversation: messages,
                conversationId: nextProps.selectedConversation && nextProps.selectedConversation.conversation && nextProps.selectedConversation.conversation.conversation_id,
                fileContent: '',
                file: null,
                popoverOpen: false,
                openGpt: false,
                message: null
            }, this.scrollToLastMessage)
        }
    }
           
    submitMessage = (e, conversation_id) => {
        e.preventDefault();
        this.setState({ block: true });

        const formData = new FormData();
        formData.append('ChannelInstanceId', this.props.selectedConnection);
        formData.append('ConversationId', conversation_id);
        formData.append('Message', this.state.message);
        if (this.state.file) {
            formData.append('Attachment', this.state.file);
        }

        postMultiPartAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.data && data.data.ok === true) {
                    handleNotification(data, <FormattedMessage id="BookingChatWindow.MessageSent" />, <FormattedMessage id="General.Success" />);
                    this.props.selectConversation(conversation_id);
                    this.setState({ block: false, error: errorMessage, message: '' }, this.scrollToLastMessage);
                }
                return;
            }
            this.setState({ block: false });
        }, `/api/Rate/MailBox/v1/messages/conversation/postmessage`, formData);
    }

    getParicipantType = (sender_id) => {
        var senderType = '';

        senderType = this.props.selectedConversation.conversation.participants.find(sc => sc.participant_id === sender_id);

        return senderType && senderType.metadata ? senderType.metadata.type : '';
    }

    getGptConversation = () => {
        const { conversation } = this.state;

        let gptConversation = conversation && conversation.length > 0 ?
            conversation.map(x => { return { content: x.content, role: this.props.selectedConversation?.conversation?.participants?.find(sc => sc.participant_id === x.sender_id)?.metadata?.type == 'guest' ? 'user' : 'assistant' } }) : [];
        this.setState({ gptConversation })
    }

    togglePopover = () => {
        this.setState(prevState => ({
            popoverOpen: !prevState.popoverOpen
        }));
    }

    toggleGpt = () => {
        this.setState(prevState => ({
            openGpt: !prevState.openGpt
        }), () => this.getGptConversation());
    }

    addAttach = () => {
        var file = document.querySelector('input[type=file]').files[0];

        if (file) {
            if (file.size <= 1048576) { //1MB
                const reader = new FileReader();
                this.setState({
                    file: file
                })

                reader.addEventListener("load", () => {
                    this.setState({
                        fileContent: reader.result
                    })
                }, false);

                if (file) {
                    reader.readAsDataURL(file);
                }
            }
            else {
                handleNotification('', <FormattedMessage id="BookingChatWindow.PleaseSelectAFileLessThan" />, <FormattedMessage id="BookingChatWindow.SelectedFileIsTooBig" />, 'info');
            }
        }
        else {
            this.setState({ fileContent: '', file: null })
        }
    }
    
    markAsRead = (e, senderId, msgId) => {
        e.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.data && data.data.ok === true) {
                    handleNotification(data, <FormattedMessage id="BookingChatWindow.MarkedAsRead" />, <FormattedMessage id="General.Success" />);
                    this.props.selectConversation(this.state.conversationId);
                    this.setState({ block: false, error: errorMessage, message: '' });
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/Rate/MailBox/v1/messages/${this.props.selectedConnection}/conversation/${this.state.conversationId}/MarkAsRead/${senderId}?messagesIds=${msgId}`);
    }

    removeReadTag = (e, senderId, msgId) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0 || data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.data && data.data.ok === true) {
                    handleNotification(data, <FormattedMessage id="BookingChatWindow.ReadTagRemoved" />, <FormattedMessage id="General.Success" />);
                    this.props.selectConversation(this.state.conversationId);
                    this.setState({ block: false, error: errorMessage, message: '' });
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/Rate/MailBox/v1/messages/${this.props.selectedConnection}/conversation/${this.state.conversationId}/MarkAsRead/${senderId}?messagesIds=${msgId}`);
    }

    getAttachment = (e, id) => {
        e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.data && data.data.ok === true) {
                    this.setState({
                        attachment: {
                            ...this.state.attachment,
                            content: data.data.file_content
                        }
                    }, () => this.getAttachmentMetada(id));
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/Rate/MailBox/v1/messages/${this.props.selectedConnection}/conversation/${this.state.conversationId}/attachment/${id}`);
    }

    getAttachmentMetada = (id) => {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.data && data.data.ok === true) {
                    this.setState({
                        attachment: {
                            ...this.state.attachment,
                            name: data.data.file_name,
                            type: data.data.file_type,
                            size: data.data.file_size
                        },
                        modal: true, block: false
                    });
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/Rate/MailBox/v1/messages/${this.props.selectedConnection}/conversation/${this.state.conversationId}/attachment/${id}/metadata`);
    }

    changeMessage = (e) => {
        this.setState({ message: e.target.value }, () => { if (this.state.openGpt) this.getGptConversation() })
    }
    render() {
        const { conversation, message, openGpt, gptConversation } = this.state;

        const readAndWrite = this.props.selectedConversation.conversation && this.props.selectedConversation.conversation.access === "read_write";

        return (
            <div style={{ backgroundColor: 'whitesmoke' }}>
                {global.isMobile ?
                    <Col className='pt-2'>
                        <span className="fas fa-arrow-left pointer" onClick={() => this.props.selectConversation(null)}></span>
                    </Col>
                :''}
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <div id="chatWindow" className="scrollableDiv px-3" style={{ overflowY: 'auto', overflowX: 'hidden', height: (readAndWrite ? '58vh' : '67vh') }}>
                        {conversation && conversation.map((msg, key) =>
                            <div key={key}>
                                <Row>
                                    <Col className="col-12 text-center text-secondary mt-3 mb-2">{moment(msg.timestamp).fromNow() + " , " + moment(msg.timestamp).format("YYYY-MM-DD HH:mm")}</Col>
                                </Row>
                                <Row>
                                    {msg.content || msg.attachment_ids ?
                                        <Col>
                                            <Card body style={{ width: 'fit-content', borderRadius: '20px' }} className={(this.getParicipantType(msg.sender_id) === "property" ? "float-right" : "float-left bg-soft-blue") + " py-2 px-3 shadow"}>  
                                                <div className="mailboxballon">
                                                    {msg.content} 
                                                    {msg.attachment_ids && msg.attachment_ids.length > 0 ? <i className="fa-sm fas fa-paperclip ml-2 pointer" onClick={(e) => this.getAttachment(e, msg.attachment_ids[0])}/> : ''}
                                                    {msg.tags && msg.tags.read && msg.tags.read.set === true ?
                                                        <i className="fa-sm fas fa-check pointer ml-2" style={{ color: '#3ec15c' }} onClick={(e) => this.removeReadTag(e, msg.sender_id, msg.message_id)} />
                                                        :
                                                        <i className="fa-sm fas fa-check pointer ml-2" onClick={(e) => this.markAsRead(e, msg.sender_id, msg.message_id)} />
                                                    }
                                                </div>
                                            </Card>
                                        </Col>
                                    : ''}
                                </Row>
                            </div>
                        )}
                    </div>

                    <div className="px-3">
                        {readAndWrite ?
                            <Form onSubmit={(e) => this.submitMessage(e, this.props.selectedConversation.conversation.conversation_id)}>
                                <Row className="mt-3 pb-3">
                                    <Col className="col-lg-10 col-9 pr-0">
                                        <Input
                                            type="textarea"
                                            required
                                            value={message ? message : ''}
                                            onChange={(e) => this.changeMessage(e)}>
                                        </Input>
                                    </Col>
                                    <Col className="col-lg-2 col-3 text-right">
                                        <ButtonGroup>
                                            <Button className=" btn btn-host  btn-sm mr-1" type="submit">
                                                <span className="fas fa-play"> </span>
                                            </Button>
                                            <Authorization
                                                perform="heyTravelAI:view"
                                                yes={() => (
                                                    <Button className=" btn btn-host  btn-sm mr-1" onClick={() => this.toggleGpt()}>
                                                        <img className="white" src={ChatGPTWhite} alt="gpt" style={{ width: '15px' }} />
                                                    </Button>
                                                )}
                                                no={() => ''}
                                            />
                                            <Button className=" btn btn-host  btn-sm mr-1" id="addAttach" onClick={() => document.getElementById("selectFile").click()}>
                                                <span className="far fa-image"> </span>
                                            </Button>
                                            <input type="file" className=" btn btn-host  btn-sm" hidden id='selectFile' onChange={this.addAttach.bind(this)} accept="image/*"></input>
                                        </ButtonGroup>
                                        <Popover placement="top" isOpen={this.state.popoverOpen} target="addAttach" toggle={this.togglePopover.bind(this)}>
                                            <PopoverBody>
                                                <div style={{ 'height': '5px' }} onClick={() => this.setState({ fileContent: '', popoverOpen: false, file: null })}><i className=" float-right fas fa-times"></i></div>
                                                {this.state.fileContent && this.state.fileContent != '' ?
                                                    <img src={this.state.fileContent} id="imagePreview" style={{ 'maxHeight': '50px', 'maxWidth': global.isMobile ? '40px' : '' }} alt="Preview" />
                                                    : <i className="fas fa-image fa-3x"></i>}
                                            </PopoverBody>
                                        </Popover>
                                    </Col>
                                </Row>
                                {openGpt ?
                                    <Row className='mt-1 pb-2'>
                                        <Col>
                                            <InputGPT
                                                onChange={this.changeMessage}
                                                conversation={gptConversation ? gptConversation : []}
                                            />
                                        </Col>
                                    </Row>
                                    :
                                    ''}
                            </Form>
                            : ''}
                    </div>
                </BlockUi>

                {this.state.attachment && this.state.attachment.content && this.state.attachment.type ?
                    <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false, attachment: null })} fade={false} className="modal-xl " style={{ minWidth: 0 }}>
                        <ModalBody className="modal-xl">
                            <Row>
                                <Col className="text-center">
                                    <embed
                                        type={this.state.attachment.type}
                                        src={`data:${this.state.attachment.type};base64,${this.state.attachment.content}`}
                                        style={{ width: 'auto', maxWidth: '100%' }}
                                        alt={this.state.attachment.name}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                : ''}
            </div>
        )
    }
}