import moment from 'moment';
import React, { Component } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col,  Input, InputGroup, Row } from 'reactstrap';
import { deleteAPI, getAPI, postAPI, putAPI } from "../../Base/API";
import { ReservationStatusBadge, SelectHotel, SelectChannelConfig, StyledCard, getCommunicationStatus } from "../../Base/Common/CommonUIComponents";
import CustomSelect from "../../Base/Common/CustomSelect";
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";
import { ReservationStatusDashboardModal } from './ReservationStatusDashboardModal';
import { getConvertedDateToHotelTimezone } from '../../Base/Common/ReferenceDataFunctions';

class ReservationStatusDashboard extends Component {

    state = {
        block: true,
        blockStats: true,
        status: { value: false },
        sendInfoStatus: [],
        channelCode: null,
        startDate: moment().startOf('day'),
        endDate: moment(),
        pageIndex: 0,
        pageSize: 10,
        totalItems: 0,
        modal: false,
        selectedReservationId: null,
        selectedReservationResIdValue: null,
        selectedReservationStatus: null,
        reservationStatusList: [
            {
                'value': 'Created',
                'label': <FormattedMessage id="ReservationList.Created" />
            },
            {
                'value': 'Modified',
                'label': <FormattedMessage id="ReservationList.Modified" />
            },
            {
                'value': 'Canceled',
                'label': <FormattedMessage id="ReservationList.Cancel" />
            }
        ],
        communicationStatusList: getCommunicationStatus(),
        channelChannelSummary: { "datasets": [{ "label": "Reservations by Channel", "data": [], "backgroundColor": ["#004c6d", "#036c9a", "#0586bf", "#346888", "#5886a5", "#9dc6e0", "#03acf7", "#61ceff", "#a4e3ff", "#ddf5ff", "#dbd2ff", "#b4a6ef", "#8a76de", "#7858ff", "#a008ff", "#b744ff", "#cc7bff", "#dfadff"] }], "labels": [] },
        commChannelSummary: JSON.parse('{ "datasets": [{"label" : "" ,"data": [],"backgroundColor": ["#004c6d", "#346888", "#5886a5", "#9dc6e0"]}],"labels": []}'),
        commStatusSummary: JSON.parse('{ "datasets": [{"label" : "" ,"data": [],"backgroundColor": ["#004c6d", "#346888", "#5886a5", "#9dc6e0"]}],"labels": []}'),
        commCommSummary: JSON.parse('{ "datasets": [{"label" : "" ,"data": [],"backgroundColor": ["#004c6d", "#346888", "#5886a5", "#9dc6e0"]}],"labels": []}'),
        statusChannelSummary: JSON.parse('{ "datasets": [{"label" : "" ,"data": [],"backgroundColor": [ "#28a745", "#ffc107","#dc3545"]}],"labels": ["Created","Modified","Canceled","Undefined"]}'),
        reservationId: null,
        isRadioSelect: true,
        resIdsToPMS: []
    }

    componentDidMount() {
        this.getReservations();
    }

    getReservationList(param, cb) {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data, "search", 'Success');
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    this.setState({ sendInfoStatus: data.response, block: false, totalItems: data.totalItems }, () => {
                        if (cb) {
                            cb();
                        }
                    });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Reservation/v1/reservation/AllHotels?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + param);
    }

    getReservationStats(param) {
        this.setState({ blockStats: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockStats: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data, "search", 'Success');
                    this.setState({ error: errorMessage, blockStats: false });
                }
                if (data.response) {
                    const objChannel = JSON.parse('{ "datasets": [{ "data": [], "backgroundColor": ["#004c6d", "#036c9a", "#0586bf", "#346888", "#5886a5", "#9dc6e0", "#03acf7", "#61ceff", "#a4e3ff", "#ddf5ff", "#dbd2ff", "#b4a6ef", "#8a76de", "#7858ff", "#a008ff", "#b744ff", "#cc7bff", "#dfadff"] }], "labels": [] }');
                    const objStatus = JSON.parse('{ "datasets": [{"label" : "" ,"data": [],"backgroundColor": [ "#28a745", "#ffc107","#dc3545"]}],"labels": ["Created","Modified","Canceled","Undefined"]}');
                    const objCom = JSON.parse('{ "datasets": [{"label" : "" ,"data": [],"backgroundColor": ["#28a745", "#ffc107","#dc3545", "#fc7f43", "#6c757d"]}],"labels": ["Processed", "Sent","Error", "Mapping Error", "Not Send","Pending" ]}');

                    data.response[0].channels.forEach(c => {
                        objChannel.datasets[0].data.push(c.count);
                        objChannel.labels.push(c.name);
                    });
                    objStatus.datasets[0].data = new Array(4);
                    objStatus.datasets[0].data[0] = this.getCorrespondentCount(data.response[0].status, "Created");
                    objStatus.datasets[0].data[1] = this.getCorrespondentCount(data.response[0].status, "Modified");
                    objStatus.datasets[0].data[2] = this.getCorrespondentCount(data.response[0].status, "Canceled");
                    objStatus.datasets[0].data[4] = this.getCorrespondentCount(data.response[0].status, "null");

                    objCom.datasets[0].data = new Array(4);
                    objCom.datasets[0].data[0] = this.getCorrespondentCount(data.response[0].communicationStatus, "Processed");
                    objCom.datasets[0].data[1] = this.getCorrespondentCount(data.response[0].communicationStatus, "Sent");
                    objCom.datasets[0].data[2] = this.getCorrespondentCount(data.response[0].communicationStatus, "Error"); 
                    objCom.datasets[0].data[3] = this.getCorrespondentCount(data.response[0].communicationStatus, "MappingError");
                    objCom.datasets[0].data[4] = this.getCorrespondentCount(data.response[0].communicationStatus, "NoSend");
                    objCom.datasets[0].data[5] = this.getCorrespondentCount(data.response[0].communicationStatus, null);

                    this.setState({ channelChannelSummary: objChannel, statusChannelSummary: objStatus, commChannelSummary: objCom, error: errorMessage, blockStats: false });
                }
            }
            else {
                this.setState({ error: errorMessage, blockStats: false });
            }
        }, `/api/Price/Reservation/v1/reservation/AllHotels/Stats/Results?${param}`);
    }

    getCorrespondentCount(data, name) {
        const elem = data.find(c => c.name === name);
        return elem && elem.count;
    }

    getReservations() {
        const param = this.getSearchParam();

        this.getReservationList(param);
        this.getReservationStats(param);
    }

    getSearchParam() {
        let param = '';

        if (this.state.startDate)
            param += '&fromReservUpdateDate=' + this.state.startDate.format('YYYY-MM-DD')
        if (this.state.endDate)
            param += '&toReservUpdateDate=' + this.state.endDate.format('YYYY-MM-DD')
        if (this.state.channelCode)
            param += '&channelName=' + this.state.channelCode
        if (this.state.communicationStatus && this.state.communicationStatus !== '')
            param += '&pmsStatus=' + this.state.communicationStatus
        if (this.state.channelStatus && this.state.channelStatus !== '')
            param += '&status=' + this.state.channelStatus
        if (this.state.hotelId && this.state.hotelId !== '')
            param += '&hotelId=' + this.state.hotelId
        if (this.state.reservationId && this.state.reservationId !== '')
            param += '&reservationId=' + this.state.reservationId

        return param;
    }

    setComboStatus = (evt, inputValue, actionMeta) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    setFilterState = (name, combo) => {
        this.setState({ [name]: combo });

    }

    setFilterSelectedChannel = (name, combo) => {
        var label = combo ? combo.label : null
        var val = combo ? combo.value : null
        this.setState({
            channelCode: label,
            channelConfigId: val
        }
        );
    }
    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => {
            const param = this.getSearchParam();
            this.getReservationList(param);
        });
    }

    toggleModal = () => {
        this.setState(prevState => ({
             modal: !prevState.modal
        }));
    }

    doSearch() {
        this.setState({
            pageIndex: 0,
            resIdsToPMS: []
        }, this.getReservations);
    }
    
    resendToPms = () => {
        this.setState({ block: true });

        var resId = this.state.isRadioSelect ? [this.state.selectedReservationId] : [...this.state.resIdsToPMS];

        postAPI(result => {
            const { error, data } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ modalError: errorMessage, block: false });
                return;
            }
            handleNotification(data, <FormattedMessage id="ReservationDetail.ResendToPMSSuccessMessage" />, <FormattedMessage id="General.Success" />);
            this.setState({ block: false, resIdsToPMS: [], modalError: errorMessage });
        }, `/api/Price/Reservation/v1/ReSendReservationToPMS`, resId);
    }

    handleOnClick(cb) {
        const { pageIndex, pageSize } = this.state;
        const totalResults = 1000;
        const param = this.getSearchParam();

        this.setState({ pageIndex: 0, pageSize: totalResults }, () => this.getReservationList(`${param}&isToIncludeReservationLogs=true`, () => cb(() => this.setState({ pageIndex: pageIndex, pageSize: pageSize }, () => this.getReservationList(param)))));
    }

    handleTextChange(evt) {
        if (evt) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    changeStatus = (status) => {
        const { selectedReservationId, sendInfoStatus } = this.state;
        this.setState({ block: true });
        putAPI(result => {
            const { error, data } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, modalError: errorMessage });
            }
            else {
                handleNotification(data, <FormattedMessage id="ReservationStatusDashboard.ChangeStatusSuccessMessage" />, <FormattedMessage id="General.Success" />);
                const idx = sendInfoStatus.indexOf(sendInfoStatus.find(elem => elem.id === selectedReservationId));
                if (idx >= 0) {
                    sendInfoStatus[idx].status = status
                }
                this.setState({ block: false, modalError: errorMessage, selectedReservationStatus: status, sendInfoStatus });
            }
        }, `/api/Price/Reservation/v1/reservation/${selectedReservationId}/Status?status=${status}&sendToPms=true`);
    }

    deleteReservation = (cb) => {
        const { selectedReservationId } = this.state;
        this.setState({ block: true });
        deleteAPI(result => {
            const { error, data } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, modalError: errorMessage });
            }
            else {
                handleNotification(data, <FormattedMessage id="ReservationStatusDashboard.DeleteReserationSuccessMessage" />, <FormattedMessage id="General.Success" />);
                if (cb) {
                    cb();
                }
                this.toggleModal();
                this.getReservations();
            }
        }, `/api/Price/Reservation/v1/reservation/${selectedReservationId}`);
    }

    toggleSelectMode = () => {
        this.setState(prevState => ({
            isRadioSelect: !prevState.isRadioSelect,
            resIdsToPMS: []
        }))
    }

    renderTableButtons = () => {
        const { isRadioSelect } = this.state;

        return  <Col>
            <Button className="btn-sm btn-host btn mr-2" onClick={this.toggleSelectMode}>
                {isRadioSelect ?
                    <span><FormattedMessage id="ReservationList.SelectMultiReservations" />  </span>
                    : <span> <FormattedMessage id="ReservationList.ChangeView" />  </span>}
            </Button>
            {isRadioSelect === false ?
                <Button className="btn-sm btn-host btn" onClick={this.resendToPms} disabled={this.state.resIdsToPMS.length === 0}>
                    <FormattedMessage id="ReservationList.ResendResToPMS" />
                </Button>
                : ''}
        </Col>
    }

    render() {
        const { block, blockStats, error, modal, modalError, selectedReservationId, selectedReservationStatus, isRadioSelect } = this.state;
       
        const barOptions = {
            legend: {
                display: false
            }
        };

        const options = {
            legend: {
                position: "right"
            }
        };

        const helpContent =
            <div>
                Reservation Dashboard
                <hr />
                <FormattedHTMLMessage  id="ReservationStatusDashboard.Help" />
            </div>

        const columns = [
            {
                dataField: 'hotelName2',
                text: this.props.intl.formatMessage({ id: "ReservationTimerDashboard.HotelName" }),
               
            },
            {
                dataField: 'propertyCode',
                text: this.props.intl.formatMessage({ id: "ReservationTimerDashboard.PropertyCode" }),
               
            },
            {
                dataField: 'channelInstanceName',
                text: this.props.intl.formatMessage({ id: "ReservationTimerDashboard.ChannelName" }),
                
            },
            {
                dataField: 'resIdValue',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationId" }),
               
            },
            {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "ReservationDetail.CreatedAt" }),
                formatter: (cell) => cell ? getConvertedDateToHotelTimezone(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'resIdDate',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationDate" }),
                formatter: (cell) => cell ? getConvertedDateToHotelTimezone(cell).format("YYYY-MM-DD HH:mm:ss") : ''
                
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "ReservationList.Status" }),
                
                formatter: (cell, row) => <ReservationStatusBadge status={cell}></ReservationStatusBadge>,
            },
            {
                dataField: 'communicationStatus',
                text: this.props.intl.formatMessage({ id: "ReservationList.communicationStatus" }),
                formatter: (cell) => {
                    const status = this.state.communicationStatusList.find(cs => cs.value === cell);

                    return status?.icon || '';
                }
            },
            {
                dataField: 'sentToPmsat',
                text: this.props.intl.formatMessage({ id: "ReservationList.sentToPmsAt" }),
                formatter: (cell, row) => cell ? getConvertedDateToHotelTimezone(cell).format("YYYY-MM-DD HH:mm:ss") : '',
            },
            {
                dataField: 'currentVersion',
                text: this.props.intl.formatMessage({ id: "ReservationList.CurrentVersion" }),
                hidden: true,
                formatter: (_, row) => row.currentVersion !== null && row.currentVersion !== undefined ? row.currentVersion : ``,
                csvFormatter: (_, row) => row.currentVersion !== null && row.currentVersion !== undefined ? row.currentVersion : ``
            },
            {
                dataField: 'receivedFromPmsAt',
                text: this.props.intl.formatMessage({ id: "ReservationStatusDashboard.ReceivedFromPmsAt" }),
                formatter: (cell) => cell ? getConvertedDateToHotelTimezone(cell).format("YYYY-MM-DD HH:mm:ss") : '',
            },
            {
                dataField: 'logMessageUpdatedAt',
                text: this.props.intl.formatMessage({ id: "ReservationList.logMessageUpdatedAt" }),
                hidden: true,
                formatter: (_, row) => row.logMessageUpdatedAt ? row.logMessageUpdatedAt : ``
            },
            {
                dataField: 'logMessage',
                text: this.props.intl.formatMessage({ id: "ReservationList.logMessage" }),
                hidden: true,
                formatter: (_, row) => row.logMessageUpdatedAt ? row.logMessageUpdatedAt : ``
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: row => this.setState({ modal: true, selectedReservationId: row.id, selectedReservationResIdValue: row.resIdValue, selectedReservationStatus: row.status })
        };

        const multiSelectRow = {
            mode: 'checkbox',
            bgColor: '#f8f9fa',
            clickToSelect: true,
            selected: [...this.state.resIdsToPMS],
            onSelect: (row, isSelect, rowIndex, e) => {
                let list = [...this.state.resIdsToPMS];

                if (isSelect) {
                    list.push(row.id);
                }
                else {
                    list = list.filter(el => el !== row.id);
                }

                this.setState({ resIdsToPMS: list });
            },
            onSelectAll: (isSelect, rows, e) => {
                if (isSelect) {
                    this.setState({ resIdsToPMS: rows.map(r => r.id) });
                }
                else {
                    this.setState({ resIdsToPMS: [] });
                }
            }
        };

        return (
            <StyledCard block={block || blockStats} error={error} icon={'fas fa-key'} title={'ReservationStatusDashboard.title'} help={helpContent}>
                {
                    this.state.modal ?
                        <ReservationStatusDashboardModal
                            block={block}
                            deleteReservation={this.deleteReservation}
                            changeStatus={this.changeStatus}
                            isOpen={modal}
                            modalError={modalError}
                            resendToPms={this.resendToPms}
                            selectedReservationId={selectedReservationId}
                            selectedReservationStatus={selectedReservationStatus}
                            toggleModal={this.toggleModal}
                        />
                        :
                        ''
                }
                <Row className="mb-2">

                    <Col className="col-3">
                        <SelectHotel required={true} name={'hotelId'} icon={'fas fa-hotel'} value={this.state.hotelId} onChangeFunc={this.handleChangeSelect} />
                    </Col>

                    <Col className="col-3">
                        <SelectChannelConfig required={true} name={'channelConfigId'} icon={'fas fa-plug'} value={this.state.channelConfigId} onChangeFunc={this.setFilterSelectedChannel} />
                    </Col>
                    <Col className="col-2">
                        <Input type="text" id="reservationId" name="reservationId" value={this.state.reservationId} onChange={this.handleTextChange.bind(this)} placeholder={this.props.intl.formatMessage({ id: "ReservationList.ReservationId" })} />
                    </Col>
                    <Col className="col-2 px-1">
                        <InputGroup size="sm text-right" >
                            <DateRangePicker
                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                startDateId="your_unique_start_date_id_announcement"
                                isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : null}
                                endDateId="your_unique_end_date_id_announcement"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <Button className="float-right btn-sm btn-host btn" onClick={this.doSearch.bind(this)}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-3">
                        <CustomSelect icon={'fas fa-suitcase'} isClearable isSearchable placeholder={<FormattedMessage id="ReservationList.ReservationStatus" />} options={this.state.reservationStatusList} onChange={this.setComboStatus.bind(this, 'channelStatus')} />
                    </Col>
                    <Col className="col-3">
                        <CustomSelect icon={'fas fa-share'} isClearable isSearchable placeholder={<FormattedMessage id="ReservationList.CommunicationStatus" />} options={this.state.communicationStatusList} onChange={this.setComboStatus.bind(this, 'communicationStatus')} />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-4">
                        <Bar data={this.state.channelChannelSummary} options={barOptions} height={100} />
                    </Col>
                    <Col className="col-4">
                        <Pie data={this.state.statusChannelSummary} options={options} height={100} />
                    </Col>
                    <Col className="col-4">
                        <Pie data={this.state.commChannelSummary} options={options} height={100} />
                    </Col>
                </Row>

                <Row>
                    <Col className="h-100 mt-4">
                        <CustomTable
                            data={this.state.sendInfoStatus ? this.state.sendInfoStatus : []}
                            columns={columns}
                            page={this.state.pageIndex + 1}
                            sizePerPage={this.state.pageSize}
                            totalSize={this.state.totalItems}
                            onTableChange={this.handleTableChange}
                            shadow={false}
                            search={false}
                            showTotal={true}
                            exportCSV={true}
                            customExportCsv={true}
                            remote={true}
                            hideSizePerPage={false}
                            selectRow={isRadioSelect ? selectRow : multiSelectRow}
                            handleOnClick={this.handleOnClick.bind(this)}
                            tooltipMessage={<FormattedMessage id="generic.table.customexportcsv" values={{ value: '1000'}} />}
                            tableButtons={this.renderTableButtons()}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }

};
export default injectIntl(ReservationStatusDashboard)