import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TabContent, TabPane } from 'reactstrap';
import KeyConfig  from './KeyConfig';
import CustomToolTip from '../../Utils/CustomToolTip';
import { getAPI } from '../Base/API';
import moment from 'moment';
import Dashboard from './Dashboard';
import EmailSearch from './EmailSearch';
import EmailDetail from './EmailDetail';
import EmailReservationStats from './EmailReservationStats';
import EmailReservationWebHooks from './EmailReservationWebHooks';
import EmailReservationDomainAuth from './EmailReservationDomainAuth';
import EmailReservationWhiteList from './EmailReservationWhiteList';
import { handleNotification } from '../Base/Notification';
import { CommonHelper } from '../Base/Common/CommonUIComponents';

class EmailReservationDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            activeTab: 'Dashboard',
            tabs: [
                'InboundStats',
                'KeyConfig',
                'MailBoxConfig',
                'Dashboard',
                'EmailDetails',
                'EmailSearch',
                'WhiteList'
            ],
            emails: [],
            domains: [],
            total: 0,
            emailId: null,
            startDate: moment().add(-6, 'month'),
            endDate: moment().add(1, 'd'),
            roomCategoriesDump: [],
            roomCategories: [],
            rates: [],
            ratesDump: [],
            openWorkList: true,
            workList: [],
            pageSize: 25,
            pageIndex: 0,
            total: 0,
            searchFrom: '',
            orderBy: null,
            status: null,
            startDate: moment().add(-6, 'month'),
            endDate: moment().add(1, 'day')
        }
    }

    componentDidMount() {
        this.getEmails(true);
    }

    getEmails = (firstCall) => {
        let { startDate, endDate, pageSize, pageIndex, status, searchFrom } = this.state;

        let params = '?';

        if(startDate || firstCall)
            params += `startDate=${firstCall ? this.state.startDate?.format('YYYY-MM-DD') : startDate?.format('YYYY-MM-DD')}&`;
        
        if(endDate || firstCall)
            params += `endDate=${firstCall ? this.state.endDate?.format('YYYY-MM-DD') : endDate?.format('YYYY-MM-DD')}&`;

        if(pageSize || firstCall)
            params += `pageSize=${firstCall ? '25' : pageSize}&`;
            
        if(pageIndex || firstCall)
            params += `pageIndex=${firstCall ? '0' : pageIndex}&`;
        
        if(status)
            params += `status=${status}&`;
        
        if(searchFrom)
            params += `searchFrom=${searchFrom}&`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if(data){
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(err => errorMessage.push({ message: err.message, stack: err.stack, messageType: 'danger' }));
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                else if (data.response) {
                    const workList = firstCall ? data.response : this.state.workList;
                    const activeTab = firstCall ? 'EmailSearch' : this.state.activeTab;
                    const filteredWorkList = workList.filter(w => w.status === "DataExtracted" || w.status === "Received" || w.status === "PossibleReservation" || w.status === "Modification" || w.status === "AskingInformation"); 
                    
                    this.setState({ workList: filteredWorkList, activeTab, emails: data.response, block: false, pageSize: data.pageSize, pageIndex: data.pageIndex, total: data.totalItems }, () => {
                        if(firstCall)
                            this.getCategories();
                    });
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/Price/EmailReservation/EmailReservation/v1/Emails${params}`);
    }

    getCategories = () => {
        this.setState({ block: true }, () =>{
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    const errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                    }
                    if (data.response && data.response.length > 0) {
                        const roomCategories = data.response.map(r => ({
                            value: r.id,
                            label: r.description,
                        }));

                        this.setState({ roomCategoriesDump: roomCategories, roomCategories }, () => this.getRates());
                    }
                }
                else {
                    this.setState({ block: false });
                }
            }, `/api/hotel/RoomCategory/v1/Info`);
        });
    }

    getRates = () => {
        const { roomCategories } = this.state;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data?.response && data.response.length > 0) {
                const rates = roomCategories && roomCategories.map(({label, value}) => ({
                    label,
                    options: data.response.filter(r => r.categoryId === value).map(r => ({
                        label: r.name,
                        value: r.id,
                        roomCategoryId: r.categoryId
                    }))
                }));

                this.setState({ rates, ratesDump: rates, block: false});
            }
            else {
                this.setState({ error: errorMessage, block: false });
            };
        }, `/api/Rate/Rate/v1`);
    }

    setDomains = (d) => {
        var domains = d.filter(function (o) {
            return o.valid
        }).map(function (o) {
            return { label: o.domain, default: o.default, value: o.id };
        });

        this.setState({ domains })
    }

    setRoomCateogriesOrRates = (roomCategoriesValue, ratesValue) => {
        this.setState({ roomCategories: roomCategoriesValue, rates: ratesValue });
    }

    toggleTab = (tabName, emailId) => {
        if (this.state.activeTab !== tabName || emailId) {
            this.setState({
                activeTab: tabName,
                emailId
            })
        }
    }

    toggleWorkList = () => {
        this.setState({ openWorkList: !this.state.openWorkList });
    }

    clearEmailDetail = () => {
        this.setState({ emailId: null, activeTab: "EmailSearch", block: true }, () => this.getEmails(true));
    }
    
    handlePagination = (value) => {
        const { pageIndex } = this.state;

        this.setState({ pageIndex: pageIndex + value, block: true }, () => this.getEmails());
    }
    
    changePageSize = (evt) => {
        const { value } = evt.target;
        
        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0, block: true }, _ => this.getEmails());
        }
    }

    handleFilterCombo = (combo, name) => {
        this.setState({ [name]: combo?.value });
    }

    changeDates = (startDate, endDate) => {
        this.setState({ startDate, endDate });
    }

    changeSearchFrom = (e) => {
        this.setState({ searchFrom: e.target.value })
    }

    searchEmails = () => {
        this.setState({ block: true }, () => this.getEmails())
    }

    render() {
        const { block, activeTab, emails, workList, total, emailId, openWorkList, searchFrom, startDate, endDate, pageIndex, pageSize } = this.state;

        const helpContent = (
            <div>
                <div className='mb-2'>
                    <b style={{ marginRight: '5px', color: 'red' }}>*</b>
                    <FormattedMessage id="EmailReservation.DivergentDataHelp" />
                </div>
                <div>
                    <b style={{ marginRight: '5px', color: 'blue' }}>*</b>
                    <FormattedMessage id="EmailReservation.DivergentDataInheritedHelp" />
                </div>
            </div>
        );

        return (
            <div id="EmailReservation">
                <div className='d-flex align-items-center justify-content-between' style={{ height: '92vh' }}>
                        {openWorkList ?
                            <div style={{ width: '18%' }} className='h-100 d-flex align-items-center justify-content-center'>
                                <div className="h-100 w-100">
                                    <div className="py-0 px-0 h-100 border-0 w-100">
                                        <div className='d-flex align-items-center justify-content-between flex-column h-100 w-100'>
                                            <div className='h-100 w-100 d-flex align-items-center justify-content-between w-100' style={{ maxHeight: '93%' }}>
                                                <div className='h-100 d-flex align-items-center justify-content-start flex-column bg-white w-100' style={{ border: '1px solid #bbc1c9ab', borderRadius: '5px', boxShadow: '0px 3px 15px #6F73881A' }}>
                                                    <div style={{ height: '6%', position: 'relative' }} className='px-2 w-100 d-flex align-items-center justify-content-center'>
                                                        <div className='d-flex align-items-center justify-content-between w-100'>
                                                            <div className='mr-1 text-truncate' style={{ fontWeight: '500' }}>
                                                                <FormattedMessage id={`EmailReservation.YourEmailReservations`}/>
                                                            </div>
                                                            <div style={{ whiteSpace: 'nowrap' }} className='h-100 d-flex align-items-center justify-content-center text-muted mr-1'>
                                                                <div className='TotalEmailRes cursor-pointer px-2 py-2' style={{ fontSize: '0.8em' }} onClick={_ => this.toggleTab('EmailSearch')}>
                                                                    <span>{workList.length > 0 ? '1' : '0'}</span>
                                                                    <span>-</span>
                                                                    <span>{workList.length}</span>
                                                                    <span className='mx-1'>
                                                                        <FormattedMessage id={`EmailReservation.of`}/>
                                                                    </span>
                                                                    <span>{total}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div onClick={this.toggleWorkList} className='coolCollapse text-muted'>
                                                            <i className="fas fa-chevron-left"/>
                                                        </div>
                                                    </div>
                                                    <div className='h-100 d-flex w-100 align-items-center justify-content-start flex-column' style={{ maxHeight: '94%', overflow: 'auto', fontSize: '0.8em', gap: '0.2rem' }}>
                                                        <BlockUi tag="div" className='w-100 h-100' blocking={block}>
                                                            {workList.map((email, key) =>                                            
                                                                <div key={key} className={`p-2 w-100 EmailFromEmailRes cursor-pointer ${!email.isRead ? 'UnReadEmailRes' : ''} ${email.id === emailId ? 'SelectedEmail' : ''}`} onClick={_ => this.toggleTab("EmailDetails", email.id)}>
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <div className='text-truncate'>
                                                                            {email.name ?
                                                                                <>
                                                                                    <span style={{ fontWeight: '500' }}>
                                                                                        {email.name}
                                                                                    </span>
                                                                                    <span className='mx-1'>-</span>
                                                                                </>
                                                                            :''}
                                                                            <span className='text-truncate' style={{ fontSize: '0.9em', fontWeight: email.name ? '' : '500' }}>
                                                                                {email.from}
                                                                            </span>
                                                                        </div>
                                                                        <div className='text-muted' style={{ fontSize: '0.85em' }}>
                                                                            {moment(email.createdAt).format('DD/MM')}
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100 d-flex align-items-center justify-content-between' style={{ fontSize: '0.95em' }}>
                                                                        <div className='text-truncate'>
                                                                            {email.subject}
                                                                        </div>
                                                                        {email.status === "DataExtracted" ?
                                                                            <div className='pl-2'>
                                                                                <div id={`dataExtractedEmail-${key}`} className='d-flex align-items-center justify-content-center' style={{ color: 'green' }}>
                                                                                    <i className="far fa-circle"/>
                                                                                </div>
                                                                                <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={`dataExtractedEmail-${key}`}>
                                                                                    <FormattedMessage id="EmailReservation.DataExtracted"/>
                                                                                </CustomToolTip>
                                                                            </div>
                                                                        :''}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </BlockUi>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 d-flex align-items-center justify-content-start' style={{ gap: '1.5rem' }}>
                                                <>
                                                    <div className='veryCoolButtons' id="WhiteList" onClick={_ => this.toggleTab('WhiteList')}>
                                                        <i className="fas fa-globe-europe"/>
                                                    </div>
                                                    <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={"WhiteList"}>
                                                        <FormattedMessage id="EmailReservation.WhiteList"/>
                                                    </CustomToolTip>
                                                </>
                                                <>
                                                    <div className='veryCoolButtons' id="InboundStats" onClick={_ => this.toggleTab('InboundStats')}>
                                                        <i className="far fa-chart-bar"/>
                                                    </div>
                                                    <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={"InboundStats"}>
                                                        <FormattedMessage id="EmailReservation.InboundStats"/>
                                                    </CustomToolTip>
                                                </>
                                                <>
                                                    <div className='veryCoolButtons' id="KeyConfig" onClick={_ => this.toggleTab('KeyConfig')}>
                                                        <i className="fas fa-key"/>
                                                    </div>
                                                    <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={"KeyConfig"}>
                                                        <FormattedMessage id="EmailReservation.KeyConfig"/>
                                                    </CustomToolTip>
                                                </>
                                                <>
                                                    <div className='veryCoolButtons' id="MailBoxConfig" onClick={_ => this.toggleTab('MailBoxConfig')}>
                                                        <i className="fas fa-cog"/>
                                                    </div>
                                                    <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={"MailBoxConfig"}>
                                                        <FormattedMessage id="EmailReservation.MailBoxConfig"/>
                                                    </CustomToolTip>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            <div style={{ width: '3%' }} className='h-100 d-flex align-items-center justify-content-center'>
                                <div className='h-100 d-flex align-items-center justify-content-start flex-column' style={{ gap: '1.5rem' }}>
                                    <>
                                        <div className='veryCoolButtons' id="YourEmailReservations" onClick={this.toggleWorkList}>
                                            <i className="fas fa-bars"></i>
                                        </div>
                                        <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={"YourEmailReservations"}>
                                            <FormattedMessage id="EmailReservation.YourEmailReservations"/>
                                        </CustomToolTip>
                                    </>
                                    <>
                                        <div className='veryCoolButtons' id="WhiteList2" onClick={_ => this.toggleTab('WhiteList')}>
                                            <i className="fas fa-globe-europe"/>
                                        </div>
                                        <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={"WhiteList2"}>
                                            <FormattedMessage id="EmailReservation.WhiteList"/>
                                        </CustomToolTip>
                                    </>
                                    <>
                                        <div className='veryCoolButtons' id="InboundStats2" onClick={_ => this.toggleTab('InboundStats')}>
                                            <i className="far fa-chart-bar"/>
                                        </div>
                                        <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={"InboundStats2"}>
                                            <FormattedMessage id="EmailReservation.InboundStats"/>
                                        </CustomToolTip>
                                    </>
                                    <>
                                        <div className='veryCoolButtons' id="KeyConfig2" onClick={_ => this.toggleTab('KeyConfig')}>
                                            <i className="fas fa-key"/>
                                        </div>
                                        <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={"KeyConfig2"}>
                                            <FormattedMessage id="EmailReservation.KeyConfig"/>
                                        </CustomToolTip>
                                    </>
                                    <>
                                        <div className='veryCoolButtons' id="MailBoxConfig2" onClick={_ => this.toggleTab('MailBoxConfig')}>
                                            <i className="fas fa-cog"/>
                                        </div>
                                        <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={"MailBoxConfig2"}>
                                            <FormattedMessage id="EmailReservation.MailBoxConfig"/>
                                        </CustomToolTip>
                                    </>
                                </div>
                            </div>
                        }
                    <div style={{ width: openWorkList ? '80%' : '95%', marginLeft: '2%' }} className="h-100">
                        <BlockUi tag="div" className='w-100 h-100' blocking={block}>
                            <div style={{ border: '1px solid #bbc1c9ab', borderRadius: '5px', boxShadow: '0px 3px 15px #6F73881A' }} className="h-100 bg-white">
                                <div style={{ height: '5%' }} className='d-flex py-2 px-3 align-items-center justify-content-between'>
                                    <div style={{ fontWeight: '500' }}>
                                        <FormattedMessage id={`EmailReservation.${activeTab}`}/>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {emailId ?
                                            <div>
                                                <CommonHelper help={helpContent} id={'EmailReservatoinHelp'} />  
                                            </div>
                                        :''}
                                        <div
                                            className={`EmailCloseTab ${activeTab !== "Dashboard" && (activeTab !== "EmailSearch" || emails.length === 0) ? 'visible' : ''}`}
                                            onClick={_ => this.toggleTab(workList.length === 0 ? "Dashboard" : "EmailSearch")}>
                                            <i className="fas fa-times"/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: '95%' }}>
                                    <TabContent activeTab={activeTab} className="h-100">
                                        <TabPane tabId='Dashboard' className="border-0 my-0 p-0 h-100 px-3 overflow-auto">
                                            <Dashboard
                                                toggleTab={this.toggleTab}
                                            />
                                        </TabPane>
                                        <TabPane tabId='InboundStats' className="border-0 my-0 p-0 h-100 px-3 overflow-auto">
                                            <EmailReservationStats />
                                        </TabPane>
                                        <TabPane tabId='KeyConfig' className="border-0 my-0 p-0 h-100 px-3 overflow-auto">
                                            <KeyConfig />
                                        </TabPane>
                                        <TabPane tabId='MailBoxConfig' className="border-0 my-0 py-3 px-0 h-100 px-3 overflow-auto" style={{ overflow: 'auto' }}>
                                            <EmailReservationWebHooks domains={this.state.domains} />
                                            <hr className="my-4" />
                                            <EmailReservationDomainAuth setDomains={this.setDomains} />
                                        </TabPane>
                                        <TabPane tabId='EmailDetails' className="border-0 my-0 p-0 h-100 px-3 overflow-auto">
                                            {emailId ?
                                                <EmailDetail
                                                    emailId={emailId}
                                                    clearEmailDetail={this.clearEmailDetail}
                                                    rates={this.state.rates}
                                                    ratesDump={this.state.ratesDump}
                                                    roomCategories={this.state.roomCategories}
                                                    roomCategoriesDump={this.state.roomCategoriesDump}
                                                    setRoomCateogriesOrRates={this.setRoomCateogriesOrRates}
                                                />
                                            :''}
                                        </TabPane>
                                        <TabPane tabId='EmailSearch' className="border-0 my-0 p-0 h-100 px-3 overflow-auto">
                                            <EmailSearch
                                                toggleTab={this.toggleTab}
                                                searchEmails={this.searchEmails}
                                                hasEmails={workList.length > 0}
                                                emails={emails}
                                                searchFrom={searchFrom}
                                                startDate={startDate}
                                                endDate={endDate}
                                                pageIndex={pageIndex}
                                                pageSize={pageSize}
                                                total={total}
                                                handlePagination={this.handlePagination}
                                                changePageSize={this.changePageSize}
                                                handleFilterCombo={this.handleFilterCombo}
                                                changeDates={this.changeDates}
                                                changeSearchFrom={this.changeSearchFrom}
                                                clearEmailDetail={this.clearEmailDetail}
                                            />
                                        </TabPane>
                                        <TabPane tabId='WhiteList' className="border-0 my-0 p-0 h-100 px-3 overflow-auto">
                                            <EmailReservationWhiteList />
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(EmailReservationDashboard);