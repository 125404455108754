import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Button, Card, CardBody, CardHeader, Input } from 'reactstrap';
import { getAPI, postAPI } from "../../../Base/API"
import { handleNotification } from "../../../Base/Notification"
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { FormattedMessage } from 'react-intl';
import { getBookingsForSameDayOptions, getCheckInTime, getCurrency } from '../../../Base/Common/ReferenceDataFunctions'
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorTextMultiLing } from '../../../Base/Common/SupportedMultiLang';
import CustomSelect from '../../../Base/Common/CustomSelect';
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';

export class ComplexTermsAndConditions extends Component {

    constructor(props) {
        super(props);
        this.formPolicies = React.createRef();
        this.onPaymentPolicyEditorStateChange = this.onPaymentPolicyEditorStateChange.bind(this);
        this.onTermsAndConditionsEditorStateChange = this.onTermsAndConditionsEditorStateChange.bind(this);
        this.togglePaymentPolicy = this.togglePaymentPolicy.bind(this);
        this.toggleTermsAndConditions = this.toggleTermsAndConditions.bind(this);
        this.paymentPolicyAddNewLanguage = this.paymentPolicyAddNewLanguage.bind(this);
        this.termsAndConditionsAddNewLanguage = this.termsAndConditionsAddNewLanguage.bind(this);
        this.handlePoliciesSubmit = this.handlePoliciesSubmit.bind(this);
        this.state = {
            error: null,
            block: true,
            selectedComplex: this.props.selectedComplex,
            paymentPolicyActiveTab: 0,
            termsAndConditionsActiveTab: 0,
            policy: {
                paymentPolicy: [],
                termsAndConditions: []
            },
            supportedLanguages: ['pt-PT', 'en-GB', 'es-ES', 'fr-FR', 'de-DE'],
            selectedLang: 'pt-PT'
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedComplex && nextProps.selectedComplex !== this.props.selectedComplex) {
            this.setState({
                selectedComplex: nextProps.selectedComplex
            }, () => this.getPolicy())
        }
    }

    componentDidMount() {
        this.getPolicy();
    }

    getPolicy = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ blocking: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ blocking: false, error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, policy: {} });
                    return;
                }

                if (data.response) {
                    data.response.paymentPolicy.map(policy => {
                        const blocksFromHtml = htmlToDraft(policy.description);
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        policy.editorState = EditorState.createWithContent(contentState);
                    });
                    data.response.termsAndConditions.map(termsAndCondition => {
                        const blocksFromHtml = htmlToDraft(termsAndCondition.description);
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        termsAndCondition.editorState = EditorState.createWithContent(contentState);
                    });
                }

                this.setState({ policy: data.response ? data.response : {} });
            }
            this.setState({ block: false });
        }, `/api/hotel/Complexes/v1/${this.state.selectedComplex.id}/Policy`);
    }

    onPaymentPolicyEditorStateChange(newEditorState, lang) {
        const policy = { ...this.state.policy };
        let text = policy && policy.paymentPolicy && policy.paymentPolicy.find(el => el.culture === lang);

        if (text) {
            text.editorState = newEditorState;
        }
        else {
            if (!Array.isArray(policy.paymentPolicy)) {
                policy.paymentPolicy = [];
            }

            let newItem = {};
            newItem.culture = lang;
            newItem.editorState = newEditorState;
            policy.paymentPolicy.push(newItem);
        }

        this.setState({ policy });
    };

    onTermsAndConditionsEditorStateChange(newEditorState, lang) {
        const policy = { ...this.state.policy };
        let text = policy && policy.termsAndConditions && policy.termsAndConditions.find(el => el.culture === lang);

        if (text) {
            text.editorState = newEditorState;
        }
        else {
            if (!Array.isArray(policy.termsAndConditions)) {
                policy.termsAndConditions = [];
            }

            let newItem = {};
            newItem.culture = lang;
            newItem.editorState = newEditorState;
            policy.termsAndConditions.push(newItem);
        }

        this.setState({ policy });
    };

    termsAndConditionsAddNewLanguage() {
        const e = document.getElementById("termsAndConditionsSelectNewCountryId");
        const strCountry = e.options[e.selectedIndex].value;
        const newTermAndCondition = {
            culture: strCountry,
            editorState: EditorState.createEmpty()
        };
        this.setState(prevState => (
            {
                termsAndConditionsActiveTab: prevState.policy.termsAndConditions.length,
                policy: {
                    ...prevState.policy,
                    termsAndConditions: [...prevState.policy.termsAndConditions, newTermAndCondition]
                }
            }))
    }

    paymentPolicyAddNewLanguage() {
        const e = document.getElementById("paymentPolicySelectNewCountryId");
        const strCountry = e.options[e.selectedIndex].value;
        const newPaymentPolicy = {
            culture: strCountry,
            editorState: EditorState.createEmpty()
        };
        this.setState(prevState => (
            {
                paymentPolicyActiveTab: prevState.policy.paymentPolicy.length,
                policy: {
                    ...prevState.policy,
                    paymentPolicy: [...prevState.policy.paymentPolicy, newPaymentPolicy]
                }
            }))
    }

    togglePaymentPolicy(tab) {
        if (this.state.paymentPolicyActiveTab !== tab) {
            this.setState(({ paymentPolicyActiveTab: tab.key }));
        }
    }

    toggleTermsAndConditions(tab) {
        if (this.state.termsAndConditionsActiveTab !== tab) {
            this.setState(({ termsAndConditionsActiveTab: tab.key }));
        }
    }

    setFilterState = (name, combo) => {
        let val = null;
        if (Array.isArray(combo)) {
            val = combo.map(el => el.value);
        } else {
            val = combo ? combo.value : null;
        }
        this.setState(prevState => ({
            ...prevState,
            policy: {
                ...prevState.policy,
                [name]: val
            }
        }));
    }

    handlePoliciesSubmit(e) {
        e.preventDefault();
        if (this.formPolicies.current.reportValidity()) {
            this.setState({
                block: true
            });

            const body = {
                request: {
                    checkinFrom: this.state.policy.checkinFrom,
                    checkinTo: this.state.policy.checkinTo,
                    checkoutFrom: this.state.policy.checkoutFrom,
                    checkoutTo: this.state.policy.checkoutTo,
                    payementType: this.state.policy.payementType,
                    childAge: this.state.policy.childAge,
                    infantAge: this.state.policy.infantAge,
                    bookingsForSameDay: this.state.policy.bookingsForSameDay,
                    bookingsForSameDayTime: this.state.policy.bookingsForSameDayTime,
                    maxReservationRooms: this.state.policy.maxReservationRooms === 0 || this.state.policy.maxReservationRooms === '0' ? null : this.state.policy.maxReservationRooms,
                    paymentPolicy: [],
                    termsAndConditions: []
                }
            };
            this.state.policy.paymentPolicy && this.state.policy.paymentPolicy.map(paymentPolicy => {
                const textConverted = draftToHtml(convertToRaw(paymentPolicy.editorState.getCurrentContent()));
                const newPaymentPolicy = {
                    culture: paymentPolicy.culture,
                    description: textConverted
                };
                body.request.paymentPolicy.push(newPaymentPolicy);
            });
            this.state.policy.termsAndConditions && this.state.policy.termsAndConditions.map(termsAndCondition => {
                const textConverted = draftToHtml(convertToRaw(termsAndCondition.editorState.getCurrentContent()));
                const newTermsAndCondition = {
                    culture: termsAndCondition.culture,
                    description: textConverted
                };
                body.request.termsAndConditions.push(newTermsAndCondition);
            });

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="HotelFacilitiesPolicies.SavePolicies" />, 'Success');
                    this.setState({ block: false });
                }
            }, `/api/hotel/Complexes/v1/${this.state.selectedComplex.id}/Policy`, JSON.stringify(body));
        }
    }

    changeLangSync = (lang) => {
        if (this.state.selectedLang !== lang) {
            this.setState({ selectedLang: lang });
        }
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState({
            policy: {
                ...this.state.policy,
                [name]: value ? parseInt(value) : null
            }
        });
    }

    setBookingsForSameDay = (name, combo) => {
        const { policy } = this.state;
        policy[name] = combo && combo.value;
        policy.bookingsForSameDayTime = null;
        this.setState({ policy });
    }

    render() {
        const payementTypes = this.state.policy.payementType ? this.state.policy.payementType : []

        const customEditorText = {
            options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
        };

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <form ref={this.formPolicies}>
                    <Card className="small shadow border-0">
                        <CardHeader className="bg-white border-bottom">
                            <Row>
                                <Col>
                                    <span className="fas fa-gavel mr-1"> </span>
                                    <FormattedMessage id="HotelFacilitiesPolicies.Policies" />
                                </Col>
                                <Col className="text-right">
                                    <Button color="primary btn-sm btn-host" onClick={this.handlePoliciesSubmit}><i className="fas fa-save" /></Button>

                                    <CommonHelper help={<FormattedMessage id="ComplexTermsAndConditions.Help" />} id="ComplexTermsAndConditionsHelp" />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup className="form-control-sm" row>
                                        <Label for="companyname" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.CheckinBetween" /> </Label>
                                        <Col sm={4}>
                                            <CustomSelect name={'checkinFrom'} options={getCheckInTime()} placeholder={this.state.policy.checkinFrom} value={getCheckInTime().filter(el => el.value === this.state.policy.checkinFrom)} required onChange={this.setFilterState.bind(this, 'checkinFrom')} />
                                        </Col>
                                        <Label sm={2} className="text-center"><FormattedMessage id="HotelFacilitiesPolicies.and" /></Label>
                                        <Col sm={4}>
                                            <CustomSelect name={'checkinTo'} options={getCheckInTime()} placeholder={this.state.policy.checkinTo} value={getCheckInTime().filter(el => el.value === this.state.policy.checkinTo)} onChange={this.setFilterState.bind(this, 'checkinTo')} isClearable />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 py-2">
                                    <FormGroup className="form-control-sm" row>
                                        <Label for="companyname" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.CheckoutBetween" /> </Label>
                                        <Col sm={4}>
                                            <CustomSelect name={'checkoutFrom'} options={getCheckInTime()} placeholder={this.state.policy.checkoutFrom} value={getCheckInTime().filter(el => el.value === this.state.policy.checkoutFrom)} onChange={this.setFilterState.bind(this, 'checkoutFrom')} isClearable />
                                        </Col>
                                        <Label sm={2} className="text-center"><FormattedMessage id="HotelFacilitiesPolicies.and" /></Label>
                                        <Col sm={4}>
                                            <CustomSelect name={'checkoutTo'} options={getCheckInTime()} placeholder={this.state.policy.checkoutTo} value={getCheckInTime().filter(el => el.value === this.state.policy.checkoutTo)} onChange={this.setFilterState.bind(this, 'checkoutTo')} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup className="form-control-sm" row>
                                        <Label for="infantAge" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.InfantLimitAge" /> </Label>
                                        <Col sm={4}>
                                            <Input type="number" name="infantAge"
                                                min="0" max="17"
                                                value={this.state.policy.infantAge || ''}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                        <Label sm={2} className="text-center px-0"> <FormattedMessage id="HotelFacilitiesPolicies.ChildLimitAge" /> </Label>
                                        <Col sm={4}>
                                            <Input type="number" name="childAge"
                                                min={this.state.policy.infantAge ? (this.state.policy.infantAge + 1) : 0} max="17"
                                                value={this.state.policy.childAge || ''}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup className="form-control-sm" row>
                                        <Label sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.BookingsForSameDay" /> </Label>
                                        <Col sm={4}>
                                            <CustomSelect options={getBookingsForSameDayOptions()} value={getBookingsForSameDayOptions().find(opt => opt.value === this.state.policy.bookingsForSameDay)} onChange={this.setBookingsForSameDay.bind(this, 'bookingsForSameDay')} required />
                                        </Col>
                                        <Col sm={6}>
                                            {
                                                this.state.policy.bookingsForSameDay === getBookingsForSameDayOptions()[1].value ?
                                                    <FormGroup className="form-control-sm" row>
                                                        <Col sm={4} className="p-0"> <FormattedMessage id="HotelFacilitiesPolicies.BookingsForSameDayTime" /> </Col>
                                                        <Col sm={8} className="pr-2">
                                                            <CustomSelect options={getCheckInTime()} value={getCheckInTime().filter(el => el.value === this.state.policy.bookingsForSameDayTime)} onChange={this.setFilterState.bind(this, 'bookingsForSameDayTime')} required />
                                                        </Col>
                                                    </FormGroup>
                                                    :
                                                    <div />
                                            }
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="col-12 py-2">
                                    <FormGroup className="form-control-sm" row>
                                        {!this.props.fromBEConfig ?
                                            <>
                                                <Label for="companyname" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.PaymentMethod" /> </Label>
                                                <Col sm={4}>
                                                    <CustomSelect name={'payementType'} options={getCurrency()} isMulti value={getCurrency().filter(el => el.value === payementTypes.find(pt => pt === el.value))} onChange={this.setFilterState.bind(this, 'payementType')} />
                                                </Col>
                                            </>
                                        : ''}
                                        <Label sm={2} className=""> <FormattedMessage id="HotelFacilitiesPolicies.maxReservationRooms" /> </Label>
                                        <Col sm={4}>
                                            <Input type="number" name="maxReservationRooms"
                                                min={1}
                                                value={this.state.policy.maxReservationRooms || ''}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup className="form-control-sm h-100" row>
                                        <Label for="paymentpolicies" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.PaymentPolicies" /> </Label>
                                        <Col sm={10}>
                                            <EditorTextMultiLing
                                                texts={this.state.policy ? this.state.policy.paymentPolicy : []}
                                                onEditorStateChange={this.onPaymentPolicyEditorStateChange}
                                                selectedLang={this.state.selectedLang}
                                                changeLang={this.changeLangSync}
                                                customToolbar={customEditorText}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup className="form-control-sm h-100" row>
                                        <Label for="paymentterms" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.PaymentTermsAndConditions" /> </Label>
                                        <Col sm={10}>
                                            <EditorTextMultiLing
                                                texts={this.state.policy ? this.state.policy.termsAndConditions : []}
                                                onEditorStateChange={this.onTermsAndConditionsEditorStateChange}
                                                selectedLang={this.state.selectedLang}
                                                changeLang={this.changeLangSync}
                                                customToolbar={customEditorText}
                                            /> 
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </form>
            </BlockUi>
        );
    }
}