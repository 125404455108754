import React, { Component } from 'react';
import { CardBody, Card, CustomInput, InputGroup, FormGroup, Label, Input, CardHeader, Row, Col, Badge, UncontrolledTooltip, Button } from 'reactstrap';
import { NotificationAlert } from "../Common/NotificationAlert";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { ErrorAlert } from "../Common/ErrorAlert";
import { getAPI, postAPI } from "../Base/API";
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import { handleNotification } from '../Base/Notification';
import CustomToolTip from '../../Utils/CustomToolTip';
import CustomSelect from '../Base/Common/CustomSelect';
import { getCurrencyCode, getApplyDifferencesAsDiscounts } from '../Base/Common/ReferenceDataFunctions';

export class ChannelInstanceDetail extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.saveChannelInstance = this.saveChannelInstance.bind(this);
        this.saveChannelInstanceEmailRes = this.saveChannelInstanceEmailRes.bind(this);
        this.selectedChannelForm = React.createRef();
        this.state = {
            block: this.props.manageCurrencyConfig,
            selectedChannel: this.props.selectedChannel,
            notification: {
                type: 'Success',
                message: ''
            },
            allSupportedInfo: [
                { value: "Availability", key: "sendAvailability", label: this.props.intl.formatMessage({ id: "ChannelList.SendAvailability" })},
                { value: "Price", key: "sendPrice", label: this.props.intl.formatMessage({ id: "ChannelList.SendPrice" }) },
                { value: "CTA", key: "sendCta", label: this.props.intl.formatMessage({ id: "ChannelList.SendCta" })},
                { value: "CTD", key: "sendCtd", label: this.props.intl.formatMessage({ id: "ChannelList.SendCtd" }) },
                { value: "MaxStays", key: "sendMaxStays", label: this.props.intl.formatMessage({ id: "ChannelList.SendMaxStay" }) },
                { value: "MinStays", key: "sendMinStay", label: this.props.intl.formatMessage({ id: "ChannelList.SendMinStay" }) },
                { value: "StopSales", key: "sendStopSales", label: this.props.intl.formatMessage({ id: "ChannelList.SendStopSales" })},
                { value: "MinAdvancedBookingOffset", key: "sendMinAdvancedBookingOffset", label: this.props.intl.formatMessage({ id: "ChannelList.SendMinAdvancedBookingOffset" })},
                { value: "MaxAdvancedBookingOffset", key: "sendMaxAdvancedBookingOffset", label: this.props.intl.formatMessage({ id: "ChannelList.SendMaxAdvancedBookingOffset" })}
            ],
            currencies: []
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.selectedChannel.channelName && this.props.selectedChannel.channelName.toUpperCase() === 'AIRBNB') {
            const script = document.createElement("script");

            script.src = "https://www.airbnb.com/platform_js";
            script.async = true;

            document.body.appendChild(script);
        }

        if (this.props.manageCurrencyConfig) {
            this.getCurrencies();
        }
    }



    getCurrencies = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {

                const currencies = data
                    .response
                    .reduce((acc, curr) => {
                        if (!acc.some(c => c.value === curr.targetCurrency)) {
                            getCurrenciesOption(curr.targetCurrency);
                        }
                        if (!acc.some(c => c.value === curr.baseCurrency)) {
                            getCurrenciesOption(curr.baseCurrency);
                        }

                        return acc;

                        function getCurrenciesOption(currency) {
                            const currencyCode = getCurrencyCode().find(crr => crr.value === currency);
                            const label = currencyCode ? currencyCode.label : currency;

                            const opt = {
                                value: currency,
                                label: label
                            };

                            acc.push(opt);
                        }
                    },[]);

                this.setState({ error: errorMessage, block: false, currencies });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Price/Price/Currency/v1/Conversion');
    }

    changeIntField(name, evt) {
        const val = evt.target ? evt.target.value : null;
        const { selectedChannel } = this.state;
        selectedChannel[name] = parseInt(val);
        this.setState(({ selectedChannel }));
    }

    changeStringField(name, evt) {
        const val = evt.target ? evt.target.value : null;
        const { selectedChannel } = this.state;

        if (this.props.selectedChannel.code === "HEYBE" && name === 'propertyCode' ) {
            selectedChannel[name] = val.toLowerCase();
        }
        else {
            selectedChannel[name] = val;
        }

        this.setState(({ selectedChannel }));
    }

    changeSendInfoState( name ) {
        this.setState(prevState => ({
            ...prevState,
            selectedChannel: {
                ...prevState.selectedChannel,
                [name]: !prevState.selectedChannel[name]
            }
        }));
    }

    validateSupportedInfo = () => {
        var notSupported = [];

        if (this.props.supportedData && this.props.supportedData.length > 0) {
            this.state.allSupportedInfo.forEach(el => {
                if (!this.props.supportedData.find(sd => sd.toLowerCase() === el.value.toLowerCase()) && this.state.selectedChannel[el.key]) {
                    notSupported.push(el.label);
                }
            })
        }

        return notSupported;
    }

    saveChannelInstance() {
        const maxInventoryDays = document.getElementById('maxInventoryDays');
        if (this.state.selectedChannel.maxInventoryDays <= 0) {
            maxInventoryDays.setCustomValidity(this.props.intl.formatMessage({ id: "ChannelList.MaxInventoryDaysNeedsToBeBiggerThanZero" }));
        }
        else {
            maxInventoryDays.setCustomValidity("");
        }

        const notSupported = this.validateSupportedInfo();
        if (notSupported && notSupported.length > 0) {
            const data = {
                errors: [
                    {
                        code: <FormattedMessage id="CommonUIComponents.Error" />,
                        message: `${notSupported.join(", ")} ${this.props.intl.formatMessage({ id: "ChannelDashboard.AreNotSupported" })}`
                    }
                ]
            }
            handleNotification(data)
            return;
        }

        if (this.selectedChannelForm.current.reportValidity()) {
            this.setState({ block: true });
            const errorMessage = [];

            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors) {
                        data.errors.forEach(error => {
                            errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                            this.setState({ error: errorMessage, block: false });
                            return;
                        });
                    }
                    if (data.response) {
                        handleNotification(data, <FormattedMessage id="ChannelList.ChannelInstanceUpdateSuccess" />, <FormattedMessage id="General.Success" />);
                        this.setState({ selectedChannel: data.response });
                    }
                    this.setState({ block: false });
                }
            }, "/api/Rate/ChannelConfig/v1/ChannelInstance", this.state.selectedChannel);
        }
    }

    saveChannelInstanceEmailRes() {
        const notSupported = this.validateSupportedInfo();
        if (notSupported && notSupported.length > 0) {
            const data = {
                errors: [
                    {
                        code: <FormattedMessage id="CommonUIComponents.Error" />,
                        message: `${notSupported.join(", ")} ${this.props.intl.formatMessage({ id: "ChannelDashboard.AreNotSupported" })}`
                    }
                ]
            }
            handleNotification(data)
            return;
        }

        if (this.selectedChannelForm.current.reportValidity()) {
            this.setState({ block: true }, () => {
                const errorMessage = [];

                postAPI(result => {
                    const { data, error } = result;
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors) {
                            data.errors.forEach(error => {
                                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                                this.setState({ error: errorMessage, block: false });
                                return;
                            });
                        }
                        if (data.response) {
                            handleNotification(data, <FormattedMessage id="ChannelList.ChannelInstanceUpdateSuccess" />, <FormattedMessage id="General.Success" />);
                            this.setState({ block: false, selectedChannel: data.response });
                            return;
                        }
                        this.setState({ block: false });
                    }
                }, "/api/Rate/ChannelConfig/v1/ChannelInstance", this.state.selectedChannel)
            });
        }
    }

    handleCurrency = (combo) => {
        const { selectedChannel } = this.state;
        selectedChannel.currencyConvertion = combo && combo.value;
        this.setState({ selectedChannel });
    }

    handleConvertCurrencyFromReservation = (evt) => {
        if (evt && evt.target) {
            const { checked } = evt.target;
            const { selectedChannel } = this.state;

            selectedChannel.convertCurrencyFromReservation = checked;
            this.setState({ selectedChannel });
        }
    }

    handleConvertCurrencyToChannel = (evt) => {
        if (evt && evt.target) {
            const { checked } = evt.target;
            const { selectedChannel } = this.state;

            selectedChannel.convertCurrencyToChannel = checked;
            selectedChannel.currencyConvertion = null;
            this.setState({ selectedChannel });
        }
    }

    handleConvertDifferencesToDiscount = (evt) => {
        if (evt && evt.target) {
            const { checked } = evt.target;
            const { selectedChannel } = this.state;

            selectedChannel.applyRoomsDifferenceAsDiscount = checked;
            this.setState({ selectedChannel });
        }
    }


    render() {
        const { supportedData, hideUserAndPassword, mandatoryFields, manageCurrencyConfig } = this.props;
        const { currencies, selectedChannel } = this.state;
        const isSendRoomRate = global.operationMode === 'PmsFull' && global.sendRoomRate;
        const canEditChannelName = global.operationMode === "PmsLimited";
        const userAndPasswordDisabled = global.operationMode === 'PmsFull' ? !global.sendRoomRate : !(global.operationMode === "PmsLimited" || global.operationMode === 'None' || global.PMSType === 'None' || global.PMSType === 'BookingSpaceBeds');
        const propCodeAndMaxDaysDisabled = (global.operationMode === "PmsFull" && (global.PMSType === 'None' || global.PMSType === 'Host')) && !isSendRoomRate;


        function getMaxInventoryDays(channelMaxDays) {
            return channelMaxDays ? channelMaxDays : 730;
        }

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <form ref={this.selectedChannelForm}>
                        <Card className=" shadow border-0 mb-2">
                            <ErrorAlert error={this.state.error} />
                            <CardHeader className="bg-white border-bottom" >
                                <Row>
                                    <Col>
                                        <strong>{this.props.selectedChannel.channelName}</strong> {!canEditChannelName ? <span className="text-muted"> - {this.props.selectedChannel.name} </span> : ''}
                                    </Col>
                                    <Col className="text-right">
                                        {
                                            this.props.selectedChannel.active ?
                                                <span>
                                                    <Badge className={selectedChannel?.code !== 'MAILRES' ? "p-2" : ""} color="primary" id="channelInstanceDetailActivatedAtChannel">
                                                        <FormattedMessage id="ChannelDashboard.Active" />
                                                    </Badge>
                                                    {
                                                        this.props.selectedChannel.activatedAt ?
                                                            <UncontrolledTooltip placement="bottom" target="channelInstanceDetailActivatedAtChannel">
                                                                <FormattedMessage id="ChannelDashboard.ActivatedAtChannel" values={{ activatedAt: moment(this.props.selectedChannel.activatedAt).format("YYYY-MM-DD HH:mm:ss") }} />
                                                            </UncontrolledTooltip>
                                                            :
                                                            ''
                                                    }
                                                </span>
                                                :
                                                <Badge className={selectedChannel?.code !== 'MAILRES' ? "p-2" : ""} color="secondary">
                                                    <FormattedMessage id="ChannelDashboard.Inactive" />
                                                </Badge>
                                        }
                                        {
                                            this.props.selectedChannel.channelConfigState ?
                                                <Badge color={selectedChannel?.code !== 'MAILRES' ? 'info' : 'success'} className={`ml-2 ${selectedChannel?.code !== 'MAILRES' ? "p-2" : ""}`}>
                                                    {this.props.selectedChannel.channelConfigState}
                                                </Badge>
                                                :
                                                <span />
                                        }
                                        {
                                            this.props.selectedChannel.channelName && this.props.selectedChannel.channelName.toUpperCase() === 'AIRBNB' ?
                                                <Badge color='info' className="p-2 ml-2">
                                                    <FormattedMessage id={`ChannelList.Mode${this.props.selectedChannel.mode}`} />
                                                </Badge>
                                                :
                                                <span />
                                        }
                                        {CheckAuthorization("channel:update") || getApplyDifferencesAsDiscounts(this.props.selectedChannel.channelName.toUpperCase()) ?
                                            <Button className="btn-sm btn-host ml-2" onClick={!this.props.configApiKey ? this.saveChannelInstance : this.saveChannelInstanceEmailRes}>
                                                <i className="fas fa-save" />
                                            </Button>
                                        :''}

                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <NotificationAlert notification={this.state.notification} />
                                {
                                    !this.props.selectedChannel.hasPassword && this.props.selectedChannel.channelName && this.props.selectedChannel.channelName.toUpperCase() === 'AIRBNB' ?
                                        <Row>
                                            <Col>
                                                <div>
                                                    <i className="fas fa-info-circle text-secondary mr-2 mb-3" />
                                                    <FormattedMessage id="ChannelInstanceDetail.DisconectedChannelCompleteJoiningProcess" />
                                                    <Button color="btn btn-sm shadow">
                                                        <span
                                                            className="airbnb-connect"
                                                            data-client-id={process.env.REACT_APP_AIRBNB_CODE}
                                                            data-scope="property_management,messages_read,messages_write"
                                                            data-redirect-uri={process.env.REACT_APP_AIRBNB_URL}
                                                        />
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        : ''
                                }{ /* 
                                <Row>
                                    <Col className="col-6">
                                         <FormGroup row>
                                            <Label for="name" sm={4}><FormattedMessage id="ChannelList.name" /></Label>
                                            <Col sm={8}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ChannelList.name">
                                                        {placeholder => <Input name="name" placeholder={placeholder} readOnly value={this.props.selectedChannel.name} />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-6">
                                        <FormGroup row>
                                            <Label for="channelName" sm={4}><FormattedMessage id="ChannelList.channelName" /></Label>
                                            <Col sm={8}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ChannelList.channelName">
                                                        {placeholder => <Input name="channelName" placeholder={placeholder} readOnly value={this.props.selectedChannel.channelName} />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>*/}

                                {canEditChannelName && !this.props.configApiKey &&
                                    <Row>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="user" sm={4}>
                                                    <FormattedMessage id="ChannelList.ChannelName" />
                                                </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ChannelList.ChannelName">
                                                            {placeholder =>
                                                                <Input
                                                                    name="name"
                                                                    type="text"
                                                                    placeholder={placeholder}
                                                                    disabled={!canEditChannelName}
                                                                    onChange={this.changeStringField.bind(this, 'name')}
                                                                    maxLength="20"
                                                                    value={this.props.selectedChannel.name}
                                                                />
                                                            }
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }

                                {!hideUserAndPassword ?
                                    <Row>
                                        {!this.props.configApiKey ?
                                            <Col className="col-6">
                                                <FormGroup row>
                                                    <Label for="user" sm={4}>
                                                        <FormattedMessage id="ChannelList.User" />
                                                    </Label>
                                                    <Col sm={8}>
                                                        <InputGroup size="sm">
                                                            <FormattedMessage id="ChannelList.User">
                                                                {placeholder => <Input name="user" placeholder={placeholder} disabled={userAndPasswordDisabled} onChange={this.changeStringField.bind(this, 'user')} value={this.props.selectedChannel.user} />}
                                                            </FormattedMessage>
                                                        </InputGroup>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            : ''}
                                        <Col className="col-6">
                                            <FormGroup row>
                                                {!this.props.configApiKey ?
                                                    <Label for="password" sm={4}><FormattedMessage id="ChannelList.Password" /></Label>
                                                    :

                                                    <Label for="apiKey" sm={4}><FormattedMessage id="EmailReservation.ApiKey" /></Label>
                                                }
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id={!this.props.configApiKey ? "ChannelList.Password" : "EmailReservation.ApiKey"}>
                                                            {placeholder => <Input type={"password"} name="password" onChange={this.changeStringField.bind(this, 'password')} disabled={userAndPasswordDisabled} placeholder={placeholder} defaultValue='sdsdasdasd' />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    : ''}
                                {!this.props.configApiKey ?
                                    <Row>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="user" sm={4}><FormattedMessage id="ChannelList.propertyCode" /></Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ChannelList.propertyCode">
                                                            {placeholder => <Input name="user" placeholder={placeholder} onChange={this.changeStringField.bind(this, 'propertyCode')} value={this.props.selectedChannel.propertyCode || ''} disabled={propCodeAndMaxDaysDisabled} />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="maxInventoryDays" sm={4}><FormattedMessage id="ChannelList.maxInventoryDays" /></Label>
                                                <Col sm={2}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ChannelList.maxInventoryDays">
                                                            {
                                                                placeholder => <Input type="number" min="0" max={getMaxInventoryDays(this.props.selectedChannel.maxDays)} id="maxInventoryDays" name="maxInventoryDays" placeholder={placeholder} onChange={this.changeIntField.bind(this, 'maxInventoryDays')} value={this.state.selectedChannel.maxInventoryDays ? this.state.selectedChannel.maxInventoryDays : ''} disabled={propCodeAndMaxDaysDisabled} />
                                                            }
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                                <Label sm={4} for="maxInventoryDaysChannel"><FormattedMessage id="ChannelList.ChannelMaxDays" />   </Label>
                                                <Col sm={2}>
                                                    <InputGroup size="sm">
                                                        <Input name="maxInventoryDaysChannel" readOnly defaultValue={this.props.selectedChannel.maxDays} />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    : ''}
                                {!this.props.configApiKey ?
                                    <Row>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="secondaryUser" sm={4}><FormattedMessage id="ChannelConfigState.SecondaryUser" /></Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ChannelConfigState.SecondaryUser">
                                                            {placeholder => <Input name="secondaryUser" placeholder={placeholder} onChange={this.changeStringField.bind(this, 'secondaryUser')} value={this.props.selectedChannel.secondaryUser || ''} disabled={global.operationMode !== "PmsLimited" || mandatoryFields.find(m => m === 'SecondaryUser') === undefined} />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="secondaryPassword" sm={4}><FormattedMessage id="ChannelConfigState.SecondaryPassword" /></Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ChannelConfigState.SecondaryPassword">
                                                            {placeholder => <Input type="password" name="secondaryPassword" placeholder={placeholder} onChange={this.changeStringField.bind(this, 'secondaryPassword')} value={this.props.selectedChannel.secondaryPassword || ''} disabled={global.operationMode !== "PmsLimited" || mandatoryFields.find(m => m === 'SecondaryPassword') === undefined} />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    : ''}
                            </CardBody>
                        </Card>
                        {!this.props.configApiKey ?
                            <Card className=" shadow border-0 mb-2">
                                <CardHeader className="bg-white border-bottom" >
                                    <strong>
                                        <FormattedMessage id="ChannelList.InformationSentToChannel" />
                                    </strong>

                                    {supportedData && supportedData.length > 0 && supportedData.length !== this.state.allSupportedInfo.length ?
                                        <span>
                                            <Badge color="white" className="text-warning" pill id={'sendToChannel'}>
                                                <i className="fas fa-exclamation-triangle"></i>
                                            </Badge>
                                            <CustomToolTip placement="right" target={'sendToChannel'} >
                                                {this.state.allSupportedInfo.filter(el => supportedData.find(sd => sd.toLowerCase() === el.value.toLowerCase()) ? false : true).map(item => item.label).join(", ")}  <FormattedMessage id="ChannelDashboard.AreNotSupported" />
                                            </CustomToolTip >
                                        </span>
                                        : ''}

                                    <div className="float-right">
                                        <a id="SentToChannelTooltip">
                                            <i className="fas fa-question-circle" />
                                        </a>
                                        <CustomToolTip placement="bottom" target="SentToChannelTooltip">
                                            <Badge color="primary" className={selectedChannel?.code !== 'MAILRES' ? "p-1" : ""}>
                                                <FormattedMessage id="ChannelDashboard.Active" />
                                            </Badge>
                                            <br />
                                            <Badge color="secondary">
                                                <FormattedMessage id="ChannelDashboard.Inactive" />
                                            </Badge>
                                        </CustomToolTip>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            {["sendAvailability", "sendPrice", "sendCta", "sendCtd", "sendMaxStay", "sendMinStay", "sendStopSales", "sendMinAdvancedBookingOffset", "sendMaxAdvancedBookingOffset", "sendOverbookingAvailability"].map((el, key) => (
                                                (this.props.selectedChannel.code === "TRAVELCLICK" && el === "sendOverbookingAvailability") || el !== "sendOverbookingAvailability" ?
                                                    <Badge key={key} className={`mr-2 ${selectedChannel?.code !== 'MAILRES' ? "p-2" : ""}`} style={{ cursor: 'pointer' }}
                                                        color={this.state.selectedChannel[el] ? "primary" : "secondary"}
                                                    >
                                                        <div id={el} onClick={() => this.changeSendInfoState(el)} >
                                                            <FormattedMessage id={"ChannelList." + el.charAt(0).toUpperCase() + el.slice(1)} />
                                                        </div>
                                                    </Badge>
                                                    : ''
                                            ))}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            : ''}
                        {
                            manageCurrencyConfig ?
                                <Authorization
                                    perform="channel:manageCurrencyConfig"
                                    yes={() => (
                                        <Card className="shadow border-0 mb-2">
                                            <CardHeader className="bg-white border-bottom" >
                                                <strong>
                                                    <FormattedMessage id="ChannelList.CurrencyConfig" />
                                                </strong>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col className="col-3">
                                                        <CustomInput type="switch"
                                                            id="convertCurrencyFromReservation"
                                                            name="convertCurrencyFromReservation"
                                                            onChange={this.handleConvertCurrencyFromReservation}
                                                            checked={selectedChannel.convertCurrencyFromReservation}
                                                        >
                                                            <FormattedMessage id="ChannelList.ConvertCurrencyFromReservation" />
                                                        </CustomInput>
                                                    </Col>
                                                    <Col className="col-3">
                                                        <CustomInput
                                                            type="switch"
                                                            id="convertCurrencyToChannel"
                                                            name="convertCurrencyToChannel"
                                                            onChange={this.handleConvertCurrencyToChannel}
                                                            checked={selectedChannel.convertCurrencyToChannel}
                                                        >
                                                            <FormattedMessage id="ChannelList.ConvertCurrencyToChannel" />
                                                        </CustomInput>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <FormattedMessage id="ChannelList.Currency" />
                                                    </Col>
                                                    <Col className="col-5">
                                                        <CustomSelect
                                                            options={currencies}
                                                            onChange={this.handleCurrency}
                                                            value={selectedChannel.currencyConvertion && currencies.find(crr => crr.value === selectedChannel.currencyConvertion)}
                                                            isDisabled={!selectedChannel.convertCurrencyToChannel}
                                                            isSearchable
                                                            required={selectedChannel.convertCurrencyToChannel}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    )}
                                    no={() => ""}
                                />
                                :
                                ''
                        }
                        {getApplyDifferencesAsDiscounts(this.props.selectedChannel.channelName.toUpperCase()) ?
                            <Card className="shadow border-0 mb-2">
                                <CardHeader className="bg-white border-bottom" >
                                    <strong>
                                        <FormattedMessage id="ChannelList.DiscountsConfig" />
                                    </strong>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col className="col-3">
                                            <CustomInput type="switch"
                                                id="applyRoomsDifferenceAsDiscount"
                                                name="applyRoomsDifferenceAsDiscount"
                                                onChange={this.handleConvertDifferencesToDiscount}
                                                checked={selectedChannel.applyRoomsDifferenceAsDiscount}
                                            >
                                                <FormattedMessage id="ChannelList.DifferencesAsDiscounts" />
                                            </CustomInput>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        :''}
                    </form>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(ChannelInstanceDetail);