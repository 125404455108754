import classnames from 'classnames';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { Amenity } from '../Common/Amenity';
import ComplexClassification from './ComplexClassification';
import { ComplexContacts } from './ComplexContacts';
import { ComplexDescription } from './ComplexDescription';
import { ComplexPhotos } from './ComplexPhotos';
import { ComplexTermsAndConditions } from './ComplexTermsAndConditions';
import ComplexProfile from './ComplexProfile';
import ComplexSocialMedia from './ComplexSocialMedia';

export class ComplexSetup extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            error: null,
            block: false,
            activeTab: '1',
            activeAddressTab: '1',
            hasBE: false
        };
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    hasBE: global.modules && global.modules.some(m => m === 'BookingEngine')
                });
            },
            global.modules ? 0 : 2500
        );
    }

    //componentDidMount() {
    //    getAPI(result => {
    //        const { data, error, isLoading } = result;
    //        this.setState({ block: isLoading });
    //        if (error) {
    //            var errorMessage = [];
    //            errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
    //            this.setState({ error: errorMessage, block: false });
    //            return;
    //        }
    //        if (data) {
    //            this.setState({ ComplexData: data });
    //        }
    //    },  '/api/hotel/Complexes/v1?pageSize=300&pageIndex=0');
    //}

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
        
    onToggleDropDown = (toggleDropDown) => {
        toggleDropDown();
    }
    
    handleInsertButtonClick = (onClick) => {
        // Custom your onClick event here,
        // it's not necessary to implement this function if you have no any process before onClick
        console.log('This is my custom function for InserButton click event' + onClick);
      
    }
        
    priceFormatter(cell, row) {   // String example
        return <FormattedDate value={cell}/> ;
    }

    render() {
        const descriptionsUrl = this.props.selectedComplex ? `/api/hotel/Complexes/v1/${this.props.selectedComplex.id}/Language?type=Description` : '';
        const amenityUrl = this.props.selectedComplex ? `/api/hotel/Complexes/v1/${this.props.selectedComplex.id}/Amenities` : '';
        const publishUrl = this.props.selectedComplex ? `/api/hotel/Complexes/v1/${this.props.selectedComplex.id}/PublisherChannel` : '';
        const amenityTitle = <FormattedMessage id="ComplexSetup.Amenities" />;
        const amenityTypes = [
            {
                title: <FormattedMessage id="Amenity.HACFacilit" />,
                url: "/api/hotel/ReferenceData/v1/HACFacilit"
            },
            {
                title: <FormattedMessage id="Amenity.HACService" />,
                url: "/api/hotel/ReferenceData/v1/HACService"
            },
            {
                title: <FormattedMessage id="Amenity.HACShops" />,
                url: "/api/hotel/ReferenceData/v1/HACShops"
            },
            {
                title: <FormattedMessage id="Amenity.ACC" />,
                url: "/api/hotel/ReferenceData/v1/ACC"
            },
            {
                title: <FormattedMessage id="Amenity.Experiences" />
            },
            {
                title: <FormattedMessage id="Amenity.ExperienceAdventure" />,
                url: "/api/hotel/ReferenceData/v1/XPAdventu"
            },
            {
                title: <FormattedMessage id="Amenity.ExperienceSunAndBeach" />,
                url: "/api/hotel/ReferenceData/v1/XPSun"
            },
            {
                title: <FormattedMessage id="Amenity.ExperienceWellness" />,
                url: "/api/hotel/ReferenceData/v1/XPWellnes"
            },
            {
                title: <FormattedMessage id="Amenity.ExperienceArtsAndCulture" />,
                url: "/api/hotel/ReferenceData/v1/XPArts"
            },
            {
                title: <FormattedMessage id="Amenity.ExperienceGourmet" />,
                url: "/api/hotel/ReferenceData/v1/XPGourmet"
            },
            {
                title: <FormattedMessage id="Amenity.ExperienceNature" />,
                url: "/api/hotel/ReferenceData/v1/XPNature"
            },
            {
                title: <FormattedMessage id="Amenity.ExperienceSports" />,
                url: "/api/hotel/ReferenceData/v1/XPSports"
            }
        ];        

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                <FormattedMessage id="ComplexSetup.GeneralData" /> 
                            </NavLink>
                        </NavItem>
                        {!this.props.creationMode && this.state.hasBE ?
                            <div className="d-flex">
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                        <FormattedMessage id="ComplexSetup.Descriptions" />
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <FormattedMessage id="ComplexSetup.Contacts" />
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                        <FormattedMessage id="ComplexSetup.TermsAndConditions" />
                                    </NavLink>
                                </NavItem>
                                {global.modules && global.modules.some(m => m === 'BookingEngineChain') ?
                                    <NavItem>
                                        <NavLink className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                                            <FormattedMessage id="ComplexSetup.Amenities" />
                                        </NavLink>
                                    </NavItem>
                                    :
                                    ''
                                }
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggle('6'); }}>
                                        <FormattedMessage id="ComplexSetup.Photos" />
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '7' })} onClick={() => { this.toggle('7'); }}>
                                        <FormattedMessage id="ComplexSetup.SocialMedia" />
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '8' })} onClick={() => { this.toggle('8'); }}>
                                        <FormattedMessage id="ComplexSetup.ProfileConfig" />
                                    </NavLink>
                                </NavItem>
                            </div>   
                        : ''}
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <ComplexClassification selectedComplex={this.props.selectedComplex} updateComplex={this.props.updateComplex} updateComplexs={this.props.updateComplexs} creationMode={this.props.creationMode} toggle={this.props.toggle} fromBEConfig={this.props.fromBEConfig} />
                        </TabPane>
                        
                        {!this.props.creationMode && this.state.hasBE && this.props.modal ?
                            <>
                                <TabPane tabId="2" >
                                    <ComplexDescription selectedEntity={this.props.selectedComplex} entity={'Complexes'} url={descriptionsUrl} fromBEConfig={this.props.fromBEConfig} />
                                </TabPane>
                                <TabPane tabId="3">
                                    <ComplexContacts selectedEntity={this.props.selectedComplex} entity={'Complexes'} fromBEConfig={this.props.fromBEConfig} />
                                </TabPane>
                                <TabPane tabId="4">
                                    <ComplexTermsAndConditions selectedComplex={this.props.selectedComplex} fromBEConfig={this.props.fromBEConfig} />
                                </TabPane>
                                {global.modules && global.modules.some(m => m === 'BookingEngineChain') ?
                                    <TabPane tabId="5" >
                                        <Amenity url={amenityUrl} title={amenityTitle} amenityTypes={amenityTypes} fromPropertyPage={true}/>
                                    </TabPane>
                                    :
                                    ''
                                }
                                <TabPane tabId="6">
                                    <ComplexPhotos selectedComplex={this.props.selectedComplex} />
                                </TabPane>
                                <TabPane tabId="7">
                                    <ComplexSocialMedia selectedComplex={this.props.selectedComplex} />
                                </TabPane>
                                <TabPane tabId="8">
                                    <ComplexProfile selectedComplex={this.props.selectedComplex} />
                                </TabPane>
                            </>
                        : ''}
                    </TabContent>
                </BlockUi>
            </div>
        )
    }
}