import React, { Component } from 'react';
import { Row, Col, Card, CardBody, UncontrolledCollapse, Progress, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, CardHeader, Label, Input } from 'reactstrap';
import { postAPI } from "../../Base/API"
import BlockUi from 'react-block-ui'
import { ErrorAlert } from "../../Common/ErrorAlert";
import { handleNotification } from "../../Base/Notification";
import { FormattedMessage } from 'react-intl';


export class BookingReviewDetail extends Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            error: [],

            activeTab: '1',
            Alerts: []
        };

    }

    toggleModal() {
        this.props.action();
        this.setState({ error: [] })
    }


    addReply(event) {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="BookingReviewDetail.SuccessfullySaved" />, <FormattedMessage id="BookingReviewDetail.Success" />);


                this.setState({ reply: data, block: false, error: errorMessage });
            }
        }, '/api/Rate/Review/v1/' + this.props.connection + '/Review/' + this.props.data.review_id + '/Reply?response=' + this.props.data.reply);
        event.preventDefault();
    }

    render() {
        return (
            <div>
                {this.props.data.reviewer ?
                    <Modal isOpen={this.props.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                        <Form onSubmit={this.addReply.bind(this)}>
                            <ModalHeader toggle={this.toggleModal} > <FormattedMessage id="BookingReviewDetail.ReviewDetails" /> </ModalHeader>
                            <ModalBody className="modal-lg px-2 pb-2">
                                <BlockUi tag="div" blocking={this.state.block}>
                                    <ErrorAlert error={this.state.error} />

                                    <Card className="mb-2 border-0">

                                        <CardHeader className="small bg-white">
                                            <div className="inline">
                                                <strong> <span className="far fa-id-card"></span> <FormattedMessage id="BookingReviewDetail.Reviewer" /> </strong>
                                                <a id="togglerReview" className="float-right">
                                                    <span className="fas fa-angle-down"></span>
                                                </a>
                                            </div>
                                        </CardHeader>
                                        <UncontrolledCollapse toggler="#togglerReview">
                                            <CardBody className="pb-0 small border-1">
                                                <Row>
                                                    <Col className="col-6">
                                                        <FormGroup sm="true" row>
                                                            <Label for="Headline" sm={4}> <FormattedMessage id="BookingReviewDetail.Name" /> </Label>
                                                            <Col sm={8}>
                                                                <Input type="text" name="Headline" id="Headline" bsSize="sm" className="text-sm" disabled value={this.props.data.reviewer.name} />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="col-6">
                                                        <FormGroup sm="true" row>

                                                            <Label for="Country" sm={4}> <FormattedMessage id="BookingReviewDetail.Country" /> </Label>
                                                            <Col sm={8}>
                                                                <Input type="text" name="Country" id="Headline" bsSize="sm" className="text-sm" disabled value={this.props.data.reviewer.country_code} />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </UncontrolledCollapse>
                                    </Card>

                                    <Card className="mb-2  border-0">

                                        <CardHeader className="small bg-white">
                                            <div className="inline">
                                                <strong> <span className="fas fa-award"> </span> <FormattedMessage id="BookingReviewDetail.Scoring" /></strong>
                                                <a id="togglerScoring" className="float-right">
                                                    <span className="fas fa-angle-down"></span>
                                                </a>
                                            </div>
                                        </CardHeader>
                                        <UncontrolledCollapse toggler="#togglerScoring">
                                            <CardBody className="pb-0 small border-1">
                                                <Row>
                                                    <Col className="Col-6">
                                                        <Row className='d-flex align-items-center'>
                                                            <Col className="col-lg-3 col-12"> <FormattedMessage id="BookingReviewDetail.ReviewScore" /> </Col>
                                                            <Col className="col-lg-8 col-10">
                                                                <Progress striped color="success" value={this.props.data.scoring.review_score * 10} />
                                                            </Col>
                                                            <Col className={`col-lg-1 ${global.isMobile ? 'p-0' : ''}`}>
                                                                {this.props.data.scoring.review_score}
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex align-items-center'>
                                                            <Col className="col-lg-3 col-12"> <FormattedMessage id="BookingReviewDetail.Clean" /> </Col>
                                                            <Col className="col-lg-8 col-10">
                                                                <Progress striped color="success" value={this.props.data.scoring.clean * 10} />
                                                            </Col>
                                                            <Col className={`col-lg-1 ${global.isMobile ? 'p-0' : ''}`}>
                                                                {this.props.data.scoring.clean}
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex align-items-center'>
                                                            <Col className="col-lg-3 col-12"> <FormattedMessage id="BookingReviewDetail.Location" /></Col>
                                                            <Col className="col-lg-8 col-10">
                                                                <Progress striped color="success" value={this.props.data.scoring.location * 10} />
                                                            </Col>
                                                            <Col className={`col-lg-1 ${global.isMobile ? 'p-0' : ''}`}>
                                                                {this.props.data.scoring.location}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="Col-6">

                                                        <Row className='d-flex align-items-center'>
                                                            <Col className="col-lg-3 col-12"> <FormattedMessage id="BookingReviewDetail.Comfort" /></Col>
                                                            <Col className="col-lg-8 col-10">
                                                                <Progress striped color="success" value={this.props.data.scoring.comfort * 10} />
                                                            </Col>
                                                            <Col className={`col-lg-1 ${global.isMobile ? 'p-0' : ''}`}>
                                                                {this.props.data.scoring.comfort}
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex align-items-center'>
                                                            <Col className="col-lg-3 col-12"> <FormattedMessage id="BookingReviewDetail.Staff" /></Col>
                                                            <Col className="col-lg-8 col-10">
                                                                <Progress striped color="success" value={this.props.data.scoring.staff * 10} />
                                                            </Col>
                                                            <Col className={`col-lg-1 ${global.isMobile ? 'p-0' : ''}`}>
                                                                {this.props.data.scoring.staff}
                                                            </Col>
                                                        </Row>
                                                        <Row className='d-flex align-items-center'>
                                                            <Col className="col-lg-3 col-12"> <FormattedMessage id="BookingReviewDetail.Value" /></Col>
                                                            <Col className="col-lg-8 col-10">
                                                                <Progress striped color="success" value={this.props.data.scoring.value * 10} />
                                                            </Col>
                                                            <Col className={`col-lg-1 ${global.isMobile ? 'p-0' : ''}`}>
                                                                {this.props.data.scoring.value}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </UncontrolledCollapse>
                                    </Card>


                                    <Card className="mb-2 border-0">
                                        <CardHeader className="small bg-white">
                                            <div className="inline">
                                                <strong> <span className="far fa-edit"></span> <FormattedMessage id="BookingReviewDetail.ReviewContent" /></strong>
                                                <a id="togglerReviewContent" className="float-right">
                                                    <span className="fas fa-angle-down"></span>
                                                </a>
                                            </div>
                                        </CardHeader>
                                        <UncontrolledCollapse isOpen={true} toggler="#togglerReviewContent">
                                            <CardBody className="pb-0 small border-1">
                                                <Row>
                                                    <Col>
                                                        <FormGroup sm="true" row>

                                                            <Label for="Headline" sm={2}> <FormattedMessage id="BookingReviewDetail.Headline" /></Label>
                                                            <Col sm={10}>
                                                                <Input type="textarea" name="Headline" id="Headline" bsSize="sm" className="text-sm" disabled value={this.props.data.content && this.props.data.content.headline ? this.props.data.content.headline : ""} />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup sm="true" row>
                                                            <Label for="positive" sm={2}> <FormattedMessage id="BookingReviewDetail.Positive" /></Label>
                                                            <Col sm={10}>
                                                                <Input type="textarea" name="positive" id="positive" bsSize="sm" className="text-sm" disabled value={this.props.data.content && this.props.data.content.positive ? this.props.data.content.positive : ''} />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup sm="true" row >
                                                            <Label for="Negative" sm={2}> <FormattedMessage id="BookingReviewDetail.Negative" /></Label >
                                                            <Col sm={10}>
                                                                <Input type="textarea" name="Negative" id="Negative" bsSize="sm" className="text-sm" disabled value={this.props.data.content && this.props.data.content.negative ? this.props.data.content.negative : ''} />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                            </CardBody>
                                        </UncontrolledCollapse>
                                    </Card>

                                    {this.props.data.content ?
                                        <Card className="mb-2 border-0">
                                            <CardHeader className="small bg-white">
                                                <div className="inline">
                                                    <strong> <span className="far fa-comment"></span> <FormattedMessage id="BookingReviewDetail.Reply" /></strong>
                                                    <a id="togglerReply" className="float-right">
                                                        <span className="fas fa-angle-down"></span>
                                                    </a>
                                                </div>
                                            </CardHeader>
                                            <UncontrolledCollapse isOpen={true} toggler="#togglerReply">
                                                <CardBody className="pb-0 small border-1">
                                                    <Row>
                                                        <Col>
                                                            <FormGroup sm="true" row>

                                                                <Label for="Headline" sm={2}><FormattedMessage id="BookingReviewDetail.Reply" /></Label>
                                                                <Col sm={10}>
                                                                    <Input type="textarea" name="Headline" id="Headline" bsSize="sm" className="text-sm" required value={this.props.data.reply ? this.props.data.reply.text : ''} onChange={this.props.handleChange} />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                </CardBody>
                                            </UncontrolledCollapse>
                                        </Card>
                                        : ''}

                                </BlockUi>
                            </ModalBody>
                            <ModalFooter>
                                {this.props.data.content ? <Button color="primary btn-host" type="submit" > <FormattedMessage id="BookingReviewDetail.Save" /></Button> : ''}
                                <Button color="secondary" onClick={this.toggleModal}> <FormattedMessage id="BookingReviewDetail.Cancel" /></Button>
                            </ModalFooter>
                        </Form>
                    </Modal>

                    : ''}
            </div>
        );
    }
}