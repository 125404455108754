import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Input, Row } from 'reactstrap';
import moment from 'moment'
import { getAPI } from '../Base/API';
import { FormatAmountNumber, PaymentStatusBadge, StyledCard } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { CustomTable } from '../Base/Common/CustomTable';
import { PaymentDetails } from './PaymentDetails';
import { getConvertedDateToHotelTimezone, getPaymentGatewayType, getPaymentModes, getPaymentStatusOptions, getPaymentTypes } from '../Base/Common/ReferenceDataFunctions';
import { MobileFilters } from '../Base/Common/MobileComponents';

export class Payments extends Component {

    constructor(props) {
        super(props);
        this.getPayments = this.getPayments.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setComboStatus = this.setComboStatus.bind(this);
        this.togglePaymentDetailsModal = this.togglePaymentDetailsModal.bind(this);
        this.updatePayment = this.updatePayment.bind(this);
        this.updateRefunds = this.updateRefunds.bind(this);
        this.state = {
            block: true,
            payments: [],
            resIdValue: null,
            paymentStatus: null,
            channel: null,
            paymentDetailsModal: false,
            payment: null,
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            refundsTotalAmount: 0
        };
    }

    componentDidMount() {
        this.getPaymentGateways();
    }

    getPaymentGateways() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                this.setState({ paymentGateways: data.response.map(({gatewayType}) => gatewayType) }, () => this.getPayments());
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Rate/Payment/v1/payment/gateway');
    }

    getPayments() {
        const { resIdValue, channel, paymentStatus, gatewayType, pageIndex, pageSize } = this.state;

        const params = gatewayType ? `&gatewayType=${gatewayType}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.data) {
                data.data.forEach(pmt => {
                    if (!pmt.reservationPaymentRefund) {
                        pmt.reservationPaymentRefund = [];
                    }
                    pmt.reservationPaymentRefund.forEach(rfd => {
                        rfd.createdAt = getConvertedDateToHotelTimezone(rfd.createdAt).format("YYYY-MM-DD HH:mm:ss");
                    });
                });
                this.setState({ error: errorMessage, block: false, payments: data.data, totalItems: data.count });
            }
            else {
                this.setState({ error: errorMessage, block: false, payments: [], totalItems: 0 });
            }
        }, `/api/Price/Payment/v1?pageIndex=${pageIndex}&pageSize=${pageSize}&${resIdValue ? `resIdValue=${resIdValue}` : ``}${paymentStatus ? `&paymentStatus=${paymentStatus}` : ``}` + params);
    }

    handleChange(evt) {
        if (evt) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    setComboStatus(name, combo, evt) {
        if (evt) {
            this.setState({ [name]: combo && combo.value });
        }
    }

    togglePaymentDetailsModal() {
        this.setState(prevState => ({ paymentDetailsModal: !prevState.paymentDetailsModal, payment: null }));
    }

    addPayment = (payment) => {
        const { payments } = this.state;
        payments.unshift(payment);
        this.setState({ payments });
    }

    updatePayment(newPayment) {
        const { payment } = this.state;
        Object.assign(payment, newPayment);
        this.setState({ payment });
    }

    updateRefunds(newRefund) {
        const { payment } = this.state;
        if (!payment.reservationPaymentRefund) {
            payment.reservationPaymentRefund = [];
        }
        newRefund.createdAt = moment(newRefund.createdAt).format("YYYY-MM-DD HH:mm:ss");
        payment.reservationPaymentRefund.push(newRefund);
        this.setState({ payment, refundsTotalAmount: payment.reservationPaymentRefund.reduce((acc, curr) => acc + curr.amount, 0) });
    }

    handleTableChange = (_, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
            block: true
        }, this.getPayments);
    }

    renderMainFilter = () => {
        return (
            <Col>
                <FormattedMessage id="Payments.Reservation">
                    {
                        placeholder => <Input type="text" id="resIdValue" name="resIdValue" value={this.state.resIdValue} onChange={this.handleChange} placeholder={placeholder} />
                    }
                </FormattedMessage>
            </Col>
        )
    }

    render() {
        const { block, error, payments, resIdValue, paymentGateways, gatewayType, paymentStatus, paymentDetailsModal, payment, pageIndex, pageSize, totalItems, refundsTotalAmount } = this.state;

        const paymentStatusOptions = getPaymentStatusOptions();

        const paymentTypeOptions = getPaymentTypes();

        const columns = [
            {
                dataField: 'paymentGateway',
                text: 'Gateway',
                formatter: cell => getPaymentGatewayType().filter(p => p.value === cell).map((p, key) => p.showLogoHasText ? <h6 key={key}>{p.logo}</h6> : <img height="20" src={p.logo} />)
            },
            {
                dataField: 'mode',
                text: <FormattedMessage id="Payments.Mode" />,
                formatter: (cell, row) => getPaymentModes().filter(p => p.value === cell).map(p => row.merchantIdentifier === null && row.merchantCofTxnid === null ? p.img(20) : p.img(20, 'text-success'))
            },
            {
                dataField: 'resIdValue',
                text: this.props.intl.formatMessage({ id: "Payments.ReservationId" })
            },
            {
                dataField: 'channelName',
                text: this.props.intl.formatMessage({ id: "Payments.Channel" }),
                formatter: (cell, row) => cell ? cell : row.channelInstanceId
            },
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "Payments.Type" }),
                formatter: cell => {
                    const opt = paymentTypeOptions.find(opt => opt.value === cell);
                    return opt ? opt.label : cell;
                }
            },
            {
                dataField: 'paymentStatus',
                text: this.props.intl.formatMessage({ id: "Payments.Status" }),
                formatter: cell => <PaymentStatusBadge status={cell} />
            },
            {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "Payments.Date" }),
                formatter: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "Payments.Name" })
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "Payments.Email" })
            },
            {
                dataField: 'amount',
                text: this.props.intl.formatMessage({ id: "Payments.Amount" }),
                formatter: (cell, row) => <FormatAmountNumber value={cell} currency={row.currencyCode} />
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row) => this.setState({ payment: row, paymentDetailsModal: true, refundsTotalAmount: row.reservationPaymentRefund.reduce((acc, curr) => acc + curr.amount, 0) })
        };

        const helpContent = (
            <div>
                <b>
                    <FormattedMessage id="navbar.Payments" />
                </b>
                <hr />
                <FormattedMessage id="Payments.PaymentsHelp" />
                <hr />
                <FormattedMessage id="Payments.PaymentsHelp1" />
                <br />
                <i className="text-primary fas fa-circle" />{" "}
                <FormattedMessage id="Payments.PaymentsHelp6" />
                <br />
                <i className="text-primary fas fa-circle" />{" "}
                <FormattedMessage id="Payments.PaymentsHelp4" />
                <br />
                <i className="text-primary fas fa-circle" />{" "}
                <FormattedMessage id="Payments.PaymentsHelp7" />
                <br />
                <i className="text-success fas fa-circle" />{" "}
                <FormattedMessage id="Payments.PaymentsHelp2" />
                <br />
                <i className="text-danger fas fa-circle" />{" "}
                <FormattedMessage id="Payments.PaymentsHelp3" />
                <br />
                <i className="text-danger fas fa-circle" />{" "}
                <FormattedMessage id="Payments.PaymentsHelp8" />
                <br />
                <i className="text-secondary fas fa-circle" />{" "}
                <FormattedMessage id="Payments.PaymentsHelp5" />
            </div>
        );

        const screenWidth = document.documentElement.clientWidth || document.body.clientWidth;

        return (
            <StyledCard block={block} error={error} title="navbar.Payments" help={helpContent}>
                {
                    paymentDetailsModal ?
                        <PaymentDetails
                            isOpen={paymentDetailsModal}
                            toggle={this.togglePaymentDetailsModal}
                            payment={payment}
                            refundsTotalAmount={refundsTotalAmount}
                            updatePayment={this.updatePayment}
                            updateRefunds={this.updateRefunds}
                            addPayment={this.addPayment}
                        />
                        :
                        <div />
                }
                {global.isMobile ?
                    <MobileFilters 
                        doSearch={_ => this.setState({ pageIndex: 0, block: true }, this.getPayments)} 
                        renderMainFilter={this.renderMainFilter}
                    >
                        <Row className='mb-2'>
                            <CommonFilters
                                getPaymentGatewayType={getPaymentGatewayType}
                                paymentGateways={paymentGateways}
                                gatewayType={gatewayType} 
                                setComboStatus={this.setComboStatus} 
                                paymentStatusOptions={paymentStatusOptions} 
                                paymentStatus={paymentStatus}
                            />
                        </Row>
                    </MobileFilters>
                :
                    <Row className="mb-3">
                        <Col className="col-3">
                            <FormattedMessage id="Payments.Reservation">
                                {
                                    placeholder => <Input type="text" id="resIdValue" name="resIdValue" value={resIdValue} onChange={this.handleChange} placeholder={placeholder} />
                                }
                            </FormattedMessage>
                        </Col>
                        <CommonFilters 
                            getPaymentGatewayType={getPaymentGatewayType} 
                            gatewayType={gatewayType} 
                            setComboStatus={this.setComboStatus} 
                            paymentGateways={paymentGateways}
                            paymentStatusOptions={paymentStatusOptions} 
                            paymentStatus={paymentStatus}
                        />
                        <Col className="text-right">
                            <Button className="btn btn-host btn-sm" onClick={_ => this.setState({ pageIndex: 0, block: true }, this.getPayments)}>
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                }      
                <Row>
                    <Col>
                        <CustomTable
                            data={payments}
                            columns={columns}
                            selectRow={selectRow}
                            page={pageIndex + 1}
                            sizePerPage={pageSize}
                            totalSize={totalItems}
                            onTableChange={this.handleTableChange}
                            remote={true}
                            search={false}
                            showTotal={screenWidth > 600}
                            hideSizePerPage={global.isMobile}
                        />
                    </Col>
                </Row>
            </StyledCard>    
        );
    }
}
export default injectIntl(Payments);


const CommonFilters = ({ getPaymentGatewayType, gatewayType, setComboStatus, paymentStatusOptions, paymentStatus, paymentGateways = [] }) => {
    return (
        <>
            <Col className="col-12 col-lg-3 mb-2">
                {/*<SelectChannelInstance name={'channel'} placeholder={<FormattedMessage id="ReservationList.SelectChannel" />} value={channel} onChangeFunc={this.setComboStatus} isMulti={false} />*/}

                <CustomSelect
                    options={getPaymentGatewayType()?.filter(({value}) => paymentGateways.includes(value))}
                    value={getPaymentGatewayType().find(opt => opt.value === gatewayType)}
                    onChange={setComboStatus.bind(this, 'gatewayType')}
                    placeholder={<FormattedMessage id="ManagePayments.GatewayType" />}
                    isClearable
                />
            </Col>
            <Col className="col-12 col-lg-3 mb-2">
                <CustomSelect
                    options={paymentStatusOptions}
                    value={paymentStatusOptions.find(opt => opt.value === paymentStatus)}
                    onChange={setComboStatus.bind(this, 'paymentStatus')}
                    placeholder={<FormattedMessage id="Payments.Status" />}
                    isClearable
                />
            </Col>
        </>
    )
}
