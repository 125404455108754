import React, { Component } from 'react';
import { TabPane, TabContent, Nav, NavItem, NavLink, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getOffersStatus, getOffersServicePricingType } from '../../Base/Common/ReferenceDataFunctions';
import { handleNotification } from "../../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI, postAPI, postMultiPartAPI } from "../../Base/API";
import classnames from 'classnames';
import moment from 'moment';
import AssociateRates from './AssociateRates';
import OfferDetailsCard from './OfferDetailsCard';
import OfferDetailsCardMobile from './OfferDetailsCardMobile';

class OfferDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: false,
            items: [],
            modal: false,
            sellItem: {},
            status: getOffersStatus(),
            pricingType: getOffersServicePricingType(),
            activeTab: '0',
            uploadedImg: null
        }
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params.id) {
            this.getSellItem(this.props.match.params.id);
        }
        if (this.props.id) {
            this.getSellItem(this.props.id);
        }
    }

    getSellItem = (id) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ sellItem: data.response[0] }, () => {
                        if(!this.props.rates || this.props.rates.length > 0){
                            this.getRates();
                            return;
                        }
                    });
                }
                this.setState(({ block: false }));
            }
        }, `/api/hotel/UpSellAndCrossSell/v1/sellItem/${id}`);
    }

    getRates = () => {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data && data.length > 0) {
                const channelId = data[0]?.id;
                
                getAPI(result => {
                    const { data, error } = result;
        
                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.response && data.response.length > 0) {
                            var rates = [];
        
                            data.response.forEach(room => {
                                room.rateList.forEach(rate => {
                                    if (rate.mappingList && rate.mappingList.length > 0 && rate.mappingList[0].id !== 0) {
                                        rates.push({ id: rate.id, code: rate.code });
                                    }
                                })
                            });
                        }
                        this.setState({ rates, block: false });
                        return;
                    }
                }, '/api/Rate/Rate/v1/RateMapping/' + channelId);
            }
            this.setState(({ block: false }));
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance?channelCode=HEYBE`);
    }

    saveOffer = (e) => {
        const { uploadedImg, sellItem } = this.state;
        e.preventDefault();

        const body = { ...sellItem };
        body.feeAmount = parseFloat(body.feeAmount);
        body.guestCount = null;

        if(!sellItem.isMandatory) {
            body.maxElements = body.maxElements ? parseInt(body.maxElements) : null;
            body.maxElementsPerRooms = body.maxElementsPerRooms ? parseInt(body.maxElementsPerRooms) : null;

            if(body.maxElementsPerRooms > body.maxElements){
                handleNotification({warnings:[{message: this.props.intl.formatMessage({id: "Offers.MaxRoomSmallerThanMaxRes"})}]});
                return;
            }
        }
        if (body.startDate) body.startDate = moment(body.startDate).format("YYYY-MM-DD");
        if (body.endDate) body.endDate = moment(body.endDate).format("YYYY-MM-DD");
        if (body.feeChildAmount) body.feeChildAmount = parseFloat(body.feeChildAmount);
        if (body.maxStay) body.maxStay = parseFloat(body.maxStay);
        else body.maxStay = null;
        if (body.minStay) body.minStay = parseFloat(body.minStay);
        else body.minStay = null;
        if (body.daysBefore) body.daysBefore = parseFloat(body.daysBefore);
        else body.daysBefore = null;

        if(body.servicePricingType !== 'PerPerson' && body.servicePricingType !== 'PerPersonPerNight'){
            body.feeChildAmount = null;
        };

        if(body.isMandatory)
            body.maxElements = null;

        if(body.isMandatory || (sellItem.servicePricingType !== 'PerRoom' && sellItem.servicePricingType !== 'PerRoomPerNight'))
            body.maxElementsPerRooms = null;

        if(body.sortOrder) body.sortOrder = parseInt(body.sortOrder);

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    if(uploadedImg){
                        this.submitImage();
                    }
                    handleNotification(data, <FormattedMessage id="Offers.OfferSaved" />, <FormattedMessage id="General.Success" />);

                    this.setState({ block: false, error: errorMessage, sellItem: data.response[0] });
                }
            }
        }, '/api/hotel/UpSellAndCrossSell/v1/sellItem', JSON.stringify({ request: body }));
    }

    submitImage = () => {
        const { img } = this.state;
        this.setState({ block: true });

        const formData = new FormData();
        formData.append('image', img);

        postMultiPartAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="Offers.ImageSaved" />, <FormattedMessage id="General.Success" />);

                    this.setState({
                        block: false, error: errorMessage,
                        sellItem: { ...this.state.sellItem, imageUrl: data.response[0].imageUrl }
                    });
                }
            }
        }, `/api/hotel/UpSellAndCrossSell/v1/UploadSellItemImage/${this.state.sellItem.serviceCode}`, formData);
    }

    addAttach = () => {
        var file = document.querySelector('input[type=file]').files[0];

        if (file) {
            if (file.size <= 1048576) { //1MB
                var reader = new FileReader();
                
                reader.onloadend = () => {
                    this.setState({
                        uploadedImg: reader.result,
                        img: file
                    });
                }
                reader.readAsDataURL(file);
            }
            else {
                handleNotification('', <FormattedMessage id="BookingChatWindow.PleaseSelectAFileLessThan" />, <FormattedMessage id="BookingChatWindow.SelectedFileIsTooBig" />, 'info');
            }
        }
    }

    handleChangeMultiLang = (e, lang) => {
        const { name, value } = e.target;
        let descriptions = this.state.sellItem.descriptions ? [...this.state.sellItem.descriptions] : [];
        const index = descriptions.findIndex(el => el.culture === lang);

        if (index !== -1) {
            descriptions[index][name] = value;
        }
        else {
            const newDescription = { culture: lang, description: "", title: "" };
            newDescription[name] = value;

            descriptions.push(newDescription);
        }

        this.setState({
            sellItem: {
                ...this.state.sellItem,
                descriptions: descriptions
            }
        });
    }

    toggleModal = (cancelId) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            cancelSelected: cancelId
        }));
    }

    onChangeSelect = (name, combo) => {
        this.setState({
            sellItem: {
                ...this.state.sellItem,
                [name]: combo ? combo.value : null
            }
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const { sellItem } = this.state;

        if(name === 'maxStay' || name === 'minStay'){
            const el = document.getElementById('maxStay');
            if (el && sellItem.maxStay && sellItem.minStay) {
                if((name === "maxStay" && parseFloat(value) < parseFloat(sellItem.minStay)) || (name === "minStay" && parseFloat(sellItem.maxStay) < parseFloat(value))){
                    el.setCustomValidity(this.props.intl.formatMessage({ id: "Offers.MaxStayLessThanMinStay" }));
                }
                else{
                    el.setCustomValidity("");
                }
            }
        }
        
        this.setState({
            sellItem: {
                ...this.state.sellItem,
                [name]: value
            }
        });
    }

    handleRates = (e, rateId) => {
        const { checked } = e.target;
        const { sellItem } = this.state;

        if (!checked) {
            sellItem.rateCodes = sellItem.rateCodes.filter(el => el.id !== rateId);
        }
        else {
            sellItem.rateCodes.push({ id: rateId });
        }

        this.setState({ sellItem })
    }

    handleDates = (name, date) => {
        if(name === 'startDate' && !date){
            this.setState({
                sellItem: {
                    ...this.state.sellItem,
                    endDate: null,
                    [name]: date
                }
            });
        }
        else{
            this.setState({
                sellItem: {
                    ...this.state.sellItem,
                    [name]: date
                }
            });
        }
    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { sellItem, status, pricingType, block, error, activeTab, uploadedImg } = this.state;
        const rates = this.props.rates ? this.props.rates : this.state.rates;

        const mandatoryOptions = [
            {
                value: true,
                label: <FormattedMessage id="generic.yes" />
            },
            {
                value: false,
                label: <FormattedMessage id="generic.no" />
            }
        ]
        
        return (
            <div>
                {!this.props?.isChild ?
                    <Breadcrumb className={`${global.isMobile ? 'm-2' : ''}`}>
                        <BreadcrumbItem> <a href="javascript:void(0);" onClick={() => this.props.history.push('/Offers')}> <FormattedMessage id="navbar.Offers" /> </a></BreadcrumbItem>
                        <BreadcrumbItem active> <FormattedMessage id="Offers.OfferDetails" /> </BreadcrumbItem>
                    </Breadcrumb>
                :''}
                <Nav tabs>
                    <NavItem className="cursor-pointer">
                        <NavLink className={classnames({ active: activeTab === '0' })} onClick={() => { this.toggle('0'); }}>
                            <FormattedMessage id="Offers.OfferDetails" />
                        </NavLink>
                    </NavItem>
                    <NavItem className="cursor-pointer">
                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="Offers.Rates" />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} className="h-100">
                    <TabPane tabId='0'>
                        {activeTab === '0' ?
                            !global.isMobile ?
                                <OfferDetailsCard
                                    sellItem={sellItem}
                                    status={status}
                                    pricingType={pricingType}
                                    mandatoryOptions={mandatoryOptions}
                                    handleChange={this.handleChange}
                                    handleDates={this.handleDates}
                                    handleRates={this.handleRates}
                                    toggleModal={this.toggleModal}
                                    onChangeSelect={this.onChangeSelect}
                                    handleChangeMultiLang={this.handleChangeMultiLang}
                                    saveOffer={this.saveOffer}
                                    block={block}
                                    error={error}
                                    uploadedImg={uploadedImg}
                                    addAttach={this.addAttach}
                                    rates={rates}
                                />
                            :
                                <OfferDetailsCardMobile
                                    sellItem={sellItem}
                                    status={status}
                                    pricingType={pricingType}
                                    mandatoryOptions={mandatoryOptions}
                                    handleChange={this.handleChange}
                                    handleDates={this.handleDates}
                                    handleRates={this.handleRates}
                                    toggleModal={this.toggleModal}
                                    onChangeSelect={this.onChangeSelect}
                                    handleChangeMultiLang={this.handleChangeMultiLang}
                                    saveOffer={this.saveOffer}
                                    block={block}
                                    error={error}
                                    uploadedImg={uploadedImg}
                                    addAttach={this.addAttach}
                                    rates={rates}
                                />
                        :''}
                    </TabPane>
                    <TabPane tabId='1'>
                        {activeTab === '1' ?
                            <>
                                {sellItem?.id ?
                                    <AssociateRates
                                        rates={rates}
                                        sellItemRates={sellItem ? sellItem.rateCodes : []}
                                        sellItemId={sellItem.id}
                                    />
                                : ''}
                            </>
                        :''}
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

export default injectIntl(OfferDetails)