import React, { Component } from "react";
import BlockUi from "react-block-ui";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, ListGroup, ListGroupItem, Row } from "reactstrap";
import { getAPI, postAPI } from "../Base/API";
import CustomSelect from "../Base/Common/CustomSelect";
import { handleNotification } from "../Base/Notification";
import { ChannelAdd } from "./ChannelAdd";

function ShowAirBnbFeedback(props) {
	if (props.airbnbSucess === null) {
		return <div>Processing ....</div>;
	} else if (props.airbnbSucess === true) {
		return (
			<div>
				<p className="lead">
					Conta associada com sucesso para o utilizador com o id{" "}
					{props.airbnbMessage && props.airbnbMessage.response
						? props.airbnbMessage.response.user_id
						: ""}
				</p>
			</div>
		);
	} else {
		return (
			<div>
				<p className="lead">
					Ocorreu um erro no redirecionamento, por favor tente mais
					tarde
				</p>
				Error :{" "}
				{props.airbnbMessage && props.airbnbMessage.error
					? props.airbnbMessage.error.error_message
					: ""}
			</div>
		);
	}
}

export class ChannelChoose extends Component {
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.form = React.createRef();
		this.addNewAirbnbConnection = this.addNewAirbnbConnection.bind(this);
		this.state = {
			block: false,
			CurrentStep: "1",
			name: "",
			airbnbSucess: null,
			airbnbMessage: null,
			availableChannels: [
			],
			selectedChannel: "Airbnb",
			selectedChannelValue: "Airbnb",
			Steps: [
				{ step: "1", active: true, enabled: true },
				{ step: "2", active: false, enabled: false },
				{ step: "3", active: false, enabled: false }
			],
			channelInstance: {
				id: null,
				propertyCode: null,
				user: null,
				password: null,
				secondaryUser: null,
				secondaryPassword: null,
				name: null,
				channelConfigId: null,
				active: false,
				maxInventoryDays: 0,
				sendAvailability: true,
				sendPrice: true,
				sendStopSales: true,
				sendCta: true,
				sendCtd: true,
				sendMinStay: true,
				sendMaxStay: true
			}
		};
	}

	componentDidMount() {
		const avaiChan = [
			{ value: "Airbnb", label: "Airbnb" }
		];

		if (global.operationMode === 'None' || global.operationMode === 'PmsLimited' || global.PMSType === 'BookingSpaceBeds' || (global.operationMode === 'PmsFull' && global.sendRoomRate)) {
			this.getChannels((arr, data) => data && data.filter(el => el.code !== 'AIRBNB').map(el => arr.push({ "label": el.code, "value": el.id })));
		} else {
			if (global.modules.find(mdl => mdl === 'Google')) {
				this.getChannels((arr, data) => data && data.filter(el => el.code === 'GOOGLEHOTELADS').map(el => arr.push({ "label": el.code, "value": el.id })));
			}
			else {
				this.setState({ availableChannels: avaiChan });
            }
        }
		if (this.props.ChannelCode && !this.state.airbnbSucess) {
			this.next("3");
			this.addNewAirbnbConnection();
		}
	}

    getChannels(fillAvailableChannels) {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.error) {
                    handleNotification(
                        data,
                        <FormattedMessage id="ChannelChoose.AirbnbConnectionSucesseful" />,
                        <FormattedMessage id="General.Success" />
                    );
					this.setState({ block: false });
				}
				else {
					const arr = [
						{ value: "Airbnb", label: "Airbnb" }
					];
					fillAvailableChannels(arr, data);

                    this.setState({ availableChannels : arr, block: false });
                }
            }
		}, "/api/Rate/ChannelConfig/v1?isCreatable=true");
        
	}

	addNewAirbnbConnection() {
		if (!this.form.current.reportValidity()) {
			return;
		}

		this.setState({ block: true });
		postAPI(result => {
			const { data, error } = result;
			if (error) {
				var errorMessage = [];
				errorMessage.push({
					message: error.message,
					stack: error.stack,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data) {
				if (data.error && data.error.error_id) {
					handleNotification( data );
					this.setState({
						airbnbSucess: false,
						airbnbMessage: data,
						block: false
					});
				} else {
					handleNotification(data, <FormattedMessage id="ChannelChoose.AirbnbConnectionSucesseful" />, <FormattedMessage id="General.Success" /> );
					this.setState({
						airbnbSucess: true,
						airbnbMessage: data,
						block: false
					});
				}
			}
		}, "/api/Rate/Auth/airbnb/exchange/" + this.props.ChannelCode + "?connectionName=" + this.state.name);
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	next(val, event) {
		if (event) {
			event.preventDefault();
			if (!this.form.current.reportValidity()) {
				return;
			}
		}

		const stateCopy = Object.assign({}, this.state);
		stateCopy.Steps = stateCopy.Steps.slice();

		for (var i = 0; i < stateCopy.Steps.length; i++) {
			if (stateCopy.Steps[i].step === val) {
				stateCopy.Steps[i] = Object.assign({}, stateCopy.Steps[i]);
				stateCopy.Steps[i].active = true;
				stateCopy.Steps[i].enabled = true;
			} else {
				stateCopy.Steps[i] = Object.assign({}, stateCopy.Steps[i]);
				stateCopy.Steps[i].active = false;
			}
		}
		this.setState(stateCopy);
	}

	handleChange({ target: { name, value } }) {
		this.setState(prevState => ({
			...prevState,
			channelInstance: {
				...prevState.channelInstance,
				[name]: value
			}
		}));
	}

	changeChannel = ({ value, label }) => {
		this.setState({ selectedChannelValue: value, selectedChannel: label });
	};

	createChannel = () => {
		this.setState({ block: true });
		postAPI(
			result => {
				const { data, error } = result;
				if (error) {
					var errorMessage = [];
					errorMessage.push({
						message: error.message,
						stack: error.stack,
						messageType: "danger"
					});
					this.setState({ error: errorMessage, block: false });
					return;
				}
				if (data) {
					if (data.errors) {
						handleNotification(
							data,
							<FormattedMessage id="ChannelChoose.ChannelInstanceCreated" />,
							<FormattedMessage id="General.Success" />
						);
					}

					if (data.errors.length === 0) {
						this.props.onSuccess();
					}

					this.setState({
						block: false
					});
				}
			},
			"/api/Rate/ChannelConfig/v1/ChannelInstance",
			this.state.channelInstance
		);
	};

	render() {
		return (
			<CardBody>
				<BlockUi tag="div" blocking={this.state.block}>
					<form ref={this.form}>
						<Row className="h-100">
							<Col className="col-3 pr-0 ">
								<ListGroup>
									<ListGroupItem
										tag="button"
										className={
											"btn " +
											(this.state.Steps[0].active === true
												? "active"
												: "") +
											(this.state.Steps[0].enabled ===
											true
												? " "
												: " disabled ")
										}
										action
									>
										<strong>
											<FormattedMessage id="AddRule.Step1" />
											:{" "}
										</strong>
										<p>
											<FormattedMessage id="AddRule.SelectChannel" />
										</p>
									</ListGroupItem>
									<ListGroupItem
										className={
											"btn " +
											(this.state.Steps[1].active === true
												? "active mt-1"
												: "mt-1") +
											(this.state.Steps[1].enabled ===
											true
												? " "
												: " disabled ")
										}
										action
									>
										<strong>
											<FormattedMessage id="AddRule.Step2" />
											:{" "}
										</strong>
										<p>
											<FormattedMessage id="AddRule.SetPermissions" />
										</p>
									</ListGroupItem>
									<ListGroupItem
										className={
											"btn " +
											(this.state.Steps[2].active === true
												? "active mt-1"
												: "mt-1") +
											(this.state.Steps[2].enabled ===
											true
												? " "
												: " disabled ")
										}
										action
									>
										<strong>
											<FormattedMessage id="AddRule.Step3" />
											:{" "}
										</strong>
										<p>
											<FormattedMessage id="AddRule.Confirmation" />
										</p>
									</ListGroupItem>
								</ListGroup>
							</Col>
							<Col className="col-9  pl-1 pt-0 ">
								<div style={{ height: "100%" }}>
									<Card
										className="shadow"
										style={{
											display:
												this.state.Steps[0].active ===
												true
													? "block"
													: "none",
											height: "100%"
										}}
									>
										<CardHeader className="bg-white border-bottom">
											<i className="fas fa-plug" />{" "}
											<FormattedMessage id="AddRule.ChooseChannel" />
										</CardHeader>
										<CardBody className="d-flex  flex-column">
											<Row>
												<Col className="col-12">
													<FormGroup row>
														<Label
															for="ruletype"
															sm={"2"}
														>
															<FormattedMessage id="AnalyticsDashboard.Channel" />
															:
														</Label>
														<Col sm={10}>
															<CustomSelect
																isSearchable
																options={this.state.availableChannels}
																value={this.state.availableChannels.find(
																	el =>
																		el.label ===
																		this
																			.state
																			.selectedChannel
																)}
																id="selectedChannel"
																placeholder={
																	<FormattedMessage id="AddRule.RuleType" />
																}
																onChange={e =>
																	this.changeChannel(
																		e
																	)
																}
															/>
														</Col>
													</FormGroup>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col className="col-10"></Col>
												<Col className="col-2">
													<Button
														color="primary btn-primary btn-host float-right btn-sm mt-auto "
														onClick={() =>
															this.next("2")
														}
													>
														<FormattedMessage id="AddRule.Next" />
													</Button>
												</Col>
											</Row>
										</CardBody>
									</Card>
									<Card
										className="shadow"
										style={{
											display:
												this.state.Steps[1].active ===
													true ||
												(this.state.Steps[2].active ===
													true &&
													this.state
														.selectedChannel !=
														"Airbnb")
													? "block"
													: "none",
											height: "100%"
										}}
									>
										<CardHeader className="bg-white">
											<i className="far fa-id-badge" />{" "}
											<FormattedMessage id="AddRule.Permissions" />
										</CardHeader>
										<CardBody className="d-flex  flex-column">
											<Row>
												<Col>
													<ChannelAdd
														selectedChannel={this.state.selectedChannel}
														selectedChannelValue={
															this.state.selectedChannelValue
														}
														confirm={
															this.state.Steps[2].active
														}
														handleChange={e =>
															this.handleChange(e)
														}
													/>
												</Col>
											</Row>
											<Row>
												<Col className="col-12 mt-2">
													{this.state.Steps[1]
														.active ? (
														<>
															<Button
																color="primary btn-primary btn-host  btn-sm float-left "
																onClick={() =>
																	this.next(
																		"1"
																	)
																}
															>
																<FormattedMessage id="AddRule.Previous" />
															</Button>
															{this.state
																.selectedChannel !=
															"Airbnb" ? (
																<Button
																	color="primary btn-primary btn-host  btn-sm float-right "
																	onClick={() => {
																		if (
																			this.form.current.reportValidity()
																		) {
																			this.next(
																				"3"
																			);
																		}
																	}}
																>
																	{" "}
																	<FormattedMessage id="AddRule.Next" />
																</Button>
															) : (
																""
															)}
														</>
													) : (
														<>
															{" "}
															<Button
																color="primary btn-primary btn-host  btn-sm float-left "
																onClick={() =>
																	this.next(
																		"2"
																	)
																}
															>
																<FormattedMessage id="AddRule.Previous" />
															</Button>
															<Button
																color="primary btn-primary btn-host  btn-sm float-right "
																onClick={() =>
																	this.createChannel()
																}
															>
																{" "}
																<FormattedMessage id="AddRule.Confirm" />
															</Button>{" "}
														</>
													)}
												</Col>
											</Row>
										</CardBody>
									</Card>
									<Card
										className="shadow"
										style={{
											display:
												this.state.Steps[2].active ===
													true &&
												this.state.selectedChannel ===
													"Airbnb"
													? "block"
													: "none",
											height: "100%"
										}}
									>
										<CardHeader className="bg-white">
											<i className="fas fa-clipboard-check bg-white"></i>{" "}
											<FormattedMessage id="AddRule.Confirmation" />
										</CardHeader>
										<CardBody className="d-flex  flex-column">
											<Row>
												<Col className="col-12">
													{this.state
														.selectedChannel ===
														"Airbnb" &&
													this.state.airbnbSucess ? (
														<CardBody className="text-center">
															<h5>
																Airbnb
																Connection
																Settings
															</h5>
															<ShowAirBnbFeedback
																airbnbSucess={
																	this.state
																		.airbnbSucess
																}
																airbnbMessage={
																	this.state
																		.airbnbMessage
																}
															/>
														</CardBody>
													) : (
														""
													)}

													{this.state
														.selectedChannel ===
														"Airbnb" &&
													!this.state.airbnbSucess ? (
														<CardBody className="text-center">
															<h5>
																Failed to
																Connect to
																Airbnb
															</h5>
														</CardBody>
													) : (
														""
													)}
												</Col>
											</Row>
											<Row>
												<Col>
													{/*!this.state.airbnbSucess ? 
                                                        <Button color="primary btn-primary btn-host  btn-sm float-right" disabled={this.state.airbnbSucess ? true : false} onClick={() => this.addNewAirbnbConnection()} > <FormattedMessage id="AddRule.Save" /> </Button>
                                                        : '' 
                                                       */}
												</Col>
											</Row>
										</CardBody>
									</Card>
								</div>
							</Col>
						</Row>
					</form>
				</BlockUi>
			</CardBody>
		);
	}
}
