import React, { Component } from 'react';
import { Button, Col, Card, CardBody, Row } from 'reactstrap';
import CalendarBox from "./CalendarBox";
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-dates/initialize';
import { CustomSingleDatePicker } from '../Base/Common/CustomReactDates';

export class CalendarBar extends Component {

    state = {
        currentDay: new Date(),
        next14Days: [],
        availabilitys: [],
        currentCol: null
    };

    constructor(props) {
        super(props);
        this.state.currentDay = this.props.currentDay;
        this.state.next14Days = this.props.currentDay;
    };

    componentWillReceiveProps(newProps){
        if(newProps.currentCol !== this.state.currentCol){
            this.setState({ currentCol: newProps.currentCol });
        }
    }

    renderMonthElement = (action, { month, onYearSelect }) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Row>
                    <Col>
                        {moment(month).locale(this.props.intl.locale).format('MMMM')}
                    </Col>
                    <Col>
                        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
                            <option value={moment().year()}>{moment().year()}</option>
                            <option value={moment().year() + 1}>{moment().year() + 1}</option>
                            <option value={moment().year() + 2}>{moment().year() + 2}</option>
                        </select>
                    </Col>
                </Row>
                <div style={{ position: 'absolute', bottom: '0' }}>
                    <Button style={{ backgroundColor: '#EDC934' }} className="border-0" onClick={evt => this.setState({ focused: false }, _ => action(evt, moment()))}>
                        <FormattedMessage id="CalendarBar.Today" />
                    </Button>
                </div>
            </div>
        );
    }

    componentDidUpdate() { }

    render() {
        const { days, inventorySeeOneMonth, handleSeeOneMonth, handleScroll, next14Days } = this.props;
        const { currentCol } = this.state;

        return (
            <div className="position-sticky" id="CalendarBar" style={{ 'top': '0', zIndex: '3' }}>
                <Card className="border-0 ">
                    <CardBody className="card-header-gridview border-0">
                        {!global.isMobile ?
                            <Row className="d-flex flex-row flex-nowrap">
                                <Col className="col-3 border-right border" id="ChooseDates">
                                    <div className="d-inline-block">
                                        <button type="button" className="btn btn-link d-inline-block text-secondary" onClick={() => this.props.action(this, moment(this.props.currentDay).add((days*-1), 'day'))}>
                                            <span className="fas fa-angle-double-left ml-2" />
                                        </button>
                                        <div className="font-weight-bold text-secondary d-inline-block" >

                                            <CustomSingleDatePicker
                                                date={this.props.currentDay.locale(this.props.intl.locale)}
                                                id="Date"
                                                required={true}
                                                isOutsideRange={day => day < moment().subtract(1, 'day') || day > moment().add(24, 'month')}
                                                showTodaysButton={true}
                                                showYearOptions={{ pastYears: false, futureYears: true }}
                                                onDateChange={date => this.props.action(this, date)}
                                                noBorder={true}
                                                block={true}
                                                displayFormat="DD, MMMM, YYYY"
                                                showDefaultInputIcon={false}
                                            />
                                        </div>
                                        <button type="button" className="btn btn-link d-inline-block text-secondary" onClick={() => this.props.action(this, moment(this.props.currentDay).add(days, 'day'))}>
                                            <span className="fas fa-angle-double-right ml-2" />
                                        </button>
                                    </div>
                                    <div>
                                        <span className="small">
                                            <span className="mr-2">
                                                <FormattedMessage id="Inventory.SeeOneMonth" />
                                            </span>
                                            <Button className="btn btn-inventory mr-2" style={{ padding: '1px 5px', fontSize: '12px', lineHeight: '1.5', opacity: '1' }} disabled={!inventorySeeOneMonth} onClick={handleSeeOneMonth}>
                                                <FormattedMessage id="Inventory.FifteenDays" className="font_size_xxs" />
                                            </Button>
                                            <Button className="btn btn-inventory mr-2" style={{ padding: '1px 5px', fontSize: '12px', lineHeight: '1.5', opacity: '1' }} disabled={inventorySeeOneMonth} onClick={handleSeeOneMonth}>
                                                <FormattedMessage id="Inventory.ThirtyDays" className="font_size_xxs" />
                                            </Button>
                                        </span>
                                    </div>
                                </Col>
                                <Col id="IventoryDaysScroll" style={{ overflowX: 'auto' }} onScroll={handleScroll}>
                                    <Row className="flex-nowrap">
                                        {next14Days.map((item, key) =>
                                            <CalendarBox day={item} key={key} newCol={currentCol} colKey={key} /> )
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        : 
                            <Row className="d-flex flex-row flex-nowrap" id="IventoryDaysScroll" style={{ overflowX: 'auto' }} onScroll={handleScroll}>
                                <Col className="col-3 border-right border"/>
                                <Col>
                                    <Row className="flex-nowrap">
                                        {next14Days.map((item, key) =>
                                            <CalendarBox day={item} key={key} newCol={currentCol} colKey={key} /> )
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }
};

export default injectIntl(CalendarBar);