import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button, Card } from 'reactstrap';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import { FormattedMessage } from 'react-intl';
import { postAPI } from '../Base/API';
import { handleNotification } from "../Base/Notification";

export class ImportListingsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: [],
            lines: [],
            data: [],
            duplicate: []
        }
        this.onSelectFile = this.onSelectFile.bind(this);
        this.processFile = this.processFile.bind(this);
        this.postListings = this.postListings.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    onSelectFile(evt) {
        if (evt && evt.target) {
            const file = evt.target.files[0];
            if (file !== undefined) {
                const reader = new FileReader();

                reader.readAsText(file);

                reader.onload = (e) => {
                    const csv = e.target.result;
                    this.processFile(csv);
                }
            }
        }
    }

    processFile(csv) {
        const { error } = this.state;
        const allLines = csv.split(/\r\n|\n/);
        const data = [];
        const duplicate = [];
        const lines = ['Room Type,Room Rate,Room Code,Rate Code,Inventory Code'];

        allLines
            .map(data => data.split(/,|;/))
            .slice(1) //Remove column names
            .forEach((line, idx) => {
                if (line.length > 1) {
                    if (line.length !== 5) {
                        error.push({ message: <FormattedMessage id="ImportListingsModal.MissingData" />, stack: error.stack, messageType: 'danger' });
                    }

                    const channelData = {
                        id: data.length,
                        roomType: this.getChannelDataField(line, 0),
                        roomRate: this.getChannelDataField(line, 1),
                        roomCode: this.getChannelDataField(line, 2),
                        rateCode: this.getChannelDataField(line, 3),
                        inventoryCode: this.getChannelDataField(line, 4)
                    };

                    if (!duplicate.some(el => el.roomCode === channelData.roomCode && el.rateCode === channelData.rateCode )) { //don't add duplicate rate/room codes
                        data.push(channelData);
                        lines.push(line.toString());
                    }

                    duplicate.push(channelData);
                }
            });


        if (error.length > 0) {
            this.setState({ error });
        }
        else {
            this.setState({ lines: lines, data: data, duplicate });
        }
    }

    getChannelDataField = (line, index) => {
        if (this.props.channelCode === "SITEMINDER") {
            return line[index].replaceAll('"', '');
        }
        else {
            return line[index];
        }
    }

    postListings() {
        const { channelInstanceId } = this.props;
        const { lines } = this.state;

        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            handleNotification(data, <FormattedMessage id="ChannelConf.ChannelDataRefreshed" />, <FormattedMessage id="ChannelConf.Success" />);
            this.setState({ block: false, lines: [], data: [], duplicate: [] }, this.toggleModal);
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/${channelInstanceId}/ChannelData`, JSON.stringify(lines));
    }

    toggleModal() {
        const { toggleModal } = this.props;
        this.setState({ data: [], lines: [], duplicate: [] }, toggleModal);
    }

    render() {
        const { modal, channel } = this.props;
        const { block, error, data, lines, duplicate } = this.state;

        const columns = [
            {
                dataField: 'id',
                text: '#'
            },
            {
                dataField: 'roomType',
                text: <FormattedMessage id="ImportListingsModal.RoomType" />
            },
            {
                dataField: 'roomRate',
                text: <FormattedMessage id="ImportListingsModal.RoomRate" />
            },
            {
                dataField: 'roomCode',
                text: <FormattedMessage id="ImportListingsModal.RoomCode" />
            },
            {
                dataField: 'rateCode',
                text: <FormattedMessage id="ImportListingsModal.RateCode" />
            },
            {
                dataField: 'inventoryCode',
                text: <FormattedMessage id="ImportListingsModal.InventoryCode" />
            }
        ];

        return (
            <Modal isOpen={modal} toggle={this.toggleModal} fade={true} className="modal-lg">
                <ModalHeader toggle={this.toggleModal}>
                    <FormattedMessage id="ImportListingsModal.ImportSiteminderListings" values={{ channel: channel }} />
                </ModalHeader>
                <ModalBody>
                    <StyledCard title="ImportListingsModal.Title" block={block} error={error}>
                        <Row>
                            <Col>
                                <input id='multi' type="file" accept=".csv" onChange={this.onSelectFile} />
                            </Col>
                            <Col className="text-right">
                                <Button className="btn btn-host btn-sm float-right" onClick={this.postListings} disabled={lines.length === 0}>
                                    <i className="fas fa-save"/>
                                </Button>
                            </Col>
                        </Row>
                        {duplicate?.length > 0 ?
                            <div className="my-3">
                                <i className="fas fa-exclamation-triangle text-warning mr-2" />
                                <FormattedMessage id="ImportListingsModal.DuplicateDataText" />
                            </div>
                        : '' }
                        {
                            data.length > 0 ?
                                <Row className="mt-2">
                                    <Col>
                                        <CustomTable
                                            data={data}
                                            columns={columns}
                                            remote={false}
                                        />
                                    </Col>
                                </Row>
                                :
                                <div/>
                        }
                    </StyledCard>
                </ModalBody>
            </Modal>    
        );
    }
}