import React, { Component } from 'react';
import { Badge, Row, Col, Card, CardHeader, CardBody, InputGroup, InputGroupAddon, InputGroupText, Input, Button, ButtonGroup, Nav, NavItem, NavLink, TabContent, TabPane, CustomInput } from 'reactstrap';
import moment from 'moment';
import classnames from 'classnames';
import CustomSelect from '../Base/Common/CustomSelect';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import BlockUi from 'react-block-ui';
import { getAPI, postAPI, putAPI } from "../Base/API"
import { handleNotification } from "../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import { ErrorAlert } from '../Common/ErrorAlert';
import AvailabilityCalendar from './AvailabilityCalendar';
import { getPercentage, getRmsTypes } from '../Base/Common/ReferenceDataFunctions';
import CustomToolTip from '../../Utils/CustomToolTip';
import { BulkUpdatePriceForm } from './BulkUpdatePriceForm';

export class BulkUpdate extends Component {

    constructor(props) {
        super(props);
        this.formPrice = React.createRef();
        this.formAvailability = React.createRef();
        this.formResctriction = React.createRef();
        this.formCommon = React.createRef();
        this.toggle = this.toggle.bind(this);
        this.onClickWeekDays = this.onClickWeekDays.bind(this);

        const paxListOrdered = this.props.inventoryData.rateCodePax ?
            this.props.inventoryData.rateCodePax
                .filter((px) => this.props.selectedRecord.selectRate === px.rateCodeId)
                .sort(function (a, b) { return a.adults === b.adults ? (a.adults + a.children) - (b.adults + b.children) : a.adults - b.adults })
            :
            [];

        const channelInstances = this.props.inventoryData.channelInstance ? this.props.inventoryData.channelInstance.filter(ci => this.props.inventoryData.ratecodechannel.find(rcc => ci.id === rcc.channelInstanceId && rcc.rateCodeId === this.props.selectedRecord.selectRate) !== undefined).map(ci => { const opt = { value: ci.id, label: `${ci.name}(${ci.propertyCode})`, code: ci.channelConfigCode }; return opt; }) : [];
        const beChannels = ['HEYBE', 'GOOGLEHOTELADS'];

        const heyBeAndGoogleChannels = channelInstances
                                        .filter(ci => beChannels.some(be => (ci.code??ci.label)?.toUpperCase()?.includes(be)))
                                        .map(ci => ci.value);
        
        this.state = {
            block: true,
            blockRestrictions: false,
            activeTab: '1',
            modal: false,
            freeRooms: null,
            childrenPrice: [],
            price: [],
            selectedRate: this.props.selectedRecord.selectRate,
            startDate: null,
            endDate: null,
            dates: [
                {
                    startDate: moment(this.props.selectedRecord.startDate, 'YYYY-MM-DD').format("YYYY-MM-DD"),
                    endDate: moment(this.props.selectedRecord.endDate, 'YYYY-MM-DD').format("YYYY-MM-DD")
                }
            ],
            weekdays: [],
            priceAction: 'amount',
            priceType: null,
            priceActions: [
                {
                    value: 'amount',
                    label: <FormattedMessage id="BulkUpdate.Amount" />
                },
                {
                    value: 'modify',
                    label: <FormattedMessage id="BulkUpdate.Modify" />
                }
            ],
            priceTypes: [
                {
                    value: 'increment',
                    label: '€'
                },
                {
                    value: 'percentage',
                    label: '%'
                }
            ],
            channels: [],
            channelInstances: channelInstances,
            channelInstancesWithRestrictions: [],
            channelInstanceId: null,
            selectAllChannels: true,
            validWeekDays: [
                {
                    value: "Mon",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysMon" />,
                    weekday: 0
                },
                {
                    value: "Tue",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysTue" />,
                    weekday: 1
                },
                {
                    value: "Wed",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysWed" />,
                    weekday: 2
                },
                {
                    value: "Thu",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysThu" />,
                    weekday: 3
                },
                {
                    value: "Fri",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysFri" />,
                    weekday: 4
                },
                {
                    value: "Sat",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSat" />,
                    weekday: 5
                },
                {
                    value: "Sun",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSun" />,
                    weekday: 6
                }
            ],
            currAvailabilities: { availability: [] },
            availabilities: { availability: [] },
            startDateDay: moment(this.props.selectedRecord.startDate, 'YYYY-MM-DD').format("YYYY-MM-DD"),
            endDateDay: moment(this.props.selectedRecord.endDate, 'YYYY-MM-DD').add(1, 'month').endOf('month').format("YYYY-MM-DD"),
            calendarMonths: [],
            applyRestrictionsToChannels: false,
            colors: [
                '#4F80F6',
                '#4EEBB5',
                '#5FD4FA',
                '#FFCA65',
                '#95B3F9',
                '#3f66c5',
                '#3ebc91',
                '#4caac8',
                '#cca251',
                '#778fc7',
                '#203362',
                '#1f5e48',
                '#265564',
                '#665128',
                '#3c4864'
            ],
            hasDerivedOccupancies: paxListOrdered.some(pax => pax.isBaseOccupancy),
            paxList: paxListOrdered.filter(pax => pax.children === 0),
            childrenPaxList: this.getChildrenPaxList(paxListOrdered),
            hasChildren: paxListOrdered.filter(pax => pax.children > 0).length > 0,
            hasMoreThanTwoChildren: paxListOrdered.filter(pax => pax.children > 2).length > 0,
            heyBeAndGoogleChannels: heyBeAndGoogleChannels,
            displayRestrictionsByChannels: global.RMSType === getRmsTypes()[2].value ? 'none' : ''
        };
    }

    getChildrenPaxList = (paxListOrdered) => {
        let childrenPax = [];

        if (this.props.hasFlexModule) {
            childrenPax = paxListOrdered.some(occ => occ.children > 1) ? this.getChildrenPaxListForTwoChildren(paxListOrdered) : (paxListOrdered.some(occ => occ.children > 0) ? this.getChildrenPaxListForOneChild() : []);
        }
        else {
            childrenPax = paxListOrdered.some(occ => occ.children > 0) ? this.getChildrenPaxListForOneChild() : [];
        }

        return childrenPax;
    }

    getChildrenPaxListForTwoChildren = (paxListOrdered) => {
        const hasMoreThanTwoChildren = paxListOrdered.filter(pax => pax.children > 2).length > 0;
        return hasMoreThanTwoChildren ?
            [
                {
                    icon: <span>(1)</span>,
                    label: `${this.props.intl.formatMessage({ id: "BulkUpdate.PriceFor" })}1${this.props.intl.formatMessage({ id: "BulkUpdate.FirstChild" })}`
                },
                {
                    icon: <span>(2+)</span>,
                    label: `${this.props.intl.formatMessage({ id: "BulkUpdate.PriceFor" })}2${this.props.intl.formatMessage({ id: "BulkUpdate.SecondAndThenChild" })}`
                }
            ]
            :
            [
                {
                    icon: <span>(1)</span>,
                    label: `${this.props.intl.formatMessage({ id: "BulkUpdate.PriceFor" })}1${this.props.intl.formatMessage({ id: "BulkUpdate.FirstChild" })}`
                },
                {
                    icon: <span>(2)</span>,
                    label: `${this.props.intl.formatMessage({ id: "BulkUpdate.PriceFor" })}2${this.props.intl.formatMessage({ id: "BulkUpdate.SecondChild" })}`
                }
            ]
        ;
    }

    getChildrenPaxListForOneChild = () => {
        return [
            {
                icon: <span>(1)</span>,
                label: `${this.props.intl.formatMessage({ id: "BulkUpdate.PriceFor" })}1${this.props.intl.formatMessage({ id: "BulkUpdate.Children" })}`
            }
        ];
    }

    componentDidMount() {
        const { dates, endDateDay, validWeekDays } = this.state;
        const weekdays = validWeekDays.map(w => {
            const weekday = {
                value: w.value,
                weekday: w.weekday
            };
            return weekday;
        });
        this.setState({ weekdays: weekdays });
        this.getAvailability(dates[0].startDate, endDateDay);
        this.buildCalendarStruct();
    }

    getAvailability = (startDate, endDate) => {
        const { availabilities, currAvailabilities, dates, selectedRate } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.length > 0) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data[0] && data[0].availability) {
                    currAvailabilities.availability = currAvailabilities.availability.concat(data[0].availability);

                    currAvailabilities.availability = currAvailabilities.availability.map(av => {
                        const ava = av;
                        ava.blockId = data[0].roomBlock;
                        ava.id = Math.floor(Math.random() * 10000) + 1;
                        ava.restrictions = [];
                        return (ava);
                    });

                    currAvailabilities.availability.sort((avl1, avl2) => moment(avl1.hotelDate).isBefore(moment(avl2.hotelDate)) ? -1 : 1)

                    availabilities.packageId = data[0].packageId;
                    availabilities.pricelistId = data[0].pricelistId;
                    availabilities.roomBlock = data[0].roomBlock;
                    availabilities.packageId = data[0].packageId;
                    availabilities.roomCatId = data[0].roomCatId;
                    availabilities.roomCode = data[0].roomCode;
                    availabilities.roomDesc = data[0].roomDesc;
                    availabilities.availability = this.arrangeAvailabilities(currAvailabilities, dates);

                    this.setState({ block: false, blockRestrictions: true, currAvailabilities, availabilities }, _ => {
                        this.getRestrictions(startDate, endDate);
                        this.buildCalendarStruct();
                    });
                }
                else {
                    this.setState({ block: false });
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Price/Availability/v1/Availability/${selectedRate}?startDate=${startDate}&endDate=${endDate}`);
    }

    getRestrictions = (startDate, endDate) => {
        const { availabilities, channelInstances, colors, currAvailabilities, selectedRate } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockRestrictions: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ blockRestrictions: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    currAvailabilities.availability.forEach(avl => {
                        avl.restrictions = data.response.filter(resp => resp.hotelDate == avl.hotelDate);
                    });

                    this.setState({ blockRestrictions: false, currAvailabilities, channelInstancesWithRestrictions: this.arrangeChannelWithRestrictions(availabilities, channelInstances) });
                }
                else {
                    this.setState({ blockRestrictions: false });
                }
            }
            else {
                this.setState({ blockRestrictions: false });
            }
        }, `/api/Price/Availability/v1/Restrictions/${selectedRate}?startDate=${startDate}&endDate=${endDate}`);
    }

    getNewAvailabilities = () => {
        const { currAvailabilities, dates } = this.state;

        const lastDate = dates[dates.length - 1];
        const days = moment(lastDate.endDate).diff(moment(lastDate.startDate), 'days');
        const availability = currAvailabilities.availability.filter(avl => moment(lastDate.startDate).isSameOrBefore(moment(avl.hotelDate)) && moment(lastDate.endDate).isSameOrAfter(moment(avl.hotelDate)));

        if (days !== (availability.length -1)) {
            const startDate = currAvailabilities.availability.find(avl => moment(avl.hotelDate).format("YYYY-MM-DD") === lastDate.startDate) !== undefined ? moment(lastDate.startDate).add(1, 'month').startOf('month').format("YYYY-MM-DD") : moment(lastDate.startDate).startOf('month').format("YYYY-MM-DD");
            const endDateDay = moment(lastDate.endDate).add(1, 'month').format("YYYY-MM-DD");
            const endDate = currAvailabilities.availability.find(avl => moment(avl.hotelDate).format("YYYY-MM-DD") === endDateDay) !== undefined ? moment(lastDate.endDate).endOf('month').format("YYYY-MM-DD") : moment(lastDate.endDate).add(1, 'month').endOf('month').format("YYYY-MM-DD");

            this.setState({ block: true }, _ => this.getAvailability(startDate, endDate));
        }
        else {
            this.setNewAvailabilities();
        }
    }

    setNewAvailabilities = () => {
        const { availabilities, channelInstances, currAvailabilities, dates } = this.state;
        availabilities.availability = this.arrangeAvailabilities(currAvailabilities, dates);

        this.setState({ availabilities, channelInstancesWithRestrictions: this.arrangeChannelWithRestrictions(availabilities, channelInstances) }, this.buildCalendarStruct);
    }

    arrangeAvailabilities = (currAvailabilities, dates) => {
        return currAvailabilities.availability.filter(avl => dates.find(dt => moment(dt.startDate).isSameOrBefore(moment(avl.hotelDate)) && moment(dt.endDate).isSameOrAfter(moment(avl.hotelDate))) !== undefined);
    }

    arrangeChannelWithRestrictions = (availabilities, channelInstances) => {
        const { colors } = this.state;
        
        if(!availabilities.availability || availabilities.availability.length > 0)
            return [];

        const channels = availabilities.availability
            .flatMap(avl => avl.restrictions)
            .map(rstr => rstr.channelInstanceId)
            .reduce((acc, curr) => {
                const elem = acc.find(chn => chn === curr);
                if (elem === undefined) {
                    acc.push(curr);
                }
                return acc;
            }, []);

        return channelInstances
            .filter(ci => channels.find(ch => ch === ci.value))
            .map((channel, idx) => { channel.color = colors[idx % colors.length]; return channel; });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    updateRestrictionsBulk(event) {

        event.preventDefault();

        const datesElem = document.getElementById("reststartDate");
        if (this.state.dates.length === 0) {
            datesElem.setCustomValidity(this.props.intl.formatMessage({ id: "BulkUpdate.DatesEmpty" }));
        }
        else {
            datesElem.setCustomValidity("");
        }

        var el = document.getElementById("priceCard");
        if (!this.formCommon.current.reportValidity()) {
            return;
        }

        const selectAllChannels = document.getElementById("selectAllChannels");
        if (!this.state.selectAllChannels && this.state.channels.length === 0) {
            selectAllChannels.setCustomValidity(this.props.intl.formatMessage({ id: "BulkUpdate.SelectAtLeastOneChannel" }));
        }
        else {
            selectAllChannels.setCustomValidity("");
        }

        if (this.state.minimumStayLen != undefined || this.state.maximumStayLen != undefined || this.state.stopSales != undefined || this.state.closedToDeparture != undefined || this.state.closedToArrival != undefined || this.state.minAdvancedBookingOffset != undefined || this.state.maxAdvancedBookingOffset != undefined) {
            el.setCustomValidity("");
        } else {
            el.setCustomValidity("At Least one of the restrictions needs to be filled");
        }
        if (!this.formResctriction.current.reportValidity()) {
            return;
        }
       
        let req = {
            rateCodeIds: Array.of(this.state.selectedRate),
            dates: this.state.dates,
            weekdays: this.state.weekdays.map(w => w.value),
            channelInstanceIds: this.state.channels,
            applyRestrictionsToChannels: this.state.applyRestrictionsToChannels
        };

        if(this.state.closedToArrival != undefined ) req.closedToArrival = this.state.closedToArrival;
        if (this.state.closedToDeparture != undefined)   req.closedToDeparture = this.state.closedToDeparture;
        if (this.state.stopSales != undefined)  req.stopSales = this.state.stopSales;
        if (this.state.maximumStayLen)  req.maximumStayLen = this.state.maximumStayLen;
        if (this.state.minimumStayLen)  req.minimumStayLen = this.state.minimumStayLen;
        if (this.state.minAdvancedBookingOffset) req.minAdvancedBookingOffset = this.state.minAdvancedBookingOffset;
        if (this.state.maxAdvancedBookingOffset) req.maxAdvancedBookingOffset = this.state.maxAdvancedBookingOffset;

        this.setState({ block: true },() => this.props.updatedInventory());

        putAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="BulkUpdate.RestrictionsUpdatedSucessfuly" />, <FormattedMessage id="General.Success" />);
                this.setState({ block: false });
            }
        }, '/api/Price/Availability/v1/RestrictionNDays', req);
    }

    updateAvailabilityBulk(event) {

        event.preventDefault();

        const datesElem = document.getElementById("reststartDate");
        if (this.state.dates.length === 0) {
            datesElem.setCustomValidity(this.props.intl.formatMessage({ id: "BulkUpdate.DatesEmpty" }));
        }
        else {
            datesElem.setCustomValidity("");
        }

        if (!this.formCommon.current.reportValidity()) {
            return;
        }
        if (!this.formAvailability.current.reportValidity()) {
            return;
        }

        const req = {
            roomCategoryId: this.props.selectedRecord.selectAvailability,
            blockId: this.props.selectedRecord.selectedBlockId,
            weekdays: this.state.weekdays.map(w => w.value),
            dates: this.state.dates.map(date => {
                const obj = {
                    startDate: date.startDate,
                    endDate: date.endDate,
                    freeRooms: this.state.freeRooms,
                };
                return obj;
            }),
        };

        this.setState({ block: true },() => this.props.updatedInventory());

        putAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    const errors = [{ code: "PRCAVA0016", message: "", dates: [] }];

                    data.errors.forEach(error => {
                        if (error.code === "PRCAVA0016") {
                            errors[0].dates.push(error.message.substring(20, 30));
                        }
                        else {
                            errors.push( error );
                        }
                    })

                    this.setState({ block: false, availabilityErrors: errors });
                }
                else {
                    handleNotification(data, <FormattedMessage id="BulkUpdate.AvailabilityUpdatedSucessfuly" />, <FormattedMessage id="General.Success" />);
                    this.setState({ block: false, availabilityErrors: null });
                }
            }
        }, '/api/Price/Availability/v1/AvailabilityNDays', req);
    }

    updatePriceBulk(event) {

        event.preventDefault();

        const datesElem = document.getElementById("reststartDate");
        if (this.state.dates.length === 0) {
            datesElem.setCustomValidity(this.props.intl.formatMessage({ id: "BulkUpdate.DatesEmpty" }));
        }
        else {
            datesElem.setCustomValidity("");
        }

        if (!this.formCommon.current.reportValidity()) {
            return;
        }

        if (this.state.price && this.state.price.length > 1) {
            let lst = this.state.price.sort((a, b) => (a.position - b.position)) 
            let p = 0;
            lst.forEach(element => {
                const el = document.getElementById('price' + element.position);
                if (element.priceMode === 'amount') {
                    if (element.adultPrice < p) {
                        el.setCustomValidity("The price needs to be greater for larger occuppations")
                    } else {
                        p = element.adultPrice
                        el.setCustomValidity("");
                    }
                }
                else if (element.priceMode === 'increment') {
                    if (element.adultPrice < -200 || element.adultPrice > 200) {
                        el.setCustomValidity("The increment needs to be between -200 and 200")
                    } else {
                        el.setCustomValidity("");
                    }
                }
                else if (element.priceMode === 'percentage') {
                    if (element.adultPrice < -100 || element.adultPrice > 100) {
                        el.setCustomValidity("The percentage needs to be between -100 and 100")
                    } else {
                        el.setCustomValidity("");
                    }
                }                
            });
        }

        if (!this.formPrice.current.reportValidity()) {
            return;
        }

        let req = {
            rateCodeId: this.props.selectedRecord.selectRate,
            weekdays: this.state.weekdays.map(w => w.value),
            dates: this.state.dates
        }

        if (this.state.price.length > 0) {
            req.adultPrices = this.state.price;
        }
        if (this.state.childrenPrice.length > 0) {
            req.childPrices = this.state.childrenPrice;
        }

        this.setState({ block: true },() => this.props.updatedInventory());
        
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="BulkUpdate.PriceUpdatedSucessfuly" />, <FormattedMessage id="General.Success" />);  
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/Price/Price/Price/v1/UpdateNDays', req);
    }

    setComboStatus = (evt, inputValue) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value).join(',');
        } else {
            targetValue = inputValue ? inputValue.value : null;
        }
        this.setState({ [evt]: targetValue });
    }
    
    setPrice(adult, child, pos, priceMode, evt) {
        const adultPrice = evt.target ? evt.target.value : null;
        const price = [...this.state.price];
        const idx = price.indexOf(price.find(p => p.position === adult));
        if (idx === -1) {
            price.push({
                position: adult,
                adultPrice: parseFloat(adultPrice),
                priceMode: priceMode
            })
        }
        else {
            const p = price[idx];
            p.adultPrice = parseFloat(adultPrice);
        }
        this.setState(({ price }));
    }

    setChildrenPrice(position, priceMode, evt) {
        const adultPrice = evt.target ? evt.target.value : null;
        const childrenPrice = [...this.state.childrenPrice];
        const idx = childrenPrice.indexOf(childrenPrice.find(p => p.position === position));
        if (idx === -1) {
            childrenPrice.push({
                position: position,
                adultPrice: parseFloat(adultPrice),
                priceMode: priceMode
            })
        }
        else {
            const p = childrenPrice[idx];
            p.adultPrice = parseFloat(adultPrice);
        }
        this.setState(({ childrenPrice }));
    }

    setBasePrice(adult, child, pos, priceMode, evt) {
        if (evt.target) {
            const { paxList } = this.state;
            const value = parseFloat(evt.target.value);
            const prices = paxList.map(pax => {
                const price = {
                    position: pax.adults,
                    adultPrice: 0,
                    priceMode: priceMode
                };
                if (pax.isBaseOccupancy) {
                    price.adultPrice = value;
                }
                else {
                    if (pax.derivedType === 'amount') {
                        price.adultPrice = value + pax.derivedAmount;
                    }
                    else {
                        price.adultPrice = parseFloat((value * (1 + pax.derivedAmount / 100)).toFixed(2));
                    }
                }
                return price;
            });
            this.setState({ price: prices });
        }
    }

    setComboStatusOnAdultPrice = (name, adult, inputValue) => {
        const targetValue = inputValue ? inputValue.value : null;
        this.setState(prevState => ({
            ...prevState,
            priceType: targetValue,
            price:
                [...prevState.price.filter(el => el.position != adult), { position: adult, adultPrice: 0, priceMode: targetValue }]
        }));
    }

    setComboStatusOnAdultChild = (name, adult, inputValue) => {
        const targetValue = inputValue ? inputValue.value : null;
        this.setState(prevState => ({
            ...prevState,
            priceType: targetValue,
            childrenPrice:
                [...prevState.childrenPrice.filter(el => el.position != adult), { position: adult, adultPrice: 0, priceMode: targetValue }]
        }));
    }

    setPriceModeByPriceAction = (name, inputValue) => {
        const targetValue = inputValue ? inputValue.value : null;
        if (targetValue === this.state.priceActions[0].value) {
            this.setState(({ priceMode: targetValue, priceType: null, [name]: targetValue }));
        }
        else {
            this.setState(prevState => ({ priceMode: prevState.priceTypes[0].value, priceType: prevState.priceTypes[0].value, [name]: targetValue }));
        }
    }

    onClickWeekDays(weekDay) {
        const weekdays = [...this.state.weekdays];
        if (weekdays.find(weekday => weekday.value === weekDay.value)) {
            const elementsToRemove = 1;
            weekdays.splice(weekdays.indexOf(weekdays.find(item => item.value === weekDay.value)), elementsToRemove);
        }
        else {
            const w = {
                value: weekDay.value,
                weekday: weekDay.weekday
            };
            weekdays.push(w);
        }
        this.setState(({ weekdays }));
    }

    handleAddChannel(evt) {
        if (evt.target) {
            const { channels, heyBeAndGoogleChannels } = this.state;
            const { name, checked } = evt.target

            const isBeChannel = heyBeAndGoogleChannels.some(be => be === name);

            if (isBeChannel) {
                if (checked) {
                    channels.splice(channels.length + 1, 0, ...heyBeAndGoogleChannels);
                }
                else {
                    channels.splice(channels.indexOf(heyBeAndGoogleChannels[0]), heyBeAndGoogleChannels.length);
                }
            }
            else {
                const channel = channels.find(item => item === name);
                if (channel && !checked) {
                    channels.splice(channels.indexOf(name), 1);
                }
                else if (!channel && checked) {
                    channels.push(name);
                }
            }
            this.setState({ channels });
        }
    }

    removeDate = (index) => {
        const { dates, startDateDay } = this.state;
        dates.splice(index, 1);
        const date = dates.find(d => d.startDate === startDateDay);

        this.setState({ dates, startDateDay: date ? date.startDate : dates.length > 0 ? dates[0].startDate : moment().format("YYYY-MM-DD") }, this.setNewAvailabilities);
    }

    isDayBlocked = (day) => {
        const { dates } = this.state;
        const val = dates.find(date => {
            if(moment(date.startDate).dayOfYear() + moment(date.startDate).year() <= day.dayOfYear() + day.year()){
                if(day.dayOfYear() + day.year() <= moment(date.endDate).dayOfYear() + moment(date.endDate).year()){
                    if(day.year() === moment(date.startDate).year() && day.year() === moment(date.endDate).year()){
                        return true;
                    };
                };
            };
            return false;
        });
        return val;
    }

    buildCalendarStruct = () => {
        let blank = this.getBlankCells();
        let daysInMonth = this.getDaysInMonth();
        let totalSlots = [...blank, ...daysInMonth];
        let cells = [], rows = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                while (cells.length < 7) {
                    cells.push({ day: "" })
                }
                rows.push(cells);
            }
        });

        this.setState({ calendarMonths: [rows ] });
    }

    getFirstDayOfMonth = (sec) => {
        const { startDateDay } = this.state;

        let dateObject = startDateDay;

        if (sec) dateObject = moment(startDateDay).add(1, 'month');

        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    getDaysInMonth = (sec) => {
        const { startDateDay } = this.state;
        let daysInMonth = [];

        if (sec) {
            for (let d = 1; d <= moment(startDateDay).add(1, 'month').daysInMonth(); d++) {
                let date = moment(startDateDay).add(1, 'month').format('MM') + '-' + d + '-' + moment(startDateDay).add(1, 'month').format('YYYY');
                daysInMonth.push({
                    day: d,
                    moment: moment(date)
                });
            }
        }
        else {
            for (let d = 1; d <= moment(startDateDay).daysInMonth(); d++) {
                let date = moment(startDateDay).format('MM') + '-' + d + '-' + moment(startDateDay).format('YYYY');
                daysInMonth.push({
                    day: d,
                    moment: moment(date)
                });
            }
        }

        return daysInMonth;
    }

    getBlankCells = (sec) => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(sec); i++) {
            blanks.push({
                day: ""
            });
        }

        return blanks
    }

    renderCell = (dayAvailability) => {
        const { channelInstanceId, channelInstancesWithRestrictions } = this.state;
        const maxChannels = 3;
        const iconsToDisplay = [];
        let channelInstancesWithRestrictionsSort = channelInstancesWithRestrictions.slice();

        let restriction = null;
        if (channelInstanceId) {
            restriction = dayAvailability.restrictions.find(rstr => rstr.channelInstanceId === channelInstanceId);
            if (!restriction) {
                restriction = dayAvailability.restrictions.find(rstr => rstr.channelInstanceId === null);
            }
            const restrictionIdx = channelInstancesWithRestrictionsSort.indexOf(channelInstancesWithRestrictionsSort.find(ch => ch.value === channelInstanceId));
            if (restrictionIdx >= maxChannels) {
                channelInstancesWithRestrictionsSort = channelInstancesWithRestrictionsSort.sort((ch1, _) => ch1.value === channelInstanceId ? -1 : 0);
            }
        }
        else {
            restriction = dayAvailability.restrictions.find(rstr => rstr.channelInstanceId === channelInstanceId);
        }
        if (restriction) {
            if (restriction.stopSales) {
                const icon = <i className="fas fa-times-circle text-danger" />
                iconsToDisplay.push(icon);
            }
            if (restriction.closeToArrival) {
                const icon = <i className="fas fa-walking" />
                iconsToDisplay.push(icon);
            }
            if (restriction.closeToDeparture) {
                const icon = <i className="fas fa-walking fa-flip-horizontal" />
                iconsToDisplay.push(icon);
            }
            if (restriction.minimumStayLen > 0) {
                const icon = <div style={{ marginRight: '0.5rem' }}>
                    <i id={`restrictionMinimumStayLen${moment(restriction.hotelDate).format("YYYYMMDD")}`} className="fas fa-calendar-minus" />
                    <span style={{ position: 'absolute', margin: '-6px -5px', color: '#fff', backgroundColor: '#007bff', padding: '0.25em 0.4em', fontSize: '75%', fontWeight: '700', lineHeight: '1', borderRadius: '25%' }}>
                        {restriction.minimumStayLen}
                    </span>
                    <CustomToolTip placement="right" target={`restrictionMinimumStayLen${moment(restriction.hotelDate).format("YYYYMMDD")}`}>
                        <FormattedMessage id={"RateAvailability.MinStays"} /> {restriction.minimumStayLen}
                    </CustomToolTip>
                </div>
                iconsToDisplay.push(icon);
            }
            if (restriction.maximumStayLen > 0) {
                const icon = <div style={{ marginRight: '0.5rem' }}>
                    <i id={`restrictionMaximumStayLen${moment(restriction.hotelDate).format("YYYYMMDD")}`} className="fas fa-calendar-plus" />
                    <span style={{ position: 'absolute', margin: '-6px -5px', color: '#fff', backgroundColor: '#007bff', padding: '0.25em 0.4em', fontSize: '75%', fontWeight: '700', lineHeight: '1', borderRadius: '25%' }}>
                        {restriction.maximumStayLen}
                    </span>
                    <CustomToolTip placement="right" target={`restrictionMaximumStayLen${moment(restriction.hotelDate).format("YYYYMMDD")}`}>
                        <FormattedMessage id={"RateAvailability.MaxStays"} /> {restriction.maximumStayLen}
                    </CustomToolTip>
                </div>
                iconsToDisplay.push(icon);
            }
            if (restriction.minAdvancedBookingOffset > 0) {
                const icon = <div style={{ marginRight: '0.5rem' }}>
                    <i id={`restrictionMinAdvancedBookingOffset${moment(restriction.hotelDate).format("YYYYMMDD")}`} className="fas fa-clock" />
                    <span style={{ position: 'absolute', margin: '-6px -5px', color: '#fff', backgroundColor: '#007bff', padding: '0.25em 0.4em', fontSize: '75%', fontWeight: '700', lineHeight: '1', borderRadius: '25%' }}>
                        {restriction.minAdvancedBookingOffset}
                    </span>
                    <CustomToolTip placement="right" target={`restrictionMinAdvancedBookingOffset${moment(restriction.hotelDate).format("YYYYMMDD")}`}>
                        <FormattedMessage id={"RateAvailability.MinAdvanceBookingOffset"} /> {restriction.minAdvancedBookingOffset}
                    </CustomToolTip>
                </div>
                iconsToDisplay.push(icon);
            }
            if (restriction.maxAdvancedBookingOffset > 0) {
                const icon = <div style={{ marginRight: '0.5rem' }}>
                    <i id={`restrictionMaxAdvancedBookingOffset${moment(restriction.hotelDate).format("YYYYMMDD")}`} className="fas fa-clock" />
                    <span style={{ position: 'absolute', margin: '-6px -5px', color: '#fff', backgroundColor: '#007bff', padding: '0.25em 0.4em', fontSize: '75%', fontWeight: '700', lineHeight: '1', borderRadius: '25%' }}>
                        {restriction.maxAdvancedBookingOffset}
                    </span>
                    <CustomToolTip placement="right" target={`restrictionMaxAdvancedBookingOffset${moment(restriction.hotelDate).format("YYYYMMDD")}`}>
                        <FormattedMessage id={"RateAvailability.MaxAdvanceBookingOffset"} /> {restriction.maxAdvancedBookingOffset}
                    </CustomToolTip>
                </div>
                iconsToDisplay.push(icon);
            }
        }

        const channelRestrictions = channelInstancesWithRestrictionsSort.filter(channel => dayAvailability.restrictions.find(rstr => rstr.channelInstanceId === channel.value) !== undefined);

        return (
            <div style={{ height: '70%' }}>
                <div className="h-100">
                    <div className="text-center" style={{ width: "100%", fontSize: "12px", fontWeight: "500", alignItems: "flex-start", justifyContent: "center", display: "flex", height: "50%" }}>
                        <div className="text-left" style={{ width: '100%' }}>
                            {iconsToDisplay.map((icon, key) =>
                                <div key={key} style={{ display: 'inline-flex', minWidth: '20%', maxWidth: '33%', justifyContent: 'center', margin: '0.25rem 0' }}>
                                    {icon}
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{ height: "50%", display: "flex", alignItems: "flex-end" }}>
                        <div>
                            {
                                channelRestrictions.slice(0, maxChannels).map((channel, key) =>
                                    <div key={key} style={{ display: 'inline-block', margin: '0 0.3rem' }}>
                                        <i className={`fas fa-circle ${channel.value === channelInstanceId ? `` : `fa-xs`}`} style={{ color: channel.color }} />
                                    </div>
                                )
                            }
                            {
                                channelRestrictions.length > maxChannels ?
                                    <div style={{ display: 'inline-block', margin: '0 0.3rem' }}>
                                        <span id={`channelRestrictions${moment(dayAvailability.hotelDate).format("YYYYMMDD")}`}>...</span>
                                        <CustomToolTip placement="right" target={`channelRestrictions${moment(dayAvailability.hotelDate).format("YYYYMMDD")}`}>
                                            {
                                                channelRestrictions.map((channel, key) =>
                                                    <Row key={key}>
                                                        <Col className="text-left">
                                                            <i className="fas fa-circle fa-xs" style={{ color: channel.color }} />
                                                            <span className="ml-2">
                                                                {channel.label}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </CustomToolTip>
                                    </div>
                                    :
                                    <div />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSelectDay = () => {
        return (
            <div />
        );
    }

    render() {
        const { availabilities, calendarMonths, channelInstanceId, channelInstancesWithRestrictions, weekdays, startDateDay, availabilityErrors, paxList, childrenPaxList, hasDerivedOccupancies, hasChildren, hasMoreThanTwoChildren, displayRestrictionsByChannels } = this.state;

        const booleanOptions = [
            {
                value: true,
                label: <FormattedMessage id="BulkUpdate.True" />
            },
            {
                value: false,
                label: <FormattedMessage id="BulkUpdate.False" />
            }
        ];

        function getPriceMode(price, priceTypes, priceType, adults) {
            const p = price.find(p => p.position === adults);
            return priceTypes.find(pt => p ? p.priceMode === pt.value : pt.value === priceType);
        }

        const rates = this.props.getRateList(this).filter(el => Array.from(this.state.selectedRate, x => x.value).indexOf(el.value) >= 0);
        
        const ratesWithSameCategory = this.props.inventoryData && this.props.inventoryData.ratecode ?
            this.props.inventoryData.ratecode
                .filter(r => r.roomCategoryId === this.props.selectedRecord.selectAvailability && r.blockId === this.props.selectedRecord.selectedBlockId)
                .filter(r => rates.find(rate => rate.value === r.id))
            :
            [];

        return (
            <BlockUi tag="div" blocking={this.state.block || this.state.blockRestrictions}>
                <ErrorAlert error={this.state.error} />
                <CardBody className="py-2">
                    <form ref={this.formCommon} >
                        <Row className="mb-2">
                            <Col className="col-3">
                                <CustomSelect icon={'fas fa-gift'} isDisabled required placeholder={<FormattedMessage id='BulkUpdate.ChooseRate' />} options={this.props.getRateList(this)} value={this.props.getRateList(this).find(rate => rate.value === this.state.selectedRate)} />
                            </Col>
                            <Col className="col-3">                                       
                                <CustomSelect icon={'fas fa-bed'} isDisabled placeholder={<FormattedMessage id='BulkUpdate.ChooseRoom'/>} options={this.props.getRoomCategoryList(this)} value={this.props.getRoomCategoryList(this).filter(el => el.value === this.props.selectedRecord.selectAvailability)} />
                            </Col>
                            <Col className="col-3">
                                <DateRangePicker
                                    startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                    startDateId="reststartDate"
                                    isOutsideRange={day => day.isBefore(moment(), 'day') || day > moment().add(2, 'years') || weekdays.find(w => w.weekday === day.weekday()) === undefined}
                                    endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                    endDateId="restsendDate"
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    numberOfMonths={2}
                                    showDefaultInputIcon={true}
                                    minimumNights={0}
                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                    isDayBlocked={this.isDayBlocked}
                                    onDatesChange={({ startDate, endDate }) => {
                                        if (startDate !== null && endDate !== null) {
                                            const { dates } = this.state;
                                            dates.push({
                                                startDate: moment(startDate).format("YYYY-MM-DD"),
                                                endDate: moment(endDate).format("YYYY-MM-DD")
                                            });
                                            this.setState({ dates, startDate: null, endDate: null, startDateDay: dates[dates.length - 1].startDate }, this.getNewAvailabilities);
                                        }
                                        else {
                                            this.setState({ startDate, endDate });
                                        }
                                    }}
                                />
                            </Col>
                            <Col className="col-3 text-center">
                                <ButtonGroup name="weekdays" size="sm">
                                    {
                                        this.state.validWeekDays.map((weekDay, key) =>
                                            <Button key={key} className={this.state.weekdays.find(el => el.value === weekDay.value) ? 'btn btn-host' : 'btn'} onClick={() => this.onClickWeekDays(weekDay)}>
                                                {weekDay.label}
                                            </Button>
                                        )
                                    }
                                </ButtonGroup>
                            </Col>              
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <span>
                                    <FormattedMessage id="BulkUpdate.Dates" />:
                                </span>
                            </Col>
                            <Col>
                                <Row>
                                    {
                                        this.state.dates.map((date, key) =>
                                            <Col key={key} className="col-4">
                                                <Badge color={`${date.startDate === startDateDay ? `primary` : `light`}`} className="p-2 my-1">
                                                    <span>
                                                        <span style={{ cursor: 'pointer' }} onClick={_ => this.setState({ startDateDay: date.startDate }, this.setNewAvailabilities)}>
                                                            <FormattedMessage id="BookingPromotionCard.From" /> {`${date.startDate}`} <FormattedMessage id="BookingPromotionCard.To" /> {`${date.endDate}`}
                                                        </span>
                                                        <span className="text-right ml-3" onClick={_ => this.removeDate(key)} style={{ cursor: 'pointer' }}>
                                                            <i className="fas fa-times text-white" />
                                                        </span>
                                                    </span>
                                                </Badge>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </form>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                <FormattedMessage id="BulkUpdate.Price" />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                <FormattedMessage id="BulkUpdate.Restrictions" />
                            </NavLink>
                        </NavItem>
                        {
                            global.operationMode !== 'None' ?
                                ''
                                :
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <FormattedMessage id="BulkUpdate.Availability" />
                                    </NavLink>
                                </NavItem>
                        }
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col>
                                    {
                                        this.props.selectedRecord.dependencyMasterId == null || this.props.selectedRecord.dependencyMasterId == undefined ?
                                            <div>
                                                <CardHeader className="bg-white pb-1">
                                                    <Row>
                                                        <Col className="col-2">
                                                            <i className="fas fa-dollar-sign mr-2" />
                                                            <FormattedMessage id="BulkUpdate.Price" />
                                                        </Col>
                                                        <Col className="col-7">
                                                            <CustomSelect required placeholder={'PriceAction'} options={this.state.priceActions} value={this.state.priceActions.find(priceAction => priceAction.value === this.state.priceAction)} onChange={this.setPriceModeByPriceAction.bind(this, 'priceAction')} isDisabled={hasDerivedOccupancies} />
                                                        </Col>
                                                        <Col className="col-3">
                                                            <Button className="btn btn-sm btn-host float-right shadow" disabled={!(this.state.selectedRate === this.props.selectedRecord.selectRate)} onClick={this.updatePriceBulk.bind(this)}><i className="fas fa-save" ></i></Button>
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody>
                                                    <form ref={this.formPrice}>
                                                        {
                                                            childrenPaxList.map((item, key) => {
                                                                const child = key + 1;
                                                                const position = key + 2;
                                                                const priceObj = this.state.childrenPrice.find(el => el.position === position);
                                                                const priceTypeOpt = getPriceMode(this.state.childrenPrice, this.state.priceTypes, this.state.priceType, position);
                                                                const priceType = priceTypeOpt ? priceTypeOpt.value : 'amount'; 

                                                                return (
                                                                    <BulkUpdatePriceForm
                                                                        key={key}
                                                                        icon={<span><i className="fas fa-child" />{item.icon}</span>}
                                                                        priceType={this.state.priceType}
                                                                        priceActions={this.state.priceActions}
                                                                        priceTypes={this.state.priceTypes}
                                                                        priceTypeOpt={priceTypeOpt}
                                                                        onChangeModifyPriceFunc={this.setComboStatusOnAdultChild.bind(this, 'priceType', position)}
                                                                        priceObj={priceObj}
                                                                        formId={`childrenPrice${child}`}
                                                                        formPlaceholder={item.label}
                                                                        formIsDisabled={false}
                                                                        onChangeFormFunc={this.setChildrenPrice.bind(this, position, priceType)}
                                                                    />                                                                    
                                                                );
                                                            })
                                                        }
                                                        {
                                                            paxList.map((item, key) => {
                                                                const priceObj = this.state.price.find(el => el.position === item.adults);
                                                                const isDisabled = hasDerivedOccupancies && !item.isBaseOccupancy;
                                                                const priceTypeOpt = getPriceMode(this.state.price, this.state.priceTypes, this.state.priceType, item.adults);
                                                                const priceType = priceTypeOpt ? priceTypeOpt.value : 'amount'; 
                                                                const onChangeFunc = hasDerivedOccupancies ?
                                                                    this.setBasePrice.bind(this, item.adults, item.children, item.position, priceType)
                                                                    :
                                                                    this.setPrice.bind(this, item.adults, item.children, item.position, priceType);

                                                                return (
                                                                    <BulkUpdatePriceForm
                                                                        key={key}
                                                                        icon={<span><i className="fas fa-user" />({item.adults})</span>}
                                                                        priceType={this.state.priceType}
                                                                        priceActions={this.state.priceActions}
                                                                        priceTypes={this.state.priceTypes}
                                                                        priceTypeOpt={priceTypeOpt}
                                                                        onChangeModifyPriceFunc={this.setComboStatusOnAdultPrice.bind(this, 'priceType', item.adults)}
                                                                        priceObj={priceObj}
                                                                        formId={`price${item.adults}`}
                                                                        formPlaceholder={`${this.props.intl.formatMessage({ id: "BulkUpdate.PriceFor" })}${item.adults}${this.props.intl.formatMessage({ id: "BulkUpdate.Adults" })}`}
                                                                        formIsDisabled={isDisabled}
                                                                        onChangeFormFunc={onChangeFunc}
                                                                    />
                                                                );
                                                            })
                                                        }
                                                    </form>
                                                </CardBody>
                                            </div>
                                            :
                                            ''
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <CardBody className="pt-0">
                                <Row>
                                    <Col className="col-3">
                                        <CardHeader className="bg-white pb-3 pl-0 pt-0 pr-0">
                                            <i className="fas fa-times-circle mr-2" /> <FormattedMessage id="BulkUpdate.Restrictions" />
                                            <Button className="btn btn-sm btn-host float-right shadow" onClick={this.updateRestrictionsBulk.bind(this)}>
                                                <i className="fas fa-save" />
                                            </Button>
                                        </CardHeader>
                                        <CardBody className="pl-0 pr-0">
                                            <form ref={this.formResctriction}>
                                                <input tabIndex={-1} id="priceCard" name="priceCard" autoComplete="off" style={{
                                                    opacity: 0,
                                                    width: "100%",
                                                    height: 0,
                                                    position: "absolute"
                                                }} />
                                                <Row className="mb-1">
                                                    <Col>
                                                        <CustomSelect icon={'fas fa-times-circle'} isClearable placeholder={<FormattedMessage id="BulkUpdate.StopSales" />} options={booleanOptions} onChange={this.setComboStatus.bind(this, 'stopSales')} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1" >
                                                    <Col>
                                                        <CustomSelect icon={'fas fa-walking'} isClearable placeholder={<FormattedMessage id="BulkUpdate.CloseToArrival" />} options={booleanOptions} onChange={this.setComboStatus.bind(this, 'closedToArrival')} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1" >
                                                    <Col>
                                                        <CustomSelect icon={'fas fa-walking fa-flip-horizontal'} isClearable placeholder={<FormattedMessage id="BulkUpdate.CloseToDeparture" />} options={booleanOptions} onChange={this.setComboStatus.bind(this, 'closedToDeparture')} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>
                                                        <InputGroup size="sm" >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-calendar-minus mr-2 "></i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <FormattedMessage id="BulkUpdate.MinStay">{placeholder => <Input className="border-left-0" type="number" min='0' placeholder={placeholder} onChange={(evt) => this.setState({ minimumStayLen: evt.target.value })} />}</FormattedMessage>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>
                                                        <InputGroup size="sm" >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-calendar-plus mr-2 " />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <FormattedMessage id="BulkUpdate.MaxStay">{placeholder => <Input className="border-left-0" type="number" min='0' placeholder={placeholder} max='999' onChange={(evt) => this.setState({ maximumStayLen: evt.target.value })} />}</FormattedMessage>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <InputGroup size="sm" >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-clock mr-2 " />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <FormattedMessage id="BulkUpdate.MinAdvancedBookingOffset">{placeholder => <Input className="border-left-0" type="number" min='0' placeholder={placeholder} max='9999' onChange={(evt) => this.setState({ minAdvancedBookingOffset: evt.target.value })} />}</FormattedMessage>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <InputGroup size="sm" >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-clock mr-2 " />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <FormattedMessage id="BulkUpdate.MaxAdvancedBookingOffset">{placeholder => <Input className="border-left-0" type="number" min='0' placeholder={placeholder} max='9999' onChange={(evt) => this.setState({ maxAdvancedBookingOffset: evt.target.value })} />}</FormattedMessage>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col style={{ display: displayRestrictionsByChannels }}>
                                                        <CustomInput className="mb-2" type="switch" checked={this.state.selectAllChannels} id="selectAllChannels" onChange={(evt) => this.setState({ selectAllChannels: evt.target.checked, channels: [], applyRestrictionsToChannels: false })} label={<FormattedMessage id="BulkUpdate.SelectAllChannels" />} />
                                                        {
                                                            this.state.selectAllChannels ?
                                                                <div>
                                                                    <CustomInput type="switch" name="applyRestrictionsToChannels" id="applyRestrictionsToChannels" bsSize="sm" className="text-sm " label={<FormattedMessage id="BulkUpdate.RestrictionsToChannels" />} checked={this.state.applyRestrictionsToChannels} onChange={evt => this.setState({ applyRestrictionsToChannels: evt.target.checked })} />
                                                                </div>
                                                                :
                                                                <Row>
                                                                    {
                                                                        this.state.channelInstances.map((ci, key) =>
                                                                            <Col key={key} className="col-12 pb-2 small">
                                                                                <CustomInput type="checkbox" name={ci.value} id={ci.value} bsSize="sm" className="text-sm " label={ci.label} checked={this.state.channels.find(c => c === ci.value) != undefined} onChange={this.handleAddChannel.bind(this)} />
                                                                            </Col>
                                                                        )
                                                                    }
                                                                </Row>
                                                        }
                                                    </Col>
                                                </Row>
                                            </form>
                                        </CardBody>
                                    </Col>
                                    <Col className="col-9">
                                        <Row className="mb-2">
                                            <Col>
                                                <AvailabilityCalendar
                                                    startDate={startDateDay}
                                                    blockedWeekdays={weekdays.map(w => w.value)}
                                                    currAvailabilities={availabilities}
                                                    alwaysUpdate={true}
                                                    calendarMonths={calendarMonths}
                                                    renderCell={this.renderCell}
                                                    renderSelectDay={this.renderSelectDay}
                                                    checkSelectDay={_ => { return false; }}
                                                    cellHeight="15vh"
                                                    getFreeRooms={day => day.freeRooms}
                                                    getPercentage={getPercentage}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                channelInstancesWithRestrictions.map((channel, key) =>
                                                    <Col key={key} className="col-4">
                                                        {
                                                            channelInstanceId === channel.value ?
                                                                <Badge color="primary" className="p-2 my-1">
                                                                    <span>
                                                                        <span style={{ cursor: 'pointer' }} onClick={_ => this.setState({ channelInstanceId: channel.value })}>
                                                                            <i className="fas fa-circle fa-xs" style={{ color: channel.color }} />
                                                                            <span className="ml-2">
                                                                                {channel.label}
                                                                            </span>
                                                                        </span>
                                                                        <span style={{ cursor: 'pointer' }} className="text-right ml-3" onClick={_ => this.setState({ channelInstanceId: null })}>
                                                                            <i className="fas fa-times text-white" />
                                                                        </span>
                                                                    </span>
                                                                </Badge>
                                                                :
                                                                <Badge color="light" className="p-2 my-1">
                                                                    <span style={{ cursor: 'pointer' }} onClick={_ => this.setState({ channelInstanceId: channel.value })}>
                                                                        <i className="fas fa-circle fa-xs" style={{ color: channel.color }} />
                                                                        <span className="ml-2">
                                                                            {channel.label}
                                                                        </span>
                                                                    </span>
                                                                </Badge>
                                                        }
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col>
                                    <CardHeader className="bg-white pb-3">
                                        <i className="far fa-calendar-alt mr-2" /> <FormattedMessage id="BulkUpdate.Availability" />
                                        <Button className="btn btn-sm btn-host float-right shadow" disabled={!(rates.length === ratesWithSameCategory.length)} onClick={this.updateAvailabilityBulk.bind(this)}>
                                            <i className="fas fa-save" />
                                        </Button>
                                    </CardHeader>
                                    <CardBody>
                                        <form ref={this.formAvailability} >
                                            <Row>
                                                <Col>
                                                    <InputGroup size="sm" >
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="far fa-calendar mr-2" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <FormattedMessage id="BulkUpdate.FreeRooms">{placeholder => <Input type="number" required placeholder={placeholder} onChange={(evt) => this.setState({ freeRooms: evt.target.value })} />}</FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </form>

                                        {availabilityErrors?.length > 0 ?
                                            <Card className="px-3 pb-3 pt-2 mt-3" style={{ backgroundColor: '#ff00000a' }}>
                                                <Row className="mb-2">
                                                    <Col> <b> <FormattedMessage id="BulkUpdate.UnableToPerformOperation" />: </b> </Col>
                                                    <Col className="col-1 text-right">
                                                        <i className="fas fa-times pointer" onClick={() => this.setState({ availabilityErrors: null })} />
                                                    </Col>
                                                </Row>
                                                <span> </span>
                                                {availabilityErrors?.map((error, index) =>
                                                    <div key={index} className="pl-3">
                                                        {error.code === "PRCAVA0016" && error.dates?.length > 0 ?
                                                            <span>
                                                                <i className="fas fa-times-circle mr-2 mb-1" style={{ color: '#fd6969' }} />Number of rooms exceeds the category limit for:
                                                                <Row className="pl-4">
                                                                    {error.dates.map((date, key) =>
                                                                        <Col sm={3} key={key}> {date} </Col>
                                                                    )}
                                                                </Row>
                                                            </span>
                                                        :
                                                            <span>
                                                                <i className="fas fa-times-circle mr-2" style={{ color: '#fd6969' }} /> <b> {error.code}:  </b> {error.message}
                                                            </span>
                                                        }
                                                    </div>
                                                )}
                                            </Card>
                                        : ''}

                                    </CardBody>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </BlockUi>
        );

    }
};
export default injectIntl(BulkUpdate);