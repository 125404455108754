import React, { Component } from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { KebabMenu, ConfirmActionModal } from '../Base/Common/CommonUIComponents';
import { deleteAPI, getAPI } from '../Base/API';
import { handleNotification } from "../Base/Notification"
import EmailReservationWhiteListModal from './EmailReservationWhiteListModal';

class EmailReservationWhiteList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            whiteList: [],
            whiteListModal: false
        };
    }

    componentDidMount() {
        this.getWhitelist();
    }

    getWhitelist = () => {
        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });

                    }
                    else if (data.response) {
                        this.setState({ error: errorMessage, block: false, whiteList: data.response });
                    }
                    else {
                        this.setState({ error: errorMessage, block: false });
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/hotel/EmailReservation/v1/Config`));
    } 

    deleteWhiteList = (e, id) => {
        if (e) e.preventDefault();

        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="EmailRes.WhiteListRemoved" />, <FormattedMessage id="generic.success" />);
                    }

                    this.setState({ deleteModal: false }, () => this.getWhitelist());
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/hotel/EmailReservation/v1/Config/${id}`));
    }

    toggleModal = (modal, whiteList) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedwhiteList: whiteList
        }), () => this.getWhitelist());
    }

    render() {
        const { block, error, whiteList, whiteListModal, selectedwhiteList, deleteModal } = this.state;
        return (
            <>
                <Row>
                    <Col className="d-flex justify-content-end align-items-center mb-2">
                        <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal("whiteListModal")}>
                            <FormattedMessage id="EmailRes.NewWhiteList" />
                        </Button>
                    </Col>
                </Row>
                {whiteListModal ?
                    <EmailReservationWhiteListModal
                        isOpen={whiteListModal}
                        toggleModal={() => this.toggleModal("whiteListModal")}
                        selectedwhiteList={selectedwhiteList}
                        getWhitelist={this.getWhitelist}
                    />
                    : ''}
                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={() => this.toggleModal('deleteModal')}
                        actionFunction={(e) => this.deleteWhiteList(e, selectedwhiteList.id)}
                        text={<FormattedMessage id="EmailRes.ConfirmDeleteText" values={{ name: selectedwhiteList.source }} />}
                        block={block}
                    />
                    : ''}
                <Row block={block} error={error}>
                    {whiteList && whiteList.length > 0 ?
                        <Col className="pt-1">
                            {whiteList.map((white, key) =>
                                <Card className='py-2 px-3 w-100 bg-white mb-3' style={{ border: '1px solid #bbc1c9ab', borderRadius: '5px', boxShadow: '0px 3px 15px #6F73881A', maxHeight: '75px' }} key={key}>
                                    <Row className="mr-1">
                                        <Col className="col-2"> <div className="title-sm" style={{ height: '25%' }}> <FormattedMessage id="EmailRes.Source" /> </div>
                                            <div style={{ fontWeight: "500", alignItems: "center", display: "flex" }}>{white.source} </div>
                                        </Col>
                                        <Col id="whitelistemail" className="h-100 col-7">
                                            <div className="title-sm" style={{ height: '25%', marginBottom: '-3px' }}>  <FormattedMessage id="EmailResWhiteList.emails" /> </div>
                                            <div className={`d-flex h-100`} style={{flexWrap: 'wrap'}}>
                                                {white.email && white.email.length > 0 && white.email.map((email, idx) =>
                                                    <div className={`${idx == 0 ? 'pr-2' : 'px-2'} `} style={{ borderRight: idx == white.email.length -1 ? "" : "1px solid lightgray" }}>{email}</div>
                                                )}
                                            </div>
                                        </Col>
                                        <Col className="text-right d-flex justify-content-end" style={{ alignItems: "center" }}>
                                            <KebabMenu
                                                editFunction={() => this.toggleModal('whiteListModal', white)}
                                                deleteFunction={() => this.toggleModal('deleteModal', white)}
                                            />
                                        </Col>
                                    </Row>

                                </Card>
                            )}
                        </Col>
                        : ''}
                </Row>
            </>          
        )
    }
}

export default injectIntl(EmailReservationWhiteList)