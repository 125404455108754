import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Label, Row, Col, Button, FormText, Modal, ModalBody, ModalHeader, Input, CustomInput, Badge, UncontrolledTooltip, FormGroup } from 'reactstrap';
import { CommonHelper, EmptyCard, getIsChildRateLabel } from '../Base/Common/CommonUIComponents';
import { getInterfaceMode, getMarkupType, getOperationModes, priceMarkupIncludePCOptions } from "../Base/Common/ReferenceDataFunctions";
import { postAPI, deleteAPI } from "../Base/API";
import CustomSelect from '../Base/Common/CustomSelect';
import { handleNotification } from '../Base/Notification';
import moment from 'moment';
import RateCodeChannelHistory from './RateCodeChannelHistory';
import Authorization from '../Base/Authorization';
import CustomToolTip from '../../Utils/CustomToolTip';

export class EditChannelMapping extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();      
        this.toggleRemove = this.toggleRemove.bind(this);
        this.removeMapping = this.removeMapping.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleSelectBookingEngine = this.handleSelectBookingEngine.bind(this);
        this.state = {
            error: [],
            errorRemoveMapping: [],
            block: false,
            blockRemoveMapping: false,
            rateid: this.props.rateid,
            channelid: this.props.channelid,
            selectedchannelid: this.props.selectedchannelid,
            pricemode: this.props.pricemode,
            pricePosition: this.props.pricePosition,
            active: this.props.active == undefined ? false : this.props.active,
            isActivated: this.props.isActivated,
            deactivatedBy: this.props.deactivatedBy,
            deactivatedOn: this.props.deactivatedOn,
            interfaceMode: this.props.interfaceMode,
            priceMarkupIncludePackageComponents: this.props.priceMarkupIncludePackageComponents,
            priceMarkupValue: this.props.priceMarkupValue,
            priceMarkupType: this.props.priceMarkupType,
            applyMarkup: this.props.applyMarkup,
            iCalUrl: this.props.iCalUrl,
            isIcal: this.props.isIcal,
            associatedRateCodeChannels: this.props.associatedRateCodeChannels,
            priceType: null,
            selectedChannelData: this.getSelectedChannelData(this.props.selectedchannelid),
            isHostelWorld: this.props.isHostelWorld ? <FormattedMessage id="EditChannelMapping.Occupancy" /> : <FormattedMessage id="EditChannelMapping.BaseOccupancy" />,
            roomTypeMapping: null,
            rateCodeMapping: null,
            selectedRate: this.props.rateList && this.props.rateList.map((el) => { return el.rateList.find(item => item.id === this.props.rateid) }).find(x => x && x.id === this.props.rateid),
            pricePositionOptions: [],
            showHistory: false,
            blockInputMappings: false,
            interfaceModeWarning: this.validateInterfaceMode(this.getSelectedChannelData(this.props.selectedchannelid), this.props.interfaceMode),
            excludePriceComponent: this.props.excludePriceComponent,
            occupationsToSend: this.props.occupationsToSend ? this.props.occupationsToSend : []
        };
    }

    componentDidMount() {
        if (this.state.selectedRate && (this.props.hasChannelData || this.state.isIcal)) {
            let rateCodeMapping = this.state.selectedRate.mappingList && this.state.selectedRate.mappingList.length > 0 ? this.state.selectedRate.mappingList[0].rateCode : '';
            let roomTypeMapping = this.state.selectedRate.mappingList && this.state.selectedRate.mappingList.length > 0 ? this.state.selectedRate.mappingList[0].roomCode : '';

            //getOperationModes()[0].value = 'PmsFull'
            const blockInputMappings = (global.sendRoomRate || global.operationMode !== getOperationModes()[0].value) && this.props.hasChannelData && !this.state.channelid;
            if (blockInputMappings) {
                rateCodeMapping = this.state.rateid;
                const category = this.props.rateList.find(cat => cat.rateList.find(rate => rate.id === this.state.rateid));
                roomTypeMapping = category ? category.id : ' ';
            }

            this.setState({ roomTypeMapping, rateCodeMapping, blockInputMappings });
        }

        if (this.props.priceModes.length === 1 && this.state.pricemode == null) {
            this.setState({ pricemode: this.props.priceModes[0].value })
        }
        
        const pricePositionOptions = this.state.selectedRate.occupancyList.map(occ => {
            const opt = {
                value: occ.position,
                id: occ.id,
                label: `${this.props.intl.formatMessage({ id: "EditChannelMapping.Adults" })}(${occ.adults})${occ.childrens > 0 ? ` + ${this.props.intl.formatMessage({ id: "EditChannelMapping.Children" })}(${occ.childrens})` : ``}`
            };
            return opt;
        });
        this.setState({ pricePositionOptions: pricePositionOptions })
    }

    saveNewMapping(e) {
        e.preventDefault();

        const iCalUrl = document.getElementById('iCalUrl');
        if (this.state.isIcal) {
            if (this.state.active && (this.state.iCalUrl === null || this.state.iCalUrl === '' || this.state.iCalUrl === undefined)) {
                iCalUrl.setCustomValidity(this.props.intl.formatMessage({ id: "EditChannelMapping.FieldRequired" }));
            }
            else {
                iCalUrl.setCustomValidity("");
            }
        }

        if (this.props.selectedChannel.code === 'HEYBE' && !global.sendRoomRate) {
            const regex = /^([a-zA-Z]|[0-9]|[-_.])+$/i;

            const roomTypeMappingInput = document.getElementById('roomTypeMapping');
            if (!regex.test(this.state.roomTypeMapping)) {
                roomTypeMappingInput.setCustomValidity(this.props.intl.formatMessage({ id: "EditChannelMapping.InvalidCharacters" }));
            }
            else {
                roomTypeMappingInput.setCustomValidity("");
            }

            const rateCodeMappingInput = document.getElementById('rateCodeMapping');
            if (!regex.test(this.state.rateCodeMapping)) {
                rateCodeMappingInput.setCustomValidity(this.props.intl.formatMessage({ id: "EditChannelMapping.InvalidCharacters" }));
            }
            else {
                rateCodeMappingInput.setCustomValidity("");
            }
        }

        if (this.form.current.reportValidity()) {
            if (this.props.channelDataMap) {
                const maxOccupancy = this.state.selectedRate.occupancyList[this.state.selectedRate.occupancyList.length - 1];
                const maxAdults = 8;
                const pricePosition = this.state.pricemode !== 0 || this.props.isJuniper ? this.state.pricePosition : (maxOccupancy.childrens > 0 ? maxAdults + maxOccupancy.childrens : maxOccupancy.adults);

                const req = {
                    "request": {
                        "id": this.state.channelid,
                        "rateCodeId": this.state.rateid,
                        "active": this.state.active,
                        //"localRoomCode": "string",
                        //"localRoomDesc": "string",
                        //"localRateCode": "string",
                        //"localRateDesc": "string",
                        //"availabilityGroupMapping": "string",
                        //"extraMapping1": "string",
                        //"extraMapping2": "string",
                        //"extraMapping3": "string",
                        //"sortOrder": 0,
                        "channelInstanceId": this.props.selectedChannel.id,
                        "priceMode": this.state.pricemode !== null ? this.state.pricemode : -1,
                        "priceMarkupType": this.state.priceMarkupType ? this.state.priceMarkupType : 0,
                        "priceMarkupValue": this.state.priceMarkupValue ? this.state.priceMarkupValue : 0,
                        "priceMarkupIncludePackageComponents": this.state.priceMarkupIncludePackageComponents,
                        "interfaceMode": this.state.interfaceMode ? this.state.interfaceMode : -1,
                        "iCalUrl": this.state.iCalUrl,
                        "pricePosition": pricePosition,
                        "associatedRateCodeChannels": this.state.associatedRateCodeChannels,
                        "excludePriceComponent": this.state.excludePriceComponent,
                        "occupationsToSend": this.state.occupationsToSend
                    }
                };
                if (this.state.isIcal || this.props.hasChannelData) {
                    req.request.rateCodeMapping = this.state.rateCodeMapping;
                    req.request.roomTypeMapping = this.state.roomTypeMapping;
                }
                else {
                    let r = this.props.channelDataMap.find(el => el.id === this.state.selectedchannelid);
                    if (r === undefined) {
                        req.request.rateCodeMapping = this.state.rateid;
                        const category = this.props.rateList.find(cat => cat.rateList.find(rate => rate.id === this.state.rateid));
                        req.request.roomTypeMapping = category ? category.id : ' ';
                    }
                    else {
                        req.request.rateCodeMapping = r.rateCode;
                        req.request.roomTypeMapping = r.roomCode;
                        //Mandatory for channel HotelBeds
                        req.request.availabilityGroupMapping = r.hotelBedsSpecific ? `${this.props.mapIncommingOffice && r.hotelBedsSpecific ? `${r.hotelBedsSpecific.incommingOffice}` : `${r.hotelBedsSpecific.contractName}/${r.hotelBedsSpecific.incommingOffice}/${r.hotelBedsSpecific.sequence}`}` : (this.props.hasAvailabilityGroupMapping && this.state.selectedChannelData ? this.state.selectedChannelData.extraMapping : '');
                    }
                }

                this.setState({ block: true });
                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ error: errorMessage, block: false });
                            return;
                        }
                        if (data.response && data.response.length > 0) {
                            const rateCodeChannel = data.response[0];
                            this.setState({ error: errorMessage, block: false, isActivated: rateCodeChannel.active, channelid: rateCodeChannel.id, associatedRateCodeChannels: rateCodeChannel.associatedRateCodeChannels });
                        }
                        else {
                            this.setState({ error: errorMessage, block: false });
                        }
                        handleNotification(data, <FormattedMessage id="EditChannelMapping.MappingSaved" />, <FormattedMessage id="EditChannelMapping.Success" />);
                        if (this.props.getRateMappingFromBEConfig) this.props.getRateMappingFromBEConfig();
                        this.props.getRates();
                        this.props.reloadChannelData();
                    }
                    else {
                        this.setState({ error: errorMessage, block: false });
                    }
                }, '/api/Rate/RateCodeChannel/v1/ratecodechannel', req);
            }
        }
    }

    setComboChannelCode = (inputValue) => {
        let targetValue = inputValue ? inputValue.value : '';

        const selectedChannelData = this.getSelectedChannelData(targetValue);
        let interfaceModeWarning = this.validateInterfaceMode(selectedChannelData, this.state.interfaceMode);

        this.setState({
            selectedchannelid: targetValue,
            selectedChannelData: selectedChannelData,
            interfaceModeWarning,
        })
    }

    validateInterfaceMode = (channelData, value) => {
        let warning = null;

        if (channelData?.rateType && value) {
            if (channelData?.rateType === "AvailabilityOnly" && (value === 3 || value === 4 || value === 5 || value === 6)) { // != 2 Way, 2 Way No Prices and Price Only
                warning = "EditChannelMapping.InterfaceWarningText";
            }
            else if (channelData?.rateType === "PriceOnly" && (value === 3 && value === 4 && value === 1 || value === 6)) { // != 2 Way, 2 Way No Prices and Inventory
                warning = "EditChannelMapping.InterfaceWarningText";
            }
        }

        if (channelData?.isChildRate !== true && value === 6) {
            warning = "ChannelMapping.MismatchInterfaceMode";
        }

        return warning;
    }

    setCombo = (evt, inputValue, actionMeta) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    } 

    setInterfaceMode = (inputValue) => {
        var targetValue = inputValue ? inputValue.value : '';
        let interfaceModeWarning = this.validateInterfaceMode(this.state.selectedChannelData, targetValue);

        this.setState({ interfaceMode: targetValue, interfaceModeWarning });
    }

    setPriceMode(combo) {
        this.setState({ pricemode: combo && combo.value, pricePosition: 0, occupationsToSend: [] });
    }

    getSelectedChannelData(selectedchannelid) {
        return this.props.channelDataMap && this.props.channelDataMap.find(el => el.id === selectedchannelid)
    }

    toggleRemove() {
        this.setState(prevState => ({
            modalRemove: !prevState.modalRemove
        }));
    }

    removeMapping() {
        this.setState({ blockRemoveMapping: true });
        deleteAPI(result => {
            const { data, error } = result;
            var errorRemoveMapping = []
            if (error) {
                errorRemoveMapping.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorRemoveMapping: errorRemoveMapping, blockRemoveMapping: false });
                return;
            }
            handleNotification(data, <FormattedMessage id="ChannelMapping.RemoveMappingSuccess" />, <FormattedMessage id="EditChannelMapping.Success" />);

            this.setState({ errorRemoveMapping: errorRemoveMapping, blockRemoveMapping: false });

            if (this.props.getRateMappingFromBEConfig) this.props.getRateMappingFromBEConfig();
            this.props.reloadChannelData();
            this.props.getRates();
            this.toggleRemove();
            this.props.toggle();
        }, `/api/Rate/RateCodeChannel/v1/mapping/${this.state.channelid}`);
    }

    changeField(name, evt) {
        const value = evt.target ? evt.target.value : null;

        this.setState({ [name]: value });
    }

    handleChangeStatus(evt) {
        if (evt && evt.target) {
            const { checked } = evt.target;
            if (checked) {
                if (this.form.current.reportValidity()) {
                    this.setState({ active: checked });
                }
            }
            else {
                this.setState({ active: checked });
            }
        }
    }

    handleSelectBookingEngine(evt) {
        const { associatedRateCodeChannels } = this.state;
        if (evt && evt.target) {
            const { checked, name } = evt.target;

            if (checked) {
                associatedRateCodeChannels.push(
                    {
                        id: null,
                        channelCode: name
                    }
                );
            }
            else {
                const idx = associatedRateCodeChannels.indexOf(associatedRateCodeChannels.find(aRcc => aRcc.channelCode === name));
                if (idx >= 0) {
                    associatedRateCodeChannels.splice(idx, 1);
                }
            }
            this.setState({ associatedRateCodeChannels });
        }
    }

    handleSelectoOcupationsToSend = (combo) => {
        const occupationsToSend = combo ? combo.map(cmb => cmb.id) : [];

        this.setState({ occupationsToSend });
    }

    render() {
        const { isNotSapo, hasChannelData, bookingEngines, isJuniper, isHrs, isHrsSpecialRate, isHrsEconomyRate, interfaceModes } = this.props;
        const { blockInputMappings, selectedRate, interfaceModeWarning, occupationsToSend } = this.state;

        function getPriceType(priceModes, priceType) {
            const priceMode = priceModes.find(priceMode => priceMode.value == priceType);
            return priceMode ? priceMode.value : '';
        }

        return (
            <div>
                <Modal isOpen={this.state.modalRemove} toggle={this.toggleRemove} fade={false}>
                    <ModalHeader toggle={this.toggleRemove}>
                        <FormattedMessage id="ChannelMapping.RemoveMappingTitle" />
                    </ModalHeader>
                    <ModalBody className="p-0">
                        <EmptyCard block={this.state.blockRemoveMapping} error={this.state.errorRemoveMapping}>
                            <Row>
                                <Col>
                                    <FormattedMessage id="ChannelMapping.RemoveMappingPhrase1" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormattedMessage id="ChannelMapping.RemoveMappingPhrase2" />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-right">
                                    <Button color="btn btn-host mr-1" onClick={this.removeMapping}>
                                        <FormattedMessage id="ChannelMapping.RemoveMappingYes" />
                                    </Button>
                                    <Button color="btn btn-host" onClick={this.toggleRemove}>
                                        <FormattedMessage id="ChannelMapping.RemoveMappingNo" />
                                    </Button>
                                </Col>
                            </Row>
                        </EmptyCard>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.props.modal} toggle={this.props.toggle} className={`modal-lg ${this.props.classes ? this.props.classes : ''}`} fade={false} >
                    <ModalHeader toggle={this.props.toggle}>
                        <FormattedMessage id="EditChannelMapping.MappingDetail" />
                    </ModalHeader>
                    <ModalBody className="modal-lg p-0">
                        <EmptyCard block={this.state.block} error={this.state.error}>
                            <form ref={this.form}>
                                <Row className="mb-1">
                                    <Col className="text-right">

                                        {this.state.channelid ?
                                            <>
                                                <Authorization
                                                    perform="channel:mapping:history"
                                                    yes={() => (
                                                        <Button className="btn-sm btn-host mr-2" onClick={() => this.setState(prevState => (({ showHistory: !prevState.showHistory })))}>
                                                            <i className="fas fa-history" />
                                                        </Button>
                                                    )}
                                                    no={() => <div></div>}
                                                />

                                                <Button className="btn-sm btn-host mr-2" onClick={this.toggleRemove}>
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                            </>
                                            : ''}
                                        <Button type="button" className="btn-sm btn-host" onClick={this.saveNewMapping.bind(this)} disabled={interfaceModeWarning !== null}>
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>
                                <hr className="py-0" />
                                <Row className="mb-2">
                                    <Col className="col-3">
                                        <Row>
                                            <Col>
                                                <h5> <b> {selectedRate && selectedRate.code}</b></h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {
                                                    selectedRate && selectedRate.occupancyList && selectedRate.occupancyList.map((el, key) =>
                                                        <Row key={key}>
                                                            <Col>
                                                                <span>
                                                                    {el.adults} x <i className="fas fa-user"></i>  +  {el.childrens} x <i className="fas fa-child"></i>
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-9">
                                        <Row>
                                            <Col>
                                                <Row className="mb-2">
                                                    <Col className="col-3">
                                                        <FormattedMessage id="EditChannelMapping.Status" />
                                                    </Col>
                                                    <Col className="col-9">
                                                        <CustomInput type="switch" checked={this.state.active} id="channelActive" onChange={this.handleChangeStatus} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        {
                                            this.state.isIcal && this.state.isIcal === true ?
                                                <div>
                                                    <Row className="mb-2">
                                                        <Col className="col-3">
                                                            <FormattedMessage id="EditChannelMapping.ICalUrl" />
                                                        </Col>
                                                        <Col className="col-9">
                                                            <Input type="text" id="iCalUrl" name="iCalUrl" value={this.state.iCalUrl ? this.state.iCalUrl : ''} onChange={this.changeField.bind(this, 'iCalUrl')} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-2">
                                                        <Col className="col-3">
                                                            <FormattedMessage id="EditChannelMapping.RoomCode" />
                                                        </Col>
                                                        <Col className="col-3">
                                                            <Input type="text" name="roomTypeMapping" value={this.state.roomTypeMapping ? this.state.roomTypeMapping : ''} onChange={this.changeField.bind(this, 'roomTypeMapping')} required disabled={global.operationMode === 'PmsFull' || blockInputMappings} />
                                                        </Col>
                                                        <Col className="col-3">
                                                            <FormattedMessage id="EditChannelMapping.RateCode" />
                                                        </Col>
                                                        <Col className="col-3">
                                                            <Input type="text" name="rateCodeMapping" value={this.state.rateCodeMapping ? this.state.rateCodeMapping : ''} onChange={this.changeField.bind(this, 'rateCodeMapping')} required disabled={global.operationMode === 'PmsFull' || blockInputMappings} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                :
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <Row className="mb-2 align-items-center">
                                                                <Col className="col-3">
                                                                    <Label><FormattedMessage id="EditChannelMapping.PriceMode" /></Label>
                                                                </Col>
                                                                <Col className="col-9">
                                                                    { this.state.selectedChannelData && (this.state.selectedChannelData.priceMode !== null && this.state.selectedChannelData.priceMode !== this.state.pricemode) ?
                                                                        <Row>
                                                                            <Col className="col-11">
                                                                                {this.state.pricemode !== 0 || isJuniper ?
                                                                                    <Row>
                                                                                        <Col className="col-4">
                                                                                            <CustomSelect name="pricemode" options={this.props.priceModes} value={this.props.priceModes.find(el => el.value === this.state.pricemode)} onChange={this.setPriceMode.bind(this)} required={!this.state.active} />
                                                                                        </Col>
                                                                                        <Col className="col-8">
                                                                                            <FormGroup row>
                                                                                                <Label for="sapoRateCode" sm={3}>
                                                                                                    <FormattedMessage id="EditChannelMapping.Position" />
                                                                                                </Label>
                                                                                                <Col sm={9}>
                                                                                                    <CustomSelect
                                                                                                        name="pricePosition"
                                                                                                        options={this.state.pricePositionOptions}
                                                                                                        value={this.state.pricePositionOptions.find(el => el.value === this.state.pricePosition)}
                                                                                                        onChange={this.setCombo.bind(this, 'pricePosition')}
                                                                                                        required={!this.state.active}
                                                                                                    />
                                                                                                </Col>
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    :
                                                                                    this.state.pricemode === 0 ?
                                                                                        <Row>
                                                                                            <Col className="col-4">
                                                                                                <CustomSelect name="pricemode" options={this.props.priceModes} value={this.props.priceModes.find(el => el.value === this.state.pricemode)} onChange={this.setPriceMode.bind(this)} required={!this.state.active} />
                                                                                            </Col>
                                                                                            <Col className="col-8">
                                                                                                <FormGroup row>
                                                                                                    <Label for="occupancies" sm={3}>
                                                                                                        <FormattedMessage id="EditChannelMapping.Occupancies" />
                                                                                                    </Label>
                                                                                                    <Col sm={9}>
                                                                                                        <CustomSelect
                                                                                                            name="occupationsToSend"
                                                                                                            options={this.state.pricePositionOptions}
                                                                                                            value={this.state.pricePositionOptions.filter(el => occupationsToSend.some(occ => el.id === occ))}
                                                                                                            onChange={this.handleSelectoOcupationsToSend}
                                                                                                            isMulti={true}
                                                                                                        />
                                                                                                    </Col>
                                                                                                </FormGroup>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        :
                                                                                        <CustomSelect name="pricemode" options={this.props.priceModes} value={this.props.priceModes.filter(el => el.value === this.state.pricemode)} onChange={this.setPriceMode.bind(this)} placeholder={<FormattedMessage id="EditChannelMapping.Select" />} required={!this.state.active} />
                                                                                }
                                                                            </Col>
                                                                            <Col className="col-1 pt-1">
                                                                                <Badge id="mismatchPriceMode" color="danger">
                                                                                    <i className="fas fa-exclamation-triangle" />
                                                                                </Badge>
                                                                                <CustomToolTip placement="right" target="mismatchPriceMode">
                                                                                    <FormattedMessage id="ChannelMapping.MismatchPriceMode" />
                                                                                </CustomToolTip>
                                                                            </Col>
                                                                        </Row>
                                                                            :
                                                                        this.state.pricemode !== 0 || isJuniper ?
                                                                            <Row>
                                                                                <Col className="col-4">
                                                                                    <CustomSelect name="pricemode" options={this.props.priceModes} value={this.props.priceModes.filter(el => el.value === this.state.pricemode)} onChange={this.setPriceMode.bind(this)} placeholder={<FormattedMessage id="EditChannelMapping.Select" />} required={!this.state.active} />
                                                                                </Col>
                                                                                <Col className="col-8">
                                                                                    <FormGroup row>
                                                                                        <Label for="sapoRateCode" sm={3}>
                                                                                            <FormattedMessage id="EditChannelMapping.Position" />
                                                                                        </Label>
                                                                                        <Col sm={9}>
                                                                                            <CustomSelect
                                                                                                name="pricePosition"
                                                                                                options={this.state.pricePositionOptions}
                                                                                                value={this.state.pricePositionOptions.find(el => el.value === this.state.pricePosition)}
                                                                                                onChange={this.setCombo.bind(this, 'pricePosition')}
                                                                                                required={!this.state.active}
                                                                                            />
                                                                                        </Col>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            :
                                                                            this.state.pricemode === 0 ?
                                                                                <Row>
                                                                                    <Col className="col-4">
                                                                                        <CustomSelect name="pricemode" options={this.props.priceModes} value={this.props.priceModes.find(el => el.value === this.state.pricemode)} onChange={this.setPriceMode.bind(this)} required={!this.state.active} />
                                                                                    </Col>
                                                                                    <Col className="col-8">
                                                                                        <FormGroup row>
                                                                                            <Label for="occupancies" sm={3}>
                                                                                                <FormattedMessage id="EditChannelMapping.Occupancies" />
                                                                                            </Label>
                                                                                            <Col sm={9}>
                                                                                                <CustomSelect
                                                                                                    name="occupationsToSend"
                                                                                                    options={this.state.pricePositionOptions}
                                                                                                    value={this.state.pricePositionOptions.filter(el => occupationsToSend.some(occ => el.id === occ))}
                                                                                                    onChange={this.handleSelectoOcupationsToSend}
                                                                                                    isMulti={true}
                                                                                                />
                                                                                            </Col>
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                                </Row>
                                                                                :
                                                                                <CustomSelect name="pricemode" options={this.props.priceModes} value={this.props.priceModes.filter(el => el.value === this.state.pricemode)} onChange={this.setPriceMode.bind(this)} placeholder={<FormattedMessage id="EditChannelMapping.Select" />} required={!this.state.active} />

                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-2">
                                                                <Col className="col-3">
                                                                    <Label><FormattedMessage id="EditChannelMapping.Interface" /></Label>
                                                                </Col>
                                                                <Col>
                                                                    <CustomSelect name="interfaceMode" options={interfaceModes} value={getInterfaceMode().filter(el => el.value === this.state.interfaceMode)} onChange={this.setInterfaceMode.bind(this)} placeholder={<FormattedMessage id="EditChannelMapping.Select" />} required={!this.state.active} />

                                                                    {interfaceModeWarning ?
                                                                        <FormText className="mb-2">
                                                                            <Badge color="white" className="text-warning mr-1">
                                                                                <i className="fas fa-exclamation-triangle" />
                                                                            </Badge>
                                                                            <FormattedMessage id={interfaceModeWarning} />
                                                                        </FormText>
                                                                        : ''}
                                                                </Col>

                                                            </Row>
                                                            <Row className="my-3 align-items-center">
                                                                <Col className="col-3">
                                                                    <FormattedMessage id="EditChannelMapping.AdjustValue" />
                                                                </Col>
                                                                <Col className="col-3">
                                                                    <CustomInput type="switch" id="hasMarkup" checked={this.state.applyMarkup} onChange={(evt) => { let chk = evt.target.checked; this.setState(prevState => ({ applyMarkup: chk, priceMarkupType: chk ? prevState.priceMarkupType : null, priceMarkupValue: chk ? prevState.priceMarkupValue : null })) }} />
                                                                </Col>
                                                                <Col className="col-3">
                                                                    <FormattedMessage id="EditChannelMapping.ExcludePriceComponent" />
                                                                </Col>
                                                                <Col className="col-2">
                                                                    <CustomInput
                                                                        type="switch"
                                                                        id="excludePriceComponent"
                                                                        checked={this.state.excludePriceComponent}
                                                                        onChange={(evt) => { this.setState({ excludePriceComponent: evt.target.checked }) }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {
                                                                isHrs ?
                                                                    isHrsEconomyRate ?
                                                                        <FormText className="mb-2">
                                                                            <Badge color="white" className="text-warning mr-1">
                                                                                <i className="fas fa-exclamation-triangle" />
                                                                            </Badge>
                                                                            <FormattedMessage id="EditChannelMapping.HrsEconomyRateHelp" />
                                                                        </FormText>
                                                                        :
                                                                        isHrsSpecialRate ?
                                                                            <FormText className="mb-2">
                                                                                <Badge color="white" className="text-warning mr-1">
                                                                                    <i className="fas fa-exclamation-triangle" />
                                                                                </Badge>
                                                                                <FormattedMessage id="EditChannelMapping.HrsSpecialRateHelp" />
                                                                            </FormText>
                                                                            :
                                                                            <span />
                                                                    :
                                                                    <span />
                                                            }
                                                            {this.state.applyMarkup === true ?
                                                                <Row className="mb-3 align-items-center">
                                                                    <Col className="col-3">
                                                                        <Label className="mb-0"><FormattedMessage id="EditChannelMapping.ApplyValue" /></Label>
                                                                    </Col>
                                                                    <Col className="col-3">
                                                                        <CustomSelect name="priceMarkupType" isClearable options={this.props.availableMarkupOptions} value={getMarkupType().filter(el => el.value === this.state.priceMarkupType)} onChange={this.setCombo.bind(this, 'priceMarkupType')} placeholder={<FormattedMessage id="EditChannelMapping.Select" />} />
                                                                    </Col>
                                                                    <Col className="col-2 ">
                                                                        <Input
                                                                            type="number"
                                                                            name="priceMarkupValue"
                                                                            placeholder="Value"
                                                                            min={this.state.priceMarkupType && this.state.priceMarkupType === 2 ? '-100.00' : '0.01'}
                                                                            max="100.00"
                                                                            step="0.01"
                                                                            value={this.state.priceMarkupValue}
                                                                            onChange={(evt) => this.setState({ priceMarkupValue: evt.target.value })}
                                                                        />
                                                                    </Col>
                                                                    <Col className="col-4">
                                                                        <CustomSelect
                                                                            name="priceMarkupIncludePackageComponents"
                                                                            options={priceMarkupIncludePCOptions}
                                                                            value={priceMarkupIncludePCOptions.find(el => el.value === this.state.priceMarkupIncludePackageComponents)}
                                                                            onChange={this.setCombo.bind(this, 'priceMarkupIncludePackageComponents')}
                                                                            placeholder={<FormattedMessage id="EditChannelMapping.Select" />}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            : ''}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-2 mt-1">
                                                        {hasChannelData === true && (global.operationMode === 'None' || global.operationMode === 'PmsLimited') ?
                                                            <Col>
                                                                <Row className="mb-2">
                                                                    <Col className="col-3">
                                                                        <FormattedMessage id="EditChannelMapping.RoomCode" />
                                                                    </Col>
                                                                    <Col className="col-3 ">
                                                                        <Input type="text" id="roomTypeMapping" name="roomTypeMapping" value={this.state.roomTypeMapping ? this.state.roomTypeMapping : ''} onChange={this.changeField.bind(this, 'roomTypeMapping')} required disabled={global.operationMode === 'PmsFull' || blockInputMappings} />
                                                                    </Col>
                                                                    <Col className="col-3">
                                                                        <FormattedMessage id="EditChannelMapping.RateCode" />
                                                                    </Col>
                                                                    <Col className="col-3">
                                                                        <Input type="text" id="rateCodeMapping" name="rateCodeMapping" value={this.state.rateCodeMapping ? this.state.rateCodeMapping : ''} onChange={this.changeField.bind(this, 'rateCodeMapping')} required disabled={global.operationMode === 'PmsFull' || blockInputMappings} />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            : isNotSapo ?
                                                                <Col>
                                                                    <FormGroup row>
                                                                        <Label sm={3}>
                                                                            <FormattedMessage id="EditChannelMapping.ChannelCodes" />
                                                                        </Label>
                                                                        <Col sm={9}>
                                                                            <CustomSelect
                                                                                name="selectedchannelid"
                                                                                options={this.props.getunmappedChannelData()}
                                                                                value={this.props.getunmappedChannelData().flatMap(el => el.options).find(el => el.value === this.state.selectedchannelid)}
                                                                                onChange={this.setComboChannelCode.bind(this)}
                                                                                placeholder={<FormattedMessage id="EditChannelMapping.Select" />}
                                                                                required={!this.state.active}
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                                :
                                                                <Col>
                                                                    <FormGroup row>
                                                                        <Label for="sapoRateCode" sm={3}>
                                                                            <FormattedMessage id="EditChannelMapping.Rate" />
                                                                        </Label>
                                                                        <Col sm={9}>
                                                                            <Input type="text" name="sapoRateCode" id="sapoRateCode" value={selectedRate && selectedRate.code} disabled />
                                                                        </Col>
                                                                    </FormGroup>
                                                                </Col>
                                                        }
                                                    </Row>
                                                    <Row className="mt-2">
                                                        <Col>
                                                            {
                                                                this.state.selectedChannelData ?
                                                                    <Row>
                                                                        <Col>
                                                                            <Row>
                                                                                <Col className="col-12" ><b>{this.state.selectedChannelData.rateDescription}</b>
                                                                                    <hr className="my-2" />
                                                                                </Col>
                                                                                {/*<Col />*/}
                                                                            </Row>
                                                                            {
                                                                                this.state.selectedChannelData.priceType ?
                                                                                    <Row>
                                                                                        <Col className="col-4"><FormattedMessage id="EditChannelMapping.PriceType" /></Col>
                                                                                        <Col className="col-8">
                                                                                            {getPriceType(this.props.priceModes, this.state.selectedChannelData.priceType)}
                                                                                        </Col>
                                                                                        <Col />
                                                                                    </Row>
                                                                                    :
                                                                                    <div />
                                                                            }
                                                                            {
                                                                                this.state.selectedChannelData.baseOccupancy ?
                                                                                    <Row>
                                                                                        <Col className={`col-${this.state.selectedChannelData.occupancies ? `8` : `4`}`}>
                                                                                            {this.state.isHostelWorld}
                                                                                        </Col>
                                                                                        <Col className={`col-${this.state.selectedChannelData.occupancies ? `4` : `8`}`}>
                                                                                            {this.state.selectedChannelData.baseOccupancy}
                                                                                        </Col>
                                                                                    </Row>
                                                                                    :
                                                                                    <div />
                                                                            }
                                                                            {
                                                                                this.state.selectedChannelData.maxPersons ?
                                                                                    <Row>
                                                                                        <Col className={`col-${this.state.selectedChannelData.occupancies ? `8` : `4`}`}>
                                                                                            <FormattedMessage id="EditChannelMapping.MaxPersons" />
                                                                                        </Col>
                                                                                        <Col className={`col-${this.state.selectedChannelData.occupancies ? `4` : `8`}`}>
                                                                                            {this.state.selectedChannelData.maxPersons}
                                                                                        </Col>
                                                                                    </Row>
                                                                                    :
                                                                                    <div />
                                                                            }
                                                                            {
                                                                                this.state.selectedChannelData.maxChildren ?
                                                                                    <Row>
                                                                                        <Col className="col-4"><FormattedMessage id="EditChannelMapping.MaxChildren" /></Col>
                                                                                        <Col className="col-8">
                                                                                            {this.state.selectedChannelData.maxChildren}
                                                                                        </Col>
                                                                                        <Col />
                                                                                    </Row>
                                                                                    :
                                                                                    <div />
                                                                            }
                                                                            {
                                                                                this.state.selectedChannelData.hotelBedsSpecific ?
                                                                                    <div>
                                                                                        <Row>
                                                                                            <Col className="col-3">
                                                                                                <FormattedMessage id="EditChannelMapping.Contract" />
                                                                                            </Col>
                                                                                            <Col className="col-9">
                                                                                                {this.state.selectedChannelData.hotelBedsSpecific.comment}
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col className="col-3">
                                                                                                <FormattedMessage id="EditChannelMapping.ValidFrom" />
                                                                                            </Col>
                                                                                            <Col className="col-2">
                                                                                                {moment(this.state.selectedChannelData.hotelBedsSpecific.dateFrom).format("YYYY-MM-DD")}
                                                                                            </Col>
                                                                                            <Col className="col-1">
                                                                                                <FormattedMessage id="EditChannelMapping.To" />
                                                                                            </Col>
                                                                                            <Col className="col-2">
                                                                                                {moment(this.state.selectedChannelData.hotelBedsSpecific.dateTo).format("YYYY-MM-DD")}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                    :
                                                                                    <div />
                                                                            }
                                                                            {
                                                                                this.state.selectedChannelData.isChildRate !== null ?
                                                                                    <Row>
                                                                                        <Col className="col-4">
                                                                                            <FormattedMessage id="EditChannelMapping.IsChildRate" />
                                                                                        </Col>
                                                                                        <Col className="col-8">
                                                                                            {getIsChildRateLabel(this.state.selectedChannelData.isChildRate)}
                                                                                        </Col>
                                                                                        <Col />
                                                                                    </Row>
                                                                                    :
                                                                                    <div />
                                                                            }
                                                                        </Col>
                                                                        {
                                                                            this.state.selectedChannelData.occupancies ?
                                                                                <Col>
                                                                                    <Row>
                                                                                        <Col className="col-12">
                                                                                            <b>
                                                                                                <FormattedMessage id="ChannelConf.Occupancies" />
                                                                                            </b>
                                                                                            <hr className="py-1" />
                                                                                        </Col>
                                                                                    </Row>
                                                                                    {
                                                                                        this.state.selectedChannelData.occupancies.map((occ, key) =>
                                                                                            <Row key={key}>
                                                                                                <Col>
                                                                                                    {occ.adults} x <i className="fas fa-user"></i>  +  {occ.childrens} x <i className="fas fa-child"></i>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        )
                                                                                    }
                                                                                </Col>
                                                                                :
                                                                                <div />
                                                                        }
                                                                    </Row>
                                                                    :
                                                                    <div />
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Authorization
                                                        perform="channelMapping:mapGoogleMappings"
                                                        yes={() => (
                                                            !isNotSapo && this.props.selectedChannel.code !== "SAPO" ?
                                                                <Row>
                                                                    <Col className="col-3">
                                                                        <FormattedMessage id="EditChannelMapping.PublishOn" />
                                                                    </Col>
                                                                    {
                                                                        bookingEngines.map((bookingEngine, bookingEngineIdx) =>
                                                                            <Col key={bookingEngineIdx} className="col-2">
                                                                                <CustomInput type="checkbox" checked={this.state.associatedRateCodeChannels.find(aRcc => aRcc.channelCode === bookingEngine.value)} id={`${bookingEngine.value}`} name={`${bookingEngine.value}`} onChange={this.handleSelectBookingEngine}>
                                                                                    {bookingEngine.label}
                                                                                </CustomInput>
                                                                            </Col>
                                                                        )
                                                                    }
                                                                </Row>
                                                                :
                                                                <div />
                                                        )}
                                                        no={() => <div />}
                                                    />
                                                </div>
                                        }
                                        {
                                            this.state.isActivated === false ?
                                                <div>
                                                    <hr className="py-0" />
                                                    <Row className="mb-2">
                                                        <Col>
                                                            <FormattedMessage id="ChannelMapping.DeactivatedChannel1" />
                                                            <b>{this.state.deactivatedBy}</b>
                                                            <FormattedMessage id="ChannelMapping.DeactivatedChannel2" />
                                                            <b>{moment(this.state.deactivatedOn).format("YYYY-MM-DD HH:mm:ss")}</b>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                :
                                                <div />
                                        }
                                    </Col>
                                </Row>
                            </form>


                            <Authorization
                                perform="channel:mapping:history"
                                yes={() => (
                                    this.state.showHistory &&
                                    <div className="mt-4">
                                        <RateCodeChannelHistory
                                            rateCodeChannelId={this.state.channelid}
                                            priceModes={this.props.priceModes}
                                            pricePositionOptions={this.state.pricePositionOptions}
                                        />
                                    </div>
                                )}
                                no={() => <div></div>}
                            />
                        </EmptyCard>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(EditChannelMapping);