import React, { Component } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import  Home from './components/Home';
import { Profile } from './components/profile/Profile';
import { Inventory } from './components/Inventory/Inventory';
import { RoomRateConfig } from './components/Setup/Room/RoomRateConfig';
import { RoomCategorySetup } from './components/HotelSetting/Property/Room/RoomCategorySetup';
import AnalyticsDashboard from './components/Analytics/AnalyticsDashboard';
import { PropertySetup } from './components/HotelSetting/Property/PropertySetup';
import { OnlineRatesDashboard } from './components/AddOns/OnlineReputation/OnlineRatesDashboard';
import RuleConfig from './components/Inventory/Rules/RuleConfig';
import { RoomSetup } from './components/HotelSetting/Property/Room/RoomSetup';
import  ReservationDashboard  from './components/Reservations/ReservationDashboard';
import  ReservationList  from './components/Reservations/ReservationList';
import  PackageList  from './components/HotelSetting/Property/Package/PackageList';
import { ChannelDashboard } from './components/Channel/ChannelDashboard';
import { OportunityDashboard } from './components/AddOns/Oportunity/OportunityDashboard';
import { Promotions } from './components/AddOns/Promotions/Promotions';
import { LogsDashboard } from './components/Channel/Logs/LogsDashboard';
import { MailDashboard } from './components/AddOns/MailBox/MailDashboard';
import  ComplexList  from './components/HotelSetting/Property/Complex/ComplexList';
import  Security  from './components/profile/Security';
import { AdminHome } from './components/Admin/Sync/AdminHome';
import DirtyDataDashboard from './components/Admin/HealthCheck/DirtyDataDashboard';
import { QueueStatusDashboard } from './components/Admin/HealthCheck/QueueStatusDashboard';
import ConfigChannelAutoActions from './components/Admin/Config/ConfigChannelAutoActions';
import Announcement from './components/Admin/Config/Announcement';
import ManageUser from './components/profile/ManageUser';
import ManageChannelConfig from './components/Admin/Config/ManageChannelConfig'
import ManageChannelInstance from './components/Admin/Config/ManageChannelInstance'
import SystemEvents from './components/Admin/HealthCheck/SystemEvents'
import { Notification } from './components/Admin/Config/Email/Notification'
import SyncEvents from './components/Admin/Sync/SyncEvents'
import { SapoAccounting } from './components/HotelSetting/Accounting/SapoAccounting';
import ManageScheduleTask from './components/Admin/SchedulteTask/ManageScheduleTask';
import OTAManagement from './components/Admin/OTAManagement/OTAManagement';
import InvalidReservationDashboard from './components/Admin/HealthCheck/InvalidReservationDashboard';
import ReservationTimerDashboard from './components/Admin/HealthCheck/ReservationTimerDashboard';
import ReservationStatusDashboard from './components/Admin/HealthCheck/ReservationStatusDashboard';
import HotelChannelInfo from './components/Admin/HotelData/HotelChannelInfo';
import ConfigLicenses from './components/Admin/Config/ConfigLicenses';
import HotelLicenses from './components/Admin/HotelData/HotelLicenses';
//import PriceListManagement from './components/HotelSetting/Property/PriceList/PriceListManagement';
import PropertyScore from './components/AddOns/PropertyScore/PropertyScore';
import MarketInsights from './components/AddOns/MarketInsights/MarketInsights';
import ImportHotelConfig from './components/Admin/ImportHotelData/ImportHotelConfig';
import AdminDashboard from './components/Admin/AdminDashboard/AdminDashboard';
import SAPOReservations from './components/SAPO/SAPOReservations';
import SAPOBilling from './components/SAPO/SAPOBilling';
import SAPOClients from './components/SAPO/SAPOClients';
import { ApiGatewayUserManagement } from './components/Admin/APIGateway/ApiGatewayUserManagement';
import ApiGatewayUserDetails from './components/Admin/APIGateway/ApiGatewayUserDetails';
import HotelList from './components/Admin/HotelData/HotelList';
import DBCoherence from './components/Admin/HealthCheck/DBCoherence';
import HotelDataUpdates from './components/Admin/HealthCheck/HotelDataUpdates';
import CancelationPolicy from './components/HotelSetting/CancelationPolicy/CancelationPolicy';
import DataClean from './components/Admin/Maintenance/DataClean';
import HotelFullPush from './components/Admin/Maintenance/HotelFullPush';
import HotelGroup from './components/Admin/HotelData/HotelGroup';
import AdminUserManagement from './components/Admin/AdminUserManagement';
import UpgradePage from './components/UpgradePage';
import ErrorEvents from './components/Admin/HealthCheck/ErrorEvents';
import UpgradeRequests from './components/Admin/HotelData/UpgradeRequests';
import AutomatedActions from './components/Admin/HealthCheck/AutomatedActions';
import RNTProperties from './components/Admin/RNTProperties/RNTProperties';
import PropertyDetails from './components/Admin/RNTProperties/PropertyDetails';
import ClientReservationsSummary from './components/Admin/Analytics/ClientReservationsSummary';
import ReservationByNationality from './components/Admin/Analytics/ReservationByNationality';
import ReservationGlobalData from './components/Admin/Analytics/ReservationGlobalData'; 
import AdminUserEmailReport from './components/Admin/AdminUserEmailReport'; 
import RecordsByEntityType from './components/Admin/HealthCheck/RecordsByEntityType'; 
import { ManualReservation } from './components/Reservations/ManualReservation';
import NewChannelPassword from './components/Admin/ChangePassword/NewChannelPassword';
import DeliveryTime from './components/Admin/HealthCheck/DeliveryTime';
import PaymentGateway from './components/Payment/PaymentGateway';
import ManagePayments from './components/Admin/Payments/ManagePayments';
import Payments from './components/Payment/Payments';
import Offers from './components/HotelSetting/Offers/Offers'; 
import OfferDetails from './components/HotelSetting/Offers/OfferDetails';
import PromoCodes from './components/HotelSetting/PromoCodes/PromoCodes'; 
import PromoCodeDetails from './components/HotelSetting/PromoCodes/PromoCodeDetails';
import BEAnalitics from './components/Booking Engine/BEAnalitics';
import BEConfigDashboard from './components/Booking Engine/BEConfigDashboard/BEConfigDashboard';
import ConfirmationLetters from './components/Booking Engine/ConfirmationLetter/ConfirmationLetters';
import PMSHistoryAdmin from './components/Admin/HealthCheck/PMSHistory/PMSHistoryAdmin';
import PMSHistory from './components/Channel/PMSHistory/PMSHistory';
import ComparePMSData from './components/Channel/ComparePmsData/ComparePmsData';
import ApiGatewayUsage from './components/Admin/HealthCheck/ApiGatewayUsage';
import PMSLogs from './components/Channel/PMSLogs/PMSLogs'; 
import PMSQueues from './components/Admin/HealthCheck/PMSQueues/PMSQueues';
import CheckMappings from './components/Admin/HealthCheck/CheckMappings/CheckMappings';
import BulkUpdatePrices from './components/Inventory/BulkUpdatePrices';
import { BulkUpdateRestrictions } from './components/Inventory/BulkUpdateRestrictions';
import BulkUpdateAvailability from './components/Inventory/BulkUpdateAvailability';
import Google from './components/Admin/MetaSearch/Google/Google';
import Tripadvisor from './components/Admin/MetaSearch/Tripadvisor';
import ResetCache from './components/Admin/Cache/ResetCache';
import HeyTravelAI from './components/AddOns/HeyTravelAI/HeyTravelAI';
import './css/custom.css';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/es-us';
import 'moment/locale/pt';

//import { Planning } from './components/Hstays/Planning/Planning';

import 'react-dates/lib/css/_datepicker.css';
import './css/react_dates_overrides.css';
import 'react-notifications/lib/notifications.css';

//import { adalApiFetch } from './Utils/AdalConfig';
import Authorization, { CheckAuthorization } from './components/Base/Authorization';

import HotelBulkLoad from './components/Admin/HotelData/HotelBulkLoad';

import CompareAvailability from './components/Channel/CompareAvailability/CompareAvailability';
import CreateEmail from './components/Admin/Config/Email/CreateEmail';
import EmailCreation from './components/Admin/Config/Email/EmailCreation';
import ManageBeData from './components/Admin/ManageBeData/ManageBeData';
import RunningProcesses from './components/Admin/Maintenance/RunningProcesses';
import MicroInventoryUpdate from './components/Inventory/MicroInventoryUpdate/MicroInventoryUpdate';
import CityTaxConfig from './components/Admin/Config/CityTax/CityTaxConfig';
import GoogleAnalytics from './components/Base/Common/GoogleAnalytics';
import { NotificationContainer } from 'react-notifications';
import { ConfigCategoriesAndAvailability } from './components/Admin/HotelData/ConfigCategoriesAndAvailability/ConfigCategoriesAndAvailability';
import { getOperationModes, getPmsTypes, getRmsTypes } from './components/Base/Common/ReferenceDataFunctions';
import { BookingConnections } from './components/Admin/Config/BookingConnections/BookingConnections';
import PlatformDeepDive from './components/Admin/HealthCheck/PlatformDeepDive/PlatformDeepDive';
import EmailReservationDashboard from './components/EmailReservations/EmailReservationDashboard';
import { ChangeOperationMode } from './components/Admin/HotelData/ChangeOperationMode/ChangeOperationMode';
import CurrencyConversion from './components/HotelSetting/Currency/CurrencyConversion';
import { CheckPasswords } from './components/Admin/Maintenance/CheckPasswords';
import MailExtractorTemplates from './components/HotelSetting/MailExtractor/MailExtractorTemplates';
import MailExtractorDetails from './components/HotelSetting/MailExtractor/MailExtractorDetails';

export default class App extends Component<{}> {
    static displayName = App.name;

    state = {
        apiResponse: '',
    };


    render() {       
        const { hasBEModule, hasERModule } = this.props;


        return (
            <div>
                {CheckAuthorization("bookingEngineOnly:view") ?
                    //Booking Engine Only apenas tem acesso ao menu BE 
                    //E � redirecionado para a p�gina BEConfigDashboard como homepage
                    hasBEModule &&
                    <>
                        <Route exact path='/' component={BEConfigDashboard} />
                    </>
                :
                    <>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/Inventory' component={Inventory} />
                        <Route exact path='/MicroInventoryUpdate' component={MicroInventoryUpdate} />

                        {global.operationMode !== 'PmsFull' || global.modules?.some(m => m === 'PriceRules' || m === 'ComplexPriceRules') ?
                            <Route exact path='/BulkUpdatePrices' component={BulkUpdatePrices} />
                            : ''}

                        <Authorization
                            perform="bulkUpdate:PricesAndRestrictions"
                            yes={() => (
                                <div>
                                    <Route exact path='/BulkUpdateRestrictions' component={BulkUpdateRestrictions} />
                                    <Route exact path='/BulkUpdateAvailability' component={BulkUpdateAvailability} />
                                </div>
                            )}
                            no={() => <div></div>}
                        />

                        <Route exact path='/RoomRateConfig' component={RoomRateConfig} />
                        <Route exact path='/AnalyticsDashboard' component={AnalyticsDashboard} />
                        <Route exact path='/PropertySetup' component={PropertySetup} />
                        <Route exact path='/OnlineRatesDashboard' component={OnlineRatesDashboard} />

                        <Authorization
                            perform="cancellationPolicy:hasHeyBe"
                            yes={() => (
                                <Route exact path='/CancellationPolicy' component={CancelationPolicy} />
                            )}
                            no={() => <div></div>}
                        />

                        <Route exact path='/RoomSetup' component={RoomSetup} />
                        <Route exact path='/Profile' component={Profile} />
                        <Route exact path='/ReservationDashboard' component={ReservationDashboard} />
                        <Route exact path='/PackageList' component={PackageList} />
                        <Route exact path='/ChannelDashboard' component={ChannelDashboard} />
                        <Route exact path='/OportunityDashboard' component={OportunityDashboard} />
                        <Route exact path='/PromotionDashboard' component={Promotions} />
                        <Route exact path='/LogsDashboard' component={LogsDashboard} />
                        <Route exact path='/ReservationList' component={ReservationList} />
                        <Route exact path='/Security' component={Security} />
                        <Route exact path='/RoomCategorySetup' component={RoomCategorySetup} />
                        <Route exact path='/MailDashboard' component={MailDashboard} />
                        <Route exact path='/ComplexList' component={ComplexList} />
                        <Route exact path='/ManageUser' component={ManageUser} />
                        <Route exact path='/PropertyScore' component={PropertyScore} />

                        <Route exact path='/UpgradePage' component={UpgradePage} />

                        <Route exact path='/CompareAvailability' component={CompareAvailability} />

                        <Route exact path='/PMSHistory' component={PMSHistory} />

                        <Authorization
                            perform="comparepmsdata: view"
                            yes={() => (
                                <Route exact path='/ComparePMSData' component={ComparePMSData} />
                            )}
                            no={() => ''}
                        />

                        <Route exact path='/MarketInsights' component={MarketInsights} />
                        <Authorization
                            perform="heyTravelAI:view"
                            yes={() => (
                                <Route exact path='/HeyTravelAI' component={HeyTravelAI} />
                            )}
                            no={() => ''}
                        />

                        <Authorization
                    perform="sapo:reservations"
                    yes={() => (
                        <Route exact path='/SAPOReservations' component={SAPOReservations} />
                    )}
                    no={() => <div></div>}
                    />

                    <Authorization
                        perform="sapo:billing"
                        yes={() => (
                            <Route exact path='/SAPOBilling' component={SAPOBilling} />
                        )}
                        no={() => <div></div>} 
                    />
                    <Authorization
                        perform="sapo:clients"
                        yes={() => (
                            <Route exact path='/SAPOClients' component={SAPOClients} />
                        )}
                        no={() => <div></div>}
                    />


                        {/*<Authorization
                        perform="PriceListManagment:view"
                        yes={() => (

                            <Route exact path='/PriceListManagement' component={PriceListManagement} />

                        )}
                        no={() => <div></div>}
                    />*/}



                        {/* <Route exact path='/Planning' component={Planning}/>*/}


                        <Authorization
                            perform="configuration:accouting:view"
                            yes={() => (
                                <Route exact path='/Accounting' component={SapoAccounting} />
                            )}
                            no={() => <div></div>}
                        />

                        <Authorization
                            perform="admin:view"
                            yes={() => (
                                <>
                                    <Route exact path='/RNTProperties' component={RNTProperties} />
                                    <Route exact path='/PropertyDetails/:id?' component={PropertyDetails} />
                                    <Route exact path='/NewChannelPassword' component={NewChannelPassword} />
                                    <Route exact path='/DeliveryTime' component={DeliveryTime} />
                                    <Route exact path='/ManagePayments' component={ManagePayments} />
                                    <Route exact path='/PMSHistoryAdmin' component={PMSHistoryAdmin} />
                                    <Route exact path='/ApiGatewayUsage' component={ApiGatewayUsage} />
                                    <Route exact path='/PMSQueues' component={PMSQueues} />
                                    <Route exact path='/CheckMappings' component={CheckMappings} />
                                    <Route exact path='/Google' component={Google} />
                                    <Route exact path='/Tripadvisor' component={Tripadvisor} />
                                    <Route exact path='/ResetCache' component={ResetCache} />
                                    <Route exact path='/RunningProcesses' component={RunningProcesses} />
                                    <Route exact path='/AutomatedActions' component={AutomatedActions} />
                                    <Route exact path='/ErrorEvents' component={ErrorEvents} />
                                    <Route exact path='/AdminDashboard' component={AdminDashboard} />
                                    <Route exact path='/HotelGroup' component={HotelGroup} />
                                    <Route exact path='/HotelList' component={HotelList} />
                                    <Route exact path='/HotelLicenses' component={HotelLicenses} />
                                    <Route exact path='/HotelChannelInfo' component={HotelChannelInfo} />
                                    <Route exact path='/SyncEvents' component={SyncEvents} />
                                    <Route exact path='/SystemEvents' component={SystemEvents} />
                                    <Route exact path='/ConfigLicenses' component={ConfigLicenses} />
                                    <Route exact path='/Announcement' component={Announcement} />
                                    <Route exact path='/ConfigChannelAutoActions' component={ConfigChannelAutoActions} />
                                    <Route exact path='/AdminHome' component={AdminHome} />
                                    <Route exact path='/QueueStatusDashboard' component={QueueStatusDashboard} />
                                    <Route exact path='/DirtyDataDashboard' component={DirtyDataDashboard} />
                                    <Route exact path='/ConfigCategoriesAndAvailability' component={ConfigCategoriesAndAvailability} />
                                    <Route exact path='/PlatformDeepDive' component={PlatformDeepDive} />
                                    <Route exact path='/ChangeOperationMode' component={ChangeOperationMode} />
                                    <Route exact path='/MailExtractorTemplates' component={MailExtractorTemplates} />
                                    <Route exact path='/MailExtractorDetails/:id?' component={MailExtractorDetails} />
                                </>
                            )}
                            no={() => <div></div>}
                        />

                        <Authorization
                            perform="upgradeRequests:view"
                            yes={() => (
                                <Route exact path='/UpgradeRequests' component={UpgradeRequests} />
                            )}
                            no={() => <div></div>}
                        />

                        <Authorization
                            perform="admin:config"
                            yes={() => (
                                <div>
                                    <Route exact path='/ManageScheduleTask' component={ManageScheduleTask} />
                                    <Route exact path='/OTAManagement' component={OTAManagement} />
                                    <Route exact path='/InvalidReservationDashboard' component={InvalidReservationDashboard} />
                                    <Route exact path='/ReservationTimerDashboard' component={ReservationTimerDashboard} />
                                    <Route exact path='/ReservationStatusDashboard' component={ReservationStatusDashboard} />
                                    <Route exact path='/ImportHotelConfig' component={ImportHotelConfig} />
                                    <Route exact path='/DBCoherence' component={DBCoherence} />
                                    <Route exact path='/HotelDataUpdates' component={HotelDataUpdates} />
                                    <Route exact path='/CityTaxConfig' component={CityTaxConfig} />
                                    <Route exact path='/EmailCreation/:id/:duplicate?' component={EmailCreation} />
                                    <Route exact path='/CreateEmail' component={CreateEmail} />
                                    <Route exact path='/Notification' component={Notification} />
                                    <Route exact path='/HotelBulkLoad' component={HotelBulkLoad} />
                                    <Route exact path='/ManageChannelInstance' component={ManageChannelInstance} />
                                    <Route exact path='/ManageChannelConfig' component={ManageChannelConfig} />
                                    <Route exact path='/ManageBeData' component={ManageBeData} />
                                    <Route exact path='/BookingConnections' component={BookingConnections} />
                                </div>
                            )}
                            no={() => <div></div>}
                        />

                        <Authorization
                            perform="admin:maintenance"
                            yes={() => (
                                <div>
                                    <Route exact path='/DataClean' component={DataClean} />
                                    <Route exact path='/HotelFullPush' component={HotelFullPush} />
                                </div>
                            )}
                            no={() => <div></div>}
                        />

                        <Authorization
                            perform="apiGatewayUser:view"
                            yes={() => (
                                <>
                                    <Route exact path='/ApiGatewayUserManagement' component={ApiGatewayUserManagement} />
                                    <Route exact path='/ApiGatewayUserDetails/:id?' component={ApiGatewayUserDetails} />
                                </>
                            )}
                            no={() => <div></div>}
                        />

                        <Authorization
                            perform="AdminUserManagement:view"
                            yes={() => (
                                <Route exact path='/AdminUserManagement' component={AdminUserManagement} />
                            )}
                            no={() => <div></div>}
                        />

                        <Authorization
                            perform="analyticsAdmin:view"
                            yes={() => (
                                <>
                                    <Route exact path='/ClientReservationsSummary' component={ClientReservationsSummary} />
                                    <Route exact path='/ReservationByNationality' component={ReservationByNationality} />
                                    <Route exact path='/ReservationGlobalData' component={ReservationGlobalData} />
                                </>
                            )}
                            no={() => <div></div>}
                        />

                        <Authorization
                            perform="adminUserEmailReport:view"
                            yes={() => (
                                <Route exact path='/AdminUserEmailReport' component={AdminUserEmailReport} />
                            )}
                            no={() => <div></div>}
                        />


                        <Authorization
                            perform="recordsByEntityName:view"
                            yes={() => (
                                <>
                                    <Route exact path='/RecordsByEntityType' component={RecordsByEntityType} />
                                </>
                            )}
                            no={() => <div></div>}
                        />


                        <Authorization
                            perform="paymentGateway:view"
                            yes={() => (
                                <Route exact path='/PaymentGateway' component={PaymentGateway} />
                            )}
                            no={() => <div></div>}
                        />

                        {global.modules && global.modules.some(m => m === 'Payments') ?
                            <Authorization
                                perform="paymentGateway:view"
                                yes={() => (
                                    <Route exact path='/Payments' component={Payments} />
                                )}
                                no={() => <div></div>}
                            />
                            : ''}


                        <Authorization
                            perform="manualReservations:view"
                            yes={() => (
                                global.modules && global.modules.some(m => m === 'ManualReservations') ?
                                    <Route exact path='/ManualReservation/:id?' component={ManualReservation} />
                                    : ''
                            )}
                            no={() => <div></div>}
                        />


                        {global.modules && global.modules.some(m => m === 'PriceRules') ?
                            <Authorization
                                perform="yieldRules:view"
                                yes={() => (
                                    <Route exact path='/RuleConfig' component={RuleConfig} />
                                )}
                                no={() => <div></div>}
                            />
                            : ''}


                        {hasBEModule ?
                            <>
                                <Authorization
                                    perform="offers:view"
                                    yes={() => (
                                        <>
                                            <Route exact path='/Offers' component={Offers} />
                                            <Route exact path='/OfferDetails/:id?' component={OfferDetails} />
                                        </>
                                    )}
                                    no={() => <div></div>}
                                />

                                <Route exact path='/PromoCodes' component={PromoCodes} />
                                <Route exact path='/PromoCodeDetails/:id?' component={PromoCodeDetails} />
                            </>
                            : ''}

                        {global.PMSType === getPmsTypes()[2].value || global.RMSType === getRmsTypes()[1].value || global.RMSType === getRmsTypes()[2].value ?
                            <Route exact path='/PMSLogs' component={PMSLogs} />
                            : ''}
                    </>
                }

                {/* Common to all users including BE Only*/}
                {hasBEModule ?
                    <>
                        <Route exact path='/BEAnalytics' component={BEAnalitics} />
                        <Route exact path='/BEConfigDashboard' component={BEConfigDashboard} />
                        <Route exact path='/ConfirmationLetters' component={ConfirmationLetters} />
                    </>
                : ''}

                {hasERModule ?
                    <Route exact path='/EmailReservationDashboard' component={EmailReservationDashboard}/>
                :''}

                {
                    global.modules && global.modules.some(m => m === 'CurrencyConvertion') && global.operationMode !== getOperationModes()[0].value || global.operationMode === getOperationModes()[0].value && global.sendRoomRate ?
                        <Route exact path='/CurrencyConversion' component={CurrencyConversion} />
                        :
                        ''
                }

                <Authorization
                    perform="admin:checkPasswords"
                    yes={() => (
                        <Route exact path='/CheckPasswords' component={CheckPasswords} />
                    )}
                    no={() => <div></div>}
                />

                <GoogleAnalytics />
                <NotificationContainer />
            </div>
            
        );
    }
}
